@charset "UTF-8";

@import "../utilities/mixins";

.pagination {
    position: relative;
    display: block;
    margin: 0 auto;
    margin-top:32px;
    text-align: center;

    //BTOCSITE-9921 first, last 클래스 추가
    .prev,
    .next,
    .first,
    .last {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        background-image: url("/lg5-common/images/icons/ic-left-16.svg");
        background-repeat:no-repeat;
        background-size: 16px 16px;
        padding: 8px;
        background-position: center;
        cursor: pointer;
        border:1px solid #ddd;
        border-radius: 4px;
        
        //BTOCSITE-9921 disabled CASE_포인터값 default 처리 - S
        &.disabled {
            // S : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            background-color:#f7f7f7;
            border-color:#ddd;
            // E : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            cursor: default;
        }
        //BTOCSITE-9921 disabled CASE_포인터값 default 처리 - E
    }

    .prev,
    .next {
        margin:0 6px;
    }
    //BTOCSITE-9921 각 arrow 버튼 이미지 변경 - S
    .prev.disabled {
        background-image: url("/lg5-common/images/icons/ic-left-disable-16.svg");
    }
    .next {
        background-image: url("/lg5-common/images/icons/ic-right-16.svg");
        &.disabled {
            background-image: url("/lg5-common/images/icons/ic-right-disabled-16.svg");
        }
    }

    .first,
    .last {
        margin:0;
    }

    .first{
        background-image: url("/lg5-common/images/icons/ic-fleft-16.svg");
        &.disabled {
            background-image: url("/lg5-common/images/icons/ic-fleft-disable-16.svg");
        }
    }
    .last {
        background-image: url("/lg5-common/images/icons/ic-lright-16.svg");
        &.disabled {
            background-image: url("/lg5-common/images/icons/ic-lright-disabled-16.svg");
        }
    }
    //BTOCSITE-9921 각 arrow 버튼 이미지 변경 - E
    .page_num {
        display: inline-block;
        margin: 0 0; // BTOCSITE-9921 마진값 제거
        font-size: 0;
        vertical-align: middle;
        a,
        button,
        strong,
        span:not(.blind) { // BTOCSITE-62561 [선감지][모니터링 G] APP 단골매장에서 단골매장 보유시 텍스트 상이한 현상
            display: inline-block;
            // width: 20px;
            // margin:0 2px;
            padding:4px 15px;
            @include font-small;
            color: #666; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
            vertical-align: middle;
            cursor: pointer;
            @include mobile {
                padding:6px 10px;
            }
        }
        // S : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
        strong {
            text-decoration:underline; 
            color:#000; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지   
        }
        // E : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
        //BTOCSITE-16830	웹접근성 확인 요청_ LGE.com(렌탈케어십 신청/조회, 출장서비스 신청/조회) 접근성 이슈 검토 요청
        .on {
            font-weight: 500;
            color: #fff;
            background:$color-primary;
            border-radius: 4px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            padding:0;
            span {
                padding:0;
            }
        }
        span.dot {
            cursor:auto;
        }
    }
}