//@charset "UTF-8";
///* BTOCISTE-1967 웹하단바*/
//
//.mobile-status-bar {
//    display: none;
//    position:fixed;
//    bottom:0;
//    left:0;
//    width:100%;
//    height:70px;
//    z-index:9000;
//    background: #fff;
//    border-top:1px solid #ddd;
//    font-family: 'Noto Sans',sans-serif;
//    will-change: bottom;
//    transform: translateZ(0px);
//
//    .mobile-status-list {
//        display: flex;
//        height:100%;
//
//        > li {
//            flex:1;
//
//            > a {
//                display: block;
//                position: relative;
//                text-align: center;
//                padding:10px 10px 17px;
//
//                &:before {
//                    display: block;
//                    content:"";
//                    width:24px;
//                    height:24px;
//                    margin:0 auto;
//                    background-position: center center;
//                    background-size: 100% 100%;
//                    background-repeat: no-repeat;
//                }
//
//                span {
//                    display: block;
//                    margin-top:4px;
//                    font-size:11px;
//                    line-height:1.56;
//                    color:#000;
//                    font-weight:500;
//                    letter-spacing:-0.66px;
//                }
//            }
//
//            &.mypage > a:before {
//                background-image: url("/lg5-common/images/icons/icon-status-my.svg");
//            }
//            &.chatbot > a:before {
//                background-image: url("/lg5-common/images/icons/icon-status-chat.svg");
//            }
//            &.home > a:before {
//                background-image: url("/lg5-common/images/icons/icon-status-home.svg");
//            }
//            &.my-collection > a:before {
//                background-image: url("/lg5-common/images/icons/icon-status-coll.svg");
//            }
//            &.nav-anchor > a:before {
//                background-image: url("/lg5-common/images/icons/icon-status-menu.svg");
//            }
//        }
//    }
//}
//
//.swiper-slide {
//    .mobile-status-bar {
//        display: none;
//    }
//}
//
//
//.mdevice:not(.app) {
//    //BTOCSITE-1967 2차 추가수정 모바일 사이즈만 적용
//    @include mobile {
//        .mobile-status-bar {
//            display:block;
//        }
//    }
//}

@charset "UTF-8";
/* BTOCISTE-1967 웹하단바*/

.mobile-status-bar {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 53px;   // BTOCSITE-27076 하단탭바 메뉴 추가 및 위치 변경
    z-index: 9000;
    background: #fff;
    border-top: 1px solid #ddd; // BTOCSITE-85517 [퍼블리싱] 닷컴 Next.js 전환 1차 하단툴바 아이콘 반영
    font-family: 'Noto Sans',sans-serif;
    will-change: bottom;
    transform: translateZ(0px);
    box-sizing: content-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    .mobile-status-list {
        display: flex;
        height: 100%;

        > li {
            flex: 1;

            > a {
                display: block;
                position: relative;
                text-align: center;
                padding: 0 10px 6px; // BTOCSITE-85517 [퍼블리싱] 닷컴 Next.js 전환 1차 하단툴바 아이콘 반영

                &:before {
                    display: block;
                    content: "";
                    width: 32px;
                    height: 32px;
                    margin: 0 auto;
                    background-position: center center;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                span {
                    display: block;
                    margin-top: 0;
                    font-size: 10px; // BTOCSITE-85517 [퍼블리싱] 닷컴 Next.js 전환 1차 하단툴바 아이콘 반영
                    line-height: 15px; // BTOCSITE-85517 [퍼블리싱] 닷컴 Next.js 전환 1차 하단툴바 아이콘 반영
                    font-weight: normal;
                    letter-spacing: 0;
                    color: #222;
                }
            }
            // S : BTOCSITE-27076 하단탭바 메뉴 추가 및 위치 변경
            &.on > a span {
                font-weight:bold;
            }
            // E : BTOCSITE-27076 하단탭바 메뉴 추가 및 위치 변경
            // S : BTOCSITE-44601 [전시] 아이콘2.0 변경 건
            // S : BTOCSITE-85517 [퍼블리싱] 닷컴 Next.js 전환 1차 하단툴바 아이콘 반영
            &.mypage > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_my_32.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_my_next.svg");
            }
            &.chatbot > a:before {
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_chat_64.svg");
            }
            &.home > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_home_32.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_home_next.svg");
            }
            &.my-collection > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_product_32.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_product_next.svg");
            }
            &.nav-anchor > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_menu_32.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_menu_next.svg");
            }
            // S : BTOCSITE-27076 하단탭바 메뉴 추가 및 위치 변경
            &.recently-viewed > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_recently_32.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_recently_next.svg");
            }
            // E : BTOCSITE-44601 [전시] 아이콘2.0 변경 건
            &.recently-viewed.on > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_recently_on.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_recently_next_on.svg");
            }
            // E : BTOCSITE-27076 하단탭바 메뉴 추가 및 위치 변경
            // S : BTOCSITE-26633 모바일 하단탭바 수정 건
            &.mypage.on > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_my_on.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_my_next_on.svg");
            }
            &.chatbot.on > a:before {
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_chat_on.svg");
            }
            &.home.on > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_home_on.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_home_next_on.svg");
            }
            &.my-collection.on > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_product_on.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_product_next_on.svg");
            }
            &.nav-anchor.on > a:before {
                // background-image: url("/lg5-common/images/icons/icon_bottom_bar_menu_on.svg");
                background-image: url("/lg5-common/images/icons/icon_bottom_bar_menu_next_on.svg");
            }
            // E : BTOCSITE-85517 [퍼블리싱] 닷컴 Next.js 전환 1차 하단툴바 아이콘 반영
            // E : BTOCSITE-26633 모바일 하단탭바 수정 건
        }
    }
}

.swiper-slide {
    .mobile-status-bar {
        display: none;
    }
}


.mdevice:not(.app) {
    //BTOCSITE-1967 2차 추가수정 모바일 사이즈만 적용
    @include mobile {
        .mobile-status-bar {
            display: block;
        }
    }
}


// S : BTOCSITE-48265 [전시] B2B OBS 구축
.mobile-status-bar.edm-status {
    .mobile-status-list {
        > li {
            &.on {
                &.nav-anchor {
                    > a:before {
                        background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_menu_on_24.svg);
                    }
                }
                &.home {
                    > a:before {
                        background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_home_on_24.svg);
                    }
                }
                &.mypage {
                    > a:before {
                        background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_my_on_24.svg);
                    }
                }
                &.price {
                    > a:before {
                        background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_price_on_24.svg);
                    }
                }
            }
            &.nav-anchor {
                > a:before {
                    background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_menu_off_24.svg);
                }
            }
            &.home {
                > a:before {
                    background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_home_off_24.svg);
                }
            }
            &.mypage {
                > a:before {
                    background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_my_off_24.svg);
                }
            }
            &.price {
                > a:before {
                    background-image: url(/lg5-common/images/EDM/ic_toolbar_b2b_price_off_24.svg);
                }
            }
            > a {
                padding: 6px 10px 3px;
                &:before {
                    width: 24px;
                    height: 24px;
                }
                span {
                    margin-top: 4px;
                    font-size: 11px;
                    line-height: 16px;
                }
            }
        }
    }
}
// E : BTOCSITE-48265 [전시] B2B OBS 구축
//BTOCSITE-54725 [분석-퍼블] App [All] - 페이지(일부) 이동 시 하단 탭 바 만큼 빈 공간이 발생하는 현상
@include mobile {
    .mdevice.app{
        .mobile-nav-button.active+.header-bottom{
            padding-bottom:60px;
        }
    }
}
