.icon-new-window {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url("/lg5-common/images/icons/icon-new-window.svg");
    background-size: 100% 100%;

    @include mobile {
    }

}