//tab
// H2 base
.tabs-wrap {
    position:relative;
    display:block;
    text-align:center;
    &.left {
        text-align:left;
    }
    .tabs {
        display:inline-block;
        max-width:100%;
        position:relative;
        margin:0 -2px;
        padding:0 2px;
        font-size:0;
        vertical-align:top;
        white-space:nowrap;
        overflow-x:auto;
        overflow-y:hidden;
        -webkit-overflow-scrolling: touch;
        > li {
            display:inline-block;
            margin-left:36px;
            padding: 2px 0;
            vertical-align:top;
            @include mobile {
                margin-left:20px;
            }
            &:first-child {
                margin-left:0;
            }
            > a {
                display:block;
                position:relative;
                color:#767676;
                font-size:28px;
                line-height:40px;
                font-weight:700;
                em.count {
                    display:inline-block;
                    margin-left:4px;
                    vertical-align:top;
                }
                @include mobile {
                    font-size:18px;
                    line-height:26px;
                }
            }
            &.on > a {
                color:#000;
            }
        }
    }
    &.ui_smooth_scroll {
        .tabs {
            width: auto !important;
            max-width:initial;
            overflow-x:initial;
        }
    }
    .overflow-btn {
        position:absolute;
        top:0;
        width:20px;
        height:26px;
        background:url('/lg5-common/images/icons/btn-arr-24x24-black.svg') center no-repeat;
        background-size:20px;
        font-size:0;
        &.prev {
            left:0;
            transform:rotate(180deg);
        }
        &.next {
            right:0;
        }
        @include mobile {
            top:-8px;
            width:57px;
            height:57px;
            background:url('/lg5-common/images/icons/btn-arr-72x72.svg') center no-repeat;
            background-size:57px;
        }
    }
    @include mobile {
        text-align:left;
        .tabs {
            padding:0 16px;
        }
    }

    &.btn-type {
        text-align:center; /*2021-01-04 edit*/
        &.left {
            text-align:left;
        }
        &:before {
            position: absolute;
            bottom: 1px !important;
            right: 0;
            left: 0;
            height: 1px;
            background: #8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
            content: '';
            @include mobile {
                bottom:-1px !important;
            }
        }
        .tabs {
            display:inline-block;
            font-size:0;
            vertical-align:top;
            // overflow-x:hidden; /* 0219 원복(다른경우 새로운 클래스 상속 필요) */
            > li {
                display:inline-block;
                margin-left:32px;
                &:first-child {
                    margin-left:0;
                }
                > a {
                    display:block;
                    padding:5px 0;
                    //border-radius:25px;
                    background-color:transparent;
                    @include font-large;
                    line-height:32px;
                    font-weight:700;
                }
                &.active > a,
                &.on > a {
                    color:#000;
                    border-bottom:2px solid #222; /*2021-01-04 edit*/
                    //background-color:#e4e4e4;
                }
            }
            @include mobile {
                >li {
                    margin-left:8px;
                    &:first-child {
                        margin-left:0;
                    }
                    > a {
                        padding:8px 12px;
                        //border-radius:22px;
                        font-size:18px;
                        line-height:26px;
                    }
                }
            }
        }
    }

    // H2
    &.border-type {
        position:relative;
        &:before {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 1px;
            background: #8f8f8f;
            content: '';
        }
        //@include pc {
        //    &:not(.left) {
        //        &::before {
        //            display:none;
        //        }
        //    }
        //}
        //@include mobile {
        //    padding-top:9px;
        //}
        .tabs {
            > li {
                margin-left:34px;
                @include mobile {
                    margin-left:20px;
                }
                &:first-child {
                    margin-left:0;
                }
                > a {
                    padding-bottom:12px;
                    font-weight:700;
                    font-size: $font-large;
                    line-height: 28px;
                    @include mobile {
                        padding-bottom:10px;
                        border-width:3px;
                        font-size:($font-large - 1);
                        line-height:25px;
                    }
                }
                &.on {
                    > a:after {
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        width: 100%;
                        height: 5px;
                        border-radius: 5px 5px 0 0;
                        background-color: $color-default;
                        content: '';
                        @include mobile {
                            height: 4px;
                            //border-radius: 4px 4px 0 0;
                        }
                    }
                }
            }
        }
    }

    // H3
    &.bar-type {
        .tabs {
            @include mobile {
                padding:0 16px;
            }
            > li {
                position:relative;
                margin-left:16px;
                padding-left:17px;
                @include mobile {
                    margin-left:12px;
                    padding-left:13px;
                }
                &::before {
                    content:' ';
                    position:absolute;
                    top:50%;
                    left:0;
                    width:1px;
                    height:12px;
                    margin-top:-6px;
                    background:#d8d8d8;
                }
                &:first-child {
                    margin-left:0;
                    padding-left:0;
                    &::before {
                        display:none;
                    }
                }
                a {
                    font-size:16px;
                    line-height:26px;
                    font-weight:500;
                    color:#767676;
                }
                &.on {
                    a {
                        font-weight:700;
                        color:#000;
                        text-decoration:underline; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    }
                }
            }
        }
        
    }


    &.new-type {
        position:relative;
        text-align:left;
        //BTOCSITE-42647
        // &:before {
        //     content:' ';
        //     position:absolute;
        //     bottom:2px;
        //     left:0;
        //     z-index:1;
        //     width:100%;
        //     height:1px;
        //     background-color:#222;
        // }
        .tabs {
            display: block;
            @include mobile {
                max-width: none;
                padding:0;
                // padding: 0 2px;//BTOCSITE-42647
            }
            //BTOCSITE-42647
            &:before {
                content:' ';
                position:absolute;
                bottom:2px;
                left:0;
                z-index:1;
                width:100%;
                height:1px;
                background-color:#222;
            }
            li {
                width:206px;
                margin-left:1px;
                @include mobile {
                    margin-left:0;
                }
                &:first-child {
                    margin-left:0;
                }
                a {
                    height:56px;
                    border-radius:8px 8px 0 0;
                    // S : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    background-color:#f7f7f7;
                    color:#666;
                    // E : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    font-size:16px;
                    line-height:56px;
                    text-align:center;
                }
                &.on { 
                    & + li {
                        margin-left:0;
                    }
                    a {
                        position:relative;
                        z-index:1;
                        border:1px solid #222;
                        border-bottom:1px solid #fff;
                        background-color:#fff;
                        color:#000;
                        overflow:hidden;
                        
                    }
                }
                @include mobile {
                    width:50%;
                    a {
                        height:48px;
                        line-height:48px;
                    }
                }
            }
            li:first-child:nth-last-child(3), li:first-child:nth-last-child(3) ~ li { 
                @include mobile {
                    width:calc(100% / 3);
                } 
            }
        }
    }    
}

//BTOCSITE-26559 new tab style
.ui_sticky_wrap {
    background: #fff;
}
.tabs-wrap-new {
    position: relative;
    display: block;
    max-width: 1380px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;
    &.mt0{
        margin-top:0;
    }
    button:focus {
        outline: none !important;
    }
    @include mobile {
        margin: 0 auto;
    }
    &:before {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 1px;
        background: #8f8f8f;
        content: '';
        background: #ddd;   // BTOCSITE-47780 닷컴 이벤트&기획전 통합 - 웹접근성으로 변경 된 컬러 재 수정 (추후 재작업시 삭제해도 무방)
    }
    .tabs {
        position: relative;
        display: inline-block;
        max-width: 100%;
        padding-right: 80px;
        font-size: 0;
        vertical-align: top;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        @include mobile {
            padding: 0 90px 0 16px;
        }
        > li {
            display: inline-block;
            height: 58px;
            margin-left: 40px;
            vertical-align: top;
            @include mobile {
                height: 55px;
                margin-left: 0;
                padding: 0 16px;
            }
            &:first-child {
                margin-left: 0;
            }
            > a {
                position: relative;
                display: inline-block;
                height: 100%;
                padding-top: 16px;
                color: #666;
                font-size: $font-large;
                line-height: 1.4;
                font-weight: normal;
                @include mobile {
                    border-width: 3px;
                    font-size: ($font-large - 3);
                }
            }
            &.on {
                > a {
                    color: #000;
                    font-weight: 700;
                    &:after {
                        position: absolute;
                        left: 0;
                        bottom: 0 !important;
                        width: 100%;
                        height: 4px;
                        background-color: $color-default;
                        content: '';
                        @include mobile {
                            bottom: 0px;
                            height: 3px;
                        }
                    }
                }                
            }
        }
    }
    .btn-expanded {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 79px;
        height: 57px;
        background-image: linear-gradient(to left, #fff 62%, white 19%, rgba(255, 255, 255, 0));
        z-index: 1;
    
        .icon {
            position: absolute;
            display: block;
            top: 50%;
            right: 14px;
            transform: translate(0, -50%);
            width: 32px;
            height: 32px;
            background: url("/lg5-common/images/icons/icon_arrow_down_24.svg") no-repeat center center;
            background-size: 100%;
        }
        @include mobile {
            width: 90px;
            height:54px;
            .icon {
                right: 26px;
                width: 24px;
                height: 24px;
            }
        }
    }
    
    &.fixed-tab {
        @include mobile {
            .tabs {
                display: flex;
                margin: 0;
                padding: 0 16px;
                height:auto;
                min-height: 55px;
                >li {
                    flex: 1;
                    text-align: center;
                    margin-left: 0 !important;
                    padding: 0;
                    > a {
                        display: block;
                    }
                }
            }
        }        
    }
    &.small-type {
        @include mobile {
            .tabs {
                > li {
                    // height: 37px; BTOCSITE-26559 높이값을 다른것과 동일하게 요청
                    padding:0 12px;
                    > a {
                        // padding-top: 8px; BTOCSITE-26559 높이값을 다른것과 동일하게 요청
                        font-size: ($font-large - 4);
                    }
                }
            }
        }
    }
    &.expanded {
        .tabs{
            display: inline-block;
            font-size:0;
            white-space: normal;
            padding: 16px 100px 0 16px;
            @include mobile {
                padding: 12px 90px 0 16px;
            }
            > li {
                height: auto;
                margin-left:0;
                @include mobile {
                    // min-height: 55px;
                    // margin-left:0;
                    padding:0;
                }
                > a {
                    position:relative;
                    display:inline-block;
                    height: 40px;
                    margin: 0 8px 16px 0;
                    padding: 0 20px;
                    border-radius: 20px;
                    // S : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    background-color: #f7f7f7;  
                    border: 1px solid #f7f7f7;              
                    // E : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드    
                    color: #000;
                    font-size: $font-large;
                    line-height: 40px;
                    font-weight: normal;
                    @include mobile {
                        margin: 0 8px 10px 0;
                        padding: 0 12px;
                        height: 32px;
                        line-height: 32px;
                        font-size:($font-large - 5);
                    }                    
                }
                &.on {
                    > a {
                        color: #000;
                        font-weight: 700;
                        border-radius: 20px;
                        border: solid 1px #222;
                        background-color: #fff;
                        &:after {
                            display:none;
                        }
                    }                
                }
            }
        }        
        .btn-expanded {
            top: 0;
            background-image: none;
            .icon {
                transform: translate(0, -45%) rotate(180deg);
                @include mobile {
                    transform: translate(0, -45%) rotate(180deg);
                }
            }
        }        
    }
    &.small-type {
        .btn-expanded {
            @include mobile {
                // height: 36px; BTOCSITE-26559 높이값을 다른것과 동일하게 요청
            }
        }
        &.expanded {
            .btn-expanded {
                @include mobile {
                    // height: 54px; BTOCSITE-26559 높이값을 다른것과 동일하게 요청
                }
            }
        }
    }
    &.flexible-tab-box {
        &::before {
            display: none;
        }
        .tabs{
            display: inline-block;
            font-size:0;
            padding: 0;
            white-space:normal;
            overflow: hidden;
            @include mobile {
                white-space: nowrap;
                padding: 0;
            }
            > li {
                height: auto;
                margin: 8px 8px 0;
                @include mobile {
                    padding:0;
                    margin: 0 6px 0 0;
                }
                > a {
                    position: relative;
                    display: inline-block;
                    height: 40px;
                    padding: 0 24px;
                    border-radius: 40px;
                    background-color: #fff;
                    border: solid 1px #8f8f8f;  // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드                   
                    color:#767676;
                    font-size:($font-small);
                    line-height: 40px;
                    font-weight: normal;
                    @include mobile {
                        margin: 0 6px 0 0;
                        padding: 0 12px;
                        height: 28px;
                        margin: 0;
                        border-radius: 15px;
                        line-height: 28px;
                        font-size:($font-small - 1);
                    }                    
                }
                &.on {
                    > a {
                        color: #000;
                        font-weight:700;
                        border-radius: 20px;
                        border: solid 2px #222; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        background-color: #fff;
                        // S : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        @include pc {
                            line-height:38px;
                        }
                        @include mobile {
                            line-height:26px;
                        }
                        // E : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        &:after {
                            display:none;
                        }
                    }                
                }
            }
        }        
        .btn-expanded {
            top: 0;
            background-image: none;
            .icon {
                transform: translate(0, -45%) rotate(180deg);
            }
        }        
    }
    
    &.ui_smooth_scroll {
        .tabs {
            width: auto !important;
            max-width:initial;
            overflow-x:initial;
        }
    }
    .overflow-btn {
        position:absolute;
        top:0;
        width:20px;
        height:26px;
        background:url('/lg5-common/images/icons/btn-arr-24x24-black.svg') center no-repeat;
        background-size:20px;
        font-size:0;
        &.prev {
            left:0;
            transform:rotate(180deg);
        }
        &.next {
            right:0;
        }
        @include mobile {
            top:-8px;
            width:57px;
            height:57px;
            background:url('/lg5-common/images/icons/btn-arr-72x72.svg') center no-repeat;
            background-size:57px;
        }
    }

    &.btn-type {
        
    }   
    // BTOCSITE-65935 3차 기획전 제작 모듈화 개발_상품유닛 수정
    &:has(.list-change-cont) {
        @include mobile {
            padding-right: 48px;
        }
        
        &.expanded {
            @include mobile {
                padding-right: 0;
            }
        }
        
        .btn-expanded {
            @include mobile {
                right: 48px;
            }
            .icon {
                @include mobile {
                    right: 18px;
                }
            }
        }
        
        .list-change-cont {
            @include pc {
                display: none;
            }
            @include mobile {                        
                position: absolute;
                top: 12px;
                right: 16px;
                display: block;
            }
            width: 32px;
            height: 32px;
            &::before {
                @include mobile {
                    content: '';
                    position: absolute;
                    right: -16px;
                    display: block;
                    width: 48px;
                    height: 100%;
                    background-color: #fff;
                }
            }
            .btn-change {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                &.list-type {
                    .list-ico {
                        display: block;
                    }
                    .twin-ico {
                        display: none;
                    }
                }
                &.twin-type {
                    .list-ico {
                        display: none;
                    }
                    .twin-ico {
                        display: block;
                    }
                }
                i {
                    display: block;
                    font-size: 0;
                    width: 100%;
                    height: 100%;
                }
                .list-ico {
                    background: url('/lg5-common/images/icons/ico_unit_view_04.svg') no-repeat;
                    background-size: 100% 100%;
                }
                .twin-ico {
                    background: url('/lg5-common/images/icons/ico_unit_view_03.svg') no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }
}
.tabs-2depth {
    background: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
    .ui_stickyTab {
        max-width: 1380px;
        margin: 0 auto;
    }
    // .tabs-2depth-wrap {
    //     max-width: 1380px;
    //     text-align: left;
    // }
    @include mobile {
        border-bottom: 1px solid #ddd;
        .ui_smooth_tab {
            width: 100% !important;
            transform: none !important;
        }
    }
    .tabs {
        padding: 16px 0 7px;
        font-size: 0;
        white-space:normal;
        @include mobile {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 -1px;
            padding: 0;
        }
        li {
            display: inline-block;
            position: relative;
            padding-right: 40px;
            margin-bottom:10px;
            @include mobile {
                width: calc(100% / 3);
                padding: 0;
                margin: 0;
                text-align: center;
                border-right: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                &:not(:first-child) {
                    margin-left: 0;
                    padding-left: 0;
                }
                &:nth-child(3n) {
                    border-right: 0;
                }
            }
            &:before {
                content:' ';
                position:absolute;
                top:50%;
                right:20px;
                width:1px;
                height:12px;
                margin-top:-6px;
                background:#d8d8d8;
                @include mobile {
                    display: none;
                }
            }
            &:last-child:before {
                display: none;
            }
            &.on a {
                font-weight: 500;
                border-bottom: 1px solid #000;
                color: #000;
                @include mobile {
                    border-bottom: 0;
                }
            }        
        }
        a {
            display: inline-block;
            font-size: 16px;
            font-weight: normal;
            color: #666;
            line-height: 1.5;
            @include mobile {
                display: flex;
                position: relative;
                height: 44px;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                line-height: 1.43;
                font-weight: normal;
            }
            br {
                display: none;
                @include mobile {
                    display: block;
                }
            }
        }
    }
}
//BTOCSITE-26559 new tab style

.page-header .tabs-bg {
    max-width: 100%;
    background-color: $color-bg;
    @include mobile {
        background: #fff;
    }
    .tabs-wrap.bar-type {
        position: relative; 
        max-width: calc(1146px + 80px);
        padding: 0 40px;
        background: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
        &.w-btn {
            padding: 0 190px 0 40px;
        }
        @include mobile {
            padding: 0;
        }
        .tabs {
            padding: 16px;
            @include mobile {
                padding: 16px;
            }
        }
    }
    
    .btn-area {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        margin-top: 4px;
        @include mobile {
            position: static;
            transform: translateY(0);
            margin-top: 0;
            padding: 25px 16px 0;
            background-color: #fff;
        }
        .btn {
            background-color: transparent;
            @include mobile {
                background-color: #fff;
            }
        }
    }
}


.contents {
    > .tabs-wrap {
        .tabs {
            padding:0 40px;
            @include mobile {
                padding:0 16px;
            }
        }
    }
}

.tab-panel {
    padding: 24px;
    @include mobile {
        padding:24px 12px;
    }
}

.scroll-wrap {
    position: relative;
    .tabs-wrap {
        .tabs {
            max-width:initial;
            overflow:unset; 
        }
    }
    .scroll-controls {
        .btn-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            &.prev {
                left: 4px;
            }
            &.next {
                right: 4px;
            }
        }
    }
    .tabs-wrap.btn-type + .scroll-controls {
        .btn-arrow {
            margin-top: 4px;
        }
    }
    &.border-type {
        .tabs {
            margin: 0 auto;
            padding: 0 16px;
            font-size: 0;
            white-space:nowrap;
            vertical-align: top;
            > li {
                display: inline-block;
                margin-left: 32px;
                white-space: nowrap;
                vertical-align: top;
                &:first-child {
                    margin-left: 0;
                }
                > a {
                    display: block;
                    position: relative;
                    padding-bottom: 2px;
                    border-bottom: 2px solid transparent;
                    font-weight: 700;
                    @include font-large;
                    color: #767676;
                }
                &.on > a {
                    border-color: #222;
                    color: #000;
                }

                @include mobile {
                    margin-left: 24px;
                    &:first-child {
                        margin-left: 0;
                    }
                    > a {
                        border-width: 3px;
                    }
                }
            }
        }
    }
}

/*2021-01-04*/
.tabs-wrap-description{
    margin-bottom:24px;
    p{
        @include font-medium;
        color:#000;
    }
}