.icon-notice {
    display:inline-block;
    width:20px;
    height:20px;
    vertical-align:middle;;
    background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='20' height='20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23bbb' cx='10' cy='10' r='10' /%3E%3Cpath fill='%23f4f4f4' fill-rule='nonzero' d='M 9 10 v 4 c 0 0.552 0.448 1 1 1 s 1 -0.448 1 -1 v -4 c 0 -0.552 -0.448 -1 -1 -1 s -1 0.448 -1 1 Z m 1.005 -4 C 9.437 6 9 6.437 9 7 c 0 0.562 0.437 1 1.005 1 c 0.569 0 0.995 -0.438 0.995 -1 c 0 -0.563 -0.426 -1 -0.995 -1 Z' /%3E%3C/g%3E%3C/svg%3E");
    background-repeat:no-repeat;
    background-size:100% 100%;
    @include mobile {
        width:16px;
        height:16px;
    }
}