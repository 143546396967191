.lay-filter {
    float:left;
    width:215px;
    margin-right: 25px;
    margin-top: 36px;

    @at-root .search & {
        @media screen and (min-width: 1025px){
            margin-top: 46px;
            .filter-head {
                display: none;
            }
        }
        &.search-all {
            display: none;
        }
    }

    @media screen and (max-width: 1024px) {
        display:none;
        position:fixed;
        top:0;
        //left:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;
        margin:0;
        backface-visibility:hidden;
        transform:translateZ(0);
        z-index:9000;
        //background:#fff;
        background: transparent;
        overflow: hidden;
        &.open{
            display:block;
        }

        @at-root .search .lay-filter {
            position: fixed;
            right: 0;
            left: auto;
            transform: translateX(0);
            background-color: transparent;
            &:before {
                position: fixed;
                background-color: rgba($color-default,.3);
                opacity: 0;
                transition: opacity .3s;
                content: '';
            }
            &.open {
                &:before {
                    opacity: 1;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 10;
                }
                /* BTOCSITE-2161 */
                @media screen and (max-width: 1024px) {
                    &:before {
                        display:none;
                    }
                }
                /* //BTOCSITE-2161 */
                .lay-fliter-con,
                .lay-filter-con {
                    @media screen and (max-width: 1024px) {
                        position: absolute;
                        right: 0;
                        //width:calc(100% - 20px);
                        width:260px;
                    }
                }
                .filter-head,
                .filter-btn-wrap {
                    right: 0;
                    left: auto;
                    //width:calc(100% - 20px);
                    width:260px;
                }
            }
        }
    }

    .lay-fliter-con,
    .lay-filter-con {
        @media screen and (max-width: 1024px) {
            position:absolute;
            top:0;
            right:-100%;
            transition: right .5s;
            //width:100%;
            width:260px;
            height:100%;
            overflow-y:auto;
            z-index:9000;
            background-color: #fff;
        }
    }

    &.open {
        .lay-fliter-con,
        .lay-filter-con {
            @media screen and (max-width: 1024px) {
                position: absolute;
                right: 0;
            }
        }
    }
    .fiter-head,
    .filter-head {
        //position:relative;
        width:100%;
        background-color: #fff;
        //text-align:center;
        .tit {
            padding-bottom: 12px;
            font-weight:700;
            @include font(14px,24px);
        }
        @include tablet {
            position: fixed;
            z-index:20;
            width:260px;    /* BTOCSITE-2161 modify */
            //height:48px;  /* BTOCSITE-2161 */
            padding: 48px 96px 0 16px;
            &::after {
                display: inline-block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 16px;
                width: calc(100% - 32px);
                height: 2px;
                background-color: #222;
            }
            .tit {
                //padding-top:12px; /* BTOCSITE-2161 */
                padding-bottom: 16px;  /* BTOCSITE-2161 add */
                font-size: 16px;
            }
        }
        //@media screen and (min-width: 1024px) {
        //    display:none;
        //}
    }

    .plp-filter-wrap {

        @media screen and (max-width: 1024px) {
            //padding:40px 20px 115px;
            //padding:48px 16px 135px;
            padding:88px 16px 48px;
        }

        .btn-reset {
            margin:20px 0 12px;
            button {
                color:$color-default;
                @include font-small;
                span {
                    position:relative;
                    padding-right:24px;
                    &:after {
                        content:'';
                        display:block;
                        position:absolute;
                        top:50%;
                        right:0;
                        width:20px;
                        height:20px;
                        margin-top:-10px;
                        background:url('/lg5-common/images/icons/icon-reset-20.svg') no-repeat center center;
                    }
                }
            }
            @media screen and (max-width: 1024px) {
                //display:none;
                position: fixed;
                top: 46px;  /* BTOCSITE-2161 modify*/
                right: 52px;
                width: 32px;
                height: 32px;
                margin: 0 !important;
                z-index: 100;
                button {
                    width: 32px;
                    height: 32px;
                    span {
                        display: inline-block;
                        text-indent: -99999px;
                        padding: 0;
                        &:after {
                            top: 50%;
                            left: 50%;
                            right: auto;
                            transform: translate(-50%, -50%);
                            width: 32px;
                            height: 32px;
                            padding: 4px;
                            margin-top: 0;
                            background:url('/lg5-common/images/icons/icon-reset-48.svg') no-repeat center center;
                            background-size: 24px 24px;
                        }
                    }
                }
            }
        }
        .list-acco-sorting{
            @media screen and (max-width: 1024px) {
                display:block;
            }
            @media screen and (min-width: 1024px) {
                display:none;
            }
        }

        .list-acco {
            ul {
                border-top: 1px solid $color-border;
                @media screen and (max-width: 1024px) {
                    border-top: none;
                }
                li {
                    background-color:#fff;
                    border-bottom: 1px solid $color-border;
                    //overflow:hidden;
                    @media screen and (max-width: 1024px) {
                        margin:0;
                        border-radius:inherit;

                        &:last-child {
                            border-bottom:0;
                        }
                    }

                    .head {
                        position:relative;
                        /*
                        @media screen and (max-width: 1024px) {
                            border-bottom:1px solid #ededed;
                        }*/
                        .link-acco {
                            display:block;
                            padding:0 45px 0 0;
                            @media screen and (max-width: 1024px) {
                                display:block;
                                padding:0 45px 0 0;
                            }
                            &:before {
                                content:'';
                                display:block;
                                position:absolute;
                                top:28px;
                                right:0;
                                width:16px;
                                height:16px;
                                background:url('/lg5-common/images/icons/btn-down-16-black.svg') no-repeat;
                                background-size: cover;
                                transition: all .3s;
                                @media screen and (max-width: 1024px) {
                                    //top:30px;
                                    top:34px; /* BTOCSITE-2161 */
                                    right:0;
                                }
                            }
                        }

                        .tit {
                            display:block;
                            padding:24px 0;
                            color:#000;
                            @include font-small;
                            font-weight:$font-weight-bold;
                            word-break:break-all;
                            span {
                                word-break:break-all;
                            }
                            @media screen and (max-width: 1024px) {
                                //padding:32px 0 24px;
                                padding:32px 0 26px; /* BTOCSITE-2161 */
                                font-size: 16px; /* BTOCSITE-2161 add */
                            }
                        }
                    }
                    &.on .head a:before {
                        transform: rotate(180deg);
                    }
                    .desc {
                        display:none;
                        padding:0 1px 32px 1px;
                        outline-color: #fff;
                        @media screen and (max-width: 1024px) {
                            padding:0 0 32px 0;
                            //padding:20px 8px 32px;
                            //border-top:1px solid #ededed;
                        }
                        .anchor-txt2  {  // BTOCSITE-19776 필터 내 용어사전 추가
                            .slide-btn {
                                p {
                                    display:inline-block; 
                                    font-size:13px;   
                                    position: relative;    
                                    line-height: 18px; 
                                    font-weight: 500; 
                                    margin-bottom:16px;
                                    &::before {
                                        content:"";
                                        display:inline-block;
                                        background-color:#e81953;
                                        width:100%;
                                        height:10px;
                                        position:absolute;
                                        bottom:0;
                                        left:0;
                                        opacity:0.2;
                                    }
                                }
                                .tooltip-wrap {
                                    margin:1.6px 0 0 -2px;
                                }
                                span {
                                    width:18px;
                                    height:18px;
                                }
                            }
                        } // BTOCSITE-19776 필터 내 용어사전 추가
                    }
                    .cont {
                        position:relative;
                        word-break:break-all;
                        p {
                            word-break:break-all;
                            @include font-small;
                        }

                        .range-wrap {
                            width: 100%;
                            padding: 0 2px;
                            font-size:0;
                            /* BTOCSITE-2161 add */
                            @include mobile {
                                padding-top: 4px;
                            }
                            /* // BTOCSITE-2161 */
                            .ui-range-slider {
                                height: 4px;
                                margin-bottom: 10px;
                                //background-color: #fff;
                                background-color: #ddd;
                                .ui-range-slider-bg {
                                    height: 4px;
                                    background-color: #ddd;
                                    border-radius: 2px;
                                }
                                .ui-range-slider-range {
                                    height: 4px;
                                    border-radius: 2px;
                                }
                            }
                            .range-num {
                                display:inline-block;
                                width:50%;
                                vertical-align:middle;
                                text-align:left;
                                color:#000;
                                font-size:12px;
                                line-height:22px;
                                &.max {
                                    text-align:right;
                                }
                            }
                        }

                        .rdo-wrap,
                        .chk-wrap,
                        .chk-wrap-colorchip {
                            display:block;
                            margin-top:16px;
                            &:first-child {
                                margin-top:0;
                            }
                            & input + label {
                                word-break:break-all;
                                @include font-small;
                            }
                        }
                        //.rdo-wrap input:checked + label {
                        //    &:before {
                        //        background-image: url("/lg5-common/images/bg-input-checked-gray.svg");
                        //    }
                        //    &:after {
                        //        background: $color-gray-1;
                        //    }
                        //}

                        .chk-wrap-colorchip {
                            & input:disabled + label {
                                color:#adadad;
                                &:before {
                                    background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                }
                            }
                        }
                    }
                     // BTOCSITE-19776 필터 내 용어사전 추가
                }
            }

        }

        .service-link {
            margin-top:30px;
            text-align:center;
            li {
                margin-top: 8px;
                &:first-child {
                    margin-top: 0;
                }
            }
            .btn-text {
               display: flex;
               align-items: center;
               justify-content: space-between;
               width: 100%;
               min-height: 72px;
               padding: 24px 16px 24px 20px;
               background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
               border-radius: 8px;
               @include font-small;
               font-weight: 700;
               span {
                   padding-right: 12px;
                   text-align: left;
               }
               img {
                   width: 40px;
                   height: 40px;
               }
            }
            @media screen and (max-width: 1024px) {
                display:none;
            }
        }

        .service-link-box {
            ul {
                margin-top: 16px;
                li {
                    margin-bottom: 8px;
                    .btn-gray-box {
                        display: inline-flex;
                        align-items: center;
                        width: 100%;
                        min-height: 80px;
                        padding: 20px;
                        border-radius: 8px;
                        background: #aeaeae;
                        justify-content: center;
                        vertical-align: middle;
                        span {
                            display: inline-block;
                            position: relative;
                            padding-right: 24px;
                            @include font-medium;
                            color: $color-bg-wt;
                            &::after {
                                display: inline-block;
                                content: '';
                                position: absolute;
                                top: 4px;
                                right: 0;
                                width: 20px;
                                height: 20px;
                                background: url('/lg5-common/images/icons/btn-arrow-right-white-20.svg') no-repeat;

                            }

                        }
                    }
                }

            }
        }
        .banner-box {
            margin-top: 16px;
            .inner {
                a {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    min-height: 80px;
                    padding: 20px;
                    border-radius: 8px;
                    background: #aeaeae;
                    justify-content: center;
                    vertical-align: middle;
                    span {
                        display: inline-block;
                        position: relative;
                        padding-right: 24px;
                        @include font-medium;
                        color: $color-bg-wt;
                    }
                }
            }
        }
        .banner-box + .banner-box {
            margin-top: 8px;
        }

        .filter-btn-wrap {
            display:none;
            position:fixed;
            left:0;
            bottom:0;
            width:100%;
            padding:0;
            text-align:center;
            font-size:0;
            z-index:1;
            .btn {
                display:inline-block;
                //width:50% !important;
                vertical-align:middle;
                height:59px;
                width:100%;
                color:#fff;
                font-size:16px;
                font-weight:500;
                background-color: #EC455A;
                border-radius:0;
                border:none;
                @include mobile {
                    width:100%;
                    height:56px;
                    border-radius:0;
                }
                /*
                &:first-child {
                    background:#ededed;
                    color:#EC455A;
                }*/
            }
            @media screen and (min-width: 1025px) {
                display:none;
            }
        }

        .filter-close {
            position:fixed;
            top:46px; /* BTOCSITE-2161 modify*/
            right:12px;
            width:32px;
            height:32px;
            z-index:20;
            @media screen and (min-width: 1025px) {
                display:none;
            }
            .btn-close {
                position:relative;
                width:32px;
                height:32px;
                &:after {
                    content: '';
                    position:absolute;
                    top:0;
                    left:0;
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                    background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center;
                    background-size: 24px 24px;
                }
                /*
                &:before {
                    content: '';
                    position:absolute;
                    top:0;
                    left:0;
                    margin:3.25px 0 0 11.25px;
                    height: 18px;
                    border:solid #222;
                    border-width: 0 1.5px 0px 0;
                    transform: rotate(45deg);
                }
                &:after {
                    content: '';
                    position:absolute;
                    top:0;
                    left:0;
                    height: 18px;
                    margin:3.25px 0 0 11.25px;
                    border:solid #222;
                    border-width: 0 1.5px 0px 0;
                    transform: rotate(-45deg);
                }*/
            }
        }
    }
}

.btn-filter {
    //float: left;
    position: absolute;
    top: 0;
    //left: 16px;
    z-index: 2;
    &:before {
        content:' ';
        position:absolute;
        top: 0;
        bottom: 0;
        left:48px;
        z-index:0;
        width:15px;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
        background-image: -webkit-linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
    }
    @include tablet {
        left: 40px;
    }
    @include mobile {
        top: 0;
        left: 0;
    }
    .ico-filter {
        display: inline-block;
        position:relative;
        z-index:1;
        width: 48px;
        height: 100%;
        margin-right: 24px;
        background: #fff url("/lg5-common/images/icons/btn-filter-64.svg") no-repeat left center;
        background-size: 32px;
        @include mobile {
            //top:3px;
            margin-right: 15px;
            background-position:  left 16px center;
        }
    }
    @media screen and (min-width: 1025px) {
        display: none !important;
    }
    &.applied {
        .ico-filter {
            background-image: url("/lg5-common/images/icons/btn-filter-64-applied.svg");
        //position: relative;
            //&:after {
            //    position: absolute;
            //    top: -7px;
            //    right: 0;
            //    width: 5px;
            //    height: 5px;
            //    border-radius: 50%;
            //    background-color: #ec455a;
            //    content: '';
            //}
        }
    }
}

.cate-m {
    @include tablet {
        position: relative;
        min-height: 76px;
        border-bottom: 1px solid #ddd;
        // BTOCSITE-2847 PLP > 상세필터 동작오류 : [QA]모니터링항목 : 59번
        z-index: 0;
        @include mobile {
            min-height: 65px;
        }
        .btn-filter {
            bottom: 0;
        }
    }
    //border-bottom: 12px solid #f4f4f4;
    background:#fff; /* 2021.2.15 추가 */
}

.lay-filter .plp-filter-wrap .filter-top {
    .filter-search-area {
        display: none;
    }
}
@media screen and (max-width: 1024px) {

    .lay-filter .plp-filter-wrap .filter-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0;
        border-bottom: 1px solid #ddd;

        .filter-search-area {
            display: inline-block;
            position: relative;
            //max-width: 216px;
            flex: 1;
            vertical-align: middle;

            .search-inner {
                 width: 100%;
                 height: 34px;
                 padding: 5px 52px 5px 16px;
                 border-radius: 17px;
                 background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                 .inp-txt {
                    width: 100%;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    font-size: 14px;
                    line-height: 24px;
                    color: #767676;
                 }
                 .btn-sch {
                    display: inline-block;
                    position: absolute;
                    top: 7px;
                    right: 16px;
                    width: 20px;
                    height: 20px;
                    background: url('/lg5-common/images/icons/icon-search-20.svg');
                }
            }
        }
    }
}

// term-filter
.term-filter {
    padding:24px 0;
    border-radius:8px;
    // background:#f4f4f4;
    @include mobile {
        padding:40px 0;
    }
    .inner {
        @include clearfix;
        .filter-tit {
            // float:left;
            // width:14%;
            color:#000;
            @include font-medium;
            font-weight:700;
            line-height:26px;
            @include mobile {
                float:none;
                width:100%;
                margin-bottom:6px;
            }
        }
        .filter-tbl {
            // float:left;
            display:table;
            width:100%;
            margin-top:16px;
            // width:86%;
            .cell {
                display:table-cell;
                width:14%;
                vertical-align:top;
            }
            .rdo-cell {
                width:10%;
                padding-right:24px;
                font-size:0;
                white-space:nowrap;
                // .rdo-wrap {
                //     // margin-left:8px;
                //     // &:first-child {
                //     //     margin-left:0;
                //     // }
                // }
            }
            @media screen and (max-width: $desktop-s-width) {
                display:block;
                .cell {
                    // display:block;
                    margin-top:8px;
                    &:first-child {
                        margin-top:0;
                    }
                }
                .rdo-cell {
                    // width:auto;
                    padding-right:0;
                }
            }
            @include mobile {
                width:100%;
                .cell {
                    display:block;
                    width:auto;
                    margin-top:12px;
                }
                .rdo-cell {
                    display:flex;
                    margin:0 -5px 0 2px;
                    .rdo-wrap {
                        // width:calc(100% / 4);
                        width:100%;
                        margin-left:-1px;
                        // margin-left:6px;
                        input + label {
                            width:100%;
                        }
                    }
                }
            }
        }
        .datepic-tbl {
            display:table;
            width:100%;
            @include mobile {
                display:block;
            }
            .dp-cell {
                display:table-cell;
                font-size:0;
                vertical-align:top;
                white-space:nowrap;
                @include mobile {
                    display:block;
                }
                .bar {
                    display:inline-block;
                    width:24px;
                    color:#000;
                    text-align:center;
                    @include font-medium;
                    line-height:48px;
                    @include mobile {
                        line-height:46px;
                    }
                }
                .input-wrap.datepicker {
                    width:calc((100% - 24px) / 2);
                    margin-top:0;
                    input, input:read-only {
                        background:#fff;
                        & + .cell {
                            background:#fff !important;
                        }
                    }
                    .ui-calendar-container {
                        min-width:296px;
                    }
                    &.right {
                        .ui-calendar-container {
                            left:auto;
                            right:0;
                        }
                    }
                }
            }
            .btn-cell {
                display:table-cell;
                width:1%;
                padding-left:8px;
                @include mobile {
                    display:block;
                    width:100%;
                    margin-top:24px;
                    padding-left:0;
                    .btn {
                        width:100%;
                    }
                }
            }
        }
    }
}

/* BTOCSITE-2161 :: dim 추가 */
.dimmed {
    display:none;
    @media screen and (max-width: 1024px) {
        display:block;
        opacity: 0.3;
    }
}
/* //BTOCSITE-2161 :: dim 추가 */

/* BTOCSITE-1967 */
.mdevice:not(.app) {
    //BTOCSITE-1967 2차 추가수정 모바일 사이즈만 적용
    @include mobile {
        .lay-filter {
            .plp-filter-wrap {
                padding-bottom:80px;
            }
        }
    }
}

// LGECOMVIO-16
.search.re22,
.search-layer.re22 {
    .lay-filter {
        float:left;
        width:210px;
        margin-right: 24px;
        margin-top: 0;
        height: auto !important; /* BTOCSITE-32801 스크롤 제거 및 높이값 고정풀기 */
        @at-root .search & {
            @media screen and (min-width: 1025px){
                // margin-top: 46px;
                .filter-head {
                    display: none;
                }
            }
            &.search-all {
                display: none;
            }
        }

        @media screen and (max-width: 1024px) {
            display:none;
            position:fixed;
            top:0;
            //left:0;
            right:0;
            bottom:0;
            width:100%;
            height:100%;
            margin:0;
            backface-visibility:hidden;
            transform:translateZ(0);
            z-index:9000;
            //background:#fff;
            background: transparent;
            overflow: hidden;
            &.open{
                display:block;
            }

            @at-root .search .lay-filter {
                position: fixed;
                right: 0;
                left: auto;
                transform: translateX(0);
                background-color: transparent;
                &:before {
                    position: fixed;
                    background-color: rgba($color-default,.3);
                    opacity: 0;
                    transition: opacity .3s;
                    content: '';
                }
                &.open {
                    &:before {
                        opacity: 1;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 10;
                    }
                    /* BTOCSITE-2161 */
                    @media screen and (max-width: 1024px) {
                        &:before {
                            display:none;
                        }
                    }
                    /* //BTOCSITE-2161 */
                    .lay-fliter-con,
                    .lay-filter-con {
                        @media screen and (max-width: 1024px) {
                            position: absolute;
                            right: 0;
                            //width:calc(100% - 20px);
                            width:296px;
                        }
                    }
                    .filter-head,
                    .filter-btn-wrap {
                        right: 0;
                        left: auto;
                        width:296px;
                    }
                }
            }
        }

        .lay-fliter-con,
        .lay-filter-con {
            @media screen and (max-width: 1024px) {
                position:absolute;
                top:0;
                right:-100%;
                transition: right .5s;
                //width:100%;
                width:296px;
                height:100%;
                overflow-y:auto;
                z-index:9000;
                background-color: #fff;
                &.is_scroll {
                    .filter-head {
                        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
                    }
                }
            }
        }

        &.open {
            .lay-fliter-con,
            .lay-filter-con {
                @media screen and (max-width: 1024px) {
                    position: absolute;
                    right: 0;
                    padding: 0 12px;
                }
            }
        }
        .fiter-head,
        .filter-head {
            width:100%;
            background-color: #fff;
            .tit {
                padding-bottom: 12px;
                font-weight:700;
                @include font(14px,24px);
            }
            @include tablet {
                position: fixed;
                z-index:20;
                width:260px;
                padding: 16px 96px 16px 16px;
                &::after {
                    display: inline-block;
                    content: none;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 10px;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                }
                .tit {
                    padding-bottom: 0;
                    font-size: 16px;
                }
            }
        }

        .plp-filter-wrap {
            padding-bottom: 50px;

            @media screen and (max-width: 1024px) {
                //padding:40px 20px 115px;
                //padding:48px 16px 135px;
                padding:56px 0 48px;
            }

            .btn-reset {
                margin:18px 0 12px;
                text-align: right;
                button {
                    color:$color-default;
                    @include font-small;
                    span {
                        position:relative;
                        padding:0 0 0 28px;
                        &:after {
                            content:'';
                            display:block;
                            position:absolute;
                            top:50%;
                            left:0;
                            width:24px;
                            height:24px;
                            margin-top:-10px;
                            background:url('/lg5-common/images/icons/icon-reset-20.svg') no-repeat center center;
                        }
                    }
                }
                @include pc {
                    display: none;
                }
                @media screen and (max-width: 1024px) {
                    //display:none;
                    position: fixed;
                    top: 15px;
                    right: 52px;
                    width: 32px;
                    height: 32px;
                    margin: 0 !important;
                    z-index: 100;
                    button {
                        width: 32px;
                        height: 32px;
                        span {
                            display: inline-block;
                            text-indent: -99999px;
                            padding: 0;
                            &:after {
                                top: 50%;
                                left: 50%;
                                right: auto;
                                transform: translate(-50%, -50%);
                                width: 32px;
                                height: 32px;
                                padding: 4px;
                                margin-top: 0;
                                background:url('/lg5-common/images/icons/icon-reset-48.svg') no-repeat center center;
                                background-size: 24px 24px;
                            }
                        }
                    }
                }
            }
            .list-acco-sorting{
                @media screen and (max-width: 1024px) {
                    display:block;
                }
                @media screen and (min-width: 1024px) {
                    display:none;
                }
            }

            .list-acco {
                @media screen and (max-width: 1024px) {
                    border-top: 1px solid #000;
                    margin-top: -30px;
                }
                .filter-section {
                    padding: 80px 0 8px 0;
                    @include font(14px,20px);
                    font-weight: 700;
                    color: #000;
                    @include tablet {
                        padding: 40px 0 8px 0;
                        border-bottom: 1px solid #000;
                        @include font(13px,19px);
                    }
                }
                ul {
                    border-top: 1px solid #000;
                    @media screen and (max-width: 1024px) {
                        border-top: none;
                    }
                    li {
                        background-color:#fff;
                        border-bottom: 1px solid $color-border;
                        //overflow:hidden;
                        @media screen and (max-width: 1024px) {
                            margin:0;
                            border-radius:inherit;
                        }

                        .head {
                            position:relative;
                            .link-acco {
                                display:block;
                                padding:0 20px 0 0;
                                @media screen and (max-width: 1024px) {
                                    display:block;
                                    padding:0 28px 0 4px;
                                }
                                &:before {
                                    content:'';
                                    display:block;
                                    position:absolute;
                                    top:28px;
                                    right:0;
                                    width:16px;
                                    height:16px;
                                    background:url('/lg5-common/images/icons/btn-down-16-black.svg') no-repeat;
                                    background-size: cover;
                                    transition: all .3s;
                                    @media screen and (max-width: 1024px) {
                                        top: 18.5px;
                                        right: 4px;
                                    }
                                }
                            }

                            .tit {
                                display:block;
                                padding:24px 0;
                                color:#000;
                                @include font-small;
                                font-weight:$font-weight-bold;
                                word-break:break-all;
                                span {
                                    word-break:break-all;
                                }
                                @media screen and (max-width: 1024px) {
                                    font-weight: 400;
                                    padding:17px 0 17px;
                                    @include font(15px,21px);
                                }
                                .tit_result {
                                    display: flex;
                                    justify-content: space-between;
                                    text-align: center;
                                    .tit_result_count {
                                        @include font(14px,20px);
                                        @media screen and (max-width: 1024px) {
                                            @include font(15px,21px);
                                        }
                                        em {
                                            margin-left: 4px;
                                            color: #ea1917;
                                        }
                                    }
                                    .tit_result_content {
                                        flex: 1;
                                        margin-left: 16px;
                                        @include font(12px,18px);
                                        font-weight: 400;
                                        color: #000;
                                        @include textEllipsis;
                                        text-align: right;
                                        @media screen and (max-width: 1024px) {
                                            @include font(11px,19px);
                                        }
                                    }
                                    .tit_result_text {
                                        margin-left: 3px;
                                        @include font(12px,18px);
                                        font-weight: 400;
                                        color: #000;
                                        @media screen and (max-width: 1024px) {
                                            @include font(13px,19px);
                                            font-weight: 500;
                                        }
                                    }
                                    .tit_result_colorchip {
                                        flex: 1;
                                        height: 18px;
                                        text-align: right;
                                        padding:2px 0 0 12px;
                                        @include textEllipsis;
                                        text-overflow: initial;
                                        .chk-wrap-colorchip {
                                            height: 100%;
                                            input {
                                                width: 16px;
                                                height: 16px;
                                                &::before {
                                                    top: 0;
                                                    left: 0;
                                                    width: 16px;
                                                    height: 16px;
                                                }
                                                &:checked {
                                                    &::after {
                                                        content: none;
                                                    }
                                                }
                                                & + label {
                                                    width: 16px;
                                                    height: 16px;
                                                    padding: 0;
                                                    font-size: 0;
                                                    color: transparent;
                                                    &::before {
                                                        top: -4px;
                                                        left: -4px;
                                                        width: 24px;
                                                        height: 24px;
                                                        transform: scale(0.6665);
                                                    }
                                                    &::after {
                                                        content: none;
                                                    }
                                                }
                                            }
                                            & + .chk-wrap-colorchip {
                                                margin-left: 6px;
                                            }

                                            &.onlytext {
                                                input {
                                                    &::before {
                                                        content: none;
                                                    }
                                                    & + label {
                                                        width: auto;
                                                        @include font(12px,18px);
                                                        color: #000;
                                                        font-weight: 400;
                                                        @include textEllipsis;
                                                        &::before {
                                                            content: none;
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                            &.inactive {
                                a{
                                    pointer-events: none;
                                }

                                .tit {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                }
                            }
                        }
                        &.on .head a:before {
                            transform: rotate(180deg);
                        }
                        .desc {
                            display:none;
                            padding:0 1px 32px 1px;
                            outline-color: #fff;
                            @media screen and (max-width: 1024px) {
                                padding:20px 2px 28px 2px;
                                border-top: 1px solid #ddd;
                            }
                            .colorchip_tit {
                                margin-bottom: 8px;
                                @include font(12px,18px);
                                color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                &.objet {
                                    margin-top: 24px;
                                }
                            }
                        }
                        .cont {
                            position:relative;
                            word-break:break-all;
                            p {
                                word-break:break-all;
                                @include font-small;
                            }

                            .range-wrap {
                                width: 100%;
                                padding: 0 2px;
                                font-size:0;
                                @include mobile {
                                    padding-top: 4px;
                                }
                                .ui-range-slider {
                                    height: 4px;
                                    margin-bottom: 10px;
                                    //background-color: #fff;
                                    background-color: #ddd;
                                    .ui-range-slider-bg {
                                        height: 4px;
                                        background-color: #ddd;
                                        border-radius: 2px;
                                    }
                                    .ui-range-slider-range {
                                        height: 4px;
                                        border-radius: 2px;
                                        background-color: #000;
                                    }
                                    .ui-range-slider-handler {
                                        width: 16px;
                                        height: 16px;
                                        box-shadow: rgba(0, 0, 0, 0.14) 0 1px 4px 0;
                                        border: 0;
                                        top: -6px !important;
                                    }
                                }
                                .range-num {
                                    display:inline-block;
                                    width:50%;
                                    vertical-align:middle;
                                    text-align:left;
                                    color:#000;
                                    font-size:12px;
                                    line-height:22px;
                                    &.max {
                                        text-align:right;
                                    }
                                }
                            }

                            .rdo-wrap,
                            .chk-wrap,
                            .chk-wrap-colorchip {
                                display:block;
                                margin-top:16px;
                                &:first-child {
                                    margin-top:0;
                                }
                                & input + label {
                                    word-break:break-all;
                                    @include font-small;
                                    &::after {
                                        top: 0;
                                    }
                                }
                                &.onlytext {
                                    input {
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        border: none;
                                        background: transparent;
                                        //-moz-appearance:none;
                                        //-webkit-appearance:none;
                                        //appearance:none;
                                        &::-ms-check{
                                            display:none;
                                        }
                                        &::before {
                                            content: none;
                                        }
                                        & + label {
                                            position:relative;
                                            display:inline-block;
                                            vertical-align:top;
                                            cursor:pointer;
                                            -webkit-user-select:none;
                                            -moz-user-select:none;
                                            -ms-user-select:none;
                                            &::before {
                                                content: none;
                                            }
                                            &::after {
                                                content:' ';
                                                position:absolute;
                                                top:1px;
                                                left:0;
                                                width:24px;
                                                height:24px;
                                                border: none;
                                                background: url(/lg5-common/images/icons/btn-checkbox-off-24-black.svg) no-repeat center/100%;
                                            }
                                        }
                                        &:checked {
                                            &::after {
                                                content: none;
                                            }
                                            & + label {
                                                font-weight: bold;
                                                &::after {
                                                    background-image: url(/lg5-common/images/icons/btn-checkbox-on-24-black.svg);
                                                }
                                            }
                                        }
                                        &:disabled {
                                            & + label {
                                                color: #666;
                                                &::after {
                                                    background-image: url(/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            //.rdo-wrap input:checked + label {
                            //    &:before {
                            //        background-image: url("/lg5-common/images/bg-input-checked-gray.svg");
                            //    }
                            //    &:after {
                            //        background: $color-gray-1;
                            //    }
                            //}

                            .chk-wrap-colorchip {
                                & input {
                                    width: 24px;
                                    height: 24px;
                                    &::before {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                    }
                                    &:checked {
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: -2px;
                                            right: -2px;
                                            width: 12px;
                                            height: 12px;
                                            background: url(/lg5-common/images/icons/icon-colorchip-check.svg) no-repeat center/100%;
                                            z-index: 11;
                                        }
                                    }
                                    & + label {
                                        @include font(12px,24px);
                                        @media screen and (max-width: 1024px) {
                                            @include font(13px,24px);
                                        }
                                        @include mobile {
                                            line-height: 24px !important;
                                        }
                                        &::before {
                                            top: 0;
                                            left: 0;
                                            width: 24px;
                                            height: 24px;

                                            @include mobile {
                                                border-radius: 9px;
                                            }
                                        }
                                        &::after {
                                            content: none;
                                        }
                                    }
                                    &:checked + label {
                                        font-weight: 700;
                                    }
                                }
                                & input:disabled + label {
                                    color:#adadad;
                                    &:before {
                                        background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    }
                                }
                            }

                            .chk-wrap {
                                input {
                                    & + label {
                                        @include font(12px,24px);
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-checkbox-off-24-black.svg);
                                        }
                                    }
                                    &:checked + label {
                                        @include font(12px,24px);
                                        font-weight: 700;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-checkbox-on-24-black.svg);
                                        }
                                    }
                                    &:disabled + label {
                                        @include font(12px,24px);
                                        color: #666;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg);
                                        }
                                    }
                                }
                            }
                            .rdo-wrap {
                                input {
                                    & + label {
                                        @include font(12px,24px);
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-radio-off-24-black.svg);
                                        }
                                    }
                                    &:checked + label {
                                        @include font(12px,24px);
                                        font-weight: 700;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-radio-on-24-black.svg);
                                        }
                                    }
                                    &:disabled + label {
                                        @include font(12px,24px);
                                        color: #666;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-radio-disabled-24-black.svg);
                                        }
                                    }
                                }
                            }

                        }

                    }
                }
            }

            .service-link {
                margin-top:30px;
                text-align:center;
                li {
                    margin-top: 8px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                .btn-text {
                   display: flex;
                   align-items: center;
                   justify-content: space-between;
                   width: 100%;
                   min-height: 72px;
                   padding: 24px 16px 24px 20px;
                   background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                   border-radius: 8px;
                   @include font-small;
                   font-weight: 700;
                   span {
                       padding-right: 12px;
                       text-align: left;
                   }
                   img {
                       width: 40px;
                       height: 40px;
                   }
                }
                @media screen and (max-width: 1024px) {
                    display:none;
                }
            }

            .service-link-box {
                ul {
                    margin-top: 16px;
                    li {
                        margin-bottom: 8px;
                        .btn-gray-box {
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                            min-height: 80px;
                            padding: 20px;
                            border-radius: 8px;
                            background: #aeaeae;
                            justify-content: center;
                            vertical-align: middle;
                            span {
                                display: inline-block;
                                position: relative;
                                padding-right: 24px;
                                @include font-medium;
                                color: $color-bg-wt;
                                &::after {
                                    display: inline-block;
                                    content: '';
                                    position: absolute;
                                    top: 4px;
                                    right: 0;
                                    width: 20px;
                                    height: 20px;
                                    background: url('/lg5-common/images/icons/btn-arrow-right-white-20.svg') no-repeat;

                                }

                            }
                        }
                    }

                }
            }
            .banner-box {
                margin-top: 16px;
                .inner {
                    a {
                        display: inline-flex;
                        align-items: center;
                        width: 100%;
                        min-height: 80px;
                        padding: 20px;
                        border-radius: 8px;
                        background: #aeaeae;
                        justify-content: center;
                        vertical-align: middle;
                        span {
                            display: inline-block;
                            position: relative;
                            padding-right: 24px;
                            @include font-medium;
                            color: $color-bg-wt;
                        }
                    }
                }
            }
            .banner-box + .banner-box {
                margin-top: 8px;
            }

            .filter-btn-wrap {
                display:none;
                position:fixed;
                left:0;
                bottom:0;
                width:100%;
                padding:0;
                text-align:center;
                font-size:0;
                z-index:1;
                .btn {
                    display:inline-block;
                    //width:50% !important;
                    vertical-align:middle;
                    height:59px;
                    width:100%;
                    color:#fff;
                    font-size:16px;
                    font-weight:500;
                    background-color: #EC455A;
                    border-radius:0;
                    border:none;
                    @include mobile {
                        width:100%;
                        height:56px;
                        border-radius:0;
                    }
                    /*
                    &:first-child {
                        background:#ededed;
                        color:#EC455A;
                    }*/
                }
                @media screen and (min-width: 1025px) {
                    display:none;
                }
            }

            .filter-close {
                position:fixed;
                top:15px;
                right:12px;
                width:32px;
                height:32px;
                z-index:20;
                @media screen and (min-width: 1025px) {
                    display:none;
                }
                .btn-close {
                    position:relative;
                    width:32px;
                    height:32px;
                    &:after {
                        content: '';
                        position:absolute;
                        top:0;
                        left:0;
                        width: 32px;
                        height: 32px;
                        padding: 4px;
                        background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center;
                        background-size: 24px 24px;
                    }
                    /*
                    &:before {
                        content: '';
                        position:absolute;
                        top:0;
                        left:0;
                        margin:3.25px 0 0 11.25px;
                        height: 18px;
                        border:solid #222;
                        border-width: 0 1.5px 0px 0;
                        transform: rotate(45deg);
                    }
                    &:after {
                        content: '';
                        position:absolute;
                        top:0;
                        left:0;
                        height: 18px;
                        margin:3.25px 0 0 11.25px;
                        border:solid #222;
                        border-width: 0 1.5px 0px 0;
                        transform: rotate(-45deg);
                    }*/
                }
            }
        }
    }
    .afilter_area {
        @media screen and (min-width: 1025px) {
            display: none;
        }
        @media screen and (min-width: 768px) and (max-width:1024px) {
            height: 38px;
            margin-bottom: 27px;
            border-bottom: 10px solid #f4f4f4;
            &.no_lower {
                border-bottom: 0;
            }
            .afilter_item {
                display: none;
            }
            .btn-filter {
                top: 0;
                right: 0;
                left: auto;
                padding-left: 0;
                &::before {
                    content: none;
                }
                .ico-filter {
                    width: 38px;
                    height: 38px;
                    border-radius: 8px;
                    background:#222 url("/lg5-common/images/icons/btn_afilter_19.svg") no-repeat center/24px;
                    em.text {
                        display: none;
                    }
                }
            }
        }
        @include mobile {
            position: relative;
            margin-bottom: 20px;
            border-bottom: 10px solid #f4f4f4;
            &.no_lower {
                border-bottom: 0;
                .afilter_item {
                    .afilter_selectitem {
                        .selectitem_area {
                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }
            .afilter_item {
                .tabs-wrap {
                    padding: 12px 32px 12px 0;
                    background-color: #f8f8f8;
                    .tabs {
                        padding-right: 34px;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        li {
                            display: inline-block;
                            background-color: #fff;
                            border: 1px solid #fff;
                            border-radius: 8px;
                            padding: 0;
                            a {
                                padding:5px 28px 4px 12px;
                                background: url('/lg5-common/images/icons/btn-afilter-accord-open.svg') no-repeat right 12px center/12px;
                                @include font(13px,19px);
                                font-weight: 400;
                                color: #000;
                                .count {
                                    margin-left: 4px;
                                    font-weight: 500;
                                    color: #ea1917;
                                }
                                &.open {
                                    background-image: url('/lg5-common/images/icons/btn-afilter-accord-close.svg');
                                }
                            }
                            &::before {
                                content: none;
                            }
                            &.on {
                                border-color: #ea1917;
                                a {
                                    color: #ea1917;
                                    background-image: url('/lg5-common/images/icons/btn-afilter-accord-close.svg');
                                }
                                .count {
                                    font-weight: 700;
                                }
                            }
                            &.inactive {
                                a {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    background-image: url('/lg5-common/images/icons/btn-afilter-accord-disabled.svg');
                                }
                            }
                        }
                    }
                }
                .slide-wrap {
                    .indi-wrap {
                        margin: 12px 0 4px 0;
                        li {
                            .btn-indi {
                                width: 14px;
                                height: 6px;
                                padding: 0 4px;
                                &::before {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    background-color: #e4e4e4;
                                }
                            }
                            &.on {
                                .btn-indi {
                                    width: 26px;
                                    height: 6px;
                                    &::before {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 3px;
                                        background-color: #000;
                                    }
                                }
                            }
                        }
                    }
                }

            }
            .afilter_cntarea {
                padding: 12px 16px;
                min-height: 117px;
                .afiter_cont {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: -12px 0 8px 0;
                    li {
                        width: 50%;
                        margin-top: 20px;
                        padding-right: 12px;
                        .chk-wrap {
                            input {
                                & + label {
                                    @include font(13px,24px);
                                    padding-left: 28px;
                                    word-break: break-all;
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-checkbox-off-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:checked + label {
                                    font-weight: 700;
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-checkbox-on-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:disabled + label {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                        }
                        .rdo-wrap {
                            input {
                                & + label {
                                    @include font(13px,24px);
                                    padding-left: 28px;
                                    word-break: break-all;
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-radio-off-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:checked + label {
                                    font-weight: 700;
                                    &::after {
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-radio-on-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:disabled + label {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    &::after {
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-radio-disabled-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                        }
                    }
                }
                .afilter_colorchip {
                    .colorchip_tit {
                        @include font(11px,16px);
                        font-weight: 700;
                    }
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: start;
                        li {
                            width: 20%;
                            margin-top: 16px;
                            .chk-wrap-colorchip {
                                input {
                                    &::before {
                                        width: 40px;
                                        height: 40px;
                                    }
                                    &:checked {
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: -4px;
                                            right: -4px;
                                            width: 18px;
                                            height: 18px;
                                            background: url(/lg5-common/images/icons/icon-colorchip-check.svg) no-repeat center/100%;
                                            z-index: 1;
                                        }
                                    }
                                    & + label {
                                        //display: flex;
                                        //align-items: center;
                                        width: 40px;
                                        padding:45px 0 0 0;
                                        border: 0;
                                        text-align: center;
                                        word-break: break-all;
                                        @include font(11px,16px);
                                        color: #666;
                                        &::before {
                                            top: 8px;
                                            left: 8px;
                                            width: 24px;
                                            height: 24px;
                                            border: none;
                                            transform: scale(1.6665);
                                        }
                                        &::after {
                                            content: none;
                                        }
                                    }
                                    &:checked + label {
                                        font-weight: 700;
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                    &.objet {
                        margin-top: 16px;
                        padding-top: 12px;
                        border-top: 1px solid #ddd;
                        ul {
                            flex-wrap: nowrap;
                            white-space: nowrap;
                            overflow-x: auto;
                            -ms-overflow-style: none;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            li {
                                width: auto;
                                margin-right: 12px;
                                .chk-wrap-colorchip {
                                    display: flex;
                                    align-items: center;
                                }
                                input {
                                    &::after {
                                        top: -4px;
                                        left: 26px;
                                    }
                                    & + label {
                                        vertical-align: middle;
                                        width: 137px;
                                        min-height: 40px;
                                        padding:0 0 0 52px;
                                        text-align: left;
                                        word-break: break-all;
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .afilter_selectitem {
                position: relative;
                background-color: #fff;
                .selectitem_area {
                    padding:0 48px 0 0;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 16px;
                        width: calc(100% - 32px);
                        height: 1px;
                        background-color: #ddd;
                    }
                    ul {
                        display: flex;
                        white-space: nowrap;
                        overflow-x: auto;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        li {
                            position: relative;
                            display: inline-block;
                            margin:16px;
                            padding: 0 33px 0 12px;
                            border-radius: 8px;
                            background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                            & + li {
                                margin-left: 0;
                            }
                            .item_name {
                                @include font(13px,31px);
                            }
                            .item_del {
                                position: absolute;
                                top: 8.5px;
                                right: 12px;
                                width: 14px;
                                height: 14px;
                                font-size: 0;
                                color: transparent;
                                background: url("/lg5-common/images/icons/btn-afilter-delete.svg") no-repeat center/100%;
                            }
                        }
                    }
                }
                .alldel_area {
                    position: absolute;
                    top: 12px;
                    right: 16px;
                    width: 70px;
                    height: 40px;
                    .item_alldel {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding-left: 42px;
                        background: url("/lg5-common/images/icons/btn-afilter-reset.svg") no-repeat right center/28px;
                        font-size: 0;
                        color: transparent;
                        &:before {
                            content:'';
                            position:absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            width: 42px;
                            height: 40px;
                            background: url("/lg5-common/images/icons/icon_afilter_shadow_02.svg") no-repeat right center/100%;
                        }
                    }
                }
            }
        }
    }
    .btn-filter {
        position: absolute;
        top: 12px;
        right: 16px;
        z-index: 2;
        padding-left: 38px;
        &:before {
            content:'';
            position:absolute;
            top: -4px;
            left:5px;
            z-index:0;
            width:43px;
            height: 40px;
            background: url("/lg5-common/images/icons/icon_afilter_shadow_01.svg") no-repeat right center/100%;
        }
        &:after {
            content:'';
            position:absolute;
            top: calc(50% - 7px);
            left: 24px;
            width: 1px;
            height: 14px;
            background-color: #d8d8d8;
        }
        .ico-filter {
            display: inline-block;
            position:relative;
            z-index:1;
            width: 33px;
            height: 33px;
            padding: 0;
            border-radius: 8px;
            background: #000 url("/lg5-common/images/icons/btn_afilter_19.svg") no-repeat center/24px;
            @include mobile {
                em.text {
                    display: none;
                }
            }
        }
        .filter_count {
            position: absolute;
            top: -7px;
            right: -6px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #ea1917;
            @include font(10px,18px);
            color: #fff;
            font-weight: 500;
            text-align: center;
            z-index: 1;
            @include mobile {
                display: block;
            }
        }
        // @media screen and (min-width: 1025px) {
        //     display: none !important;
        // }
        &.applied {
            .ico-filter {
                background-image: url("/lg5-common/images/icons/btn_afilter_19.svg");
            //position: relative;
                //&:after {
                //    position: absolute;
                //    top: -7px;
                //    right: 0;
                //    width: 5px;
                //    height: 5px;
                //    border-radius: 50%;
                //    background-color: #ec455a;
                //    content: '';
                //}
            }
        }
    }
    .cate-m {
        @include tablet {
            position: relative;
            min-height: 76px;
            border-bottom: 1px solid #ddd;
            // BTOCSITE-2847 PLP > 상세필터 동작오류 : [QA]모니터링항목 : 59번
            z-index: 0;
            @include mobile {
                min-height: 65px;
            }
            .btn-filter {
                bottom: 0;
            }
        }
        //border-bottom: 12px solid #f4f4f4;
        background:#fff; /* 2021.2.15 추가 */
    }
    .lay-filter .plp-filter-wrap .filter-top {
        .filter-search-area {
            display: none;
        }
    }
    @media screen and (max-width: 1024px) {

        .lay-filter .plp-filter-wrap .filter-top {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 40px 0;
            border-bottom: 1px solid #ddd;

            .filter-search-area {
                display: inline-block;
                position: relative;
                //max-width: 216px;
                flex: 1;
                vertical-align: middle;

                .search-inner {
                     width: 100%;
                     height: 34px;
                     padding: 5px 52px 5px 16px;
                     border-radius: 17px;
                     background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                     .inp-txt {
                        width: 100%;
                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        font-size: 14px;
                        line-height: 24px;
                        color: #767676;
                     }
                     .btn-sch {
                        display: inline-block;
                        position: absolute;
                        top: 7px;
                        right: 16px;
                        width: 20px;
                        height: 20px;
                        background: url('/lg5-common/images/icons/icon-search-20.svg');
                    }
                }
            }
        }
    }
    .term-filter {
        padding:24px 0;
        border-radius:8px;
        // background:#f4f4f4;
        @include mobile {
            padding:40px 0;
        }
        .inner {
            @include clearfix;
            .filter-tit {
                // float:left;
                // width:14%;
                color:#000;
                @include font-medium;
                font-weight:700;
                line-height:26px;
                @include mobile {
                    float:none;
                    width:100%;
                    margin-bottom:6px;
                }
            }
            .filter-tbl {
                // float:left;
                display:table;
                width:100%;
                margin-top:16px;
                // width:86%;
                .cell {
                    display:table-cell;
                    width:14%;
                    vertical-align:top;
                }
                .rdo-cell {
                    width:10%;
                    padding-right:24px;
                    font-size:0;
                    white-space:nowrap;
                    // .rdo-wrap {
                    //     // margin-left:8px;
                    //     // &:first-child {
                    //     //     margin-left:0;
                    //     // }
                    // }
                }
                @media screen and (max-width: $desktop-s-width) {
                    display:block;
                    .cell {
                        // display:block;
                        margin-top:8px;
                        &:first-child {
                            margin-top:0;
                        }
                    }
                    .rdo-cell {
                        // width:auto;
                        padding-right:0;
                    }
                }
                @include mobile {
                    width:100%;
                    .cell {
                        display:block;
                        width:auto;
                        margin-top:12px;
                    }
                    .rdo-cell {
                        display:flex;
                        margin:0 -5px 0 2px;
                        .rdo-wrap {
                            // width:calc(100% / 4);
                            width:100%;
                            margin-left:-1px;
                            // margin-left:6px;
                            input + label {
                                width:100%;
                            }
                        }
                    }
                }
            }
            .datepic-tbl {
                display:table;
                width:100%;
                @include mobile {
                    display:block;
                }
                .dp-cell {
                    display:table-cell;
                    font-size:0;
                    vertical-align:top;
                    white-space:nowrap;
                    @include mobile {
                        display:block;
                    }
                    .bar {
                        display:inline-block;
                        width:24px;
                        color:#000;
                        text-align:center;
                        @include font-medium;
                        line-height:48px;
                        @include mobile {
                            line-height:46px;
                        }
                    }
                    .input-wrap.datepicker {
                        width:calc((100% - 24px) / 2);
                        margin-top:0;
                        input, input:read-only {
                            background:#fff;
                            & + .cell {
                                background:#fff !important;
                            }
                        }
                        .ui-calendar-container {
                            min-width:296px;
                        }
                        &.right {
                            .ui-calendar-container {
                                left:auto;
                                right:0;
                            }
                        }
                    }
                }
                .btn-cell {
                    display:table-cell;
                    width:1%;
                    padding-left:8px;
                    @include mobile {
                        display:block;
                        width:100%;
                        margin-top:24px;
                        padding-left:0;
                        .btn {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
    .dimmed {
        display:none;
        @media screen and (max-width: 1024px) {
            display:block;
            opacity: 0.3;
        }
    }
    .mdevice:not(.app) {
        //BTOCSITE-1967 2차 추가수정 모바일 사이즈만 적용
        @include mobile {
            .lay-filter {
                .plp-filter-wrap {
                    padding-bottom:80px;
                }
            }
        }
    }
}

// LGECOMVIO-15
.KRP0007.re22 {
    .lay-filter {
        float:left;
        width:210px;
        margin-right: 24px;
        margin-top: 0;

        @at-root .search & {
            @media screen and (min-width: 1025px){
                // margin-top: 46px;
                .filter-head {
                    display: none;
                }
            }
            &.search-all {
                display: none;
            }
        }

        @media screen and (max-width: 1024px) {
            display:none;
            position:fixed;
            top:0;
            //left:0;
            right:0;
            bottom:0;
            width:100%;
            height:100%;
            margin:0;
            backface-visibility:hidden;
            transform:translateZ(0);
            z-index:9000;
            //background:#fff;
            background: transparent;
            overflow: hidden;
            &.open{
                display:block;
            }

            @at-root .search .lay-filter {
                position: fixed;
                right: 0;
                left: auto;
                transform: translateX(0);
                background-color: transparent;
                &:before {
                    position: fixed;
                    background-color: rgba($color-default,.3);
                    opacity: 0;
                    transition: opacity .3s;
                    content: '';
                }
                &.open {
                    &:before {
                        opacity: 1;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 10;
                    }
                    /* BTOCSITE-2161 */
                    @media screen and (max-width: 1024px) {
                        &:before {
                            display:none;
                        }
                    }
                    /* //BTOCSITE-2161 */
                    .lay-fliter-con,
                    .lay-filter-con {
                        @media screen and (max-width: 1024px) {
                            position: absolute;
                            right: 0;
                            //width:calc(100% - 20px);
                            width:296px;
                        }
                    }
                    .filter-head,
                    .filter-btn-wrap {
                        right: 0;
                        left: auto;
                        //width:calc(100% - 20px);
                        width:296px;
                    }
                }
            }
        }

        .lay-fliter-con,
        .lay-filter-con {
            @media screen and (max-width: 1024px) {
                position:absolute;
                top:0;
                right:-100%;
                transition: right .5s;
                //width:100%;
                width:296px;
                height:100%;
                overflow-y:auto;
                z-index:9000;
                background-color: #fff;
                &.is_scroll {
                    .filter-head {
                        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.14);
                    }
                }
            }
        }

        &.open {
            .lay-fliter-con,
            .lay-filter-con {
                @media screen and (max-width: 1024px) {
                    position: absolute;
                    right: 0;
                    padding: 0 12px;
                    width: 296px;
                }
            }
        }
        .fiter-head,
        .filter-head {
            //position:relative;
            width:100%;
            background-color: #fff;
            //text-align:center;
            .tit {
                padding-bottom: 12px;
                font-weight:700;
                @include font(14px,24px);
            }
            @include tablet {
                position: fixed;
                z-index:20;
                width:296px;
                padding: 15px 96px 0 16px;
                &::after {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 16px;
                    width: calc(100% - 32px);
                    height: 2px;
                    background-color: #222;
                }
                .tit {
                    font-size: 16px;
                }
            }
            //@media screen and (min-width: 1024px) {
            //    display:none;
            //}
        }

        .plp-filter-wrap {

            @media screen and (max-width: 1024px) {
                //padding:40px 20px 115px;
                //padding:48px 16px 135px;
                padding:88px 16px 48px;
            }

            .btn-reset {
                position: relative;
                padding-right: 12px;
                margin:18px 0 12px;
                text-align: right;
                button {
                    color:$color-default;
                    @include font-small;
                    font-weight: 500;
                    span {
                        position:relative;
                        display: inline-block;
                        padding:0 0 0 28px;
                        &:after {
                            content:'';
                            display:block;
                            position:absolute;
                            top:calc(50% - 12px);
                            left:0;
                            width:24px;
                            height:24px;
                            margin-top: 0;
                            background:url('/lg5-common/images/icons/icon-reset-20.svg') no-repeat center center;
                        }
                    }
                }
                @media screen and (max-width: 1024px) {
                    //display:none;
                    position: fixed;
                    top: 46px;  /* BTOCSITE-2161 modify*/
                    right: 42px;
                    width: 32px;
                    height: 24px;
                    margin: 0 !important;
                    z-index: 100;
                    button {
                        width: 24px;
                        height: 24px;
                        margin-right: 12px;
                        span {
                            display: inline-block;
                            text-indent: -99999px;
                            padding: 0;
                            &:after {
                                top: 50%;
                                left: 50%;
                                right: auto;
                                transform: translate(-50%, -50%);
                                width: 24px;
                                height: 24px;
                                padding: 4px;
                                margin-top: 0;
                                background:url('/lg5-common/images/icons/btn-reset-black.svg') no-repeat center center;
                                background-size: 20px 20px;
                            }
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        right: 0;
                        width: 1px;
                        height: 14px;
                        background-color: #d8d8d8;
                    }
                }
            }
            .list-acco-sorting{
                @media screen and (max-width: 1024px) {
                    display:block;
                }
                @media screen and (min-width: 1024px) {
                    display:none;
                }
            }

            .list-acco {
                .filter-section {
                    padding: 40px 0 8px 0;
                    @include font(14px,20px);
                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    @include mobile {
                        padding: 27px 0 8px 0;
                        @include font(13px,19px);
                    }
                }
                &.is_loading {
                    .cont {
                        input, label {
                            pointer-events: none;
                        }
                    }
                }
                ul {
                    border-top: 1px solid #000;
                    @media screen and (max-width: 1024px) {
                        border-top: none;
                    }
                    li {
                        background-color:#fff;
                        border-bottom: 1px solid $color-border;
                        //overflow:hidden;
                        @media screen and (max-width: 1024px) {
                            margin:0;
                            border-radius:inherit;

                            &:last-child {
                                border-bottom:0;
                            }
                        }

                        .head {
                            position:relative;
                            @media screen and (max-width: 1024px) {
                                border-bottom: 0;
                            }
                            .link-acco {
                                display:block;
                                padding:0 26px 0 0;
                                @media screen and (max-width: 1024px) {
                                    display:block;
                                    padding:0 40px 0 14px;
                                }
                                &:before {
                                    content:'';
                                    display:block;
                                    position:absolute;
                                    top: calc(50% - 8px);
                                    right:0;
                                    width:16px;
                                    height:16px;
                                    background:url('/lg5-common/images/icons/btn-down-16-black.svg') no-repeat;
                                    background-size: cover;
                                    transition: all .3s;
                                    @media screen and (max-width: 1024px) {
                                        top: 18.5px;
                                        right: 16px;
                                    }
                                }
                            }

                            .tit {
                                display:block;
                                padding:24px 0;
                                color:#000;
                                @include font-small;
                                font-weight:$font-weight-bold;
                                word-break:break-all;
                                span {
                                    word-break:break-all;
                                }
                                @media screen and (max-width: 1024px) {
                                    font-weight: 400;
                                    padding:17px 0 17px;
                                    @include font(15px,21px);
                                }
                                .tit_result {
                                    display: flex;
                                    justify-content: space-between;
                                    text-align: center;
                                    align-items: center;
                                    .tit_result_count {
                                        max-width: 120px;
                                        @include font(14px,20px);
                                        text-align: left;
                                        @media screen and (max-width: 1024px) {
                                            @include font(14px,20px);
                                        }
                                        em {
                                            margin-left: 4px;
                                            color: #ea1917;
                                        }
                                    }
                                    .tit_result_content {
                                        flex: 1;
                                        margin-left: 16px;
                                        @include font(12px,18px);
                                        font-weight: 400;
                                        color: #666;
                                        @include textEllipsis;
                                        text-align: right;
                                    }
                                    .tit_result_text {
                                        margin-left: 3px;
                                        @include font(12px,18px);
                                        font-weight: 500;
                                        color: #666;
                                        @media screen and (max-width: 1024px) {
                                            @include font(13px,19px);
                                        }
                                    }
                                    .tit_result_colorchip {
                                        flex: 1;
                                        height: 18px;
                                        text-align: right;
                                        padding:2px 0 0 12px;
                                        @include textEllipsis;
                                        .chk-wrap-colorchip {
                                            height: 100%;
                                            input {
                                                width: 16px;
                                                height: 16px;
                                                &::before {
                                                    top: 0;
                                                    left: 0;
                                                    width: 16px;
                                                    height: 16px;
                                                }
                                                &:checked {
                                                    &::after {
                                                        content: none;
                                                    }
                                                }
                                                & + label {
                                                    width: 16px;
                                                    height: 16px;
                                                    padding: 0;
                                                    font-size: 0;
                                                    color: transparent;
                                                    &::before {
                                                        top: -4px;
                                                        left: -4px;
                                                        width: 24px;
                                                        height: 24px;
                                                        transform: scale(0.6665);
                                                    }
                                                    &::after {
                                                        content: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.inactive {
                                a{
                                    pointer-events: none;
                                }
                                
                                .tit {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                }
                            }
                        }
                        &.on .head a:before {
                            transform: rotate(180deg);
                        }
                        &.on {
                            .head {
                                @media screen and (max-width: 1024px) {
                                    border-bottom: 1px solid #ddd;
                                }
                            }
                        }
                        .desc {
                            display:none;
                            padding:0 1px 32px 1px;
                            outline-color: #fff;
                            @media screen and (max-width: 1024px) {
                                padding:20px 14px 28px 14px;
                            }
                            .colorchip_tit {
                                margin-bottom: 8px;
                                @include font(12px,18px);
                                color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                &.objet {
                                    margin-top: 24px;
                                }
                            }
                        }
                        // 필터 가이드 tablet,mobile
                        .bfilter_guide {
                            margin: -20px -14px 20px -14px;
                            @media screen and (min-width: 1025px) {
                                display: none;
                            }
                            ul {
                                .lists {
                                    border: 1px solid #f4f4f4;
                                    margin-top: 8px;
                                    padding-bottom: 15px;
                                    background-color: #fafafa;
                                    border-radius: 4px;
                                    &.on {
                                        box-shadow: none;
                                        .accord-btn {
                                            &::after {
                                                background-image: url("/lg5-common/images/icons/btn-arr-16-up.svg");
                                            }
                                        }

                                    }
                                    .head {
                                        border: 0;
                                        .accord-btn {
                                            padding: 15px 20px 0 20px;
                                            &::after {
                                                top: 13px;
                                                right: 12px;
                                                width: 24px;
                                                height: 24px;
                                                margin: 0;
                                                border: 0;
                                                background: url("/lg5-common/images/icons/icon_category_open.svg") no-repeat center/100%;
                                            }
                                            .acc_tit {
                                                position: relative;
                                                display: inline-block;
                                                @include font(13px,19px);
                                                font-weight: 500;
                                                &::before {
                                                    content: "";
                                                    position: absolute;
                                                    bottom: 0;
                                                    left: 0;
                                                    width: 100%;
                                                    height: 10px;
                                                    background-color: #e81953;
                                                    opacity: 0.2;
                                                }
                                            }
                                        }
                                    }
                                    .accord-cont {
                                        padding: 12px 20px 0 20px;
                                        @include font(11px,16px);
                                        color: #666;
                                        p {
                                            @include textEllipsisClamp(8);
                                        }
                                        .guide_link {
                                            display: inline-block;
                                            margin-top: 8px;
                                            padding-right: 15px;
                                            background: url("/lg5-common/images/icons/btn-arr-16-gray-thin.svg") no-repeat right center/12px;
                                            @include font(10px,15px);
                                        }
                                    }
                                }
                            }
                        }
                        // 필터 가이드 PC
                        .bfilter_guidepc {
                            padding: 11px 0 11px 12px;
                            border-radius: 4px;
                            border: solid 1px #f4f4f4;
                            background-color: #f9f9f9;
                            margin-bottom: 16px;
                            @media screen and (max-width: 1024px) {
                                display: none;
                            }
                            .guidepc_tit {
                                position: relative;
                                display: inline-block;
                                @include font(12px,18px);
                                font-weight: 500;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 10px;
                                    background-color: #e81953;
                                    opacity: 0.2;
                                }

                            }
                            .guidepc_btn {
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                font-size: 0;
                                margin-left: 4px;
                                color: transparent;
                                background: url("/lg5-common/images/icons/icon-tooltip.svg") no-repeat center/16px;
                            }
                            .tooltip-wrap {
                                height: 16px;
                                margin-top: 4px;
                                .tooltip-icon {
                                    width: 16px;
                                    height: 16px;
                                }
                                .tooltip-box {
                                    padding: 32px;
                                    .guidepcbox_tit {
                                        @include font(14px,20px);
                                    }
                                    .guidepcbox_cnt {
                                        margin-top: 12px;
                                        @include font(14px,20px);
                                        color: #666;
                                        em {
                                            color: #000;
                                        }
                                    }
                                    .guidepcbox_link {
                                        display: inline-block;
                                        margin-top: 24px;
                                        padding-right: 20px;
                                        background: url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat right center/16px;
                                        @include font(14px,20px);
                                    }
                                    .btn-close {
                                        width: 24px;
                                        height: 24px;
                                        top: 16px;
                                        right: 16px;
                                        &::after {
                                            width: 24px;
                                            height: 24px;
                                        }
                                    }
                                    //new 스타일
                                    .n_toolcnt {
                                        border: 0;
                                        li {
                                            border: 0;
                                            .accord-cont {
                                                padding: 0;
                                                @include font(14px,20px);
                                                color: #000;
                                                font-weight: 400;
                                                .guide_tit {
                                                    display: block;
                                                    margin-bottom: 12px;
                                                    font-weight: 700;
                                                }
                                                .guide_cnt {
                                                    display: block;
                                                    @include textEllipsisClamp(8);
                                                }
                                                .guide_linkarea {
                                                    display: block;
                                                    margin-top: 24px;
                                                }
                                                .guide_link {
                                                    display: inline-block;
                                                    margin-top: 8px;
                                                    padding-right: 15px;
                                                    background: url("/lg5-common/images/icons/btn-arr-16-gray-thin.svg") no-repeat right center/12px;
                                                    @include font(14px,20px);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .cont {
                            position:relative;
                            word-break:break-all;
                            p {
                                word-break:break-all;
                                @include font-small;
                            }

                            .range-wrap {
                                width: 100%;
                                padding: 0 4px;
                                font-size:0;
                                /* BTOCSITE-2161 add */
                                @include mobile {
                                    padding-top: 16px;
                                }
                                /* // BTOCSITE-2161 */
                                .ui-range-slider {
                                    height: 4px;
                                    margin-bottom: 10px;
                                    //background-color: #fff;
                                    background-color: #ddd;
                                    .ui-range-slider-bg {
                                        height: 4px;
                                        background-color: #ddd;
                                        border-radius: 2px;
                                    }
                                    .ui-range-slider-range {
                                        height: 4px;
                                        border-radius: 2px;
                                    }
                                }
                                .range-num {
                                    display:inline-block;
                                    width:50%;
                                    vertical-align:middle;
                                    text-align:left;
                                    color:#000;
                                    font-size:12px;
                                    line-height:22px;
                                    &.max {
                                        text-align:right;
                                    }
                                }
                            }

                            .rdo-wrap,
                            .chk-wrap,
                            .chk-wrap-colorchip {
                                display:block;
                                margin-top:8px;
                                &:first-child {
                                    margin-top:0;
                                }
                                & input + label {
                                    word-break:break-all;
                                    @include font-small;
                                }
                            }
                            //.rdo-wrap input:checked + label {
                            //    &:before {
                            //        background-image: url("/lg5-common/images/bg-input-checked-gray.svg");
                            //    }
                            //    &:after {
                            //        background: $color-gray-1;
                            //    }
                            //}

                            .chk-wrap-colorchip {
                                & input {
                                    &::before {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                    }
                                    &:checked {
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            left: 14px;
                                            width: 10px;
                                            height: 10px;
                                            background: url(/lg5-common/images/icons/icon-colorchip-check.svg) no-repeat center/100%;
                                            z-index: 11;
                                        }
                                    }
                                    & + label {
                                        @include font(12px,24px);
                                        @media screen and (max-width: 1024px) {
                                            @include font(13px,19px);
                                        }
                                        &::before {
                                            top: 0;
                                            left: 0;
                                            width: 24px;
                                            height: 24px;
                                            border: 0;
                                        }
                                        &::after {
                                            content: none;
                                        }
                                    }
                                    &:checked + label {
                                        font-weight: 700;
                                    }
                                }
                                & input:disabled + label {
                                    color:#adadad;
                                    &:before {
                                        background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    }
                                }
                            }

                            .chk-wrap {
                                input {
                                    & + label {
                                        @include font(12px,24px);
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-checkbox-off-24-black.svg);
                                        }
                                    }
                                    &:checked + label {
                                        @include font(12px,24px);
                                        font-weight: 700;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-checkbox-on-24-black.svg);
                                        }
                                    }
                                    &:disabled + label {
                                        @include font(12px,24px);
                                        color: #666;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg);
                                        }
                                    }
                                }
                            }
                            .rdo-wrap {
                                input {
                                    & + label {
                                        @include font(12px,24px);
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-radio-off-24-black.svg);
                                        }
                                    }
                                    &:checked + label {
                                        @include font(12px,24px);
                                        font-weight: 700;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-radio-on-24-black.svg);
                                        }
                                    }
                                    &:disabled + label {
                                        @include font(12px,24px);
                                        color: #666;
                                        &::after {
                                            background-image: url(/lg5-common/images/icons/btn-radio-disabled-24-black.svg);
                                        }
                                    }
                                }
                            }

                        }
                        &.is-range-slider {
                            .cont {
                                width: calc(100% - 2px);
                            }
                        }
                    }
                }
            }

            .service-link {
                margin-top:30px;
                text-align: initial;
                li {
                    margin-top: 8px;
                    &:first-child {
                        margin-top: 0;
                    }
                }
                .btn-text {
                    position: relative;
                    display: block;
                    align-items: initial;
                    justify-content: initial;
                    width: 100%;
                    min-height: initial;
                    padding: 16px 0 16px 24px;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    border-radius: 8px;
                    @include font-small;
                    font-weight: 700;
                    span {
                        @include font(14px,20px);
                        font-weight: 700;
                        }
                    img {
                        position: absolute;
                        top: calc(50% - 16px);
                        right: 16px;
                        width: 32px;
                        height: 32px;
                    }
                }
                @media screen and (max-width: 1024px) {
                    display:none;
                }
            }

            .service-link-box {
                ul {
                    margin-top: 16px;
                    li {
                        margin-bottom: 8px;
                        .btn-gray-box {
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                            min-height: 80px;
                            padding: 20px;
                            border-radius: 8px;
                            background: #aeaeae;
                            justify-content: center;
                            vertical-align: middle;
                            span {
                                display: inline-block;
                                position: relative;
                                padding-right: 24px;
                                @include font-medium;
                                color: $color-bg-wt;
                                &::after {
                                    display: inline-block;
                                    content: '';
                                    position: absolute;
                                    top: 4px;
                                    right: 0;
                                    width: 20px;
                                    height: 20px;
                                    background: url('/lg5-common/images/icons/btn-arrow-right-white-20.svg') no-repeat;

                                }

                            }
                        }
                    }

                }
            }
            .banner-box {
                margin-top: 16px;
                .inner {
                    a {
                        display: inline-flex;
                        align-items: center;
                        width: 100%;
                        min-height: 80px;
                        padding: 20px;
                        border-radius: 8px;
                        background: #aeaeae;
                        justify-content: center;
                        vertical-align: middle;
                        span {
                            display: inline-block;
                            position: relative;
                            padding-right: 24px;
                            @include font-medium;
                            color: $color-bg-wt;
                        }
                    }
                }
            }
            .banner-box + .banner-box {
                margin-top: 8px;
            }

            .filter-btn-wrap {
                display:none;
                position:fixed;
                left:0;
                bottom:0;
                width:100%;
                padding:0;
                text-align:center;
                font-size:0;
                z-index:1;
                .btn {
                    display:inline-block;
                    //width:50% !important;
                    vertical-align:middle;
                    height:59px;
                    width:100%;
                    color:#fff;
                    font-size:16px;
                    font-weight:500;
                    background-color: #EC455A;
                    border-radius:0;
                    border:none;
                    @include mobile {
                        width:100%;
                        height:56px;
                        border-radius:0;
                    }
                    /*
                    &:first-child {
                        background:#ededed;
                        color:#EC455A;
                    }*/
                }
                @media screen and (min-width: 1025px) {
                    display:none;
                }
            }

            .filter-close {
                position:fixed;
                top:46px; /* BTOCSITE-2161 modify*/
                right:12px;
                width:24px;
                height:24px;
                z-index:20;
                @media screen and (min-width: 1025px) {
                    display:none;
                }
                .btn-close {
                    position:relative;
                    width:24px;
                    height:24px;
                    &:after {
                        content: '';
                        position:absolute;
                        top:0;
                        left:0;
                        width: 24px;
                        height: 24px;
                        padding: 4px;
                        background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center;
                        background-size: 24px 24px;
                    }
                    /*
                    &:before {
                        content: '';
                        position:absolute;
                        top:0;
                        left:0;
                        margin:3.25px 0 0 11.25px;
                        height: 18px;
                        border:solid #222;
                        border-width: 0 1.5px 0px 0;
                        transform: rotate(45deg);
                    }
                    &:after {
                        content: '';
                        position:absolute;
                        top:0;
                        left:0;
                        height: 18px;
                        margin:3.25px 0 0 11.25px;
                        border:solid #222;
                        border-width: 0 1.5px 0px 0;
                        transform: rotate(-45deg);
                    }*/
                }
            }
        }
    }
    .afilter_area {
        @media screen and (min-width: 1025px) {
            display: none;
        }

        @media screen and (min-width: 768px) and (max-width:1024px) {
            position: relative;
            height: 38px;
            margin: 40px 0 16px 0;
            &.no_lower {
                border-bottom: 0;
            }
            .afilter_item {
                display: none;
            }
            .btn-filter {
                top: 0;
                left: auto;
                right: 24px;
                padding-left: 0;
                background-color: #fff;
                &::before {
                    content: none;
                }
                &::after {
                    content: none;
                }
                .ico-filter {
                    width: 38px;
                    height: 38px;
                    border-radius: 8px;
                    background:#222 url("/lg5-common/images/icons/btn_afilter_19.svg") no-repeat center/24px;
                    em.text {
                        display: none;
                    }
                }
            }
        }
        @include mobile {
            position: relative;
            top: 0;
            z-index: 15;
            transition: top .3s ease;
            margin-bottom: 28px;
            border-bottom: 10px solid #f4f4f4;
            &.no_lower {
                border-bottom: 0;
            }

            &.is_loading {
                .afiter_cont {
                    input, label {
                        pointer-events: none;
                    }
                }
            }

            .afilter_item {
                background-color: #fff;
                .tabs-wrap {
                    padding: 12px 16px 12px 0;
                    background-color: #f8f8f8;
                    .tabs {
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        padding:0 73px 0 0;
                        min-height: 33px;
                        li {
                            display: inline-block;
                            margin-left: 8px;
                            padding: 0;
                            background-color: #fff;
                            border: 1px solid #fff;
                            border-radius: 8px;
                            &:first-child {
                                margin-left: 16px;
                            }
                            a {
                                padding:7px 28px 7px 12px;
                                background: url('/lg5-common/images/icons/btn-afilter-accord-open.svg') no-repeat right 12px center/12px;
                                @include font(13px,19px);
                                font-weight: 400;
                                color: #000;
                                .count {
                                    margin-left: 4px;
                                    font-weight: 500;
                                    color: #ea1917;
                                }
                                &.open {
                                    background-image: url('/lg5-common/images/icons/btn-afilter-accord-close.svg');
                                }
                            }
                            &::before {
                                content: none;
                            }
                            &.on {
                                border-color: #ea1917;
                                a {
                                    color: #ea1917;
                                    background-image: url('/lg5-common/images/icons/btn-afilter-accord-close.svg');
                                    font-weight: 500;
                                    .count {
                                        font-weight: 700;
                                    }
                                }
                            }
                            &.inactive {
                                a {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    background-image: url('/lg5-common/images/icons/btn-afilter-accord-disabled.svg');
                                    pointer-events: none;
                                }
                            }
                            &.nofilter {
                                @include font(13px,33px);
                                color: #666;
                                background-color: #f8f8f8;
                                border: 0;
                            }
                        }
                    }
                }
                .slide-wrap {
                    .indi-wrap {
                        margin: 12px 0 4px 0;
                        li {
                            .btn-indi {
                                width: 14px;
                                height: 6px;
                                padding: 0 4px;
                                &::before {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    background-color: #e4e4e4;
                                }
                            }
                            &.on {
                                .btn-indi {
                                    width: 26px;
                                    height: 6px;
                                    &::before {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 3px;
                                        background-color: #000;
                                    }
                                }
                            }
                        }
                    }
                }

            }
            .afilter_cntarea {
                position: relative;
                padding: 12px 16px;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 16px;
                    width: calc(100% - 32px);
                    height: 1px;
                    background-color: #ddd;
                }
                .slide-conts {
                    width: 100%;
                }
                .afiter_cont {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: -12px 0 8px 0;
                    li {
                        width: 50%;
                        margin-top: 20px;
                        padding-right: 12px;
                        .chk-wrap {
                            input {
                                & + label {
                                    @include font(13px,24px);
                                    padding-left: 28px;
                                    word-break: break-all;
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-checkbox-off-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:checked + label {
                                    font-weight: 700;
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-checkbox-on-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:disabled + label {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-checkbox-disabled-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                        }
                        .rdo-wrap {
                            input {
                                & + label {
                                    @include font(13px,24px);
                                    padding-left: 28px;
                                    word-break: break-all;
                                    &::after {
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-radio-off-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:checked + label {
                                    font-weight: 700;
                                    &::after {
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-radio-on-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                            input {
                                &:disabled + label {
                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    &::after {
                                        width: 24px;
                                        height: 24px;
                                        background: url('/lg5-common/images/icons/btn-radio-disabled-24-black.svg') no-repeat center/100%;
                                    }
                                }
                            }
                        }
                    }
                }
                .afilter_colorchip {
                    margin-top: 0;
                    padding-top: 0;
                    border-top: 0;
                    ul {
                        white-space: nowrap;
                        overflow-x: auto;
                        margin-top: -16px;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        li {
                            display: inline-block;
                            vertical-align: middle;
                            width: auto;
                            margin-top: 16px;
                            margin-right: 20px;
                            .chk-wrap-colorchip {
                                display: flex;
                                align-items: center;
                                input {
                                    &::before {
                                        width: 40px;
                                        height: 40px;
                                    }
                                    &::after {
                                        top: -4px;
                                        left: 26px;
                                    }
                                    &:checked {
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: -4px;
                                            right: -4px;
                                            width: 18px;
                                            height: 18px;
                                            background: url(/lg5-common/images/icons/icon-colorchip-check.svg) no-repeat center/100%;
                                            z-index: 1;
                                        }
                                    }
                                    & + label {
                                        display: flex;
                                        align-items: center;
                                        max-width: 137px;
                                        min-height: 40px;
                                        padding:0 0 0 52px;
                                        border: 0;
                                        @include font(11px,16px);
                                        color: #666;
                                        vertical-align: middle;
                                        text-align: left;
                                        word-break: break-all;
                                        white-space: normal;
                                        &::before {
                                            top: 8px;
                                            left: 8px;
                                            width: 24px;
                                            height: 24px;
                                            border: none;
                                            transform: scale(1.6665);
                                        }
                                        &::after {
                                            content: none;
                                        }
                                    }
                                    &:checked + label {
                                        font-weight: 700;
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                    &.objet {
                        margin-top: 16px;
                        padding-top: 12px;
                        border-top: 1px solid #ddd;
                        ul {
                            flex-wrap: nowrap;
                            white-space: nowrap;
                            overflow-x: auto;
                            li {
                                width: auto;
                                margin-right: 12px;
                                .chk-wrap-colorchip {
                                    display: flex;
                                    align-items: center;
                                }
                                input {
                                    &::after {
                                        top: -4px;
                                        left: 26px;
                                    }
                                    & + label {
                                        vertical-align: middle;
                                        width: 137px;
                                        min-height: 40px;
                                        padding:0 0 0 52px;
                                        text-align: left;
                                        word-break: break-all;
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
                .afilter_guide{
                    ul {
                        .lists {
                            border-bottom: 1px solid #ddd;
                            margin-bottom: 2px;
                            padding-bottom: 20px;
                            &.on {
                                box-shadow: none;
                                .accord-btn {
                                    &::after {
                                        background-image: url("/lg5-common/images/icons/btn-arr-16-up.svg");
                                    }
                                }

                            }
                            .head {
                                .accord-btn {
                                    padding: 8px 12px 0 12px;
                                    &::after {
                                        top: 12px;
                                        right: 10px;
                                        width: 12px;
                                        height: 12px;
                                        margin: 0;
                                        border: 0;
                                        background: url("/lg5-common/images/icons/btn-arr-16-down.svg") no-repeat center/100%;
                                    }
                                    .acc_tit {
                                        position: relative;
                                        display: inline-block;
                                        @include font(13px,19px);
                                        font-weight: 500;
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 10px;
                                            background-color: #e81953;
                                            opacity: 0.2;
                                        }
                                    }
                                }
                            }
                            .accord-cont {
                                padding: 5px 12px 0 12px;
                                @include font(11px,16px);
                                color: #666;
                                p {
                                    @include textEllipsisClamp(6);
                                };
                                .guide_link {
                                    display: inline-block;
                                    margin-top: 8px;
                                    padding-right: 15px;
                                    background: url("/lg5-common/images/icons/icon_afilter_guide_arr.svg") no-repeat right center/12px;
                                    @include font(10px,15px);
                                }
                            }
                        }
                    }
                }
            }
            .afilter_selectitem {
                position: relative;
                padding: 16px 16px 16px 0;
                border-top: 0;
                .selectitem_area {
                    padding-right: 32px;
                    ul {
                        display: flex;
                        white-space: nowrap;
                        overflow-x: auto;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        li {
                            position: relative;
                            display: inline-block;
                            margin-right: 16px;
                            padding: 0 33px 0 12px;
                            border-radius: 8px;
                            background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                            &:first-child {
                                margin-left: 16px;
                            }
                            &:last-child {
                                margin-right: 36px;
                            }
                            .item_name {
                                @include font(13px,31px);
                            }
                            .item_del {
                                position: absolute;
                                top: 8.5px;
                                right: 12px;
                                width: 14px;
                                height: 14px;
                                font-size: 0;
                                color: transparent;
                                background: url("/lg5-common/images/icons/btn-afilter-delete.svg") no-repeat center/100%;
                            }
                        }
                    }
                }
                .alldel_area {
                    position: absolute;
                    top: 12px;
                    right: 16px;
                    width: 70px;
                    height: 40px;
                    z-index: 1;
                    .item_alldel {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding-left: 35px;
                        background: url("/lg5-common/images/icons/btn-afilter-reset.svg") no-repeat right center/28px;
                        font-size: 0;
                        color: transparent;
                        &:before {
                            content:'';
                            position:absolute;
                            top: 0;
                            left: 5px;
                            z-index: 0;
                            width: 35px;
                            height: 40px;
                            background: linear-gradient(to right,rgba(255,255,255,0),#fff);
                            //background: url("/lg5-common/images/icons/icon_afilter_shadow_02.svg") no-repeat left center/auto 100%;
                        }
                    }
                }
            }
        }
    }
    .btn-filter {
        position: absolute;
        top: 0;
        height: 56px;
        right: 16px;
        z-index: 2;
        padding-left: 0;
        background-color: #f8f8f8;
        &:before {
            content:'';
            position:absolute;
            top: 0;
            left: -38px;
            z-index:0;
            width:38px;
            height: 56px;
            //background:transparent url("/lg5-common/images/icons/icon_afilter_shadow_01.svg") no-repeat right center/auto 100%;
            background: linear-gradient(to right, rgba(248,248,248,0), #f8f8f8 50%);
        }
        &:after {
            content:'';
            position:absolute;
            top: calc(50% - 7px);
            left: -12px;
            width: 1px;
            height: 14px;
            background-color: #d8d8d8;
        }
        .ico-filter {
            display: inline-block;
            position:relative;
            z-index:1;
            width: 33px;
            height: 33px;
            margin: 12px 0 0 0;
            padding: 0;
            border-radius: 8px;
            background: #000 url("/lg5-common/images/icons/btn_afilter_19.svg") no-repeat center/24px;
            @include mobile {
                em.text {
                    display: none;
                }
            }
        }
        .filter_count {
            position: absolute;
            top: 6px;
            right: -6px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #ea1917;
            @include font(10px,18px);
            color: #fff;
            font-weight: 500;
            text-align: center;
            z-index: 1;
            @include mobile {
                display: block;
            }
        }
        // @media screen and (min-width: 1025px) {
        //     display: none !important;
        // }
        &.applied {
            .ico-filter {
                background-image: url("/lg5-common/images/icons/btn_afilter_19.svg");
                //position: relative;
                //&:after {
                //    position: absolute;
                //    top: -7px;
                //    right: 0;
                //    width: 5px;
                //    height: 5px;
                //    border-radius: 50%;
                //    background-color: #ec455a;
                //    content: '';
                //}
            }
        }
    }
    .cate-m {
        @include tablet {
            position: relative;
            min-height: 76px;
            border-bottom: 1px solid #ddd;
            @include mobile {
                min-height: 65px;
            }
            .btn-filter {
                bottom: 0;
            }
        }
        //border-bottom: 12px solid #f4f4f4;
        background:#fff;
    }
    .lay-filter .plp-filter-wrap .filter-top {
        .filter-search-area {
            display: none;
        }
    }
    @media screen and (max-width: 1024px) {

        .lay-filter .plp-filter-wrap .filter-top {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 40px 0;
            border-bottom: 1px solid #ddd;

            .filter-search-area {
                display: inline-block;
                position: relative;
                //max-width: 216px;
                flex: 1;
                vertical-align: middle;

                .search-inner {
                     width: 100%;
                     height: 34px;
                     padding: 5px 52px 5px 16px;
                     border-radius: 17px;
                     background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                     .inp-txt {
                        width: 100%;
                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        font-size: 14px;
                        line-height: 24px;
                        color: #767676;
                     }
                     .btn-sch {
                        display: inline-block;
                        position: absolute;
                        top: 7px;
                        right: 16px;
                        width: 20px;
                        height: 20px;
                        background: url('/lg5-common/images/icons/icon-search-20.svg');
                    }
                }
            }
        }
    }
    .term-filter {
        padding:24px 0;
        border-radius:8px;
        // background:#f4f4f4;
        @include mobile {
            padding:40px 0;
        }
        .inner {
            @include clearfix;
            .filter-tit {
                // float:left;
                // width:14%;
                color:#000;
                @include font-medium;
                font-weight:700;
                line-height:26px;
                @include mobile {
                    float:none;
                    width:100%;
                    margin-bottom:6px;
                }
            }
            .filter-tbl {
                // float:left;
                display:table;
                width:100%;
                margin-top:16px;
                // width:86%;
                .cell {
                    display:table-cell;
                    width:14%;
                    vertical-align:top;
                }
                .rdo-cell {
                    width:10%;
                    padding-right:24px;
                    font-size:0;
                    white-space:nowrap;
                    // .rdo-wrap {
                    //     // margin-left:8px;
                    //     // &:first-child {
                    //     //     margin-left:0;
                    //     // }
                    // }
                }
                @media screen and (max-width: $desktop-s-width) {
                    display:block;
                    .cell {
                        // display:block;
                        margin-top:8px;
                        &:first-child {
                            margin-top:0;
                        }
                    }
                    .rdo-cell {
                        // width:auto;
                        padding-right:0;
                    }
                }
                @include mobile {
                    width:100%;
                    .cell {
                        display:block;
                        width:auto;
                        margin-top:12px;
                    }
                    .rdo-cell {
                        display:flex;
                        margin:0 -5px 0 2px;
                        .rdo-wrap {
                            // width:calc(100% / 4);
                            width:100%;
                            margin-left:-1px;
                            // margin-left:6px;
                            input + label {
                                width:100%;
                            }
                        }
                    }
                }
            }
            .datepic-tbl {
                display:table;
                width:100%;
                @include mobile {
                    display:block;
                }
                .dp-cell {
                    display:table-cell;
                    font-size:0;
                    vertical-align:top;
                    white-space:nowrap;
                    @include mobile {
                        display:block;
                    }
                    .bar {
                        display:inline-block;
                        width:24px;
                        color:#000;
                        text-align:center;
                        @include font-medium;
                        line-height:48px;
                        @include mobile {
                            line-height:46px;
                        }
                    }
                    .input-wrap.datepicker {
                        width:calc((100% - 24px) / 2);
                        margin-top:0;
                        input, input:read-only {
                            background:#fff;
                            & + .cell {
                                background:#fff !important;
                            }
                        }
                        .ui-calendar-container {
                            min-width:296px;
                        }
                        &.right {
                            .ui-calendar-container {
                                left:auto;
                                right:0;
                            }
                        }
                    }
                }
                .btn-cell {
                    display:table-cell;
                    width:1%;
                    padding-left:8px;
                    @include mobile {
                        display:block;
                        width:100%;
                        margin-top:24px;
                        padding-left:0;
                        .btn {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
    .dimmed {
        display:none;
        @media screen and (max-width: 1024px) {
            display:block;
            opacity: 0.3;
        }
    }
    .mdevice:not(.app) {
        @include mobile {
            .lay-filter {
                .plp-filter-wrap {
                    padding-bottom:80px;
                }
            }
        }
    }
}
