// font Size PC / Mobile
@mixin font-x-small {
    // font-size: 12px;
    font-size: $font-x-small;
    line-height: 22px;

    @media screen and (max-width: #{$tablet-s-width}) {
        line-height: 20px;
    }
}

@mixin font-small {
    // font-size: 14px;
    font-size: $font-small;
    line-height: 24px;

    @media screen and (max-width: #{$tablet-s-width}) {
        font-size: $font-small - 1;
        line-height: 21px;
    }
}

@mixin font-medium {
    // font-size: 16px;
    font-size: $font-medium;
    line-height: 26px;

    @media screen and (max-width: #{$tablet-s-width}) {
        font-size : $font-medium - 2;
        line-height: 22px;
    }
}

@mixin font-large {
    // font-size: 18px;
    font-size: $font-large;
    line-height: 28px;

    @media screen and (max-width: #{$tablet-s-width}) {
        font-size : $font-medium - 1;
        line-height: 23px;
    }
}

@mixin font-large-1 {
    // font-size: 20px;
    font-size: $font-large-1;
    line-height: 30px;

    @media screen and (max-width: #{$tablet-s-width}) {
        font-size : $font-medium;
        line-height: 24px;
    }
}

@mixin font-large-2 {
    // font-size: 24px;
    font-size: $font-large-2;
    line-height: 34px;

    @media screen and (max-width: #{$tablet-s-width}) {
        font-size : 24px;
        line-height: 31px;
    }
}

@mixin font-large-3 {
    // font-size: 28px;
    font-size: $font-large-3;
    line-height: 38px;

    @media screen and (max-width: #{$tablet-s-width}) {
        font-size : $font-large-2;
        line-height: 32px;
    }
}

@mixin font-large-4 {
    // font-size: 32px;
    font-size: $font-large-4;
    line-height: 38px;

    //@media screen and (max-width: #{$tablet-s-width}) {
    //    font-size : $font-large;
    //    line-height: 26px;
    //}
}

@mixin font-large-5 {
    // font-size: 40px;
    font-size: $font-large-5;
    line-height: 46px;

    //@media screen and (max-width: #{$tablet-s-width}) {
    //    font-size : $font-large-1;
    //    line-height: 27px;
    //}
}

@mixin font-large-6 {
    // font-size: 48px;
    font-size: $font-large-6;
    line-height: 54px;

    //@media screen and (max-width: #{$tablet-s-width}) {
    //    font-size : $font-large-2 - 2;
    //    line-height: 26px;
    //}
}

@mixin font($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height;
}

/*
 * clearfix
 * @include clearfix;
*/
@mixin clearfix () {
    &:after { content: ""; display: block; clear: both;}
}

// only PC
@mixin pc {
    @media screen and (min-width: #{$tablet-s-width + 1}) {
        @content;
    }
}

// 1600 size
@mixin pc-size-l {
    @media screen and (max-width: #{$desktop-l-width}) {
        @content;
    }
}

// 1460 size
@mixin pc-size-m {
    @media screen and (max-width: #{$desktop-m-width}) {
        @content;
    }
}

// tablet
@mixin tablet {
    @media screen and (max-width: #{$tablet-l-width}) {
        @content;
    }
}

// tablet main
@mixin tablet-main {
    @media screen and (max-width: 1279px) {
        @content;
    }
}

// tablet main
@mixin tablet-main {
    @media screen and (max-width: 1279px) {
        @content;
    }
}

// Mobile
@mixin mobile {
    @media screen and (max-width: #{$tablet-s-width}) {
        @content;
    }
}

@mixin mobile-360-less {
    @media screen and (max-width: #{$mobile-s-width}) {
        @content;
    }
}

// 359 이하 해상도
@mixin mobile-360-under {
    @media screen and (max-width: 359px) {
        @content;
    }
}

/*
* overflowScrolling
* @include overflowScrolling;
*/
@mixin overflowScrolling () {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}


/*
* calc
* @include calc('width', 100%, 250px)
*/
@mixin calc($property, $value1, $value2) {
    #{$property}: calc( #{$value1} - #{$value2});
}


/*
* calc-mobile-size
* @include calc-mobile-size('width', 87px);
*/
@mixin calc-mobile-size($property, $expression) {
    #{$property}: (#{$expression / 2});
}


/*
* vw
* @include vw('font-size', 16px);
*/
$max-breakpoint: 1600;
$wide-screen: "(min-width: 1600px)";
// $wide-screen: "(max-width: #{$tablet-l-width})";
@function get-vw($target) {
    // 1 vw is equal to 1% of the viewport width
    $vw-context: ($max-breakpoint * .01) * 1px; // 1% viewport width
    @return ($target/$vw-context) * 1vw;
}

@mixin vw($property, $size) {
    #{$property}: get-vw($size);
    // Prevent font-size from getting too big
    @media #{$wide-screen} {
        #{$property}: $size;
    }
}


/*
* textEllipsis 한 줄
* @include textEllipsis;
*/
@mixin textEllipsis () {
    text-overflow:ellipsis;
    white-space:nowrap;
    word-wrap:normal;
    overflow:hidden;
}


/*
* textEllipsis 여러 줄
* @include textEllipsisClamp;
*/
@mixin textEllipsisClamp ($line-clamp) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
}


/*
* textClip
* @include textClip;
*/
@mixin textClipClamp ($line-clamp) {
    overflow:hidden;
    text-overflow:clip;
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
}


/*
* swiper
* @include swiper(center);
*/
@mixin swiper ($lcr) {

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        /* Center slide text vertically */
        -webkit-box-pack: $lcr;
        -ms-flex-pack: $lcr;
        -webkit-justify-content: $lcr;
        justify-content: $lcr;
        -webkit-box-align: $lcr;
        -ms-flex-align: $lcr;
        -webkit-align-items: $lcr;
        align-items: $lcr;
    }
}


/*
* translate3D
* @include translate3D;
*/
@mixin translate3D () {
    -webkit-transform: translate3D(0, 0, 0);
    -ms-transform: translate3D(0, 0, 0);
    -o-transform: translate3D(0, 0, 0);
    transform: translate3D(0, 0, 0);
}


/*
* scale
* @include scale(-1, 1);
*/
@mixin scale ($sx, $sy) {
    -webkit-transform: scale($sx, $sy);
    -ms-transform: scale($sx, $sy);
    -o-transform: scale($sx, $sy);
    transform: scale($sx, $sy);
}

/*
* transition
* @include allTransition();
*/
@mixin allTransition () {
    transition: all cubic-bezier(0.65, 0.05, 0.36, .5) .2s;
}

@mixin  blind {
    position:absolute;z-index:-10;width:0 !important;height:0 !important;overflow:hidden !important;font-size:medium;line-height:normal;word-break:break-all
}

@mixin text-underline ($bottom,$color) {
    position: relative;
    &:after {
        position: absolute;
        bottom: $bottom;
        right: 0;
        left: 0;
        height: 1px;
        background-color: $color;
        content: '';
    }
}

@mixin text-through ($color) {
    position: relative;
    &:after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color;
        content: '';
    }
}

@mixin progress-on-icon {
    color: #ec455a;
    &::before {
        border: 1px solid #aaa;
        background: url(/lg5-common/images/bg-chk-red.svg) no-repeat center #fff;
        background-size: 12px;
    }
}
@mixin progress-on-bar {
    &::after {
        background-color: #ec455a;
    }
}
@mixin progress-on-text {
    &::before {
        background: #fff;
        border: 1px solid #aaa;
    }
    .text {
        color: #000;
    }
}

/* component mixin */
@mixin font-family($font, $sytle:normal) {
    @if ($font == $font-weight-regular) or ($font == $font-weight-bold) {
        @if $sytle == italic {
            font-style: italic;
        } @else if $sytle == normal {
            font-style: normal;
        }
    }

    //font-weight:normal;
    @if ($font == $font-weight-regular) {
        font-weight: 400;
    } @else if ($font == $font-weight-medium) {
        font-weight: 500;
    } @else if ($font == $font-weight-bold) {
        font-weight: 700;
    } @else {
        font-weight: 400;
    }
}

// Title Font Mixin
@mixin eyebrow {
    margin-bottom:8px;
    font-weight:700;
    font-size:24px;
    line-height:34px;
    @include mobile {
        margin-bottom:6px;
        font-size:16px;
        line-height:24px;
    }
    &:only-child {
        margin-bottom:0;
    }
}
@mixin eyebrow-h3 {
    margin-bottom:8px;
    font-size:16px;
    line-height:26px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        margin-bottom:6px;
        font-size:14px;
        line-height:22px;
    }
    &:only-child {
        margin-bottom:0;
    }
}
@mixin title-h1 {
    font-size:52px;
    line-height:70px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        font-size:32px;
        line-height:42px;
    }
}
@mixin title-h2 {
    font-size:48px;
    line-height:66px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        font-size:32px;
        line-height:42px;
    }
}
@mixin title-h3 {
    font-size:40px;
    line-height:56px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        font-size:27px;
        line-height:38px;
    }
}
@mixin title-h4 {
    font-size:32px;
    line-height:52px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        font-size:24px;
        line-height:33px;
    }
}
@mixin title-h5 {
    font-size:24px;
    line-height:34px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        font-size:20px;
        line-height:28px;
    }
}
@mixin title-h6 {
    font-size:24px;
    line-height:34px;
    font-weight:700;
    @media screen and (max-width: #{$tablet-s-width}) {
        font-size:20px;
        line-height:28px;
    }
}

@mixin boxs ($size) {
    @if $size == w { // wide
        max-width:((1146px * 100%) / 1380px);
    } @else if $size == n { // narrow
        max-width:((678px * 100%) / 1440px);
    }
    @include mobile {
        max-width:100%;
    }
}

@mixin bodycopy ($own, $prev) {
    @if $own == h1 or $own == h2 {
        @include font-large;
    } @else if $own == h3 {
        font-size:16px;
        line-height:26px;
        @include mobile {
            font-size:14px;
            line-height:22px;
        }
    }
    
    @if $prev == h1 {
        margin-top:32px;
        @include mobile {
            margin-top:16px;
        }
    } @else if $prev == h2 {
        margin-top:20px;
        @include mobile {
            margin-top:12px;
        }
    } @else if $prev == h3 {
        margin-top:16px;
        @include mobile {
            margin-top:14px;
        }
    }

    &:first-child {
        margin-top:0;
    }
}

@mixin cta-btn {
    margin-top:32px;
    font-size:0;
    &:first-child {
        margin-top:0;
    }
    // S : BTOCSITE-71915 [스토리개편] 홈메인 전시개편
    &.type2 {
        @include mobile {
            margin-bottom: -10px;
        }
        .text-link {
            @include mobile {
                margin-bottom: 10px;
            }
        }
    }
    // E : BTOCSITE-71915 [스토리개편] 홈메인 전시개편
    .btn.border,
    .text-link {
        margin-right:16px;
        &:last-child {
            margin-right:0;
        }
    }
    @include mobile {
        margin-top: 24px;
    }
}


@mixin grid-row () {
    margin: 0 -12px;

    &:after {
        clear: both;
        display: table;
        content: '';
    }

    @media screen and (max-width: #{$tablet-s-width}) {
        margin: 0 -6px;
	}
}

@mixin grid-col ($align,$columns1,$columns2) {
    float: $align;
    width: (100% / 12) * $columns1;
    padding: 0 12px;

    @media screen and (max-width: #{$tablet-s-width}) {
        width: (100% / 12) * $columns2;
        padding: 0 6px;

        @if($columns2 == "12") {
            float: none;
            width: 100%;
        }

    }
}