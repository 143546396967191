.rounded-list {
    font-size: 0;
    li {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 8px;
        &:last-child {
            margin-right: 0;
        }
    }
    .rounded {
        display: inline-block;
        padding: 6px 12px;
        border-radius: 8px;
        border: 1px solid #ddd;
        background-color: #fff;
        .text {
            display: block;
            @include font-small;
        }
    }
    @include mobile {
        //li {
        //    display: block;
        //    margin-right: 0;
        //    margin-bottom: 6px;
        //}
        .rounded {
            .text {
                font-size: $font-small;
                line-height: 22px;
            }
        }
    }
}

.search-layer {
    display: none;
    overflow-y: auto;
    position: fixed;
    top: 150%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 105;
    background-color: #fff;
    width: 100%;
    min-width: 100%;

    &.open {
        display:block !important;
        top: 0;
        @include mobile {
            .btn-close {
                top: 24px;
            }
            .input-keyword-wrap {
                .input-keyword {
                    top: 0;
                }
            }
        }
    }

    //&:after {
    //    content: '';
    //    position: fixed;
    //    top: 0;
    //    bottom: 0;
    //    left: 50%;
    //    transform: translateX(-50%);
    //    width: 1380px;
    //    background-color: red;
    //    opacity: .2;
    //}

    .btn-close {
        position: absolute;
        top: 48px;
        @include calc(right,50%,690px + 48px);
        width: 32px;
        height: 32px;
        background: url('/lg5-common/images/icons/btn-close-32.svg') no-repeat 0 0;

        @media screen and (max-width: 1606px) {
            right: 48px;
        }
        @include mobile {
            top: 150%;
        }
    }

    .inner {
        max-width: (832px + 160px);
        margin: 0 auto;
        padding: 180px 40px;
    }

    .input-keyword-wrap {
        position: relative;
        margin-bottom: 0;
        padding: 0;
        @include pc {
            .input-keyword {
                max-width: 100%;
            }
        }

        //.input-sch {
        //    input.txt {
        //        &:focus {
        //            outline: none;
        //            border-bottom: 2px solid transparent;
        //            background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(119deg, #9c86e7 0%, #de64a0 100%) border-box;
        //        }
        //    }
        //}

        .search-similar {
            margin-top: 64px;
            @include mobile {
                margin-top: 20px;
            }
            .similar-text {
                @include font($font-large-1,30px);
                @include text-underline(0,$color-default);
                text-decoration: none;
                @include mobile {
                    @include font(15px,23px);
                }
                .search-word {
                    position: relative;
                    display: inline-block;
                    margin-right: 8px;
                    font-weight: 700;
                    color: $color-default;
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background-color: #000;
                    }
                    .word {
                        color: $color-primary;
                    }
                }
                &:after {
                    width: auto;
                    background: none;
                }
            }
        }
    }

    @include mobile {
        //min-height: auto !important;
        //&:before {
        //    position: fixed;
        //    top: 0;
        //    right: 0;
        //    left: 0;
        //    z-index: 3;
        //    height: 72px;
        //    background-color: #fff;
        //    content: '';
        //}
        .btn-close {
            position: fixed;
            right: auto;
            left: 8px;
            z-index: 103;
            width: 32px;
            height: 46px;
            background: url('/lg5-common/images/icons/btn-arr-32x32-headback.svg') no-repeat 0 center;
            background-size: 32px;
        }

        .inner {
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: (24px + 66px) 0 60px;
        }

        .input-keyword-wrap {
            .input-keyword {
                position: fixed;
                top: 150%;
                left: 0;
                right: 0;
                z-index: 3;
                padding: 24px 52px 20px 52px;
                background-color: $color-bg-wt;
                max-width: 100%;
                min-width: 100%;
            }
        }
    }

    .title-line {
        display: block;
        margin-bottom: 24px;
        font-weight: 700;
        @include font(18px,30px);
        @include mobile {
            margin-bottom: 16px;
        }
    }
    .recent-keyword{
        .title-line{
            margin-bottom: 18px;
            @include mobile {
                margin-bottom: 12px;
            }
        }
    }

    .search-keyword-area {
        display: flex;
        flex-wrap: wrap;
        margin-top: 48px !important;
        padding-top: 80px;
        @include pc {
            margin: 0 -32px;
            > div {
                flex: 1;
                //padding: 0 32px;
            }
            > .recent-keyword {
                width: 100%;
                flex: none;
            }

            > div.srch-direct-wrap {
                flex: none;
                width: 100%;
            }
        }
        @include mobile {
            display: block;
            width: 100%;
            min-width: 100%;
            margin-top: 0 !important;
            margin-left: -30px;
            padding-top: 20px;
            > div {
                padding: 0 0 40px 0;
                &:last-child {
                    flex: auto;
                    padding-bottom: 0;
                }
            }
            > div.popular-keyword {
                padding: 0 0 40px 0;
                margin: 0 12px 20px 12px;
            }
            > div.symptom-keyword {
                margin: 0 12px;
            }
            > div.recent-keyword {
                flex: none;
                width: 100%;
                min-width: 100%;
                padding-bottom: 32px !important;
                .title-line {
                    padding: 0 16px;
                }
            }
        }
    }
}

.search-keyword-area {
    .recent-keyword {
        position: relative;
        margin-bottom: 80px;
        padding-bottom: 60px !important;
        border-bottom: 1px solid #ddd;
        @include mobile {
            position: relative;
            border-bottom: 0;
            margin-bottom: 0;
        }
        .keyword-list {
            @include mobile {
                position: relative;
                height: 40px;
            }
            ul {
                @include mobile {
                    position: relative;
                    white-space: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    -ms-overflow-style: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
            li {
                display: inline-block;
                position: relative;
                margin-right: 16px;
                padding: 8px 16px;
                border-radius: 37px;
                background-color: #f4f4f4;
                max-width: 280px;
                max-height:40px;
                white-space: initial;
                vertical-align: top;
                @include mobile {
                    max-width: none;
                    margin-top: 0;
                    padding: 9px 12px 9px 16px;

                    &:first-child {
                        margin-left: 16px;
                    }
                }
                .box {
                    a{
                        @include textEllipsisClamp(1);
                        word-break: break-all;
                    }
                }
            }
            .all-delete {
                position: absolute;
                bottom: 24px;
                right: 12px;
                text-align: right;
                @include mobile {
                    bottom: auto;
                    right: 16px;
                    top: -32px;
                    //color: #0c5f7f;
                }
                a{
                    display: inline-block;
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: underline;
                    @include mobile {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
    .popular-keyword {
        @include mobile {
            position: relative;
            overflow: hidden;
            padding:0 0 32px 0;
            margin-bottom:20px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 30px;
                width: 100%;
                height: 1px;
                background-color: #ddd;
            }
        }
        .keyword-list{
            display: flex;
            ul {
                padding-right: 24px;
                flex: 1;
                li{
                    &:first-child{
                        margin-top: 0;
                    }
                    a {
                        @include mobile {
                            @include font(16px,22px);
                        }
                    }
                }
            }
        }
    }
    .symptom-keyword {
        .keyword-list{
            ul {
                li{
                    position: relative;
                    padding-left: 12px;
                    &:first-child{
                        margin-top: 0;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 12px;
                        left: 0;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #000;
                        @include mobile {
                            top: 8px;
                        }
                    }
                    a {
                        @include mobile {
                            @include font(16px,22px);
                        }
                    }
                }
            }
        }
    }
    .keyword-list {
        .no-data {
            padding: 0;
            @include font-medium;
            color: $color-gray-1;
            text-align: left;
        }
        li {
            margin-top: 8px;
            a {
                display: block;
                height: 26px;
                //margin-bottom: 8px;
                @include mobile{
                    height: 21px;
                }
                @include textEllipsisClamp(1);
                @include font(16px,24px);
                @include mobile {
                    @include font(14px,20px);
                }
                &.rounded {
                    height: auto;
                }

                b {
                    display: inline-block;
                    width: 34px;
                    margin-right: 8px;
                    @include mobile {
                        width: 26px;
                        margin-right: 4px;
                    }
                };
            }
            .box {
                position: relative;
                display: inline-block;
                padding-right: 36px;
                @include mobile{
                    height: 23px;
                    padding-right: 23px;
                }
            }
        }
        .rounded-list {
            li {
                margin: 0 8px 8px 0;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .btn-delete {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 26px;
        margin-top: 0; //간격수정
        background: url('/lg5-common/images/icons/btn-clear-32.svg') no-repeat center/16px;
        @include mobile {
            top: 4px;
            width: 16px;
            height: 16px;
            background-size: 100%;
        }
    }
    .layer_close {
        @include mobile {
            display: block;
        }
        display: none;
        margin-top: 40px;
        text-align: right;
        a {
            display: inline-block;
            @include font(12px,17px);
            font-weight: 700;
            text-decoration: underline;
        }
    }
    @include mobile {
        .keyword-list {
            li {
                .box {
                    a {
                        height: 22px;
                    }
                }
            }
        }
    }
}

.search-result-area {
    display: flex;
    padding-top: 20px;
    .auto-complete {
        position: relative;
        width: 100%;
        padding:0 54px 0 16px;
        @include mobile {
            width: 100%;
            padding: 0 0 40px;
        }
        li {
            background: url('/lg5-common/images/icons/btn-autocomplete-26.svg') no-repeat right 18px center/24px;
            @include mobile {
                background-size: 16px;
                background-position: right 12px center;
                &.on,
                &:hover,
                &:focus {
                    border-radius: 17px;
                    background-color: $color-bg;

                    a {
                        font-weight: 700;
                    }
                }
            }
            a {
                @include mobile {
                    font-size: 15px;
                    line-height: 21px;
                }
            }
            &:focus {
                border-radius: 8px !important;
            }
            &:hover {
                border-radius: 8px !important;
            }
        }

        .prod-direct-list {
            li {
                &:hover a,
                &:focus a {
                    font-weight: 400;
                }
            }
        }
    }


    .result-preview {
        //display: table-cell;
        width: ((100% / 10) * 7);
        padding-left: 32px;
        .result-category {
            padding-bottom: 40px;
        }
    }

    @include mobile {
        display: block;
        padding-top: 0;
        .result-preview {
            display: block;
            width: 100%;
            padding-left: 0;
            .result-category {
                padding-bottom: 24px;
            }
        }
    }
}

.result-preview-list {
    ul {
        display: flex;
        flex-wrap: wrap;
        > li {
            @include calc(width,50%,12px);
            &:nth-child(odd) {
                margin-right: 24px;
            }
            &:nth-child(2) ~ li {
                margin-top: 24px;
            }
        }
    }
    .item {
        position: relative;
        display: block;
        height: 100%;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
        font-size: 0;
        .image {
            position: absolute;
            top: 12px;
            left: 17px;
            max-width: 120px;
            img {
                width: 120px;
            }
        }
        .info {
            min-height: 118px;
            padding: 12px 0 12px 130px;
            @include font-medium;
            .name {
                display: block;
                font-weight: 700;
                @include textEllipsis;
            }
            .sku {
                display: block;
                @include textEllipsis;
                color: $color-gray-1;
            }
            .price {
                display: block;
                margin-top: 16px;
                font-weight: 700;
            }
        }
    }

    @include mobile {
        ul {
            > li {
                @include calc(width,50%,4px);
                &:nth-child(odd) {
                    margin-right: 8px;
                }
                &:nth-child(2) ~ li {
                    margin-top: 8px;
                }
            }
        }
        .item {
            padding: 16px;
            .image {
                position: static;
                max-width: 100%;
                margin-bottom: 8px;
                text-align: center;
                img {
                    width: auto;
                    max-width: 128px;
                }
            }
            .info {
                min-height: auto;
                padding: 0;
            }
        }
    }
}

.prod-direct-list {
    margin-bottom: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
    @include mobile {
        margin: 0 20px 8px;
        padding-bottom: 12px;
    }
    ul {
        margin-top: -8px;
        @include mobile {
            margin-top: 0;
        }
        li {
            background: none !important;
            a {
                display: flex;
                align-items: center;
                position: relative;
                min-height: 64px;
                padding: 8px 16px;
                @include mobile {
                    min-height: 43px;
                    padding: 8px 8px;
                }
                &:hover {
                    background: #f4f4f4;
                }
            }
            .prod-img {
                display: inline-block;
                width: 64px;
                height: 64px;
                @include mobile {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .model-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 100%;
                padding: 0 24px;
                @include mobile {
                    width: calc(100% - 130px);
                    padding: 0 12px;
                }
                .model-name {
                    color: #000;
                    & > .srch-word {
                        color: #ea1917;
                    }
                    @include font(18px, 26px);
                    @include mobile {
                        @include font(15px, 21px);
                    }
                }
                .prod-path {
                    font-size: 0;
                    @include mobile {
                        line-height: 1;
                    }
                    > span {
                        display: inline-block;
                        position: relative;
                        padding-right: 16px;
                        color: #666;
                        @include font(14px, 26px);
                        @include mobile {
                            padding-right: 12px;
                            @include font(12px, 16px);
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 0;
                            width: 16px;
                            height: 16px;
                            background: url(/lg5-common/images/icons/btn-down-16-gray.svg) no-repeat 50% 50%;
                            transform: translateY(calc(-50% + 1px)) rotate(-90deg);
                        }
                        @include mobile {
                            &::after {
                                width: 12px;
                                height: 12px;
                                background-size: 12px 12px;
                                transform: translateY(-50%) rotate(-90deg);
                            }
                        }
                        &:last-child {
                            padding-right: 0;
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
            .txt-direct {
                position: absolute;
                top: 50%;
                right: 8px;
                padding-right: 28px;
                color: #000;
                @include font(16px, 26px);
                transform: translateY(-50%);
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    background: url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat 50% 50%;
                    transform: translateY(-50%);
                }
                @include mobile {
                    right: 8px;
                    padding-right: 20px;
                    @include font(12px, 21px);
                    font-weight: 500;
                    &::after {
                        width: 16px;
                        height: 16px;
                        background-size: 16px 16px;
                    }
                }
            }
        }
    }
}

.brandhall {
    margin-bottom: 12px;
    @include mobile {
        padding: 0 20px;
        margin-bottom: 10px;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 18px 20px 36px;
        border: 1px solid #e4e4e4;
        background-repeat: no-repeat;

        .brand-img {
            display: inline-block;
            height: 18px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            transform: scale(1.3);
            transform-origin: 0 50%;

            &.b-collection {
                width: 97px;
                background-image: url(/lg5-common/images/header/logo-objet-collection.svg)
            }
            &.b-signature {
                width: 103px;
                background-image: url(/lg5-common/images/header/logo-signature.svg)
            }
            &.b-thinkq {
                width: 58px;
                background-image: url(/lg5-common/images/header/logo-thinq.svg)
            }
            &.b-gram {
                width: 68px;
                background-image: url(/lg5-common/images/header/logo-lets-gram.svg)
            }
        }
        
        .txt-direct {
            display: inline-block;
            position: relative;
            padding: 7px 28px 7px 0;
            color: #333;
            .brand-name {
                color: #000;
            }
            @include font(16px, 26px);
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 24px;
                height: 24px;
                background: url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat 50% 50%;
                transform: translateY(-50%);
            }
        }
        @include mobile {
            padding: 21px 10px 21px 24px;
            .brand-img {
                transform: scale(1.2);
            }
            .txt-direct {
                padding: 0 20px 0 0;
                @include font(12px, 21px);
                &::after {
                    width: 16px;
                    height: 16px;
                    background-image: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg);
                    background-size: 16px 16px;
                }
            }
        }
    }
    &.obs {
        display: none !important;
    }
}

// S : BTOCSITE-59845 [검색고도화] Quick-Win: 검색어 입력 개선
.search-layer.enhance {
    @include pc {
        max-width: 920px;
        min-width: auto;
        border-radius: 20px;
    }
    &.open {
        @include pc {
            top: 18px;
            right: auto;
            bottom: auto;
            left: calc(50% + 80px);
            max-height: calc(100% - 18px);
            border: 1px solid #f3f3f3;
            transform: translateX(-50%);
            box-shadow: 4px 2px 8px 0 rgba(0, 0, 0, 0.15);
        }
        @media screen and (min-width: 1421px) and (max-width: 1730px) {
            left: initial;
            right: 340px;
            transform: none;
        }
        @media screen and (max-width: 1420px) {
            left: 50%;
        }
        @include tablet {
            left: 50%;
        }
        @include mobile {
            left: 0;
        }
        .btn-close {
            position: relative;
            top: initial;
            right: initial;
            bottom: 20px;
            left: calc(100% - 130px);
            width: 60px;
            padding-right: 20px;
            background-position: right;
            background-size: 20px;
            @include mobile {
                position: fixed;
                top: 20px;
                left: 8px;
                width: 32px;
                padding-right: 0;
                background-position: center;
                background-size: 32px;
            }
            span.blind {
                @include pc {
                    position: static;
                    z-index: 1;
                    width: auto !important;
                    height: auto !important;
                    overflow: initial !important;
                    font-size: 14px;
                    line-height: 22px;
                    color: #666;
                    clip-path: unset;
                }
            }
        }
    }
    .inner {
        min-height:auto;
        padding: 50px 70px 20px;
        @include mobile {
            min-height: auto;
            padding: 83px 0 50px;
        }
        .input-keyword-wrap {
            margin-bottom: 40px;
            padding-top: 0;
            @include mobile {
                display: block;
                margin: 0;
            }
            .input-keyword {
                flex: none;
                width: 100%;
                padding: 0;
                @include mobile {
                    padding: 24px 16px 20px 52px;
                    border-bottom: 1px solid #eee;
                }
                .sch-input-wrap {
                    .input-sch {
                        input.txt {
                            height: 56px;
                            padding-right: 110px;
                            background-color: #f3f3f3;
                            font-size: 16px;
                            line-height: 24px;
                            @include mobile {
                                height: 38px;
                                padding: 9px 64px 9px 14px;
                                font-size: 16px;
                                font-weight: 400;
                            }
                            // S : BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                            &::placeholder {
                                font-weight: 400;
                                color: #666;
                            }
                            &::-moz-placeholder{
                                font-weight: 400;
                                color: #666;
                            }
                            &::-ms-input-placeholder{
                                font-weight: 400;
                                color: #666;
                            }
                            &::-webkit-input-placeholder{
                                font-weight: 400;
                                color: #666;
                            }
                            // E : BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                        }
                        .btn-delete {
                            right: 58px;
                            width: 20px;
                            height: 20px;
                            margin-top: 0;
                            transform: translateY(-50%);
                            @include mobile {
                                margin-right: 8px;
                                right: 32px;
                                width: 15px;
                                background-size: 16px 16px;
                            }
                        }
                        .btn-search, .btn-camera {
                            right: 10px;
                            @include mobile {
                                right: 0px !important;
                            }
                            &:after {
                                width: 24px;
                                height: 24px;
                                margin-left: 0;
                                background-size: 100%;
                                @include mobile {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .search-left-area {
                // S : BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                width:100%;
                max-width:100% !important;
                padding-left: 10px;
                padding-right:10px;
                @include mobile {
                    width: auto;
                    max-width: 100%  !important;
                    padding-left: 0;
                    padding-right:0;
                }
                // E : BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                .search-keyword-area {
                    display: block;
                    flex: none;
                    padding: 0;
                    @include mobile {
                        padding-top: 24px;
                    }
                    & > div {
                        @include pc {
                            margin-bottom: 30px;
                        }
                        @include mobile {
                            padding-bottom: 40px !important;
                        }
                    }
                    .title-line {
                        margin-bottom: 14px;
                        font-size: 16px;
                        line-height: 24px;
                        @include mobile {
                            margin-bottom: 12px;
                            padding: 0 16px;
                            font-size: 14px;
                            line-height: 20px;
                        }
                        .normal {
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: 400;
                            @include mobile {
                                margin-left: 8px;
                            }
                        }
                    }
                    .recent-keyword {
                        border: 0;
                        @include pc {
                            padding-bottom: 0 !important;
                        }
                        .keyword-list {
                            @include mobile {
                                height: auto;
                            }
                            ul {
                                display: flex;
                                flex-flow: row wrap;
                                @include mobile {
                                    display: block;
                                    padding: 0 16px;
                                }
                            }
                            li {
                                margin: 0 6px 10px 0;
                                padding: 6px 10px 6px 14px;
                                border: 1px solid #ddd;
                                background-color: #fff;
                                @include mobile {
                                    margin: 0 4px 0 0;
                                    padding: 8px 8px 8px 14px;
                                }
                                .box {
                                    @include pc {
                                        padding-right: 24px;
                                    }
                                    a {
                                        height: auto;
                                        font-size: 14px;
                                        line-height: 20px;
                                        @include mobile {
                                            line-height: 22px;
                                        }
                                    }
                                }
                            }
                            .all-delete {
                                @include pc {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                                a {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: #8f8f8f; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                                    text-decoration: none;
                                    @include mobile {
                                        font-size: 11px;
                                    }
                                }
                            }
                        }
                        .no-data {
                            padding: 0;
                            line-height: 24px;
                            text-align: left;
                            color: #8f8f8f; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                            @include mobile {
                                padding: 0 16px;
                                font-size: 13px;
                                line-height: 22px;
                            }
                        }
                    }
                    .popular-keyword {
                        @include mobile {
                            margin: 0;
                        }
                        &:after {
                            @include mobile {
                                content: none;
                            }
                        }
                        .title-line {
                            display: flex;
                            .btn-refresh {
                                position: relative;
                                margin-left: auto;
                                padding-left: 18px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                color: #8f8f8f; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                                @include mobile {
                                    font-size: 11px;
                                }
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 4px;
                                    left: 0;
                                    width: 16px;
                                    height: 16px;
                                    background: url(/lg5-common/images/icons/btn-reset-16.svg) no-repeat center / 14px;
                                    transform: rotate(-50deg);
                                    @include mobile {
                                        top: 1px;
                                    }
                                }
                            }
                        }
                        .keyword-list {
                            max-height: 78px;
                            @include mobile {
                                max-height: 84px;
                            }
                            ul {
                                display: flex;
                                flex-flow: row wrap;
                                overflow: hidden;
                                padding-right: 0;
                                @include mobile {
                                    padding: 0 16px;
                                }
                                li {
                                    display: inline-block;
                                    max-width: 240px;
                                    margin: 0 6px 10px 0;
                                    padding: 6px 14px;
                                    border: 1px solid #fac7c7;
                                    border-radius: 37px;
                                    background-color: #fef3f3;
                                    @include mobile {
                                        margin: 0 4px 6px 0;
                                        padding: 8px 14px;
                                    }
                                    a {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        word-break: break-all;
                                        font-size: 14px;
                                        line-height: 20px;
                                        @include pc {
                                            height: auto;
                                        }
                                        @include mobile {
                                            line-height: 21px;
                                        }
                                        .flag {
                                            margin-right: 4px;
                                            font-size: 12px;
                                            color: #EA1917;
                                            vertical-align: top;
                                            @include mobile {
                                                line-height: 21px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .symptom-keyword {
                        position: relative;
                        margin-bottom:0; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                        @include mobile {
                            margin: 0;
                        }
                        // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                        .title-line {
                            margin-bottom:4px;
                            @include mobile {
                                margin-bottom:12px;
                            }
                        }
                        .keyword-list {
                            @include mobile {
                                height: 40px;
                            }
                            ul {
                                display: flex;
                                flex-flow: row wrap;
                                @include mobile {
                                    flex-flow: row nowrap;
                                    padding: 0 16px;
                                    overflow-x: auto;
                                    overflow-y: hidden;
                                }
                                &::-webkit-scrollbar {
                                    display: none;
                                }
                                li {
                                    display: inline-block;
                                    margin: 10px 6px 0 0; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                                    padding: 6px 14px;
                                    border: 1px solid #ddd;
                                    border-radius: 37px;
                                    background-color: #fff;
                                    white-space: initial;
                                    @include mobile {
                                        flex: none;
                                        max-width: 280px;
                                        margin: 0 4px 0 0;
                                        padding: 8px 14px;
                                    }
                                    &:before {
                                        content: none;
                                    }
                                    a {
                                        word-break: break-all;
                                        font-size: 14px;
                                        line-height: 20px;
                                        @include pc {
                                            height: auto;
                                        }
                                        .flag {
                                            position: relative;
                                            vertical-align: top;
                                            color: #ea1917;
                                            margin-right: 4px;
                                            padding-left: 23px;
                                            font-size: 12px;
                                            font-weight: 700;
                                            &.exhibition:before {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 19px;
                                                height: 19px;
                                                background: url(/lg5-common/images/icons/icon_exhibition.svg) no-repeat center / 100%;
                                            }
                                            &.event:before {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 19px;
                                                height: 19px;
                                                background: url(/lg5-common/images/icons/icon_event.svg) no-repeat center / 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .scroll-controls {
                            position: absolute;
                            top: 54px;
                            left: 0;
                            width: 100%;
                            @include mobile {
                                display: none !important;
                            }
                            button {
                                position: absolute;
                                top: 0;
                                &:before {
                                    position: absolute;
                                    top: 0;
                                    z-index: 1;
                                }
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 46%;
                                    width: 60px;
                                    height: 44px;
                                    transform: translateY(-50%);
                                }
                                &.prev {
                                    left: 0;
                                    &:after {
                                        left: 0;
                                        background-image: linear-gradient(to right,#fff 57%,rgba(244,244,244,0));
                                    }
                                }
                                &.next {
                                    right: 0;
                                    &:after {
                                        right: 0;
                                        background-image: linear-gradient(to left,#fff 57%,rgba(244,244,244,0));
                                    }
                                }
                            }
                        }
                    }
                    .btn-delete {
                        width: 20px;
                        height: 20px;
                        background: url(/lg5-common/images/icons/btn-close-24-black.svg) no-repeat center;
                        background-size: 20px;
                        @include mobile {
                            top: 2px;
                            background-size: 16px;
                        }
                    }
                    .layer_close {
                        display: none;
                    }
                }
                // 검색어 입력 레이어
                .search-result-area {
                    padding: 0;
                    background-color: #fff;
                    .auto-complete {
                        padding: 0 0 0 10px;
                        @include mobile {
                            padding: 0;
                        }
                    }
                    .keyword-modify-wrap {
                        position: relative;
                        margin-bottom: 22px;
                        font-size: 14px;
                        line-height: 22px;
                        text-align: left;
                        @include mobile {
                            margin-bottom: 0;
                            padding: 25px 16px 0;
                            font-size: 13px;
                            line-height: 20px;
                            text-align: center;
                        }
                        .modify-info {
                            font-size: 14px;
                            line-height: 22px;
                            @include mobile {
                                font-size: 13px;
                                line-height: 20px;
                            }
                        }
                        &:after {
                            // content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #ddd;
                            @include mobile {
                                content: none;
                            }
                        }
                        .srch-word {
                            color: #ea1917;
                        }
                    }
                    .keyword-modify-wrap.on + .srch-direct-wrap .brandhall > a {
                        margin-top: 24px;
                        border-top: 1px solid #eee;
                    }
                    .srch-direct-wrap {
                        .prod-direct-list {
                            margin-bottom: 20px;
                            padding: 0;
                            border: 0;
                            @include mobile {
                                margin: 0;
                            }
                            ul {
                                margin: 0;
                                li {
                                    border: 1px solid #ddd;
                                    border-radius: 8px;
                                    overflow: hidden;
                                    @include mobile {
                                        border: 0;
                                        border-bottom: 1px solid #eee;
                                        border-radius: 0;
                                    }
                                    a {
                                        padding: 20px;
                                        @include mobile {
                                            padding: 10px 16px;
                                        }
                                        .prod-img {
                                            flex: none;
                                            overflow: hidden;
                                            width: 60px;
                                            height: 60px;
                                            border-radius: 8px;
                                            @include mobile {
                                                border-radius: 6px;
                                            }
                                        }
                                        .model-info {
                                            @include mobile {
                                                width: 100%;
                                                padding: 0 14px;
                                            }
                                            .model-name {
                                                font-size: 12px;
                                                line-height: 18px;
                                                @include mobile {
                                                    font-size: 11px;
                                                    line-height: 16px;
                                                }
                                            }
                                            .main-title {
                                                font-size: 14px;
                                                line-height: 20px;
                                                @include mobile {
                                                    font-size: 13px;
                                                }
                                                .sub {
                                                    &:before {
                                                        content: '·';
                                                        margin: 0 3px;
                                                    }
                                                }
                                            }
                                        }
                                        .txt-direct {
                                            right: 20px;
                                            font-size: 0;
                                            @include mobile {
                                                right: 16px;
                                            }
                                            &:after {
                                                width: 20px;
                                                height: 20px;
                                                background-size: 18px;
                                                @include mobile {
                                                    width: 16px;
                                                    height: 16px;
                                                    background-size: 16px;
                                                    background-image: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .brandhall {
                            margin-bottom: 20px;
                            @include mobile {
                                margin-bottom: 0px;
                                padding: 0;
                            }
                            a {
                                justify-content: initial;
                                padding: 20px;
                                border: 1px solid #ddd;
                                border-radius: 8px;
                                @include mobile {
                                    padding: 10px 16px;
                                    border: 0;
                                    border-bottom: 1px solid #eee;
                                    border-radius: 0;
                                }
                                .brand-img {
                                    flex: none;
                                    overflow: hidden;
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 8px;
                                    transform: none;
                                    @include mobile {
                                        border-radius: 6px;
                                    }
                                    &.b-collection {
                                        background-image: url(/lg5-common/images/header/img-collection.png);
                                        & + .txt-direct .brand-name {
                                            @include mobile {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                    &.b-signature {
                                        background-image: url(/lg5-common/images/header/img-signature.png);
                                        & + .txt-direct .brand-name {
                                            @include mobile {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                    &.b-thinkq {
                                        background-image: url(/lg5-common/images/header/img-thinkq.png);
                                        & + .txt-direct .brand-name {
                                            @include mobile {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                    &.b-gram {
                                        background-image: url(/lg5-common/images/header/img-gram.png);
                                        & + .txt-direct .brand-name {
                                            @include mobile {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                                .txt-direct {
                                    width: 100%;
                                    margin-left: 14px;
                                    @include mobile {
                                        font-size: 14px;
                                        line-height: 15px;
                                    }
                                    &:after {
                                        width: 20px;
                                        height: 20px;
                                        background-size: 18px;
                                        @include mobile {
                                            width: 16px;
                                            height: 16px;
                                            background-size: 16px;
                                        }
                                    }
                                    .brand-name {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                    .keyword-list {
                        @include mobile {
                            padding: 14px 16px 0;
                        }
                        ul {
                            li {
                                a {
                                    padding: 10px 0 10px 6px;
                                    font-size: 16px;
                                    width: 100%;
                                    @include mobile {
                                        padding: 10px 0;
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                    .depth {
                                        position: relative;
                                        padding-right: 20px;
                                        @include mobile {
                                            padding-right: 16px;
                                        }
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            top: 50%;
                                            right: 0;
                                            width: 18px;
                                            height: 18px;
                                            background: url(/lg5-common/images/icons/btn-arr-24x24-black.svg);
                                            background-size: 16px 16px;
                                            background-position: center;
                                            transform: translateY(-50%);
                                            @include mobile {
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }
                                    }
                                    .line-area01 {
                                        display: inline-block;
                                        max-width: calc(100% - 73px);
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden; 
                                    }
                                    .type {
                                        margin-left: 8px;
                                        font-size: 12px;
                                        line-height: 20px;
                                        color: #8f8f8f; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                                        @include mobile {
                                            margin-left: 4px;
                                            font-size: 11px;
                                            line-height: 15px;
                                        }
                                    }
                                }
                                &.line-area-wrap {
                                    .type {
                                        vertical-align: top;
                                        line-height: 28px;
                                        @include mobile {
                                            position: relative;
                                            line-height: 26px;
                                            top: -2px;
                                        }
                                    }
                                }
                                .keyword_select {
                                    width: 44px;
                                    height: 44px;
                                    margin-left: 6px;
                                    background: url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat center;
                                    background-size: 18px;
                                    @include mobile {
                                        width: 40px;
                                        height: 40px;
                                        background-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .search-right-area {
                display:none !important; // BTOCSITE-76688 [검색] 검색어 입력 레이어 개선
                position: relative;
                width: 27%;
                max-width: 200px;
                margin-top: 40px;
                margin-left: auto;
                padding-right: 10px;
                @include mobile {
                    width: 100%;
                    max-width: 100%;
                    margin-top: 0;
                    margin-left: 0;
                    padding-right: 0;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: -40px;
                    width: 1px;
                    height: 100%;
                    background-color: #d9d9d9;
                    @include mobile {
                        content: none;
                    }
                }
                .today-ranking, .search-recommend {
                    flex: none;
                    text-align: left;
                    @include mobile {
                        margin-bottom: 50px;
                        width: auto;
                    }
                    .title-line {
                        margin-bottom: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        @include mobile {
                            margin-bottom: 12px;
                            padding: 0 16px;
                            font-size: 14px;
                            line-height: 20px;
                        }
                        .normal {
                            margin-left: 8px;
                            font-weight: 400;
                            @include pc {
                                display: block;
                                margin: 0;
                                margin-top: 6px;
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                    .ranking-list {
                        ul {
                            display: flex;
                            flex-wrap: wrap;
                            position: relative;
                            @include mobile {
                                flex-wrap: nowrap;
                                overflow-x: auto;
                                overflow-y: hidden;
                                padding: 0 16px;
                            }
                            &::-webkit-scrollbar {
                                @include mobile {
                                    display: none;
                                }
                            }
                            li {
                                position: relative;
                                @include mobile {
                                    width: 142px;
                                    display: inline-block;
                                }
                                &:not(:first-child) {
                                    margin-top: 20px;
                                    @include mobile {
                                        margin-top: 0;
                                        margin-left: 10px;
                                    }
                                }
                                .item__link {
                                    @include pc {
                                        display: flex;
                                    }
                                }
                                .item__image {
                                    flex: none;
                                    position: relative;
                                    overflow: hidden;
                                    width: 70px;
                                    height: 70px;
                                    border-radius: 8px;
                                    @include mobile {
                                        width: 142px;
                                        height: 142px;
                                    }
                                    &:before {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        background: rgba(0, 0, 0, 0.035);
                                        z-index: 1;
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .item__info {
                                    margin-left: 10px;
                                    @include mobile {
                                        margin-top: 10px;
                                        margin-left: 0
                                    }
                                    .item__name {
                                        @include textEllipsisClamp(2);
                                        font-size: 14px;
                                        line-height: 22px;
                                        @include mobile {
                                            word-break: break-all;
                                            font-size: 14px;
                                            line-height: 18px;
                                        }
                                        .main {
                                            word-break: break-all;
                                        }
                                        .sub {
                                            &:before {
                                                content: '·';
                                                margin: 0 3px;
                                            }
                                        }
                                    }
                                    .item__model-wrap {
                                        .item__sku {
                                            font-size: 12px;
                                            line-height: 16px;
                                            color: #666;
                                            @include mobile {
                                                font-size: 12px;
                                                line-height: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .today-ranking {
                    .ranking-list {
                        @for $i from 1 through 6 {
                            li:nth-of-type(#{$i}) {
                                .item__link{
                                    &:before{
                                        content:'';
                                        display:block;
                                        position:absolute;
                                        top: 4px;
                                        left: 4px;
                                        width: 18px;
                                        height: 18px;
                                        background:url("/kr/images/BRK/icon_ranking_view_num"+$i+".png");
                                        background-size:100% 100%;
                                        z-index: 2;
                                        @include mobile {
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// E : BTOCSITE-59845 [검색고도화] Quick-Win: 검색어 입력 개선

// BTOCSITE-64335[QA][IOS MO] 스스로 해결 페이지>검색창 진입 시 검색입력 박스 미노출 되는 현상
@media screen and (max-width: 767px){
    .search-layer.re22 .input-keyword-wrap, .wrap .contents.search.re22 .input-keyword-wrap{
        overflow:visible;
        overflow-x:hidden;
        overflow-y:scroll;
    }
}