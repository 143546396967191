.container {
    // Page Header 
    // 201123 수정 !
    // 중앙정렬로 가기로 협의했다고 함.
    .page-header {
        max-width:1380px + 80px;
        margin:0 auto;
        padding:16px 40px 64px;
        text-align:center;
        @include mobile {
            padding: 0 16px;
        }
        &.type2 {
            padding-bottom: 24px;
        }
        .title {
            font-weight:700;
            font-size: 24px;
            line-height: 34px;
            color: #000;
            @include mobile {
                line-height: 33px;
            }
        }
        .desc {
            margin-top:12px;
            .txt {
                display:inline-block;
                @include font-medium;
                vertical-align:middle;
            }
        }
        .btn-desc {
            margin-top:24px;
            font-size:0;
            /* display: flex;
            justify-content: center; */
            .btn {
                margin:0 4px;
            }
            @include mobile {
                margin-top: 18px;
            }
        }
        .link-desc {
            margin-top: 12px;
            .btn-link {
                color: $color-default;
                font-size:14px;
                line-height:24px;
                &:after {
                    width:16px;
                    height:16px;
                }
            }
            &.arrow-left {
                .btn-link {
                    padding-right: 0;
                    padding-left: 22px;
                    &:hover {
                        text-decoration:underline;
                    }
                    &:after {
                        right: auto;
                        left: 0;
                        transform: rotate(180deg);
                    }
                }
            }
            @include mobile {
                display: none;
            }
        }
        .tabs-wrap {
            max-width:1280px;
            margin:0 auto;
        }
        .tabs-wrap.btn-type {
            padding-top:20px;
            padding-bottom:24px;
            &:only-child {
                padding-top:24px;
                padding-bottom:12px;
            }
        }
        &.no-title {
            padding:48px 0 24px;
            background:#f4f4f4;
        }
        // 추가
        &.border.wide {
            padding: 10px 0 0;
            border-bottom: 8px solid $color-bg;
            @include mobile {
                border-bottom: 0;
            }
            .tabs-wrap.btn-type {
                padding-bottom: 24px;
                @include mobile {
                    padding-bottom: 12px;
                }
            }
        }
        &.wide {
            max-width: 100%;
            margin-bottom: 48px;
            padding: 10px 0 8px;
            @include mobile {
                margin-bottom: 32px;
                padding: 0;
            }
            h2 {
                max-width: 1380px;
                margin: 0 auto;
                padding: 0 40px;
                @include mobile {
                    padding: 0 16px;
                }
            }
        }

        .step-wrap {
            margin-top: 12px;
            .steps {
                font-size: 0;
                li {
                    display: inline-block;
                    font-weight: 700;
                    @include font-small;
                    color: $color-gray-4;
                    &:not(:last-child):after {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin: 0 14px;
                        background: url('/lg5-common/images/icons/btn-down-16-black.svg') no-repeat 0 0;
                        vertical-align: -3px;
                        opacity: .385;
                        transform: rotate(-90deg);
                        content: '';
                        @include mobile {
                            width: 16px;
                            height: 16px;
                            margin: 0 6px;
                            background-size: 100%;
                            vertical-align: -3px;
                        }
                    }
                    &.current {
                        color: $color-default;
                        &:not(:last-child):after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        // BTOCSITE-15196 동영상 파일 첨부 관련 아이폰 동영상
        .progress-wrap {
            margin-top:36px;
            margin-left: auto;
            margin-right: auto;
            max-width: 673px;
            ol {
                display:flex;
                justify-content: space-between;
                align-items: center;
                counter-reset:counter;
                position: relative;
                li {
                    //@at-root:relative;
                    display:flex;
                    flex-direction: column;
                    align-items:center;
                    counter-increment:counter;
                    position: relative;
                    justify-content: center;
                    background-color: #fff;
                    padding: 0 12px;
                    color:#000;
                    // BTOCSITE-16960 웹접근성 준수를 위한 수정 요청
                    &:nth-child(1) {
                        &:before {
                            content:'STEP 01';
                        }
                    }
                    &:nth-child(2) {
                        &:before {
                            content:'STEP 02';
                        }
                    }
                    &:nth-child(3) {
                        &:before {
                            content:'STEP 03';
                        }
                    }
                    &:nth-child(4) {
                        &:before {
                            content:'STEP 04';
                        }
                    }
                    &:nth-child(5) {
                        &:before {
                            content:'STEP 05';
                        }
                    }

                    &:before {
                        // content:'STEP 0' counter(counter); // BTOCSITE-16960 웹접근성 준수를 위한 수정 요청
                        display:inline-block;
                        // width:32px;
                        // height:32px;
                        // margin-right: 12px;
                        font-size:11px;
                        font-weight:$font-weight-bold;
                        line-height:32px;
                        // border-radius:50%;
                        color: #707070;
                        background-color:#ffffff;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    // &:not(:last-child):after {
                    //     content:'';
                    //     position:absolute;
                    //     top:15px;
                    //     left:33px;
                    //     right:-215px;
                    //     display:inline-block;
                    //     height:2px;
                    //     background:#ededed;
                    //     border-radius:2px;
                    //     margin:0 12px;
                    //     z-index: -1;
                    // }
                    .txt {
                        @include font-small;
                        font-weight:$font-weight-bold;
                        white-space:nowrap;
                    }

                    &.on {
                        color:#000;

                        &:before {
                            color: #707070;
                        }
                    }
                }
                &::before {
                    content:'';
                    position:absolute;
                    top:15px;
                    left:33px;
                    right:33px;
                    display:block;
                    height:2px;
                    background:#ededed;
                    z-index: -1;
                }
            }
            @include mobile {
                width:100%;
                margin-top: 32px;
                ol {
                    align-items: flex-start;
                    &:before {
                        z-index:1;
                    }
                    li {
                        flex-direction: column;
                        padding: 0 6px;
                        width:70px;
                        &:before {
                            // width:24px;
                            // height:24px;
                            line-height:24px;
                            margin-right:0;
                            margin-bottom:5px;
                            border-left:6px solid #fff;
                            border-right:6px solid #fff;
                            box-sizing: content-box;
                            z-index:1;
                        }
                        &:not(:last-child):after {
                            //top:11px;
                            //left:calc(50% + 18px);
                            //right:calc(-50% + 18px);
                            margin:0 6px;
                            width:20%;
                        }
                        &:first-child:nth-last-child(n+4), &:first-child:nth-last-child(n+4) ~ li { 
                            width:70px;
                        }
                        &:first-child:nth-last-child(n+4) ~ li:nth-child(2n + 2) {
                            &:after {
                                content:none;
                            }    
                        }
                        &:first-child:nth-last-child(n+4) ~ li:nth-child(n + 3) {
                            margin-top:0;
                        }
                        .txt {
                            font-weight:500;
                            font-size:11px;
                            line-height:1.3;
                            white-space: normal;
                        }
                    }
                    &::before {
                        top: 11px;
                    }
                }
            }
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                /* ie 호환성 이슈 */
                ol {
                    li {
                        margin: 0 auto;
                        &:first-child {
                            margin-right: auto;
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-left: auto;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    // sub page common layout : title + white box layout
    .cont-wrap {
        max-width: 1380px + 80px;
        &.narrow {  // 서브 화면 중 1380보다 작은 타입 추가
            max-width:1146px + 80px;
        }
        margin: 0 auto;
        padding: 0 40px 120px;
        @include mobile {
            padding: 0 0 60px;
            //border-top:12px solid #f4f4f4;
         }
        &.no-bd {
            @include mobile {
                border-top:none;
            }
        }

        .row-wrap {
            @include clearfix;
            .col-left {
                float: left;
                @include calc(width,100%,448px);
            }
            .col-right {
                float: left;
                width: 400px;
                margin-left: 48px;
                &.fixed {
                    position: fixed;
                    top: 60px;
                    right: 40px;
                    @media screen and (min-width: 1460px) {
                        right: 50%;
                        margin-right: -690px;
                    }
                }
            }
            @media screen and (max-width: $tablet-l-width) {
                .col-left,
                .col-right {
                    float: none;
                    width: 100%;
                }
                .col-left {
                    .section {
                        &:first-child {
                            padding-top: 0;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .col-right {
                    margin-left: 0;
                    &.fixed {
                        position: static;
                    }
                }
            }
            //@include mobile {
            //    .col-left,
            //    .col-right {
            //        float: none;
            //        width: 100%;
            //    }
            //    .col-right {
            //        margin-left: 0;
            //    }
            //}
        }

        // layout
        .tabs-wrap.cont-tab {
            @include mobile {
                padding-top:32px;
                padding-bottom:10px;
            }
        }

        .section {
            position:relative;
            .sec-tit {
                margin-bottom:16px;
                color:#000;
                font-size:16px;
                line-height:24px;
                font-weight:700;
            }
            .tit-wrap {
                position:relative;
                margin-bottom:48px;
                &.margin-sm {
                    margin-bottom:16px;
                }
                .tit {
                    padding-bottom:16px;
                    color:#000;
                    font-size:28px;
                    font-weight:700;
                    line-height:1.63;
                    border-bottom:2px solid #222;
                    .point {
                        color:$color-primary;
                    }
                    .tooltip-wrap {
                        padding-top:5px;
                    }
                    &-text {
                        margin-top: 12px;
                    }
                }
            }
            @include mobile {
                padding:40px 16px 0;
                .sec-tit {
                    margin-bottom:32px;
                }
                .tit-wrap {
                    margin-bottom:24px;
                    .tit {
                        font-size:24px;
                        line-height:1.38;
                        padding-bottom:14px;
                    }
                }
            }

            & + .accordion-section {
                margin-top: 120px;
                @include mobile {
                    margin-top: 0;
                }
            }
        }
    }
}

// mypage 사용하던 section-wrap 가져옴
.section-wrap {
    .sects {
        margin-top:80px;
        @include mobile {
            margin:0;
            padding:60px 16px;
            border-top:12px solid #f4f4f4;
        }
        &:first-child {
            margin-top:0;
            @include mobile {
                padding-top:0;
                border-top:none;
            }
        }
        &:last-child {
            @include mobile {
                padding-bottom:0;
            }
        }
        // title
        > .tit-wrap {
            position:relative;
            @include clearfix;
            margin-bottom:0;
            border-bottom:2px solid #222;
            @include mobile {
                padding-bottom:8px;
            }
            .tit {
                float:left;
                padding-bottom:16px;
                color:#000;
                @include font-large-1;
                font-weight:700;
                em {
                    color:$color-primary;
                }
                @include mobile {
                    padding:4px 0;
                    font-size:18px;
                    line-height:26px;
                    &:only-child {
                        padding-top:0;
                    }
                }
            }
            .flt-cont {
                float:right;
                padding-top:10px;
                font-size:0;
                a.btn-link {
                    margin-top:3px;
                    @include mobile {
                        margin-top:5px;
                    }
                }
                @include mobile {
                    padding-top:1px;
                }
                &.left {
                    float:left;
                    .tit {
                        float:none;
                        display:inline-block;
                        vertical-align:top;
                        @include mobile {
                            vertical-align:middle;
                        }
                    }
                    .tooltip-wrap {
                        margin-top:13px;
                        margin-left:4px;
                        @include mobile {
                            margin-top:4px;
                            vertical-align:top;
                            .tooltip-box {
                                left:0;
                                right:0;
                            }
                        }
                    }
                }
            }
            &.type2 {
                height:auto;
                // margin-bottom:12px;
                // padding-bottom:16px;
                // border-bottom:none;
                @include mobile {
                    // margin-bottom:20px;
                    // padding-bottom:14px;
                }
                .tit {
                    @include font-large-3;
                    @include mobile {
                        padding:0 0 7px;
                        font-size:20px;
                        line-height:28px;
                    }
                }
            }
            &.type3 {
                height:auto;
                margin-bottom:32px;
                border-bottom:none;
                @include mobile {
                    margin-bottom:20px;
                }
                .tit {
                    float:none;
                    padding-bottom:0;
                }
                .desc {
                    margin-top:12px;
                    color:#000;
                    @include font-medium;
                    word-break:keep-all;
                    @include mobile {
                        margin-top:8px;
                    }
                }
            }
            // BTOCSITE-13902
            &.type4 {
                height:auto;
                border-bottom:none;
                .tit {
                    @include font-large-3;
                    padding-bottom: 0;
                    @include mobile {
                        padding:0 0 7px;
                        font-size:20px;
                        line-height:28px;
                    }
                }
            }
        }
        // contents
        .section-inner {
            padding-top:48px;
            @include mobile {
                padding-top:32px;
            }
            &.type0 {
                padding-top:0;
            }
            &.type2 {
                padding-top:16px;
            }
            &.type3 {
                padding-top:80px;
                @include mobile {
                    padding-top:60px;
                }
            }
            // 탭 밑에 붙을 때 type4
            &.type4 {
                padding-top:32px;
                @include mobile {
                    padding-top:40px;
                }
            }
            .inner-sub-tit {
                margin-bottom:48px;
                color:#000;
                @include font-medium;
            }
            .inner-box {
                margin-top:80px;
                &.type2 {
                    margin-top:48px;
                }
                @include mobile {
                    margin-top:60px;
                }
                &:first-child {
                    margin-top:0;
                }
                .inner-tit {
                    margin-bottom:24px;
                    color:#000;
                    @include font-large-2;
                    font-weight:700;
                    @include mobile {
                        font-size:18px;
                        line-height:26px;
                        margin-bottom:32px;
                    }
                }
                div.inner-tit {
                    font-size:0;
                    @include mobile {
                        margin-bottom:12px;
                    }
                    > p {
                        display:inline-block;
                        @include font-large;
                        font-weight:700;
                        vertical-align:middle;
                        @include mobile {
                            font-size:16px;
                            line-height:24px;
                        }
                    }
                    .tooltip-wrap {
                        margin-left:4px;
                        padding-top:0;
                        vertical-align:middle;
                    }
                }
            }
            .btn-group {
                margin-top:32px;
            }
            .btn-moreview {
                margin-top:32px;
                @include mobile {
                    margin-top:24px;
                }
            }
        }
        &.modify{
            display:none;
        }
    }
    & + .btn-group {
        margin-top:80px;
        @include mobile {
            margin-top:60px;
            padding:0 16px;
        }
    }
}

// no-data
.no-data {
    // margin-top:60px;
    // text-align:left;
    // p {
    //     color:#616161;
    //     font-size:16px;
    //     line-height:24px;
    // }
    margin:0;
    padding:80px 0;
    // padding:140px 0;
    // border-top:1px solid #666;
    // border-bottom:1px solid #ddd;
    background-color: #fff;
    text-align:center;
    &.mg-type {
        padding:0;
        margin:140px 0;
    }
    p {
        position:relative;
        // padding-top:76px;
        color:#666;
        font-size:16px;
        line-height:26px;
        // &::before {
        //     content:' ';
        //     position:absolute;
        //     top:0;
        //     left:50%;
        //     margin-left:-30px;
        //     width:60px;
        //     height:60px;
        //     background:url('/lg5-common/images/icons/icon-nodata.svg') center no-repeat;
        //     background-size:60px 60px;
        // }
    }
    &.full {
        padding:(140px - 56px) 0;
    }
    @include mobile {
        padding: 60px 0;
        p {
            font-size:14px;
            line-height:22px;
        }
    }
}