// component 대응을 위해 indicator, arrow button slide-wrap 바깥에 스타일 지정
// carousel arrow button
.btn-arrow {
    position:relative;
    display:block;
    width:48px;
    height:48px;
    padding:4px;
    border-radius:50%;
    font-size:0;
    @include mobile {
        width:36px;
        height:36px;
    }
    &::before {
        content:' ';
        display:block;
        width:40px;
        height:40px;
        border-radius:50%;
        background-repeat:no-repeat;
        background-size:100%;
        box-shadow:2px 4px 16px 0 rgba(#000, .14);
        @include mobile {
            width:28px;
            height:28px;
        }
    }
    &.prev {
        &::before {
            background-image:url('/lg5-common/images/icons/btn-arr-40x40-lt.svg');
            @include mobile {
                background-image:url('/lg5-common/images/icons/btn-arr-40x40-lt-m.svg');
            }
        }
        &.disabled {
            // &::before {
            //     background-image:url('/lg5-common/images/icons/btn-arr-40x40-lt2.svg');
            //     @include mobile {
            //         background-image:url('/lg5-common/images/icons/btn-arr-40x40-lt-m2.svg');
            //     }
            // }
            display:none !important;
        }
    }
    &.next {
        &::before {
            background-image:url('/lg5-common/images/icons/btn-arr-40x40-rt.svg');
            @include mobile {
                background-image:url('/lg5-common/images/icons/btn-arr-40x40-rt-m.svg');
            }
        }
        &.disabled {
            // &::before {
            //     background-image:url('/lg5-common/images/icons/btn-arr-40x40-rt2.svg');
            //     @include mobile {
            //         background-image:url('/lg5-common/images/icons/btn-arr-40x40-rt-m2.svg');
            //     }
            // }
            display:none !important;
        }
    }
    &.ui_carousel_hidden {
        display:none !important;
    }
}

// carousel indicator button
.indi-wrap {
    font-size:0;
    text-align:center;
    .indi-conts {
        display:inline-block;
        vertical-align:middle;
    }
    .ui_carousel_play {
        display:inline-block;
        vertical-align:middle;
        margin-left:10px;
        @include mobile {
            margin-left:4px;
        }
        .btn-play {
            display:inline-block;
            width:20px;
            height:20px;
            background-size:100%;
            background-repeat:no-repeat;
            @include mobile {
                width:16px;
                height:16px;
            }
        }
        &.play {
            .btn-play {
                background-image:url('/lg5-common/images/icons/btn-carousel-play.svg');
            }
        }
        &.stop {
            .btn-play {
                background-image:url('/lg5-common/images/icons/btn-carousel-pause.svg');
            }
        }
    }
    li {
        display:inline-block;
        //padding:0 6px;
        vertical-align:top;
        @include mobile {
            //padding:0 4px;
        }
        .btn-indi {
            display:inline-block;
            width:18px;
            height:18px;
            text-align: center;
            font-size: 12px;
            &:before {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius:50%;
                background:#8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                content: '';
            }
            @include mobile {
                width:16px;
                font-size: 10px;
                &:before {
                    width: 8px;
                    height: 8px;
                }
            }
        }
        &.on .btn-indi {
            width:32px;
            &:before {
                width: 24px;
                border-radius:10px;
                background:#000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            }
            @include mobile {
                width:28px;
                &:before {
                    width:20px;
                    height:8px;
                }
            }
        }
    }
}

// visual slide COMMON
.slide-wrap {
    position:relative;
    .indi-wrap {
        position:absolute;
        left:0;
        right:0;
        bottom:24px;
        z-index:10;
        @include mobile {
            bottom:17px;
        }
        .indi-conts {
            font-size:0;
            text-align:center;
        }
    }
    .slide-content {
        .slide-track {
            @include clearfix;
            overflow:hidden;
        }
        .slide-conts {
            position:relative;
        }
    }
    .slide-controls {
        position:absolute;
        top:50%;
        left:50%;
        width:100%;
        max-width:1600px;
        transform:translateX(-50%);
        z-index:10;
        button {
            position:absolute;
            &.prev {
                left:40px;
                transform:translateY(-50%);
                @include mobile {
                    left:4px;
                }
            }
            &.next {
                right:40px;
                transform:translateY(-50%);
                @include mobile {
                    right:4px;
                }
            }
        }
    }
}

// main hero visual slide 
.slide-wrap.hero-banner {
    .slide-content {
        .slide-track {
            height:480px;
            @include mobile {
                height:auto;
            }
        }
        .slide-conts {
            height:480px;
            .bg {
                display:block;
                width:100%;
                height:100%;
                background-position:center center;
                background-size:auto 100%;
                background-repeat:no-repeat;
            }
            @include mobile {
                height:auto;
                .bg {
                    height:0;
                    padding-bottom:116.6%;
                    background-size:cover;
                }
            }
            .slide-info-area {
                position:absolute;
                top:50%;
                left:0;
                right:0;
                transform:translateY(-50%);
                @include mobile {
                    top:32px;
                    transform:translateY(0);
                }
                .inner {
                    width:auto;
                    max-width:1380px + 48px + 96px + 32px; // contents width + 텍스트-버튼 사이 간격 24px X 2 + 버튼width X 2 + 화면-버튼 사이 간격 16px X 2
                    margin:0 auto;
                    padding:0 (24px + 48px + 16px);
                    @include mobile {
                        padding:0 16px;
                    }
                    .title-box {
                        color:#000;
                        font-weight:700;
                        .eyebrow {
                            margin-bottom:8px;
                            font-size:24px;
                            line-height:34px;
                            @include mobile {
                                margin-bottom:6px;
                                font-size:16px;
                                line-height:24px;
                            }
                        }
                        .tit {
                            font-size:48px;
                            line-height:64px;
                            @include mobile {
                                font-size:26px;
                                line-height:35px;
                            }
                        }
                    }
                    .slide-btn {
                        display:inline-block;
                        width:auto;
                        min-width:124px;
                        margin-top:24px;
                        border:1px solid #222;
                        border-radius:25px;
                        padding:11px 13px;
                        @include font-medium;
                        font-weight:500;
                        color:#000;
                        text-align:center;
                        @include mobile {
                            min-width:auto;
                            margin-top:20px;
                            padding:5px 15px;
                            border-radius:30px;
                        }
                    }
                }
            }
        }
    }
    .custom-indi-wrap {
        position:absolute;
        left:20px;
        bottom:4px;
        right:20px;
        z-index:10;
        font-size:0;
        text-align:center;
        .custom-indis {
            display:inline-block;
            max-width:calc(100% - 30px);
            vertical-align:top;
            .indis {
                display:table;
                width:auto;
                li {
                    display:table-cell;
                    width:104px;
                    padding:0 2px;
                    .btn-indi {
                        position:relative;
                        display:block;
                        width:100%;
                        overflow:hidden;
                        text-align:left;
                        &::after {
                            content:' ';
                            display:block;
                            position:relative;
                            width:100%;
                            height:4px;
                            background:rgba(#fff, .4);
                        }
                        p {
                            width:0;
                            height:24px;
                            color:#fff;
                            font-size:16px;
                            line-height:24px;
                            white-space:nowrap;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            opacity:0;
                        }
                        .bar {
                            position:absolute;
                            left:0;
                            bottom:0;
                            width:0;
                            height:4px;
                            background:#fff;
                        }
                    }

                    &.on {
                        width:504px;
                        .btn-indi {
                            p {
                                width:auto;
                                opacity:1;
                            }
                            @keyframes bars {
                                from { width:0; }
                                to { width:100%; }
                            }
                            .bar {
                                width:100%;
                                animation-name:pulse;
                                //animation-duration:5s;
                            }
                        }
                    }
                }
            }
        }
        .indi-control {
            display:inline-block; 
            position:relative; 
            width:26px;
            height:26px;
            //margin-top:12px;
            //margin-left:2px;
            font-size:0;
            text-align:center;
            //&::before, &::after {
            //    content:' ';
            //    display:inline-block;
            //    width:2px;
            //    height:10px;
            //    margin:0 2px;
            //    background-color:#fff;
            //    vertical-align:middle;
            //}
            &:before {
                display: inline-block;
                width: 26px;
                height: 26px;
                background: url('/lg5-common/images/icons/btn-carousel-hero-pause.svg') no-repeat 0 0;
                content: '';
            }
            &.play {
                &:before {
                    background-image: url('/lg5-common/images/icons/btn-carousel-hero-play.svg');
                }
            }
        }
    }
    .indi-wrap {
        .indi-conts {
            display:inline-block;
            max-width:(1284px - 30px);
            vertical-align:top;
            .custom-indis {
                display:flex;
                width:auto;
                max-width:100%;
            }
        }
    }
    .slide-control-wrap {
        position:absolute;
        top:50%;
        left:0;
        right:0;
        .outer {
            width:100%;
            padding:0 16px;
            @include mobile {
                padding:0 4px;
            }
        }
        .inner {
            position:relative;
            width:100%;
            max-width:1380px + 48px + 96px; // contents width + 텍스트-버튼 사이 간격 24px X 2 + 버튼width X 2
            margin:0 auto;
            .btn-arrow {
                position:absolute;
                top:0;
                &.prev {
                    left:0;
                    transform:translateY(-50%);
                }
                &.next {
                    right:0;
                    transform:translateY(-50%);
                }
            }
        }
    }
}

// Carousel slide (1 on pc, 4 on pc, 3 on pc)
.slide-wrap.carousel-type {
    margin:0 auto;
    @include mobile {
        overflow:hidden;
    }
    .slide-content {
        @include mobile {
            overflow:visible !important;
        }
    }
    .slide-conts {
        float:left;
        height:auto;
    }
    .slide-controls {
        .btn-arrow.prev {
            left:-12px;
            @include mobile {
                left:4px;
            }
        }
        .btn-arrow.next {
            right:-12px;
            @include mobile {
                right:4px;
            }
        }
    }

    // 1 on pc type carousel
    &.carousel-1 {
        margin: 0 -5px;
        .indi-wrap {
            position: static;
            margin-top: 24px;
        }
        .slide-controls {
            .btn-arrow {
                &.prev {
                    left: 0;
                    transform: translateX(-50%);
                }
                &.next {
                    right: 0;
                    transform: translateX(50%);
                }
            }
        }
        @include mobile {
            .slide-conts {
                margin: 0 5px;
            }
        }
    }

    // 3 on pc type carousel 
    &.carousel-3 {
        max-width: (1284px + 12px);
        padding-bottom: 32px;
        @include mobile {
            padding-bottom: 20px;
        }
        .indi-wrap {
            bottom: 0;
        }
        .slide-controls {
            @include mobile {
                display: none;
            }
            .btn-arrow {
                &.prev {
                    left: -12px;
                    @include mobile {
                        left:4px;
                    }
                }
                &.next {
                    right: -12px;
                    @include mobile {
                        right:4px;
                    }
                }
            }
        }
        .slide-content {
            @include mobile {
                padding: 0 23px 0 10px;
            }
        }
        .slide-conts {
            //width: 25%;
            padding: 6px 12px 12px;
            @include mobile {
                width: 61.944vw;
                padding: 6px 6px 12px;
            }
        }
        .slide-box {
            position: relative;
            padding: 0 32px 32px;
            border-radius: 8px;
            background-color: #fff;
            box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.14);
            @include mobile {
                padding: 0 28px 32px;
            }
            .product-image {
                a {
                    display: block;
                    text-align: center;
                    img {
                        width: 100%;
                        max-width: 262px;
                    }
                }
            }
            .flag-wrap {
                height: 20px;
                margin-bottom: 8px;
                & + .product-name {
                    margin-top: 0;
                }
            }
            .product-name {
                height: 52px;
                margin: 8px 0;
                @include mobile {
                    margin: 8px 0 16px;
                }
                a {
                    font-weight: 700;
                    @include font-medium;
                    @include textClipClamp(2);
                }
            }
            .price-area {
                .total {
                    display: block;
                    font-weight: 700;
                    @include font-large;
                }
                .discount {
                    font-weight: 500;
                    @include font-small;
                    color: $color-gray-1;
                }
                .discount-rate {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    color: #ec455a;
                }
            }
            .btn {
                width: 100%;
                @include mobile {
                    padding: 11px 0;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            .chk-wish-wrap {
                position: absolute;
                top: 24px;
                right: 24px;
                @include mobile {
                    top: 18px;
                    right: 18px;
                }
            }
        }
    }

    // 4 on pc type carousel 
    &.carousel-4 {
        max-width:(1284px + 24px);
        padding-bottom:34px;
        @include mobile {
            margin:0 -20px;
            padding:0 15px 38px;
        }
        .indi-wrap {
            bottom:0;
        }
        .slide-conts {
            width:25%;
            padding:0 12px;
            @include mobile {
                width:100%;
                padding:0 5px;
            }
        }
        .slide-box {
            display:block;
            position:relative;
            border-radius:4px;
            overflow:hidden;
            .flag {
                position:absolute;
                top:24px;
                left:24px;
                padding:0 8px;
                border-radius:4px;
                background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                color:#EC455A;
                font-size:12px;
                line-height:20px;
                font-weight:500;
                z-index:10;
            }
            .thumb {
                display:block;
                position:relative;
                z-index:1;
                img {
                    width:100%;
                    height:auto;
                    vertical-align:top;
                }
                &::before {
                    content:' ';
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    background:rgba(#222, .2);
                    z-index:5;
                }
                &::after {
                    content:' ';
                    position:absolute;
                    bottom:0;
                    left:0;
                    right:0;
                    height:50px;
                    background-image:linear-gradient(transparent, rgba(#222, .5));
                    z-index:6;
                }
            }
            .info-area {
                position:relative;
                padding:16px 24px 24px;
                background:#fff;
                z-index:15;
                .hashtag {
                    position:absolute;
                    bottom:calc(100% + 16px);
                    left:24px;
                    font-size:0;
                    .hash {
                        display:inline-block;
                        margin-right:8px;
                        color:#fff;
                        font-size:14px;
                        line-height:20px;
                        font-weight:500;
                        vertical-align:top;
                    }
                }
                .text-box {
                    height:68px;
                }
                .tit {
                    max-height:48px;
                    color:#000;
                    font-size:16px;
                    line-height:24px;
                    font-weight:700;
                    @include textEllipsisClamp(2);
                }
                .desc {
                    margin-top:4px;
                    color:#000;
                    font-size:14px;
                    line-height:20px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space:nowrap;
                }
                .count {
                    margin-top:20px;
                    font-size:0;
                    li {
                        display:inline-block;
                        margin-right:8px;
                        color:#616161;
                        font-size:12px;
                        line-height:16px;
                        font-weight:500;
                        vertical-align:top;
                        &::before {
                            content:' ';
                            display:inline-block;
                            width:16px;
                            height:16px;
                            margin-right:4px;
                            vertical-align:top;
                        }
                        &.like::before {
                            background:url('/lg5-common/images/icons/icon-heart-off-sm.svg') center no-repeat;
                        }
                        &.view::before {
                            background:url('/lg5-common/images/icons/icon-view-sm.svg') center no-repeat;
                        }
                    }
                }
            }
            .chk-wish-wrap {
                position:absolute;
                top:24px;
                right:24px;
                z-index:20;
            }
        }
    }
}

// S : BTOCSITE-33132 홈 메인 스크린 사이즈 미지원 범위 수정 요청
// new hero visual slide
@mixin pc-hero {
    @media screen and (min-width:#{$tablet-s-width + 1px}) and (max-width:#{$desktop-full-width}) {
        @content;
    }
}

$size--desktop: 1920px;
$size--mobile: 360px;

// 단위 제거 함수
@function deUnit($value) {
  @return ($value / ($value * 0 + 1));
}

// 1920px 이하에서 사용될 vw-hero-pc(속성, 값)
@mixin vw-hero-pc($property, $pixel){
    @if length($pixel) == 1 {
        #{$property}: deUnit($pixel) * 100 / deUnit($size--desktop) * 1vw
    } @else if length($pixel) == 2 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
    } @else if length($pixel) == 3 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,3)) * 100 / deUnit($size--desktop) * 1vw
    } @else if length($pixel) == 4 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,3))* 100 / deUnit($size--desktop) * 1vw
        deUnit(nth($pixel,4)) * 100 / deUnit($size--desktop) * 1vw
    }
}

// 360px 이상에서 사용될 vw-hero-mo(속성, 값)
@mixin vw-hero-mo($property, $pixel){
    @if length($pixel) == 1 {
        #{$property}: deUnit($pixel) * 100 / deUnit($size--mobile) * 1vw;
    } @else if length($pixel) == 2 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw;
    } @else if length($pixel) == 3 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,3)) * 100 / deUnit($size--mobile) * 1vw;
    } @else if length($pixel) == 4 {
        #{$property}: deUnit(nth($pixel,1)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,2)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,3)) * 100 / deUnit($size--mobile) * 1vw
        deUnit(nth($pixel,4)) * 100 / deUnit($size--mobile) * 1vw;
    }
}

.slide-wrap.new-hero-banner {
    &.is-random {
        opacity:0;
    }
    .slide-content {
        .slide-track  {
            height:600px;
            .slide-conts {
                position:relative;
                height:600px;
                .full-link {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
                .slide-img {
                    overflow:hidden;
                    position: relative;
                    width:100%;
                    height:100%;
                    z-index: 2;
                    pointer-events: none;
                    img, video {
                        position:absolute;
                        top:0;
                        left:50%;
                        transform:translateX(-50%);
                        width: 100%; 
                        height: auto;
                        min-width: 100%;
                        min-height: 100%;
                        max-width:none;
                    }
                    video{
                        object-fit: cover;
                    }
                    .video-button {
                        width: 46px;
                        height: 46px;
                        background: url('/lg5-common/images/MA/mainRenewal/icon-video-button-play.svg') center no-repeat;
                        background-size: 46px 46px;
                        pointer-events: all;
                        &.pause {
                            background-image: url('/lg5-common/images/MA/mainRenewal/icon-video-button-pause.svg');
                        }
                        &-wrap {
                            position: absolute;
                            margin: 0 auto;
                            padding: 0 40px;
                            bottom: 40px;
                            left: 50%;
                            width: 100%;
                            height: 46px;
                            max-width: 1460px;
                            transform: translateX(-50%)  translateZ(1px);
                            z-index:1;
                        }
                    }
                }
                .bg {
                    background-size: cover;
                    &.pc{
                        display:inline-block;
                    }
                    &.mobile{
                        display:none;
                    }
                }
                // S : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
                .slide-info-area {
                    top:160px;
                    left:0;
                    max-width:1476px;
                    margin:0 auto;
                    padding: 0 38px;
                    z-index: 2;
                    pointer-events: none;
                    transform: none;
                    .boxs {
                        display:inline-block;
                        &.fc-black {
                            color:#000;
                        }
                        &.fc-white {
                            color:#fff;
                        }
                        .brand-logo {
                            display:inline-block;
                            margin-bottom: 30px;
                            height: 21px;
                            img {
                                width: auto;
                                height: 100%;
                            }
                        }
                        .txt {
                            color:inherit;
                            font-size:46px;
                            line-height:1.3;
                            letter-spacing:-1.38px;
                        }
                        .small {
                            margin-top:20px;
                            font-size:18px;
                            line-height:1.2;
                            opacity: .8;
                            letter-spacing:-.36px;
                        }
                    }
                }
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    .slide-info-area {
                        width:100%;
                    }
                }
                &.text-white {
                    .slide-info-area {
                        .boxs {
                            color: #fff;
                            .small {
                                opacity:0.8;
                            }
                        }
                    }
                }
                // E : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
            }
        }
    }
    .custom-indi-wrap {
        display:none;
        bottom:40px;
        left:50%;
        right:auto;
        width:50%;
        max-width:730px;
        padding-right:40px;
        text-align:right;
        // E : BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
        // S : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
        .slide-controls {
            position:relative;
            top:auto;
            left:auto;
            transform:none;
            max-width:none;
            margin:0 auto;
            .controls {
                position:absolute;
                bottom:0;
                right:0;
                width:138px;
                height:36px;
                font-size:0;
                text-align:center;
                border-radius:18px;
                background:rgba(0, 0, 0, 0.50); // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            }
            .btn-arrow {
                position:absolute;
                top:50%;
                padding:0;
                width:22px;
                height:22px;
                transform:translateY(-50%);
                &:before{
                    content:'';
                    display:block;
                    width:22px;
                    height:22px;
                    box-shadow:none;
                    background-size:24px;
                }
                &.ico-prev {
                    left:14px;
                    &:before{
                        background-image: url(/lg5-common/images/icons/btn-arr-36x36-lt.svg);
                    }
                }
                &.ico-next {
                    right:14px;
                    &:before{
                        background-image: url(/lg5-common/images/icons/btn-arr-36x36-rt.svg);
                    }
                }
            }
            .slide-page{
                padding:0 9px;
                color:#fff;
                font-size:16px;
                line-height:36px;
                font-weight:400;
                letter-spacing:-.64px;
                .current{
                    font-weight:500;
                }
                .count{
                    color:#eaeaea; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    &:before{
                        content:'/';
                        margin:0 8px;
                        color:#fff;
                    }
                }
            }
        }
        // E : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
        .indi-control {
            //display: block;
            display: none;
            margin-left: 8px;
            width:36px;
            height:36px;
            &:before{
                width:36px;
                height:36px;
                background-size:100% 100%;
            }
            &.play{
                &:before{
                    background-image: url(/lg5-common/images/icons/btn-carousel-46-play.svg);
                }
            }
            &.stop{
                &:before{
                    background-image: url(/lg5-common/images/icons/btn-carousel-46-pause.svg);
                }
            }
        }
        // S : BTOCSITE-47988 메인 히어로배너 전체보기 요청
        > .ui-carousel-more {
            display:none;
        }
        // E : BTOCSITE-47988 메인 히어로배너 전체보기 요청
        //  BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
        & + .slide-arrow {
            display:none!important;
        }
        &.new-type {
            .slide-controls {
                // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                .controls{
                    width:auto;
                    padding:0 32px 0 36px;
                    border-radius:18px 0 0 18px;
                    text-align:left;
                }
                .btn-arrow {
                    width:20px;
                    height:20px;
                    &:before{
                        width:20px;
                        height:20px;
                        background-size:20px;
                    }
                    &.ico-prev {
                        left:12px;
                        &:before{
                            background-image: url(/lg5-common/images/icons/btn-arr-20x20-lt.svg);
                        }
                    }
                    &.ico-next {
                        right:8px;
                        &:before{
                            background-image: url(/lg5-common/images/icons/btn-arr-20x20-rt.svg);
                        }
                    }
                }
                .slide-page{
                    padding:0;
                    letter-spacing:initial;
                    .count{
                        &:before{
                            margin:0 4px;
                        }
                    }
                }
                // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
            }
            .indi-control {
                margin-top:8px;
                margin-left:0;
                width:20px;
                height:20px;
                vertical-align: top;
                &:before{
                    width:20px;
                    height:20px;
                }
                &.play{
                    &:before{
                        background-image: url('/lg5-common/images/icons/icon_video_play.svg');
                    }
                }
                &.stop{
                    &:before{
                        background-image: url('/lg5-common/images/icons/icon_video_pause.svg');
                    }
                }
            } 
            // S : BTOCSITE-47988 메인 히어로배너 전체보기 요청
            .ui-carousel-more {
                display:block;
            }
            // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
            > .ui_carousel_play {
                margin:0 0 0 2px;
                width:40px;
                height:36px;
                padding:0 12px 0 8px;
                background:rgba(0, 0, 0, .50); // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                border-radius:0 18px 18px 0;
            } 
            // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가  
            // E : BTOCSITE-47988 메인 히어로배너 전체보기 요청
            & + .slide-arrow {
                display:block!important;
                @include mobile {
                    display:none!important;
                }
            }
        }
        .ui-carousel-more {
            margin-left: 4px;
            width:36px;
            height:36px;
            &:before{
                content: '';
                display: block;
                width:36px;
                height:36px;
                background: url('/lg5-common/images/icons/icon_banner_more.svg') no-repeat 0 0 / 100% auto;
            }
        }
    }
    // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
    .slide-arrow {
        position:absolute;
        top:233px;
        width:100%;
        button {
            position:absolute;
            width:50px;
            height:70px;
            padding:0;
            &:before {
                content:'';
                display:block;
                width:50px;
                height:70px;
            }
            &.arrow-prev {
                padding-left:10px;
                left:180px;
                &:before {
                    background: url('/lg5-common/images/icons/banner_arrow_big_left.svg') no-repeat 0 0 / 100% auto;
                }
            }
            &.arrow-next {
                padding-right:10px;
                right:180px;
                &:before {
                    background: url('/lg5-common/images/icons/banner_arrow_big_right.svg') no-repeat 0 0 / 100% auto;
                }
            }
        }
        @include mobile {
            display:none!important;
        }
    }
    // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
    .mobile-dimmed {
        display: none;
    }
    @include pc-hero {
        .slide-content {
            .slide-track  {
                @include vw-hero-pc(height,600px);
                .slide-conts {
                    @include vw-hero-pc(height,600px);
                    .slide-img {
                        .video-button {
                            @include vw-hero-pc(width, 46px);
                            @include vw-hero-pc(height, 46px);
                            @include vw-hero-pc(background-size, 46px 46px);
                            &-wrap {
                                @include vw-hero-pc(padding, 0 40px);
                                @include vw-hero-pc(bottom, 40px);
                                @include vw-hero-pc(height, 46px);
                                @include vw-hero-pc(max-width, 1460px);
                            }
                        }
                    }
                    // S : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
                    .slide-info-area {
                        @include vw-hero-pc(top,160px);
                        @include vw-hero-pc(max-width,1476px);
                        @include vw-hero-pc(padding, 0 38px);
                        .boxs {
                            .brand-logo {
                                @include vw-hero-pc(margin-bottom, 30px);
                                @include vw-hero-pc(height, 21px);
                            }
                            .txt {
                                @include vw-hero-pc(font-size,46px);
                                @include vw-hero-pc(letter-spacing,-1.38px);
                            }
                            .small {
                                @include vw-hero-pc(margin-top,20px);
                                @include vw-hero-pc(font-size,18px);
                                @include vw-hero-pc(letter-spacing,-.36px);
                            }
                        }
                    }
                    // E : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
                }
            }
        }
        .custom-indi-wrap {
            @include vw-hero-pc(bottom,40px);
            @include vw-hero-pc(max-width,730px);
            @include vw-hero-pc(padding-right,40px);
            // S : BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
            &.new-type {
                .slide-controls {
                    // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                    .controls{
                        @include vw-hero-pc(padding,0 32px 0 36px);
                        @include vw-hero-pc(border-radius,18px 0 0 18px);
                    }
                    .btn-arrow {
                        @include vw-hero-pc(width,20px);
                        @include vw-hero-pc(height,20px);
                        &:before{
                            @include vw-hero-pc(width,20px);
                            @include vw-hero-pc(height,20px);
                            @include vw-hero-pc(background-size,20px);
                        }
                        &.ico-prev {
                            @include vw-hero-pc(left,12px);
                        }
                        &.ico-next {
                            @include vw-hero-pc(right,8px);
                        }
                    }
                    .slide-page{
                        .count{
                            &:before{
                                @include vw-hero-pc(margin,0 4px);
                            }
                        }
                    }
                    // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                }
                .indi-control {
                    @include vw-hero-pc(margin-top, 8px);
                    @include vw-hero-pc(margin-left, 0);
                    @include vw-hero-pc(width,20px);
                    @include vw-hero-pc(height,20px);
                    vertical-align: top;
                    &:before{
                        @include vw-hero-pc(width,20px);
                        @include vw-hero-pc(height,20px);
                    }
                    &.play{
                        &:before{
                            background-image: url('/lg5-common/images/icons/icon_video_play.svg');
                        }
                    }
                    &.stop{
                        &:before{
                            background-image: url('/lg5-common/images/icons/icon_video_pause.svg');
                        }
                    }
                }
                // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                > .ui_carousel_play {
                    @include vw-hero-pc(margin,0 0 0 2px);
                    @include vw-hero-pc(width,40px);
                    @include vw-hero-pc(height,36px);
                    @include vw-hero-pc(padding,0 12px 0 8px);
                    @include vw-hero-pc(border-radius,0 18px 18px 0);
                }  
                // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
            }
            // E : BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
            .slide-controls {
                .controls{
                    @include vw-hero-pc(width,138px);
                    @include vw-hero-pc(height,36px);
                    @include vw-hero-pc(border-radius,18px);
                }
                // S : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
                .btn-arrow {
                    @include vw-hero-pc(width,22px);
                    @include vw-hero-pc(height,22px);
                    &:before{
                        @include vw-hero-pc(width,22px);
                        @include vw-hero-pc(height,22px);
                        @include vw-hero-pc(background-size,24px);
                    }
                    &.ico-prev {
                        @include vw-hero-pc(left,14px);
                    }
                    &.ico-next {
                        @include vw-hero-pc(right,14px);
                    }
                }
                .slide-page{
                    @include vw-hero-pc(padding,0 6px);
                    @include vw-hero-pc(font-size,16px);
                    @include vw-hero-pc(line-height,36px);
                    @include vw-hero-pc(letter-spacing,-.64px);
                    .count{
                        &:before{
                            @include vw-hero-pc(margin,0 8px);
                        }
                    }
                }
                // E : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
            }
            .indi-control {
                @include vw-hero-pc(margin-left, 8px);
                @include vw-hero-pc(width,36px);
                @include vw-hero-pc(height,36px);
                &:before{
                    @include vw-hero-pc(width,36px);
                    @include vw-hero-pc(height,36px);
                }
            }
            // BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
            .ui-carousel-more {
                @include vw-hero-pc(margin-left, 4px);
                @include vw-hero-pc(width,36px);
                @include vw-hero-pc(height,36px);
                outline: none;
                &:before{
                    @include vw-hero-pc(width,36px);
                    @include vw-hero-pc(height,36px);
                }
            }
        }
        // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
        .slide-arrow {
            @include vw-hero-pc(top,233px);
            button {
                @include vw-hero-pc(width,50px);
                @include vw-hero-pc(height,70px);
                &:before {
                    @include vw-hero-pc(width,50px);
                    @include vw-hero-pc(height,70px);
                }
                &.arrow-prev {
                    @include vw-hero-pc(padding-left,10px);
                    @include vw-hero-pc(left,180px);
                }
                &.arrow-next {
                    @include vw-hero-pc(padding-right,10px);
                    @include vw-hero-pc(right,180px);
                }
            }
        }
        // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
    }

    @include mobile {
        @include vw-hero-mo(margin-top, -42px);
        &.is-random {
            opacity:0;
        }
        // S : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
        .slide-content {
            .slide-track  {
                @include vw-hero-mo(height,460px);
                .slide-conts {
                    @include vw-hero-mo(height,460px);
                    .slide-img {
                        img, video {
                            top:auto;
                            bottom:0;
                            left:0;
                            transform:none;
                        }
                        video{
                            width:auto;
                            height:100%;
                            top:0;
                            left:50%;
                            transform:translate(-50%,0) translateZ(1px);
                        }
                        .video-button {
                            @include vw-hero-mo(width, 24px);
                            @include vw-hero-mo(height, 24px);
                            @include vw-hero-mo(background-size, 24px 24px);
                            &-wrap {
                                @include vw-hero-mo(padding, 0 20px);
                                @include vw-hero-mo(bottom, 14px);
                                @include vw-hero-mo(height, 24px);
                            }
                        }
                    }
                    .bg {
                        height: 100%;
                        padding-bottom: 0;
                        &.pc{
                            display:none;
                        }
                        &.mobile{
                            display:inline-block;
                        }
                    }
                    .slide-info-area {
                        @include vw-hero-mo(top, 104px);
                        @include vw-hero-mo(padding,0 16px);
                        bottom:0;
                        .boxs {
                            .brand-logo {
                                position:absolute;
                                @include vw-hero-mo(bottom,16px);
                                @include vw-hero-mo(left,16px);
                                @include vw-hero-mo(height,12px);
                                font-size:0;
                                margin-bottom:0;
                            }
                            .txt {
                                position:relative;
                                @include vw-hero-mo(font-size,24px);
                                @include vw-hero-mo(letter-spacing,-.72px);
                            }
                            .small {
                                // display: none;
                                @include vw-hero-mo(margin-top,8px);
                                @include vw-hero-mo(font-size,12px);
                                @include vw-hero-mo(letter-spacing,-.36px);
                            }
                        }
                    }
                    &.is-video {
                        .slide-info-area {
                            .boxs {
                                .brand-logo {
                                    @include vw-hero-mo(left,52px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .custom-indi-wrap {
            @include vw-hero-mo(padding-right, 20px);
            @include vw-hero-mo(bottom, 16px);
            max-width: none;
            // S : BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
            &.new-type {
                .slide-controls {
                    // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                    .controls{
                        @include vw-hero-mo(width,63px);
                        @include vw-hero-mo(height,22px);
                        @include vw-hero-mo(border-radius,10px);
                        text-align:center;
                        padding:0;
                        .btn-arrow {
                            display:none!important;
                        }
                    }
                    // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                    .slide-page{
                        @include vw-hero-mo(padding,0 0 0 14px);
                        @include vw-hero-mo(line-height,22px);
                        .count{
                            &:before{
                                @include vw-hero-mo(margin,0 3px);
                            }
                        }
                    }
                }
                .indi-control {
                    @include vw-hero-mo(margin-top, 4px);
                    @include vw-hero-mo(margin-left, 0);
                    @include vw-hero-mo(width,14px);
                    @include vw-hero-mo(height,14px);
                    vertical-align: top;
                    &:before{
                        @include vw-hero-mo(width,14px);
                        @include vw-hero-mo(height,14px);
                    }
                    &.play{
                        &:before{
                            background-image: url('/lg5-common/images/icons/icon_video_play.svg');
                        }
                    }
                    &.stop{
                        &:before{
                            background-image: url('/lg5-common/images/icons/icon_video_pause.svg');
                        }
                    }
                }
                // S : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                > .ui_carousel_play {
                    position:absolute;
                    z-index:11;
                    @include vw-hero-mo(right,91px);
                    background:none;
                    border-radius:0;
                    padding:0;
                }
                // E : BTOCSITE-67826 홈 히어로 전체보기 PC 버튼 추가 
                
            }
            // E : BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
            .slide-controls {
                .controls{
                    @include vw-hero-mo(width,73px);
                    @include vw-hero-mo(height,20px);
                    @include vw-hero-mo(border-radius,10px);
                }
                .btn-arrow {
                    @include vw-hero-mo(width,18px);
                    @include vw-hero-mo(height,18px);
                    &:before{
                        @include vw-hero-mo(width,18px);
                        @include vw-hero-mo(height,18px);
                    }
                    &.ico-prev {
                        @include vw-hero-mo(left,4.5px);
                        &:before{
                            @include vw-hero-mo(background-size,18px);
                        }
                    }
                    &.ico-next {
                        @include vw-hero-mo(right,4.5px);
                        &:before{
                            @include vw-hero-mo(background-size,18px);
                        }
                    }
                }
                .slide-page{
                    @include vw-hero-mo(padding,0 1px);
                    @include vw-hero-mo(font-size,11px);
                    @include vw-hero-mo(line-height,20px);
                    @include vw-hero-pc(letter-spacing,-.45px);
                    .count{
                        &:before{
                            @include vw-hero-mo(margin,0 4px);
                        }
                    }
                }
            }
            .indi-control {
                @include vw-hero-mo(margin-left, 8px);
                @include vw-hero-mo(width,20px);
                @include vw-hero-mo(height,20px);
                &:before{
                    @include vw-hero-mo(width,20px);
                    @include vw-hero-mo(height,20px);
                }
            }
            // BTOCSITE-61382 [홈] 캐러셀 일시정지 버튼 UI
            .ui-carousel-more {
                @include vw-hero-mo(margin-left, 6px);
                @include vw-hero-mo(width,22px);
                @include vw-hero-mo(height,22px);
                &:before{
                    @include vw-hero-mo(width,22px);
                    @include vw-hero-mo(height,22px);
                }
            }
        }
        // E : BTOCSITE-44105 닷컴 홈메인 히어로배너, 중단퀵메뉴 수정
        .mobile-dimmed {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            @include vw-hero-mo(height, 60px);
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
        }
    }
}
// E : BTOCSITE-33132 홈 메인 스크린 사이즈 미지원 범위 수정 요청

// S :  BTOCSITE-78612 [LGE.COM] 렛츠그램 개선 - 그램 히어로 배너 수정
.gram-cont {
	.slide-wrap {
		&.new-hero-banner {
			.slide-content {
				.slide-track  {
					height: 640px;
					.slide-conts {
						height: 640px;
                        .slide-img {
                            .video-button {
                                width: 32px;
                                height: 32px;
                                background-size: 31px 32px;
                                background-image: url('/lg5-common/images/icons/icon-video-button-play-new.svg');
                                &-wrap {
                                    bottom: 32px;
                                    height: 32px;
                                }

                                &.pause {
                                    background-image: url('/lg5-common/images/icons/icon-video-button-pause-new.svg');
                                }
                            }
                        }
						.slide-info-area {
							top: 90px;
							text-align: center;
							.boxs {
								.txt {
									font-size: 56px;
									font-weight: 700;
									line-height: 80px;
								}
							}
						}
					}
				}
			}
			.custom-indi-wrap {
                bottom: 32px;
                .slide-controls {
                    .controls {
                        display: flex;
                        align-items: center;
                        width: 87px;
                        height: 32px;
                        text-align: left;
                    }
                    .slide-page {
                        padding: 0 5px;
                        font-size: 14px;
                        line-height: 32px;
                    }
                }
                .btn-arrow {
                    @include pc {
                        display: none !important;
                    }
                }
				.indi-control {
                    margin-left: 0;
					display: inline-block !important;
                    vertical-align: top;
                    width: 32px;
                    height: 32px;
                    &::before {
                        width: 32px;
                        height: 32px;
                    }
					&.play {
						&::before {
							background-image: url("/lg5-common/images/icons/icon_banner_play.svg");
						}
					}
					&.stop {
						&::before {
							background-image: url("/lg5-common/images/icons/icon_banner_pause.svg");
						}
					}
				}
				& + .slide-arrow {
					display: block !important;
				}
			}
			.slide-arrow {
				top: 296px;
				button {
					padding: 0;
					width: 48px;
					height: 49px;
					border-radius: 50%;
					box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
					&::before {
						width: 100%;
						height: 100%;
					}

					&.arrow-prev {
						left: 100px;
                        &::before {
                            background: url('/lg5-common/images/icons/btn-arr-40x40-lt.svg') no-repeat 0 0/100% auto;
                        }
					}
					&.arrow-next {
						right: 100px;
                        &::before {
                            background: url('/lg5-common/images/icons/btn-arr-40x40-rt.svg') no-repeat 0 0/100% auto;
                        }
					}
				}
			}
            @include pc-hero {
                .slide-content {
                    .slide-track  {
                        @include vw-hero-pc(height,640px);
                        .slide-conts {
                            @include vw-hero-pc(height,640px);
                            .slide-img {
                                .video-button {
                                    @include vw-hero-pc(width, 32px);
                                    @include vw-hero-pc(height, 32px);
                                    @include vw-hero-pc(background-size, 32px 32px);
                                    &-wrap {
                                        @include vw-hero-pc(bottom, 32px);
                                        @include vw-hero-pc(height, 32px);
                                    }
                                }
                            }
                            .slide-info-area {
                                @include vw-hero-pc(top,90px);
                                @include vw-hero-pc(max-width,1000px);
                                @include vw-hero-pc(padding, 0 40px);
                                .boxs {
                                    .txt {
                                        @include vw-hero-pc(font-size,56px);
                                        @include vw-hero-pc(line-height,56px);
                                        @include vw-hero-pc(letter-spacing,-1.38px);
                                    }
                                }
                            }
                        }
                    }
                }
                .custom-indi-wrap {
                    @include vw-hero-pc(bottom,32px);
                    .slide-controls {
                        .controls {
                            @include vw-hero-pc(width,87px);
                            @include vw-hero-pc(height,32px);
                        }
                        .slide-page {
                            @include vw-hero-pc(padding,0 5px);
                            @include vw-hero-pc(font-size,14px);
                            @include vw-hero-pc(line-height,32px);
                        }
                    }
                    .indi-control {
                        @include vw-hero-pc(width,32px);
                        @include vw-hero-pc(height,32px);
                        &::before {
                            @include vw-hero-pc(width,32px);
                            @include vw-hero-pc(height,32px);
                        }
                    }
                }
                .slide-arrow {
                    @include vw-hero-pc(top,296px);
                    button {
                        @include vw-hero-pc(width,48px);
                        @include vw-hero-pc(height,49px);
                        &.arrow-prev {
                            @include vw-hero-pc(left,100px);
                        }
                        &.arrow-next {
                            @include vw-hero-pc(right,100px);
                        }
                    }
                }
            }
            @include mobile {
                margin-top: 0;
                .slide-content {
                    .slide-track  {
                        @include vw-hero-mo(height,326px);
                        .slide-conts {
                            @include vw-hero-mo(height,326px);
                            .slide-img {
                                .video-button {
                                    @include vw-hero-mo(width, 24px);
                                    @include vw-hero-mo(height, 24px);
                                    @include vw-hero-mo(background-size, 24px 24px);
                                    &-wrap {
                                        @include vw-hero-mo(padding, 0 20px);
                                        @include vw-hero-mo(bottom, 12px);
                                        @include vw-hero-mo(height, 24px);
                                    }
                                }
                            }
                            .slide-info-area {
                                @include vw-hero-mo(top, 30px);
                                @include vw-hero-mo(padding,0 16px);;
                                .boxs {
                                    .txt {
                                        position:relative;
                                        @include vw-hero-mo(font-size,24px);
                                        @include vw-hero-mo(line-height,30px);
                                        @include vw-hero-mo(letter-spacing,-.72px);
                                    }
                                }
                            }
                        }
                    }
                }
                .custom-indi-wrap {
                    @include vw-hero-mo(bottom, 12px);
                    .slide-controls {
                        .controls {
                            @include vw-hero-mo(width,96px);
                            @include vw-hero-mo(height,24px);
                            @include vw-hero-mo(border-radius,24px);
                        }
                        .slide-page {
                            @include vw-hero-mo(padding,0 4px);
                            @include vw-hero-mo(font-size,11px);
                            @include vw-hero-mo(line-height,24px);
                        }
                    }
                    .indi-control {
                        @include vw-hero-mo(width,24px);
                        @include vw-hero-mo(height,24px);
                        &::before {
                            @include vw-hero-mo(width,24px);
                            @include vw-hero-mo(height,24px);
                        }
                    }
                    .btn-arrow {
                        position: static;
                        @include vw-hero-mo(width,16px);
                        @include vw-hero-mo(height,16px);
                        transform: none;
                        &::before {
                            @include vw-hero-mo(width,16px);
                            @include vw-hero-mo(height,16px);
                            @include vw-hero-mo(background-size,18px 18px);
                            background-position: 50% 50%;
                            border-radius: 0;
                        }
                        &.ico-prev {
                            @include vw-hero-mo(margin-left, 4px);
                        }
                    }
                    & + .slide-arrow {
                        display: none !important;
                    }
                }
            }
		}
	}
}
// E :  BTOCSITE-78612 [LGE.COM] 렛츠그램 개선 
.event-membership {
	.slide-wrap {
		&.new-hero-banner {
			.slide-content {
				.slide-track  {
					height: 480px;
					.slide-conts {
						height: 480px;
                        // .slide-img {
                        //     .video-button {
                        //         width: 32px;
                        //         height: 32px;
                        //         background-size: 31px 32px;
                        //         background-image: url('/lg5-common/images/icons/icon-video-button-play-new.svg');
                        //         &-wrap {
                        //             bottom: 32px;
                        //             height: 32px;
                        //         }

                        //         &.pause {
                        //             background-image: url('/lg5-common/images/icons/icon-video-button-pause-new.svg');
                        //         }
                        //     }
                        // }
						.slide-info-area {
							top: 128px;
							.boxs {
								.txt {
									font-size: 48px;
									font-weight: 700;
									line-height: 56px;
                                    span {
                                        margin-bottom: 8px;
                                        display: block;
                                        font-size: 24px;
                                        line-height: 32px;
                                    }
								}
                                .txt-arr {
                                    margin-top: 30px;
                                    font-size: 18px;
                                    line-height: 26px;
                                    display: block;
                                }
							}
						}
					}
				}
			}
			.custom-indi-wrap {
                bottom: 32px;
                left: 50%;
                right: auto;
                max-width: 730px;
                .slide-controls {
                    .controls {
                        display: flex;
                        align-items: center;
                        width: auto;
                        height: 32px;
                        text-align: left;
                        padding-right:12px;
                    }
                    .slide-page {
                        padding: 0 8px 0 5px;
                        font-size: 14px;
                        line-height: 32px;
                        .current {
                            color: #fff;
                        }
                    }
                }
                .btn-arrow {
                    @include pc {
                        display: none !important;
                    }
                }
				.indi-control {
                    position: static;
                    margin-left: 0;
					display: inline-block !important;
                    vertical-align: top;
                    width: 32px;
                    height: 32px;
                    &::before {
                        width: 32px;
                        height: 32px;
                    }
					&.play {
						&::before {
							background-image: url("https://www.lge.co.kr//lg5-common/images/icons/icon_banner_play.svg");
						}
					}
					&.stop {
						&::before {
							background-image: url("https://www.lge.co.kr//lg5-common/images/icons/icon_banner_pause.svg");
						}
					}
				}
				& + .slide-arrow {
					display: block !important;
				}
			}
			.slide-arrow {
				top: 233px;
				button {
					padding: 0;
					width: 48px;
					height: 48px;
					border-radius: 50%;
					box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
					&::before {
						width: 100%;
						height: 100%;
					}

					&.arrow-prev {
						left: 100px;
                        &::before {
                            background: url('https://www.lge.co.kr//lg5-common/images/icons/btn-arr-40x40-lt.svg') no-repeat 0 0/auto 100%;
                        }
					}
					&.arrow-next {
						right: 100px;
                        &::before {
                            background: url('https://www.lge.co.kr//lg5-common/images/icons/btn-arr-40x40-rt.svg') no-repeat 0 0/auto 100%;
                        }
					}
				}
			}
            @include pc-hero {
                .slide-content {
                    .slide-track  {
                        @include vw-hero-pc(height,480px);
                        .slide-conts {
                            @include vw-hero-pc(height,480px);
                            // .slide-img {
                            //     .video-button {
                            //         @include vw-hero-pc(width, 32px);
                            //         @include vw-hero-pc(height, 32px);
                            //         @include vw-hero-pc(background-size, 32px 32px);
                            //         &-wrap {
                            //             @include vw-hero-pc(bottom, 32px);
                            //             @include vw-hero-pc(height, 32px);
                            //         }
                            //     }
                            // }
                            .slide-info-area {
                                @include vw-hero-pc(top,128px);
                                @include vw-hero-pc(max-width,1476px);
                                @include vw-hero-pc(padding, 0 40px);
                                .boxs {
                                    .txt {
                                        @include vw-hero-pc(font-size,48px);
                                        @include vw-hero-pc(line-height,56px);
                                        @include vw-hero-pc(letter-spacing,-1.38px);
                                        span {
                                            @include vw-hero-pc(margin-bottom,8px);
                                            @include vw-hero-pc(font-size,24px);
                                            @include vw-hero-pc(line-height,32px);
                                        }
                                    }
                                    .txt-arr {
                                        @include vw-hero-pc(margin-top,30px);
                                        @include vw-hero-pc(font-size,18px);
                                        @include vw-hero-pc(line-height,26px);
                                    }
                                }
                            }
                        }
                    }
                }
                .custom-indi-wrap {
                    @include vw-hero-pc(bottom,32px);
                    @include vw-hero-pc(max-width,730px);
                    .slide-controls {
                        .controls {
                            @include vw-hero-pc(height,32px);
                            @include vw-hero-pc(padding-right,12px);
                        }
                        .slide-page {
                            @include vw-hero-pc(padding,0 8px 0 5px);
                            @include vw-hero-pc(font-size,14px);
                            @include vw-hero-pc(line-height,32px);
                        }
                    }
                    .indi-control {
                        @include vw-hero-pc(width,32px);
                        @include vw-hero-pc(height,32px);
                        &::before {
                            @include vw-hero-pc(width,32px);
                            @include vw-hero-pc(height,32px);
                        }
                    }
                }
                .slide-arrow {
                    @include vw-hero-pc(top,233px);
                    button {
                        @include vw-hero-pc(width,48px);
                        @include vw-hero-pc(height,48px);
                        &.arrow-prev {
                            @include vw-hero-pc(left,100px);
                        }
                        &.arrow-next {
                            @include vw-hero-pc(right,100px);
                        }
                    }
                }
            }
            @include mobile {
                margin-top: 0;
                .slide-content {
                    .slide-track  {
                        @include vw-hero-mo(height,326px);
                        .slide-conts {
                            @include vw-hero-mo(height,326px);
                            // .slide-img {
                            //     .video-button {
                            //         @include vw-hero-mo(width, 24px);
                            //         @include vw-hero-mo(height, 24px);
                            //         @include vw-hero-mo(background-size, 24px 24px);
                            //         &-wrap {
                            //             @include vw-hero-mo(padding, 0 20px);
                            //             @include vw-hero-mo(bottom, 12px);
                            //             @include vw-hero-mo(height, 24px);
                            //         }
                            //     }
                            // }
                            .slide-info-area {
                                @include vw-hero-mo(top, 30px);
                                @include vw-hero-mo(padding,0 16px);;
                                text-align: center;
                                .boxs {
                                    .txt {
                                        position:relative;
                                        @include vw-hero-mo(font-size,24px);
                                        @include vw-hero-mo(line-height,30px);
                                        @include vw-hero-mo(letter-spacing,-.72px);
                                        span {
                                            @include vw-hero-mo(margin-bottom,4px);
                                            @include vw-hero-mo(font-size,14px);
                                            @include vw-hero-mo(line-height,20px);
                                        }
                                    }
                                    .txt-arr {
                                        @include vw-hero-mo(margin-top,8px);
                                        @include vw-hero-mo(font-size,12px);
                                        @include vw-hero-mo(line-height,18px);
                                    }
                                }
                            }
                        }
                    }
                }
                .custom-indi-wrap {
                    @include vw-hero-mo(bottom, 12px);
                    left: 50%;
                    right: inherit;
                    .slide-controls {
                        .controls {
                            @include vw-hero-mo(padding-right,6px);
                            @include vw-hero-mo(height,24px);
                            @include vw-hero-mo(border-radius,24px);
                        }
                        .slide-page {
                            @include vw-hero-mo(padding,0 4px);
                            @include vw-hero-mo(font-size,11px);
                            @include vw-hero-mo(line-height,24px);
                        }
                    }
                    .indi-control {
                        @include vw-hero-mo(width,24px);
                        @include vw-hero-mo(height,24px);
                        &::before {
                            @include vw-hero-mo(width,24px);
                            @include vw-hero-mo(height,24px);
                        }
                    }
                    .btn-arrow {
                        position: static;
                        @include vw-hero-mo(width,16px);
                        @include vw-hero-mo(height,16px);
                        transform: none;
                        &::before {
                            @include vw-hero-mo(width,16px);
                            @include vw-hero-mo(height,16px);
                            @include vw-hero-mo(background-size,18px 18px);
                            background-position: 50% 50%;
                            border-radius: 0;
                        }
                        &.ico-prev {
                            @include vw-hero-mo(margin-left, 4px);
                        }
                    }
                    & + .slide-arrow {
                        display: none !important;
                    }
                }
            }
		}
	}
}

// E : BTOCSITE-47780 닷컴 이벤트&기획전 통합