/* VARIABLES
------------------------------------------------------------------------*/

// /* BREAKPOINTS */
// $bp-xs: 30em; /*  30.00 / 480px */
// $bp-small: 46.25em; /*  46.25 / 700px */
// $bp-medium: 53.75em; /*  52.50 / 860px */
// $bp-large: 75em; /*  75.00 / 1024px */

// /* COLORS */
// $color-charcoal: #3c3c3c;
// $color-gray: #cccccc;
// $color-gray-light: #ececec;
// $color-gray-tint: #efefef;
// $color-white: #ffffff;

// /* FONTS */
// $font-sans: "Open Sans", sans-serif;
// $font-serif: "Cormorant Garamond", serif;

// /* FONT-SIZES */
// $font-tiny: 12px;
// $font-small: 14px;
// $font-reg: 16px;
// $font-large: 18px;
// $font-plus: 20px;

// /* FONT-WEIGHTS */
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// /* GRID */
// $grid-gap: 3%;

// /* CONTAINER-WIDTHS */
// $c-xs: 96%;
// $c-small: 90%;
// $c-medium: 53.75em; /*  52.50 / 860px */
// $c-large: 1180px; /*  75.00 / 1024px */

// COLORS
$color-primary      :#ea1917;
$color-secondary    :#a50034;

$color-negative     :#ff3723;
$color-positive     :#76892d;
$color-neutral      :#21a9aa;

$color-default      :#000;
$color-gray-1       :#666;
$color-gray-2       :#979797;
$color-gray-3       :#ccc;
$color-gray-4       :#aaa;
$color-gray-5       :#767676;
$color-gray-6       :#e4e4e4;
$color-white        :#fff;

$color-disable      :#959595;
$color-border       :#ddd;

$color-bg           :#f4f4f4;
$color-bg-wt        :#fff;


// font size
$font-x-small       :12px;
$font-small         :14px;
$font-medium        :16px;
$font-large         :18px;
$font-large-1       :20px;
$font-large-2       :24px;
$font-large-3       :28px;
$font-large-4       :32px;
$font-large-5       :40px;
$font-large-6       :48px;


// media query
$desktop-full-width :1920px;
$desktop-l-width	:1600px;
$desktop-m-width	:1460px;
$desktop-s-width	:1284px;
$tablet-l-width		:1024px;
$tablet-s-width		:767px;
$mobile-s-width		:360px;