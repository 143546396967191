@charset "UTF-8";

.animation-box {
	position:relative;
	& .visual-area + .animation-area {
		z-index:2;
	}
	.animation-area {
		position: relative;
		width:100%;
		height:auto;
		z-index: 1;
		text-indent: -9999em;
		video {
			display: block;
			width: 100%;
			height: auto;
		}
		@media screen and (min-width: 768px) {
			video.mobile {display:none;}
		}
		@include mobile {
			video.pc {display:none;}
		}
	}

	&.size-type {  // 이미지 반응형 사이즈 따라 재생되어야 하는 타입일 때 분기처리
		img {
			display:block;
			width:100%;
			height:auto;
		}
		.animation-area {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			overflow:hidden;
			video { // height:100% 기준 좌우 짤림 // 이미지 가로 < 세로
				position:absolute;
				top:0;
				left:50%;
				width:auto;
				height:100%;
				transform:translateX(-50%);
			}
		}
	}

	&.size-type2 {
		img {
			display:block;
			width:100%;
			height:auto;
		}
		.animation-area {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			overflow:hidden;
			video { 
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				height:auto;
				transform:translateY(-50%);
			}
		}
	}
}

.play-animaion-btn {
	position:absolute;
	top:16px;
	right:16px;
	background-size:100%;
	background-repeat:no-repeat;
	z-index:9;
	text-indent:-9999px;
	width:24px;
	height:24px;
	font-size: 0;
	white-space:nowrap;
	overflow:hidden;
	&.acc-btn {
		background:url('/lg5-common/images/btn-AD-on.svg');
	}
	&.ani-btn {
		background:url('/lg5-common/images/btn-AD-off.svg');
	}
}
.controller-wrap {
	display: block;
	position: absolute;
	right: 16px;
	bottom: 16px;
	button {
		display: none;
		text-indent: -9999em;
		width: 24px;
		height: 24px;
		border: none;
		background-repeat:no-repeat;
		background-size:100%;
		border-radius: 50%;
		cursor: pointer;
		padding:0;
		&.active {
			display: block;
		}
		&.play {
			background-image: url('/lg5-common/images/btn-animation-play.svg');
		}
		&.pause {
			background-image: url('/lg5-common/images/btn-animation-pause.svg');
		}
	}

	&.btn-inline {
		position: static;
		display: inline-block;
		vertical-align: middle;
	}
}


.ani-caption {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    width: 100%;
    padding: 32px 40px 40px;
    border-radius: 8px;
	box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
	background:#fff;
    @include mobile {
        max-width: 100%;
        padding:25px 40px 25px 16px;
	}
	.cap-section{
		max-width: 1460px;
		width:100%;
		margin:0 auto;
		h1, h2{
			font-size:16px;
			line-height:26px;
			overflow-x:auto;
			white-space: nowrap;
			@include mobile {
				max-width:90%;
			}
		}
		.rectangle {
			width: 100%;
			height: 48px;
			margin: 16px 0 0;
			overflow-y: auto;
			white-space: normal;
			&:hover,
			&:focus {
				overflow-y:overlay;
			}
			@include font-small;
			@include mobile {
				margin: 13px 0 0;
				height: 84px;
				@include font-x-small;
			}
			// font opion style
			&.font-regular {
				@include font-family($font-weight-regular);
			}
			&.font-bold {
				@include font-family($font-weight-bold);
			}
			&.font-regular-italic {
				@include font-family($font-weight-regular, italic);
			}
			&.font-bold-italic {
				@include font-family($font-weight-bold, italic);
			}
			&.font-underline {
				text-decoration: underline;
			}
		}
	}
	.btn-close{
		position: absolute;
		top:32px;
		right:40px;
		display:block;
		width:24px;
		height:24px;
		background:url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat;
		@include mobile {
			top:24px;
			right:16px;
			background-image:url('/lg5-common/images/icons/btn-close-24.svg');
			
		}
	}
}

.caption-wrap {
	display: block;
	position: absolute;
	right: 16px;
	top: 16px;
	z-index: 10;
	.btn {
		display: block;
		min-width:48px;
		border: 1px solid #fff;
		background-color: rgba(0, 0, 0, 0.5);
		background-image: none;
		cursor: pointer;
		padding:0;
		span{
			color:#fff;
		}
		&.on{
			border: solid 1px #da0f47;
			background-color: rgba(255, 255, 255, 0.85);
			span{
				color:#da0f47;
			}
		}
		@include mobile {
			min-width:40px;
			line-height:26px;
		}
	}

	/*&.btn-inline {
		position: static;
		display: inline-block;
		vertical-align: middle;
	}*/
}