// SNS share

.sns-wrap {
    .sns-list {
        @include clearfix;
        display:inline-block;
        li {
            float:left;
            margin-left:8px;
            @include mobile { 
                margin-left:12px;
            }
            &:first-child {
                margin-left:0;
            }
            .ico-btn {
                display:block;
                min-width:56px;
                padding-top:36px;
                @include font-small;
                text-align:center;
                &.url {
                    background:url('/lg5-common/images/icons/icon-url.svg') center 0 no-repeat;
                    background-size:32px 32px;
                }
                &.kk {
                    background:url('/lg5-common/images/icons/icon-kakao.svg') center 0 no-repeat;
                    background-size:32px 32px;
                }
                &.fb {
                    background:url('/lg5-common/images/icons/icon-facebook.svg') center 0 no-repeat;
                    background-size:32px 32px;
                }
                &.tw {
                    background:url('/lg5-common/images/icons/icon-twitter.svg') center 0 no-repeat;
                    background-size:32px 32px;
                }
                // @include mobile {
                //     width:40px;
                //     height:40px;
                //     &.url,
                //         &.kk,
                //         &.fb,
                //         &.tw  {
                //         background-size:40px 40px;
                //     }
                // }
            }
        }
    }
}
// S : BTOCSITE-57669 [공통] 공유하기 클릭 시 공통 팝업 수정
.tooltip-wrap.share {
    .sns-wrap {
        .sns-list {
            &.new-type {
                padding: 20px 34px;
                border-radius: 8px;
                background: #F9F9F9;
                @include mobile {
                    padding: 20px 28px;
                }
                li {
                    &:first-child {
                        margin-left:0;
                    }
                    &:not(:first-child) {
                        margin-left: 24px;
                    }
                    .ico-btn {
                        display:block;
                        min-width:56px;
                        padding-top:36px;
                        @include font-small;
                        text-align:center;
                        &.url {
                            background:url('/lg5-common/images/icons/btn_sns_url_64.svg') center 0 no-repeat;
                        }
                        &.kk {
                            background:url('/lg5-common/images/icons/btn_sns_kakaotalk_64.svg') center 0 no-repeat;
                        }
                        &.fb {
                            background:url('/lg5-common/images/icons/btn_sns_facebook_64.svg') center 0 no-repeat;
                        }
                        &.ig {
                            background:url('/lg5-common/images/icons/btn_sns_instagram_64.svg') center 0 no-repeat;
                        }
                    }
                }
            }
        }
    }
}
// E : BTOCSITE-57669 [공통] 공유하기 클릭 시 공통 팝업 수정