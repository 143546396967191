@charset "UTF-8";

.btn {
    display: inline-block;
    min-width:112px;
    padding:11px 31px;
    @include font-medium;
    text-align: center;
    vertical-align: top;
    border: 1px solid $color-primary;
    border-radius: 26px;
    background-color:$color-primary;
    color:#fff;
    font-weight:500;
    @include mobile {
        min-width:124px;
        font-size:16px;
        line-height:24px;
        padding:15px 25px;
        border-radius:28px;
    }
    &:hover,
    &:focus,
    &:active {
        background-color:$color-primary;
        color: $color-white;
    }
    &.white { // BTOCSITE-13902
        border-color: #fff;
        background: #fff;
        color: #000;
        &:hover {
            background: #fff;
            color: #000;
        }

    }
    // 20210727 BTOCSITE-3577 고객지원 > Main 의 출장서비스 예약 영역에 제품 설치/철거 예약 탭 및 내용 추가
    &.black {
        border-color: #000;
        background: #000;
        color: $color-white;
        &:hover {
            background: #000;
            color: $color-white;
        }
        // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
        &:focus-visible {
            outline:3px dotted #000 !important;
        }
    }
    // BTOCSITE-72986 [스토리개편] 관련 - 마이페이지>태그 구독 관리 내 종료 안내 문구 노출
    &.gray900 {
        border-color: #0f0f0f;
        background: #0f0f0f;
        color: $color-white;
        &:hover {
            background: #0f0f0f;
            color: $color-white;
        }
    }
    &.gray {
        border-color: #eee;
        background: #eee;
        color: $color-gray-1;
        &:hover {
            background: #ededed;
            color: $color-gray-1;
        }
    }
    &.dark-gray {
        border-color: #767676;
        background: #767676;
        color: $color-white;
        &:hover {
            background: #767676;
            color: $color-white;
        }
    }
    &.mix { // 그라데이션 타입
        border:none;
        padding:12px 32px;
        background: #000; //BTOCSITE-14407-DTO
        color:#fff;
    }
    &.border {
        border-color:#000 !important;
        background:transparent;
        color:#000;
        &.pink {
            border-color: $color-primary !important;
            background:transparent;
            color: $color-primary;
        }
        &:hover,
        &:focus,
        &:active {
            border-color: $color-primary !important;
            background: $color-primary;
            color: $color-white;
        }
    }
    // S : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
    &:disabled,
    &.disabled {
        border-color:#ddd !important;
        background:#f7f7f7;
        color:#8f8f8f;
        cursor:default;
        &:hover,
        &:focus,
        &:active {
            border-color:#ddd !important;
            background:#f7f7f7;
            color: #8f8f8f;
        }
    }
    // E : BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
    // 작은사이즈 버튼
    &.size {
        min-width:64px;
        padding:3px 11px;
        border-radius:16px;
        @include font-small;
        @include mobile {
            padding:5px 15px;
            line-height:22px;
        }
        &.mix {
            padding:4px 12px;
        }
    }
    // 중간사이즈 버튼
    &.size-m {
        min-width:80px;
        padding: 7px 15px;
        border-radius:20px;
        @include font-small;
        @include mobile {
            padding:10px 19px;
            border-radius:22px;
        }        
    }
    &.block {
        display: block;
        width: 100%;
    }
    &.border {
        position: relative;
        &.arrow {
            padding-right: 40px;
            &:after {
                content: '';
                position:absolute;
                right:16px;
                top:50%;
                width:20px;
                height:20px;
                margin-top: -8px;
                //transform: translateY(-50%);
                background:url("/lg5-common/images/icons/btn-arrow-right-40.svg") center no-repeat;
                background-size: 20px 20px;
            }
            &:hover {
                background:#fff;
                border-color:#000;
                color:#000;
            }
        }
        &.plus {
            padding-right: 44px;
            &:after {
                content: '';
                position:absolute;
                right:16px;
                top:50%;
                width:20px;
                height:20px;
                //margin-top: -8px;
                transform: translateY(-50%);
                background:url("/lg5-common/images/icons/icon-plus-40.svg") center no-repeat;
                background-size: 20px 20px;
            }
            &:hover {
                background:#fff;
                border-color:#000;
                color:#000;
            }
        }
    }

    &.question {
        position:relative;
        padding-left:32px;
        @include font-small;
        &:before {
            content:'';
            position:absolute;
            left:14px;
            top:50%;
            width:14px;
            height:14px;
            transform: translateY(-50%);
            background:url("/lg5-common/images/icons/icon-question-16.svg") center no-repeat;
        }
    }
}

.btn-text {
    display:inline-block;
    font-size:16px;
    font-weight:500;
    line-height:24px;
    vertical-align:top;
    &.small {
        @include font-small;
    }
    &.pink {
        color:#ec455a;
    }
    &.black {
        color:#000;
    }
    &.red01 {
        color: #ec455a;
        border-bottom-color: #ec455a;
    }
    &:hover,
    &:focus {
        color: $color-default;
        text-decoration:underline;
    }
    &:disabled, &.disabled, &[aria-disabled=true] {
        color:#ccc;
        &:hover {
            text-decoration:none;
        }
    }
    @include mobile {
        font-size:14px;
        line-height:18px;
        text-decoration:underline;
    }
}

.btn-link {
    position: relative;
    display: inline-block;
    padding-right: 18px;
    // font-weight: 500;
    @include font-medium;
    color: #000;
    text-decoration:underline;
    vertical-align: top;
    &:after {
        content: '';
        position:absolute;
        right:0;
        top:6px;
        width:14px;
        height:14px;
        background:url('/lg5-common/images/icons/btn-arr-16-black.svg') center no-repeat;
        background-size:14px;
        @include mobile {
            top:4px;
        }
    }
    &:hover,
    &:focus {
        color: $color-default;
    }

    &.white{
        color:#fff;
        &:after{
            background:url('/lg5-common/images/icons/btn-arrow-right-white-20.svg') center;
            background-size:15px;
        }
    }
}

.btn-moreinfo {
    display:inline-block;
    position:relative;
    font-size:14px;
    line-height:16px;
    font-weight:500;
    span {
        display:inline-block;
        vertical-align:top;
    }
    &:after {
        content:'';
        display:inline-block;
        margin-left:4px;
        vertical-align:top;
        width:16px;
        height:16px;
        background:url('/lg5-common/images/icons/icon-more.svg') no-repeat;
        background-size:16px 16px;
    }
}

.btn-pr-list {
    display:inline-block;
    position:relative;
    font-size:14px;
    line-height:16px;
    font-weight:500;
    color:#767676;
    span {
        display:inline-block;
        vertical-align:top;
    }
    &:after {
        content:'';
        display:inline-block;
        margin-top:-1px;
        margin-left:4px;
        vertical-align:top;
        width:20px;
        height:20px;
        background:url('/lg5-common/images/icons/icon-more-pk-20.svg') no-repeat;
        background-size:20px 20px;
    }
}

.btn-moreview {
    //display:inline-block; 기본 display:none
    display: none;
    padding:3px 11px;
    border:1px solid #222;
    border-radius:16px;
    color:#000;
    font-size:14px;
    line-height:24px;
    font-weight:500;
    text-align:center;
    vertical-align:top;
    &::before {
        content:' ';
        display:inline-block;
        width:20px;
        height:24px;
        margin-right:2px;
        background:url('/lg5-common/images/icons/btn-more-20.svg') center no-repeat;
        vertical-align:top;
    }
    &.block {
        //display:block; 기본 display:none
        width:auto;
        margin:32px auto 0;
        @include mobile {
            margin-top:24px;
        }
    }
}

.btn-banner {
    display:inline-block;
    position:relative;
    padding:15px 72px 15px 16px;
    color:$color-primary;
    font-size:16px;
    line-height:24px;
    text-align:left;
    vertical-align:top;
    border:1px solid $color-primary;
    border-radius:8px;
    background:url(/lg5-common/images/icons/icon-arrow-right-red.svg) no-repeat right 16px top 16px;
    [class^=icon-] {
        display:block;
        padding-left:36px;
        background-repeat:no-repeat;
        background-size:24px;
        &.icon-solution {
            background-image:url(/lg5-common/images/icons/icon-solution.svg);
        }
        &.icon-driver {
            background-image:url(/lg5-common/images/icons/icon-driver.svg);
        }
        &.icon-plus {
            background-image:url(/lg5-common/images/icons/icon-plus.svg); 
        }
    }
    @include mobile {
        width:100%;
        padding:14px 12px;
        background:none;
        [class^=icon-] {
            padding-left:32px;
        }
    }
    &.block {
        display:block;
        width:100%;
    }
}

.btn-addr {
    display:inline-block;
    position:relative;
    padding:10px 16px;
    color:#ec455a;
    @include font-medium;
    text-align:center;
    vertical-align:top;
    font-weight:500;
    border:1px solid #ec455a;
    border-radius:8px;
    vertical-align:top;
    [class^=icon-] {
        display:inline-block;
        padding-left:36px;
        background-repeat:no-repeat;
        background-size:24px;
        &.icon-plus {
            background-image:url(/lg5-common/images/icons/icon-plus.svg); 
        }
    }
    @include mobile {
        width:100%;
        padding:14px 12px;
        background:none;
        [class^=icon-] {
            padding-left:26px;
            background-size:20px 20px;
        }
    }
    &.block {
        display:block;
        width:100%;
    }
}

.btn-group {
    display: block;
    font-size:0;
    &.right {
        text-align:right;
    }
    &.center {
        text-align:center;
    }
    .btn {
        margin-left:8px;
        &:first-child {
            margin-left:0;
        }
        & + .btn {
            margin-left: 8px;
        }
    }
    @include mobile {
        .btn {
            width:100%;
            margin-left:8px;
            border-radius: 60px;
            &:first-child,
            &:only-child {
                margin-left:0;
            }
            &:first-child:nth-last-child(2), 
            &:first-child:nth-last-child(2) ~ .btn {
                width:calc((100% - 8px) / 2);
            }
            &:first-child:nth-last-child(3), 
            &:first-child:nth-last-child(3) ~ .btn {
                width:calc((100% - 12px) / 3);
            }
        }
        &.block-type {
            padding: 0;
            margin-top: 0;
            .btn {
                border-radius: 0;
            }
        }
    }

    /* 부트스트랩 충돌 추가 */
    & > .btn-group:not(:last-child)>.btn,
    & > .btn:not(:last-child):not(.dropdown-toggle),
    & > .btn-group:not(:first-child)>.btn,
    & > .btn:not(:first-child) {
        border-radius: 26px;
    }
    & {
        position:initial;
    }
}
// S :  BTOCSITE-71915 [스토리개편] 홈메인 전시개편
.btn-storyHome {
    display: inline-block;
    min-width: 100px;
    height: 50px;
    padding: 14px 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    vertical-align: top;
    border: 1px solid #0F0F0F;
    border-radius: 26px;
    color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
    font-weight: 700;
    @include mobile {
        min-width: 96px;
        font-size: 13px;
    }
    // &::before {
    //     content: '';
    //     margin: 2px 6px 0 0;
    //     display: inline-block;
    //     vertical-align: top;
    //     width: 16px;
    //     height: 16px;
    //     background: url('/lg5-common/images/icons/icon_storyhome.svg') no-repeat 50% 50% / 100% auto;
    // }
}
// E :  BTOCSITE-71915 [스토리개편] 홈메인 전시개편