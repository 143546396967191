/*RangeSlider */
.ui-range-slider {
    position: relative; 
    width: 100%; 
    height: 16px; 
    text-align: left; 
    background:#ededed;
    border-radius:8px;
    &.single-type::before {
        content:' ';
        position:absolute;
        left:0;
        top:0;
        width:16px;
        height:16px;
        border-radius:8px 0 0 8px;
        background:$color-primary;
    }
    .hide {
        display:inline-block;
        position:absolute;
        z-index:-10;
        width:0 !important;
        height:0 !important;
        overflow:hidden !important;
        opacity:0 !important;
        filter:alpha(opacity=0);
        -ms-filter:'progid:DXImageTransform.Microsoft.Alpha(opacity=0)';
        font-size:medium;
        line-height:normal;
        word-break:break-all;
    }
    .ui-range-slider-bg {
        position: absolute;
        height: 16px;
        background-color: #ededed;
    }
    .ui-range-slider-range { 
        position: absolute; 
        background-color: $color-primary;
        height: 16px;
    }
    .ui-range-slider-handler {
        position: absolute; 
        height: 12px;
        width: 12px;
        border: 1px solid $color-primary;
        border-radius:50%;
        background:$color-white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .ui-range-slider-value {
        margin:0; 
        padding:0; 
        top:0;
        li {
            list-style-type: none; 
            cursor: pointer; 
            //height: 16px;
            //width: 16px;
            margin:0;	
            padding:0;
            //border:1px solid #ddd;
            //border-radius:8px;
            //background:#fff;
        }
    }
}