.result-list {
    @include clearfix;

    >li {
        position: relative;
        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 12px;
        }

        .item {
            display: flex;
            flex-wrap: wrap;
            min-height: 172px;
            padding: 16px 32px;
            //align-items: center;
            border-radius: 8px;
            box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
            background-color: #ffffff;

            @include mobile {
                min-height: 138px;
                align-items: flex-start;
                padding: 12px 20px 16px;
            }

            &.item-type2 {
                min-height: 153px;
                padding: 24px 32px;

                @include mobile {
                    display: block;
                    min-height: 142px;
                    padding: 16px 20px;
                }
            }
        }

        .point {
            color: $color-primary;
        }

        .badge {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .result-thumb {
            position: relative;
            width: 140px;
            //height: 140px;
            margin-right: 32px;

            a {
                display: block;
            }

            @include mobile {
                position: absolute;
                top: 12px;
                left: 20px;
                width: 110px;
                //height: 110px;
                margin-right: 16px;
            }

            img {
                max-width: 100%;
                vertical-align: top;
                //@at-root .result-list > li { 단종/구매불가 케이스
                //    .discontinued {
                //        .result-thumb {
                //            img {
                //                opacity: .3;
                //            }
                //        }
                //    }
                //}
            }

            .flag-end-wrap {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: rgba($color-default, .5);

                .flag {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-weight: 700;
                    @include font-large-1;
                    color: #fff;
                }
            }

            .video-play-btn {
                display: inline-block;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color-default, .2);
                text-indent: -99999em;

                &:after {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 48px;
                    height: 48px;
                    transform: translate(-50%, -50%);
                    background: url('/lg5-common/images/icons/btn-play-80.svg') no-repeat 50% 50%;
                    background-size: 100% 100%;

                    @include mobile {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }

        .item-type2,
        .item-story {
            .result-thumb {
                overflow: hidden;
                width: 140px;

                //height: 105px;
                >div {
                    position: relative;
                }

                @include mobile {
                    width: 110px;
                    //height: 82.5px;
                    top: 16px;
                }

                img.no-img {
                    width: 100%;
                    //transform: translateY(-20px);
                }
            }
        }

        // -S- BTOCSITE-16 검색 결과 구획 정리
        .result-info {
            display: flex;
            flex: 1;
            justify-content: space-between;

            //align-items: center;
            .info-text {
                display: flex;
                flex-direction: column;
                width: 100%;
                //align-items: start;
                //flex: 1;
                padding-top: 12px;
                padding-right: 80px;
            }

            @include mobile {
                display: block;

                .info-text {
                    min-height: 110px;
                    padding: 4px 0 0 126px;
                }
            }

            .detail-wrap {

                //flex: 1;
                @include mobile {
                    min-height: 110px;
                }
            }

            .spec-info {
                font-size: 0;
                // max-width: 569px;
                margin-top: 8px;
                padding-top: 4px;
                border-top: 1px solid $color-border;

                @include mobile {
                    margin: 12px 0 0 -126px;
                    padding: 8px 12px;
                    border-radius: 8px;
                    background-color: $color-bg;
                    border-top: 0;
                }

                li {
                    display: inline-block;
                    margin-right: 16px;
                    @include font-x-small;

                    &.rentalModel {
                        display: none;

                        @include mobile {
                            display: block;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    span {
                        color: $color-gray-1;
                        margin-right: 4px;

                        @include mobile {
                            margin-right: 8px;
                        }
                    }
                }
            }

            .info-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                @include pc {
                    min-width: 248px;
                    padding-left: 32px;

                    &:before {
                        position: absolute;
                        top: 16px;
                        bottom: 16px;
                        right: 280px;
                        width: 1px;
                        background-color: $color-border;
                        content: '';
                    }

                    &.pc-only {
                        &:empty:before {
                            display: none;
                        }
                    }

                    &.mo-only {
                        display: none;
                    }
                }

                @include mobile {
                    display: block;
                    width: 100%;
                    margin-top: 6px;
                    padding-top: 6px;
                    // border-top: 1px solid $color-border;
                    text-align: left;

                    &.mo-only {
                        display: block;

                        &:empty {
                            display: none;
                        }
                    }

                    &.pc-only {
                        display: none;
                    }
                }


                &.type2 {
                    align-items: center;
                    margin-top: 0;

                    @include mobile {
                        margin-top: 16px;
                        align-items: flex-start;
                    }
                }

                .price-info {
                    &.rental {
                        @include mobile {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }

                    &.sales {
                        display: flex;
                        align-items: flex-end;

                        @include pc {
                            flex-direction: row-reverse;
                            justify-content: flex-end;
                        }

                        @include mobile {
                            position: relative;
                            justify-content: flex-end;

                            .original,
                            .price-in {
                                display: inline-block;
                            }

                            .price-in {
                                .tit {
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }

                    &.lowest {
                        .price-lowest {
                            display: flex;
                            align-items: center;
                            margin-bottom: 2px;
                            justify-content: flex-end;
                            @include mobile {
                                margin-bottom: 4px;
                                justify-content: flex-start;
                            }
                            .lowest-label {
                                margin-right: 8px;
                                font-size: 16px;
                                line-height: 24px;
                                color: #ea1917; 
                                @include mobile {
                                    margin-right: 4px;
                                    font-size: 15px;
                                    line-height: 21px;
                                }
                            }
                        }
                        .price-store {
                            @include mobile {
                                text-align: left;
                            }
                            .store {
                                font-size: 12px;
                                line-height: 18px;
                                @include mobile {
                                    font-size: 11px;
                                    line-height: 16px;
                                }
                                em {
                                    font-size: 12px;
                                    line-height: 18px;
                                    @include mobile {
                                        font-size: 11px;
                                        line-height: 16px;
                                    }
                                }
                            }
                        }
                    }

                    &.sold_out { // BTOCSITE-25479 일시 품절 시 가격정보 투명화
                        position: relative;
                        overflow: initial;
                        >div {
                            opacity: 0.5;
                            @include mobile {
                                float: initial;
                                display: inline-block;
                            }
                            &.original{
                                padding-top: 0;
                                vertical-align: super;
                            }
                        }
                        &::before {
                            content: none;
                            // content: "일시품절";
                            // position: absolute;
                            // left: -72px;
                            // display: inline-block;
                            // margin-right: 16px;
                            // padding: 0 8px;
                            // border-radius: 4px;
                            // background-color: #666;
                            // @include font(12px,24px);
                            // color: #fff;
                            // @include mobile {
                            //     content: none;
                            // }
                        }
                        &::after {
                            content: "일시품절";
                            display: inline-block;
                            margin-left: 8px;
                            padding: 0 8px;
                            text-align: center;
                            border-radius: 4px;
                            background-color: #666;
                            vertical-align: middle;
                            @include font(14px,26px);
                            color: #fff;

                            @include mobile {
                                margin-left: 4px;
                                padding: 0 6px;
                                @include font(9px,18px);
                            }
                        }

                        &.executive {
                            &::after,
                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                a+a .price-info {
                    margin-top: 12px;

                    @include mobile {
                        margin-top: 5px;
                    }
                }
            }

            .btn-area {
                .btn+.btn {
                    margin-left: 4px;
                }
            }

        }

        // -E- BTOCSITE-16 검색 결과 구획 정리
        .item-type2 {
            .info-price {
                display: block;
            }
        }

        .flag-wrap {
            margin-bottom: 2px;

            .flag {
                display: inline;
            }
        }

        .result-tit {
            margin-bottom: 1px;
            font-weight: 700;
            @include font($font-medium, 26px);
            color: $color-default;

            @include mobile {
                @include font(15px, 23px);
            }

            >a:first-child,
            >strong {
                display: block;
                max-height: 52px;
                @include textEllipsisClamp(2);
                word-break: break-all;
            }

        }

        .cs {
            display: block;
            margin-top: 4px;
            font-weight: 400;
            @include font($font-medium, 26px);
            color: $color-default;

            .cs-inner+.cs-inner {
                margin-left: 4px;

                &:before {
                    display: inline-block;
                    margin-right: 4px;
                    content: '>';
                }
            }

            @include mobile {
                margin-top: 2px;
                @include font(13px, 21px)
            }
        }

        .item-type2 {
            .result-tit {
                max-width: 680px;
                max-height: 60px;
                @include font-medium;
            }

            .result-info {
                .info-text {
                    width: 100%;
                    padding-top: 0;
                    padding-right: 0;
                }

                @include mobile {
                    .info-text {
                        min-height: 83px;
                    }
                }
            }
        }

        .info-btm {
            margin-top: 12px;
            font-size: 0;

            @include mobile {
                margin-top: 6px;
            }

            .care {
                display: inline-block; //BTOCSITE-3404 210809 추가

                .text {
                    display: inline-block;
                    position: relative; //BTOCSITE-3404 210809 수정
                    margin-right: 6px;
                    padding-right: 6px;
                    @include font($font-small, 24px);

                    &:last-child {
                        margin-right: 6px;
                        padding-right: 6px;
                    }

                    &:not(:last-child):before {
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        content: '';
                        width: 1px;
                        height: 12px;
                        transform: translate(-50%, -50%);
                        background: #ddd;
                    }

                    @include mobile {
                        display: inline-block;
                        margin-right: 0;
                        padding-right: 0;
                        @include font($font-x-small, 20px);

                        &:before,
                        &:not(:last-child):before {
                            display: none;
                        }

                        &.date {
                            display: inline-block;
                            font-size: 13px;
                            line-height: 21px;
                        }

                        &.date+.hashtag-wrap {
                            display: inline-block;
                            margin-left: 4px;
                            padding-left: 4px;

                            &:before {
                                display: inline-block;
                            }
                        }
                    }

                    &.model {
                        max-height: 24px;
                        @include textEllipsisClamp(1);

                        @include mobile {
                            max-height: 20px;
                        }
                    }
                }

                /* BTOCSITE-3404 210809 추가 */
                .care-n {
                    @include font($font-small, 24px);
                    line-height: 13px;

                    //position: absolute;
                    @include mobile {
                        display: inline-block;
                        margin-left: 0;
                        padding-left: 0;
                        @include font($font-x-small, 20px);
                    }
                }

                .redcare-option {
                    margin-right: 9px;
                    padding-right: 9px;
                    @include font($font-small, 24px);
                    line-height: 13px;
                    color:$color-primary;
                    position: relative;
                    display: inline-block;

                    &:after {
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        content: '';
                        width: 1px;
                        height: 12px;
                        transform: translate(-50%, -50%);
                        background: #ddd;
                    }

                    @include mobile {
                        display: inline-block;
                        @include font($font-x-small, 20px);
                        margin-right: 3px;
                        padding-right: 3px;

                        &:after {
                            background: none;
                        }

                        &:before {
                            display: inline-block;
                            content: ',';
                            background: none;
                            margin-right: 8px;
                            color: #000;
                        }
                    }

                    // &:last-child {
                    //     margin-right: 0;
                    // }
                }


                /* //BTOCSITE-3404 210809 추가 */
            }
        }

        .hashtag-wrap {
            display: inline-block;

            .hashtag {
                display: inline-block;
                margin-right: 4px;
                @include font-small;

                @include mobile {
                    position: static;
                    height: auto;
                    margin-right: 6px;
                    padding: 0;
                    border-radius: 0;
                    border: 0;
                    font-size: 13px;
                    line-height: 13px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        // -S- BTOCSITE-16 검색 결과 구획 정리
        .sku {
            display: inline-block;
            font-weight: 500;
            vertical-align: top;
            margin-right: 6px;

            @include font-small;

            .search-word {
                color: #000;
            }
        }

        .result-detail {
            .rentalModel {
                display: inline-block;
                font-weight: 500;
                margin-right: 6px;
                @include font-small;
                vertical-align: top;

                @include mobile {
                    display: none;
                }
            }
        }

        // -E- BTOCSITE-16 검색 결과 구획 정리
        .hashtag-wrap+.careflag {
            @include mobile {
                display: block;
                margin-left: 0 !important;
                padding-left: 0 !important;

                &:before {
                    display: none !important;

                }
            }
        }

        .desc {
            margin-bottom: 6px;
            @include textEllipsisClamp(1);
            @include font($font-medium, 24px);
            color: $color-default;

            @include mobile {
                @include font(13px, 21px);
            }
        }

        // -S- BTOCSITE-16 검색 결과 구획 정리
        .review-info {
            display: inline-block;
            margin-right: 12px;
            font-size: 0;
            vertical-align: top;

            a {
                display: block;
                //padding-bottom:10px;
            }

            @include mobile {
                margin-right: 0;
                margin-left: 0;
            }

            .star,
            .average-rating,
            .review-count {
                display: inline-block;
                @include font-small;
            }

            .star {
                width: 20px;
                height: 20px;
                margin-right: 2px;
                background: url("/lg5-common/images/icons/icon-star-off-20.svg") no-repeat 0 0;
                vertical-align: -5px;

                &.is-review {
                    background-image: url("/lg5-common/images/icons/icon-star-on-20.svg");
                }
            }

        }

        // -E- BTOCSITE-16 검색 결과 구획 정리

        .shop-info {
            .desc {
                display: block;
                margin: 0;
                @include textEllipsisClamp(1);
                @include font-medium;

                @include mobile {
                    &.time {
                        margin-top: 2px;
                    }
                }

                span+span {
                    margin-left: 8px;
                }
            }
        }

        .shop-state {
            position: absolute;
            top: 24px;
            right: 32px;

            @include mobile {
                top: 16px;
                right: 20px;
            }

            span {
                @include font-x-small;

                &:before {
                    display: inline-block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    border-radius: 4px;
                }

                &.skyblue {
                    &:before {
                        background: #21a9aa;
                    }
                }

                &.olive {
                    &:before {
                        background: #76892d;
                    }
                }

                &.red {
                    &:before {
                        background: #ff3723;
                    }
                }
            }
        }

        // -S- BTOCSITE-16 검색 결과 구획 정리
        .price-info {
            white-space: nowrap;

            .tit {
                display: inline-block;
                vertical-align: middle;
                @include font(16px, 26px);
                color: #666666;
                font-weight: $font-weight-bold;
                padding-right: 5px;

                @include mobile {
                    font-size: 14px;
                    line-height: 22px;

                }
            }

            .price {
                display: inline-block;
                vertical-align: middle;
                @include font(16px, 26px);
                color: $color-default;
                font-weight: $font-weight-bold;

                @include mobile {
                    @include font(14px, 22px);
                }

                @media screen and (max-width:320px) {
                    @include font(12px, 22px);
                }

            }

            .original {
                margin-right: 8px;
                vertical-align: bottom;

                .price {
                    font-weight: 400;
                    @include font-x-small;
                    color: $color-gray-1;
                    text-decoration: line-through;
                }

                @include mobile {
                    margin-right: 4px;
                    margin-left: 0;

                    .price {
                        @include font(11px, 19px);
                    }
                }

                @media screen and (max-width:320px) {
                    .price {
                        @include font(10px, 19px);
                    }
                }

            }
        }

        // -E- BTOCSITE-16 검색 결과 구획 정리
    }

    &.product-list {
        .info-text {
            .result-tit {
                max-width: 100%;

                @include mobile {
                    max-width: 100%;
                }
            }
        }
    }

    &.story-list {
        >li .item-type2 .result-tit {
            @include textEllipsisClamp(1);
        }
    }

    &.shop-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;

        @include mobile {
            margin-left: 0;
        }

        li {
            width: 50%;
            padding-left: 16px;

            @include mobile {
                width: 100%;
                padding-left: 0;
            }

            .item {
                display: block;
                padding: 24px 32px;

                @include mobile {
                    padding: 16px 20px;
                }
            }

            .result-info {
                display: block;

                //flex-direction: column;
                //align-items: flex-start;
                .info-text {
                    padding-right: 0;
                }

                @include mobile {
                    .info-text {
                        min-height: auto;
                        padding: 0;
                    }
                }

                @include pc {
                    .result-detail {
                        height: 52px;
                    }
                }
            }
        }

        .btn-area {
            width: 100%;
            margin-top: 24px;
            text-align: right;

            .btn+.btn {
                margin-left: 4px;
            }

            @include mobile {
                margin-top: 12px;
            }
        }
    }

    &.support-list {
        .item {
            min-height: auto;
            padding: 24px 32px;

            @include mobile {
                padding: 16px 20px;
            }
        }

        .info-btm {
            .text {
                display: inline-block;
                margin-right: 6px;
                padding-right: 6px;
                font-size: 14px;
                line-height: 21px;
            }

            @include mobile {
                margin-top: 6px;
                font-size: 13px;
            }
        }

        .sku+.info-btm {
            .text {
                margin-right: 0;
                padding-right: 0;

                &:not(:last-child):after {
                    content: ' \2758';
                    display: inline-block;
                    margin: 0 5px;
                }
            }

            @include mobile {
                margin-top: 12px;
            }
        }

        .result-thumb {
            margin: -8px 0;

            //position: static;
            &+.result-info {
                padding: 4px 0 4px 32px;
            }

            @include mobile {
                position: static;
                margin: 0;

                &+.result-info {
                    padding: 0 0 0 16px;
                }
            }
        }

        .result-info {
            @include clearfix;

            @include mobile {
                .info-text {
                    padding-left: 0;
                }
            }

            .info-text {
                flex: 1;
                min-height: auto;
                padding: 0;
            }
        }

        //BTOCSITE-11326
        .result-tit {
            .search-content {
                display: block;

                >.desc {
                    margin-bottom: 6px;
                    @include textEllipsisClamp(2);
                    max-height: 46px;
                    @include font($font-medium, 24px);
                    color: $color-default;
                    font-weight: $font-weight-regular;
                    white-space: normal;

                    @include mobile {
                        @include font(13px, 21px);
                    }
                }
            }
        }

        .btn-area {
            align-self: center;

            @include mobile {
                width: 100%;
                margin-top: 12px;
                text-align: right;
            }
        }

        .video-info {
            align-self: center;
            display: inline-block;
            width: 48px;
            height: 48px;
            margin-left: 30px;
            background: url("/lg5-common/images/icons/btn-play-80.svg") no-repeat 50% 50%;
            background-size: 100% 100%;

            @include mobile {
                position: absolute;
                top: 12px;
                right: 12px;
                width: 17px;
                height: 17px;
                margin-left: 0;
            }

            span {
                display: inline-block;
                text-indent: -9999em;

                @include mobile {}
            }
        }
    }

    &.care-list {
        >li .result-info .info-text {
            display: block;
        }
    }

    // 회사소개 START
    &.company-list {
        .item {
            min-height: 142px !important;

            @include mobile {
                min-height: auto !important;
            }

            .result-info {
                .info-text {
                    @include mobile {
                        padding-left: 0
                    }
                }
            }
        }
    }

    // 회사소개 END

    // LIVE LG
    &.livelg-list {
        @include pc {
            .flag-wrap {
                background: url('/lg5-common/images/icons/ic_outlink_16.svg') no-repeat 100% 50%;
                background-size: 16px;
            }
        }

        @include mobile {
            li a {
                background: url('/lg5-common/images/icons/ic_outlink_16.svg') no-repeat top 10px right 10px;
                background-size: 16px;
            }

            .result-info {
                .info-text {
                    padding-left: 0;
                }
            }

            .result-thumb+.result-info {
                .info-text {
                    padding-left: 126px;
                }
            }
        }
    }
}


// IE10 and IE11
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .result-list {
        &>li {
            .desc {
                white-space: nowrap;
            }
        }
    }
}

// LGECOMVIO-16
.search.re22,
.search-layer.re22 {
    .result-list-wrap {
        .list-wrap {
            &.subscribe {
                .price-benefit {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
    .result-list {
        @include clearfix;
        > li {
            position: relative;
            margin-bottom: 24px;
            @include mobile {
                margin-bottom: 12px;
                .item {
                    // overflow: hidden;
                    &.supplies {
                        min-height: 128px;
                    }
                    .result-tabs {
                        .cnt_mo_default {
                            display: block;
                        }
                        .tabs-wrap {
                            display: none;
                        }
                    }
                }
                &.on {
                    .item {
                        .result-tabs {
                            .cnt_mo_default {
                                display: none;
                            }
                            .tabs-wrap {
                                display: block;
                            }
                        }
                    }
                }
            }
            .item {
                display: block;
                min-height: 290px;
                padding: 16px 32px 24px 16px;
                border-radius: 16px;
                box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
                background-color: #fff;
                @include mobile {
                    display: flex;
                    align-items: flex-start;
                    min-height: 138px;
                    padding: 12px 0 0 0;
                    border-radius: 8px;
                    box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
                }
                &.item-type2 {
                    min-height: 153px;
                    padding: 24px 32px;
                    @include mobile {
                        display: block;
                        min-height: 142px;
                        padding: 16px 20px;
                    }
                }
                &.supplies {
                    min-height: auto;
                }
            }
            .prod_badge {
                position: absolute;
                top: 16px;
                left: 24px;
                display: inline-block;
                padding: 0 6px;
                margin-right: 8px;
                border-radius: 4px;
                @include font(12px,20px);
                font-weight: 500;
                @include mobile {
                    top: 12px;
                    left: 16px;
                    padding: 0 4px;
                    @include font(10px,16px);
                }
                &.tomorrow { // BTOCSITE-32032 내일 배송 뱃지 색상 변경
                    background-color: #ffeef3;
                    color: #ea1917;
                }
            }
            .badge_box {
                position: absolute;
                top: 16px;
                left: 24px;
                font-size: 0;
                @include mobile {
                    top: 12px;
                    left: 16px;
                }
                .prod_badge {
                    position: static;
                    display: inline-block;
                    padding: 0 6px;
                    margin-right: 4px;
                    border-radius: 4px;
                    @include font(12px,20px);
                    font-weight: 500;
                    @include mobile {
                        padding: 0 4px;
                        @include font(10px,16px);
                    }
                    &.to_be_sold {
                        background-color: #f7f3ff;
                        color: #6735ea;
                    }
                    &.release_multi_product, //BTOCSITE-48682 다품목할인 추가
                    &.release23 {
                        background-color: #ffeaee;
                        color: #a50023;
                    }
                }
            }

            .item_topright {
                position: absolute;
                top: 16px;
                right: 32px;
                display: flex;
                @include mobile {
                    position: static;
                    width: 100%;
                    margin-bottom: 12px;
                    padding-right: 12px;
                    text-align: right;
                    justify-content: flex-end;
                }
            }

            .result-top-area {
                @include mobile {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                .badge_box {
                    @include mobile {
                        position: static;
                        margin: 0 0 12px 12px;
                    }
                    & + .item_topright {
                        @include mobile {
                            width: auto;
                        }
                    }
                }

                .item_topright {
                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .wish_check {
                .chk-wrap {
                    input {
                        + label {
                            color: #000;
                            padding: 0 20px 0 0;
                            @include font(12px,20px);
                            @include mobile {
                                @include font(11px,16px);
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                top: calc(50% - 8px);
                                left: auto;
                                right: 0;
                                width: 16px;
                                height: 16px;
                                background: url(/lg5-common/images/icons/btn-like-off-16.svg) no-repeat center/100%;
                            }
                        }
                        &:checked {
                            + label {
                                &::after {
                                    background: url(/lg5-common/images/icons/btn-like-on-24.svg) no-repeat center/100%;
                                }
                            }
                        }
                    }
                }
            }
            .compare_check {
                margin-left: 16px;
                @include mobile {
                    margin-left: 12px;
                }
                .chk-wrap {
                    input {
                        + label {
                            color: #000;
                            padding: 0 20px 0 0;
                            @include font(12px,20px);
                            @include mobile {
                                @include font(11px,16px);
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                top: calc(50% - 8px);
                                left: auto;
                                right: 0;
                                width: 16px;
                                height: 16px;
                                background: url(/lg5-common/images/icons/round_plus_b_32_2.svg) no-repeat center / 100%;
                            }
                        }
                        &:checked {
                            + label {
                                color: #ea1917;
                                &::after {
                                    background: url(/lg5-common/images/icons/round_plus_b_32.svg) no-repeat center / 100%;
                                }
                            }
                        }
                    }
                }

            }
            .point {
                color: $color-primary;
            }
            .badge {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
            .result-thumb {
                position: absolute;
                top: 44px;
                left: 16px;
                width: 202px;
                margin: 0;
                a {
                    display:block;
                    &.cata_noimg {
                        position: relative;
                        width: 186px;
                        height: 186px;
                        margin: 8px;
                        border-radius: 8px;
                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        @include mobile {
                            width: 100px;
                            height: 100px;
                            margin: 0;
                        }
                        img {
                            position: absolute;
                            top: calc(50% - 28px);
                            left: calc(50% - 28px);
                            width: 56px;
                            height: 56px;
                            min-height: auto;
                            @include mobile {
                                top: calc(50% - 20px);
                                left: calc(50% - 20px);
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }
                }
                @include mobile {
                    position: static;
                    width: 112px;
                    padding-left: 8px;
                }
                img {
                    max-width: 100%;
                    vertical-align: top;
                }
                .flag-end-wrap {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-color: rgba($color-default,.5);
                    .flag {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        font-weight: 700;
                        @include font-large-1;
                        color: #fff;
                    }
                }
                .video-play-btn {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color-default,.2);
                    text-indent: -99999em;
                    &:after {
                        display: inline-block;
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 48px;
                        height: 48px;
                        transform: translate(-50%, -50%);
                        background: url('/lg5-common/images/icons/btn-play-80.svg') no-repeat 50% 50%;
                        background-size: 100% 100%;
                        @include mobile {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
            .item-type2,
            .item-story {
                .result-thumb {
                    overflow: hidden;
                    width: 140px;
                    > div {
                        position: relative;
                    }
                    @include mobile {
                        width: 110px;
                        top: 16px;
                    }
                    img.no-img {
                        width: 100%;
                    }
                }
            }
            .result-info {
                display: block;
                padding-left: 242px;
                @include mobile {
                    display: block;
                    padding:0 12px 0 8px;
                    min-height: auto;
                }
                .info-text {
                    display: block;
                    width:100%;
                    padding: 44px 0 0 0;
                    @include mobile {
                        min-height: 0;
                        padding: 0;
                    }
                    .detail-wrap {
                        @include mobile {
                            min-height: 0;
                        }
                    }
                }
                .spec-info {
                    font-size: 0;
                    // max-width: 569px;
                    margin-top: 8px;
                    padding-top: 4px;
                    border-top: 1px solid $color-border;

                    @include mobile {
                        margin: 12px 0 0 -126px;
                        padding: 8px 12px;
                        border-radius: 8px;
                        background-color: $color-bg;
                        border-top: 0;
                    }

                    li {
                        display: inline-block;
                        margin-right: 16px;
                        @include font-x-small;
                        &.rentalModel {
                            display:none;
                            @include mobile {
                                display:block;
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        span {
                            color: $color-gray-1;
                            margin-right: 4px;

                            @include mobile {
                                margin-right: 8px;
                            }
                        }
                    }
                }
                .info-price {
                    display: block;
                    min-width: initial;
                    margin-top: 0;
                    padding-left: 0;
                    text-align: right;
                    @include font(0,0);
                    &::before {
                        content: none;
                    }
                    @include pc {
                        &.pc-only {
                            &:empty:before {
                                display:none;
                            }
                        }
                        &.mo-only { display:none; }
                    }
                    @include mobile {
                        display: block;
                        width: 100%;
                        margin-top: 0;
                        padding: 0;
                        text-align: left;
                        &.mo-only {
                            display:block;
                            &:empty {
                                display:none;
                            }
                        }
                        &.pc-only { display:none; }
                    }
                    a {
                        display: inline-block;
                        & > div {
                            &:first-child {
                                margin-top: 16px;
                                @include mobile {
                                    margin-top: 8px;
                                }
                            }
                        }
                    }


                    &.type2 {
                        align-items: center;
                        margin-top: 0;
                        @include mobile {
                            margin-top: 16px;
                            align-items: flex-start;
                        }
                    }
                    .price-info {
                        &.rental {
                            @include mobile {
                                display: flex;
                                justify-content: flex-start;
                            }
                        }
                        &.sales {
                            display: inline-block;
                            align-items: initial;
                            @include pc {
                                flex-direction: initial;
                                justify-content: initial;
                            }
                            @include mobile {
                                position: relative;
                                justify-content: flex-end;
                                .original,
                                .price-in {
                                    display: inline-block;
                                }
                                .price-in {
                                    .tit {
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                            }

                            &.sold_out {
                                .original {
                                    @include mobile {
                                        display: block;
                                        margin: 0 0 1px 0;
                                    }
                                }
                            }
                        }

                        &.executive {
                            display: block;
                            margin: 4px 0 1px;
                            font-size: 0;

                            @include mobile {
                                margin: 2px 0;
                                text-align: left;
                            }

                            span {
                                display: inline-block;
                                height: 24px;
                                color: #000;
                                vertical-align: middle;

                                @include mobile {
                                    height: 21px;
                                }
                            }
                            
                            .t-executive {
                                @include font(12px, 24px);
                                font-weight: 500;

                                @include mobile {
                                    @include font(11px, 22px);
                                }
                            }

                            .p-executive {
                                margin-left: 8px;
                                color: #ea1917;
                                @include font(16px, 24px);

                                @include mobile {
                                    margin-left: 4px;
                                    @include font(15px, 22px);
                                }
                            }

                            &.sold_out {
                                span {
                                    color: rgba(102, 102, 102, 0.5);

                                    &.p-executive {
                                        color: rgba(234, 25, 23, 0.5);
                                    }
                                }

                                @include mobile {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &.sold_out {
                            @include mobile {
                                display: block;
                                text-align: left;
                            }
                        }

                        &.sales.sold_out {
                            &::after {
                                @include mobile {
                                    display: inline-block;
                                    width: auto;
                                    margin: 0 0 0 2px;
                                    vertical-align: middle;
                                }
                            }
                            
                        }
                        
                        &.benefit {
                            color: #000;
                            margin-top: 4px;
                            @include mobile {
                                margin-top: 2px;
                            }
                            .benefit-price {
                                // display: flex;
                                // align-items: center;
                                // justify-content: end;
                                .txt {
                                    margin-right: 8px;
                                    @include font(12px, 24px);
                                    font-weight: 500;
                                    transform: translateY(1px);
                                }
                                em {
                                    @include font(16px, 24px);
                                }

                                @include mobile {
                                    justify-content: start;

                                    .txt {
                                        margin-right: 4px;
                                        @include font(11px, 20px);
                                    }
                                    em {
                                        @include font(15px, 20px);
                                    }
                                }
                            }

                            .member-point {
                                @include font(12px, 17px);
                                text-align: right;

                                & > span {
                                    display: inline-block;
                                    line-height: 17px;
                                }

                                @include mobile {
                                    margin-top: 1px;
                                    @include font(11px, 16px);
                                    text-align: left;

                                    & > span {
                                        line-height: 16px;
                                    }
                                }
                            }

                            &.sold_out {
                                color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                &::after {
                                    content: '';
                                    display: none;
                                }
                            }
                        }
                    }

                    a + a .price-info{
                        margin-top: 12px;
                        @include mobile {
                            margin-top: 5px;
                        }
                    }
                }

                .btn-area {
                    .btn + .btn {
                        margin-left: 4px;
                    }
                }

                .delivery-area{
                    @include mobile {
                        margin-top: 4px;
                    }

                    & > span {
                        display: inline-block;
                    }

                    .t-delivery {
                        position: relative;
                        margin-right: 8px;
                        padding-left: 32px;
                        color: #000;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 32px;

                        @include mobile {
                            margin-right: 4px;
                            padding-left: 26px;
                            font-size: 11px;
                            line-height: 20px;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 32px;
                            height: 32px;
                            background: url(/lg5-common/images/icons/ico_truck_32.svg) no-repeat 50% 50%;
                            background-size: 100% 100%;
                            transform: translateY(-50%);

                            @include mobile {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    .p-delivery {
                        color: #008182;
                        font-size: 12px;
                        line-height: 32px;

                        @include mobile {
                            font-size: 11px;
                            line-height: 20px;
                        }
                    }


                }
            }

            .result-tabs{
                margin-top: 16px;
                padding-left: 242px;
                @include mobile {
                    width: 100%;
                    margin-top: 24px;
                    padding-left: 0;
                }
                .tab_mo_mask {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    width: 22px;
                    height: 29px;
                    background: linear-gradient(to left, rgba(255,255,255,0), #fff 8px);
                    @include pc {
                        display: none;
                    }
                    @include mobile {
                        display: block;
                    }
                }
                .tab_mo_close {
                    position: absolute;
                    top: 1px;
                    right: 0;
                    width: 42px;
                    height: 29px;
                    background:#fff url("/lg5-common/images/icons/icon_category_close.svg") no-repeat left 4px center/24px;
                    font-size: 0;
                    color: transparent;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -10px;
                        width: 15px;
                        height: 29px;
                        background:linear-gradient(to right, rgba(255,255,255,0), #fff);
                    }
                    @include pc {
                        display: none;
                    }
                    @include mobile {
                        display: block;
                    }
                }
                .tabs-wrap.border-type{
                    text-align: left;
                    @include mobile {
                        border-top: 1px solid #f4f4f4;
                    }
                    .tabs{
                        @include mobile {
                            padding: 0 45px 0 19px;
                            max-width: 99%;
                            -ms-overflow-style: none;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }
                        li{
                            a{
                                padding-bottom: 4px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 24px;
                            }
                        }
                        li.on{
                            a{
                                font-weight: 700;
                                &::after {
                                    height: 2px;
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
                .tabs-contant{
                    padding-top: 24px;
                    @include mobile {
                        padding: 12px 16px 16px 16px;
                    }
                    .cnt-wrap {
                        &::after {
                            content: '';
                            display: table;
                            clear: both;
                        }
                        &.type-tbl {
                            display: table;
                            width: 100%;
                            @include mobile {
                                display: block;
                            }
                            &.one {
                                .cnt_area {
                                    &.support {
                                        ul {
                                            display: block;
                                            li {
                                                width: 100%;
                                                border: 0;
                                                border-radius: 0;
                                            }
                                        }
                                    }
                                }
                            }
                            .cnt_area {
                                display: table-cell;
                                vertical-align: middle;
                                // margin-top: -8px;
                                @include mobile {
                                    display: block;
                                    padding: 0 4px;
                                }
                                &.support {
                                    padding-right: 40px;
                                    @include tablet {
                                        padding-right: 25px;
                                    }
                                    @include mobile {
                                        padding-right: 0;
                                    }
                                    ul {
                                        display: flex;
                                        justify-content: left;
                                        margin: 8px 0 0 0;
                                        &:first-child {
                                            margin-top: 0;
                                        }
                                        @include mobile {
                                            display: block;
                                            margin-left: 0;
                                        }
                                        li {
                                            margin-right: 16px;
                                            @include mobile {
                                                display: block;
                                                width: 100%;
                                                margin-bottom: 12px;
                                                border-radius: 8px;
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                            }
                                            button{
                                                display: block;
                                                @include font(14px,20px);
                                                color: #666;
                                                // background: no-repeat 0 center/24px;
                                                span {
                                                    display: inline-block;
                                                    margin-left: 8px;
                                                    padding-right: 16px;
                                                    @include font(14px,20px);
                                                    font-weight: 700;
                                                    color: #000;
                                                    background: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg) no-repeat right center/16px;
                                                    @include mobile {
                                                        margin-left: 4px;
                                                        padding-right: 0;
                                                        background: none;
                                                        @include font(13px,19px);
                                                    }
                                                }
                                                @include mobile {
                                                    width: 100%;
                                                    padding: 10px 16px;
                                                    border: 1px solid #ddd;
                                                    border-radius: 8px;
                                                    @include font(13px,19px);
                                                    color: #000;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .btn_area {
                                display: table-cell;
                                width: 180px;
                                vertical-align: middle;
                                @include mobile {
                                    display: block;
                                    width: 100%;
                                    margin-top: 16px;
                                    .search-btn {
                                        width: 100%;
                                        height: 40px;
                                        border-radius: 8px;
                                        @include font(13px,40px);
                                    }
                                }
                            }

                            .sumore_tit {
                                @include font(14px,20px);
                                font-weight: 700;
                                @include mobile {
                                    @include font(13px,19px);
                                }
                                &.sw {
                                    font-weight: 500;
                                }
                            }
                            .sumore_cnt {
                                @include font(14px,20px);
                                color: #666;
                                @include textEllipsisClamp(2);
                                @include mobile {
                                    @include font(13px,19px);
                                }
                            }
                            .sumore_infoarea {
                                position: relative;
                                margin-top: 8px;
                                @include mobile {
                                    margin-top: 12px;
                                }
                                .sumore_info {
                                    overflow: hidden;
                                    @include font(12px,18px);
                                    color: #666;
                                    @include mobile {
                                        @include font(10px,15px);
                                    }
                                    .info_text {
                                        float: left;
                                    }
                                    .info_date {
                                        float: left;
                                        position: relative;
                                        margin-left: 8px;
                                        padding-left: 9px;
                                        @include mobile {
                                            margin-left: 4px;
                                            padding-left: 5px;
                                        }
                                        &::before {
                                            content: '';
                                            position: absolute;
                                            top: 6px;
                                            left: 0;
                                            width: 1px;
                                            height: 8px;
                                            background-color: #ddd;
                                        }
                                    }
                                }
                                .link_area {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    @include mobile {
                                        position: static;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        margin-top: 8px;
                                    }
                                    a {
                                        display: inline-block;
                                        margin-left: 12px;
                                        @include font(14px,20px);
                                        vertical-align: middle;
                                        text-decoration: underline;
                                        &:first-child {
                                            margin-left: 0;
                                        }
                                        @include mobile {
                                            width: 100%;
                                            margin-left: 7px;
                                            padding: 10px 0;
                                            border: 1px solid #ddd;
                                            border-radius: 8px;
                                            text-align: center;
                                            @include font(13px,19px);
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                        &.type-lst {
                            .cnt_area {
                                &.supplies {
                                    li {
                                        position: relative;
                                        min-height: 96px;
                                        padding: 16px 0;
                                        border-top: 1px solid #f4f4f4;
                                        @include mobile {
                                            padding: 12px 0;
                                            min-height: 80px;
                                            &:first-child {
                                                margin-top: -12px;
                                            }
                                        }
                                        &:first-child {
                                            margin-top: -16px;
                                            border-top: 0;

                                            @include mobile {
                                                margin-top: 0;
                                                padding-top: 0
                                            }
                                        }
                                        a {
                                            display: flex;
                                            align-items: flex-start;
                                            position: relative;
                                        }
                                        img {
                                            width: 64px;
                                            @include mobile {
                                                width: 56px;
                                            }
                                        }
                                        .supplies_prod{
                                            flex: 1;
                                            display: block;
                                            padding-left: 24px;
                                            @include mobile {
                                                padding-left: 16px;
                                                padding-right: 60px;
                                            }
                                            .info_area {
                                                @include mobile {
                                                    float: none;
                                                }
                                                .name {
                                                    display: block;
                                                    font-size: 16px;
                                                    font-weight: 500;
                                                    line-height: 24px;
                                                    @include mobile {
                                                        @include font(13px,19px);
                                                    }
                                                }
                                                .model {
                                                    display: block;
                                                    font-size: 12px;
                                                    color: #666;
                                                    line-height: 18px;
                                                    @include mobile {
                                                        @include font(10px,15px);
                                                    }
                                                }
                                            }
                                            .pay_area {
                                                display: flex;
                                                position: absolute;
                                                bottom: 0;
                                                right: 0;
                                                @include mobile {
                                                    position: static;
                                                    margin-top: 4px;
                                                }
                                                .salerate {
                                                    font-size: 14px;
                                                    color:$color-primary;
                                                    line-height: 20px;
                                                    @include pc {
                                                        & + .sale_pay {
                                                            margin-left: 8px;
                                                        }
                                                    }
                                                    @include mobile {
                                                        margin-right: 4px;
                                                        @include font(13px,19px);
                                                    }
                                                }
                                                .sale_pay {
                                                    margin-left: 16px;
                                                    font-size: 14px;
                                                    font-weight: 700;
                                                    line-height: 20px;
                                                    @include mobile {
                                                        margin: 0 4px 0 0;
                                                        @include font(13px,19px);
                                                    }
                                                }
                                                .orig_pay {
                                                    display: inline-block;
                                                    margin: 0 8px;
                                                    font-size: 12px;
                                                    color: #666;
                                                    line-height: 18px;
                                                    text-decoration: line-through;
                                                    @include mobile {
                                                        margin: 0 4px 0 0;
                                                        @include font(11px,19px);
                                                        vertical-align: middle;
                                                    }
                                                }
                                                &.sold_out {
                                                    display: block;
                                                    padding-right: 76px;
                                                    @include mobile {
                                                        padding-right: 0;
                                                    }
                                                    .salerate {
                                                        color: rgba(234, 25, 23, 0.5);
                                                        @include pc {
                                                            margin-left: 0;
                                                            & + .sale_pay {
                                                                margin-left: 8px;
                                                            }
                                                        }
                                                        @include mobile {
                                                            & + .sale_pay {
                                                                margin-right: 4px;
                                                            }
                                                        }
                                                    }
                                                    .sale_pay {
                                                        color: #666;
                                                        @include mobile {
                                                            margin-right: 4px;
                                                        }
                                                    }
                                                    .orig_pay {
                                                        color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                                        @include mobile {
                                                            display: block;
                                                            margin-right: 4px;
                                                        }
                                                    }
                                                    &::before {
                                                        content: "일시품절";
                                                        position: absolute;
                                                        top: 50%;
                                                        right: 0;
                                                        //margin-right: 16px;
                                                        min-width: 68px;
                                                        padding: 0 8px;
                                                        border-radius: 4px;
                                                        background-color: #666;
                                                        @include font(14px,26px);
                                                        color: #fff;
                                                        transform: translateY(-50%);
                                                        @include mobile {
                                                            content: none;
                                                        }
                                                    }
                                                    &::after {
                                                        content: "일시품절";
                                                        display: inline-block;
                                                        padding: 0 6px;
                                                        border-radius: 4px;
                                                        background-color: #666;
                                                        @include font(9px,18px);
                                                        color: #fff;
                                                        @include pc {
                                                            content: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .btn-cart {
                                            display: block;
                                            position: absolute;
                                            top: 12px;
                                            right: 0;
                                            z-index: 1;
                                            width: 40px;
                                            height: 40px;
                                            background: #fff url(/lg5-common/images/icons/ico_cart_48.svg) no-repeat 50% 50%;
                                            background-size: 24px 24px;
                                            border: 1px solid #ddd;
                                            border-radius: 8px;
                                        }

                                        &:first-child {
                                            .btn-cart {
                                                @include mobile {
                                                    top: 0;
                                                }
                                            }
                                        }
                                    }
                                    & + .btn_area {
                                        margin-top: 0;
                                        padding-top: 24px;
                                        border-top: 1px solid #f4f4f4;
                                    }
                                }
                                &.review {
                                    margin-top: -8px;
                                    @include mobile {
                                        margin-top: 4px;
                                    }
                                    .review_score {
                                        display: flex;
                                        align-items: center;
                                        padding: 12px 24px;
                                        border-radius: 8px;
                                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                        @include mobile {
                                            padding: 10px 12px;
                                        }
                                        .score_star {
                                            padding-left: 28px;
                                            background: url(/lg5-common/images/icons/icon-star-on-18.svg) no-repeat left center/24px;
                                            @include font(16px,24px);
                                            font-weight: 700;
                                            @include mobile {
                                                padding-left: 20px;
                                                background-size: 16px;
                                                @include font(13px,19px);
                                            }
                                        }
                                        .score_class {
                                            margin-left: 8px;
                                            @include font(14px,20px);
                                            color: #666;
                                            @include mobile {
                                                margin-left: 2px;
                                                @include font(11px,19px);
                                            }
                                        }
                                        .score_rate {
                                            margin-left: 24px;
                                            @include font(14px,20px);
                                            @include mobile {
                                                margin-left: 4px;
                                                @include font(12px,19px);
                                            }
                                            em {
                                                margin-right: 2px;
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                    .review_comment {
                                        padding: 16px 0;
                                        @include mobile {
                                            padding: 12px 0;
                                        }
                                        .comment_cnt {
                                            @include textEllipsisClamp(2);
                                            @include font(16px,24px);
                                            color: #666;
                                            @include mobile {
                                                @include font(13px,19px);
                                            }
                                        }
                                    }
                                    .review_photo {
                                        padding: 16px 0;
                                        border-top: 1px solid #f4f4f4;
                                        @include mobile {
                                            padding: 12px 0 16px 0;
                                            border-top: 1px solid #ddd;
                                        }
                                        .photo_tit {
                                            @include font(16px,24px);
                                            @include mobile {
                                                @include font(12px,17px);
                                                text-align: center;
                                            }
                                            em {
                                                font-weight: 700;
                                            }
                                        }
                                        ul {
                                            display: flex;
                                            align-items: center;
                                            flex-wrap: wrap;
                                            overflow: hidden;
                                            height: 104px;
                                            margin-top: 8px;
                                            @include mobile {
                                                height: 68px;
                                                margin-top: 12px;
                                            }
                                            li {
                                                width: 104px;
                                                height: 104px;
                                                border-radius: 8px;
                                                background: no-repeat center/cover;
                                                @include mobile {
                                                    width: 68px;
                                                    height: 68px;
                                                }
                                                & + li {
                                                    margin-left: 8px;
                                                }
                                                &.review_vod {
                                                    position: relative;
                                                    &::before {
                                                        content:'';
                                                        position:absolute;
                                                        left:0;
                                                        top:0;
                                                        width:100%;
                                                        height:100%;
                                                        background:#000;
                                                        opacity:0.4;
                                                        z-index:1;
                                                        border-radius: 12px;
                                                        @include mobile {
                                                            border-radius: 4px;
                                                        }
                                                    }
                                                    &::after {
                                                        content: "";
                                                        position: absolute;
                                                        top: calc(50% - 12px);
                                                        left: calc(50% - 12px);
                                                        width: 24px;
                                                        height: 24px;
                                                        background: url("/lg5-common/images/btn-video-play-s.svg") no-repeat center/100%;
                                                        z-index:1;
                                                        @include mobile {
                                                            top: calc(50% - 11px);
                                                            left: calc(50% - 11px);
                                                            width: 22px;
                                                            height: 22px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .btn_area {
                                        margin-top: 0;
                                        padding: 16px 0 8px 0;
                                        border-top: 1px solid #f4f4f4;
                                        text-align: right;
                                        @include mobile {
                                            padding: 0;
                                            border-top: 0;
                                            text-align: initial;
                                        }
                                        .search-btn.fix.black {
                                            width: 180px;
                                            background-color: #222;
                                            @include mobile {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                                &.rental {
                                    ul {
                                        overflow: hidden;
                                        @include mobile {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            margin-left: 0;
                                        }
                                        li {
                                            float: left;
                                            margin-left: 8px;
                                            @include mobile {
                                                display: block;
                                                width: 100%;
                                                margin-right: 4px;
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                            }
                                            &:first-child {
                                                margin-left: 0;
                                            }
                                            a {
                                                display: block;
                                                width: 164px;
                                                font-size: 14px;
                                                line-height: 40px;
                                                border-radius: 8px;
                                                border: solid 1px #ddd;
                                                text-align: center;
                                                @include mobile {
                                                    display: flex;
                                                    width: auto;
                                                    justify-content: center;
                                                    @include font(11px,16px);
                                                    text-align: center;
                                                }
                                                span {
                                                    display: inline-block;
                                                    padding-left: 26px;
                                                    @include mobile {
                                                        height: 100%;
                                                        padding:42px 0 12px 0;
                                                    }
                                                }
                                                &.phone{
                                                    span {
                                                        background: url(/lg5-common/images/icons/icon-shop-24-reserve.svg) no-repeat left center/24px;
                                                        @include mobile {
                                                            background-position: center 14px;
                                                        }
                                                    }
                                                }
                                                &.search{
                                                    span {
                                                        background: url(/lg5-common/images/icons/icon-shop-24-search.svg) no-repeat left center/24px;
                                                        @include mobile {
                                                            background-position: center 14px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.gallery { // BTOCSITE-21785 갤러리 탭 생성
                                    margin-bottom: -48px;
                                    @include mobile {
                                        margin-bottom: 0;
                                    }
                                    .mo-img-box {
                                        display: none;
                                        @include mobile {
                                            display: block;
                                            width: calc(100% - 24px);
                                            margin: 0 auto;
                                            margin-bottom: 12px;
                                            border-radius: 16px;
                                            box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
                                            overflow: hidden;
                                        }
                                        a {
                                            display: block;
                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }
                                    }
                                    .gallery-std { // 갤러리
                                        position: relative;
                                        .gallery-swiper {
                                            bottom: 24px;
                                            width: calc(100% - 44px);
                                            padding: 24px 0;
                                            @include tablet {
                                                width: calc(100% - 28px);
                                                padding: 32px 0 24px;
                                            }
                                            @include mobile {
                                                width: calc(100% - 24px);
                                                bottom: 12px;
                                                padding: 12px 0;
                                            }
                                            .swiper-slide {
                                                height: auto;
                                                >a {
                                                    display: block;
                                                    height: 100%;
                                                    .img-box {
                                                        display: block;
                                                        height: 100%;
                                                        max-height: 188px;
                                                        border-radius: 16px;
                                                        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
                                                        overflow: hidden;
                                                        @include mobile {
                                                            max-height: initial;
                                                        }
                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .swiper-gallery-btn {
                                            display: block;
                                            width: 48px;
                                            height: 48px;
                                            margin-top: initial;
                                            transform: translateY(-100%);
                                            border-radius: 50%;
                                            box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
                                            opacity: 1;
                                            @include tablet {
                                                width: 28px;
                                                height: 28px;
                                            }
                                            // @include mobile {
                                            //     display: none;
                                            // }
                                            &.swiper-button-next {
                                                background: url(/lg5-common/images/icons/btn-arr-40x40-rt.svg) no-repeat 50% 50% / 90%;
                                                right: 0;
                                            }
                                            &.swiper-button-prev {
                                                background: url(/lg5-common/images/icons/btn-arr-40x40-lt.svg) no-repeat 50% 50% / 90%;
                                                left: 0;
                                            }
                                            &.swiper-button-disabled {
                                                display: none;
                                            }
                                            &::after {
                                                display: none;
                                            }
                                        }
                                    }
                                    // .thumb-std { // 썸네일
                                    //     position: relative;
                                    //     .thumb-swiper {
                                    //         display: none;
                                    //         width: calc(100% - 24px);
                                    //         padding: 12px 0;
                                    //         margin-bottom: -36px;
                                    //         @include mobile {
                                    //             display: block;
                                    //             bottom: 24px;
                                    //         }
                                    //         .swiper-slide {
                                    //             height: auto;
                                    //             .img-box {
                                    //                 display: block;
                                    //                 height: 100%;
                                    //                 border-radius: 8px;
                                    //                 box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
                                    //                 overflow: hidden;
                                    //                 img {
                                    //                     width: 100%;
                                    //                     height: 100%;
                                    //                     object-fit: cover;
                                    //                 }
                                    //             }
                                    //         }
                                    //     }
                                    //     .swiper-thumb-btn {
                                    //         display: none;
                                    //         top: 45%;
                                    //         width: 36px;
                                    //         height: 36px;
                                    //         margin-top: initial;
                                    //         transform: translateY(-100%);
                                    //         border-radius: 50%;
                                    //         box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
                                    //         opacity: 1;
                                    //         @include mobile {
                                    //             display: block;
                                    //         }
                                    //         &.swiper-button-next {
                                    //             background: url(/lg5-common/images/icons/btn-arr-40x40-rt.svg) no-repeat 50% 50% / 90%;
                                    //             right: 0;
                                    //         }
                                    //         &.swiper-button-prev {
                                    //             background: url(/lg5-common/images/icons/btn-arr-40x40-lt.svg) no-repeat 50% 50% / 90%;
                                    //             left: 0;
                                    //         }
                                    //         &.swiper-button-disabled {
                                    //             display: none;
                                    //         }
                                    //         &::after {
                                    //             display: none;
                                    //         }
                                    //     }
                                    // }
                                }
                                &.shop {
                                    ul {
                                        overflow: hidden;
                                        @include mobile {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            margin-left: 0;
                                        }
                                        li {
                                            float: left;
                                            margin-left: 8px;
                                            @include mobile {
                                                display: block;
                                                width: 100%;
                                                margin-right: 4px;
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                            }
                                            &:first-child {
                                                margin-left: 0;
                                            }
                                            a {
                                                display: block;
                                                width: 150px;
                                                font-size: 14px;
                                                line-height: 40px;
                                                border-radius: 8px;
                                                border: solid 1px #ddd;
                                                @include mobile {
                                                    width: auto;
                                                    padding: 42px 0 14px 0;
                                                    @include font(13px,19px);
                                                    text-align: center;
                                                }
                                                &.reserve{
                                                    padding-left: 49px;
                                                    background: url(/lg5-common/images/icons/icon-shop-24-reserve.svg) no-repeat left 23px center/24px;
                                                    @include mobile {
                                                        padding-left: 0;
                                                        background-position: center 14px;
                                                    }
                                                }
                                                &.search{
                                                    padding-left: 49px;
                                                    background: url(/lg5-common/images/icons/icon-shop-24-search.svg) no-repeat left 23px center/24px;
                                                    @include mobile {
                                                        padding-left: 0;
                                                        background-position: center 14px;
                                                    }
                                                }
                                                &.estimate{
                                                    padding-left: 43px;
                                                    background: url(/lg5-common/images/icons/icon-shop-24-estimate.svg) no-repeat left 17px center/24px;
                                                    @include mobile {
                                                        padding-left: 0;
                                                        background-position: center 14px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.support {
                                    > ul > li {
                                        position: relative;
                                        min-height: 96px;
                                        padding: 16px 0;
                                        border-top: 1px solid #f4f4f4;
                                        &:first-child {
                                            border-top: 0;
                                        }
                                        @include mobile {
                                            padding: 12px 0;
                                        }
                                        img {
                                            position: absolute;
                                                top: 16px;
                                                left: 0;
                                                width: 64px;
                                                @include mobile {
                                                    width: 60px;
                                                    top: 24px;
                                                }
                                        }
                                        .support_prod{
                                            display: block;
                                            padding-left: 72px;
                                            @include mobile {
                                                padding-left: 70px;
                                                min-height: 50px;
                                            }
                                            .name {
                                                display: block;
                                                font-size: 16px;
                                                font-weight: 500;
                                                line-height: 24px;
                                                @include textEllipsis;
                                                @include mobile {
                                                    margin-top: 9px;
                                                    @include font(13px,19px);
                                                }
                                            }
                                            .model {
                                                display: block;
                                                font-size: 12px;
                                                color: #666;
                                                line-height: 18px;
                                                @include mobile {
                                                    @include font(10px,15px);
                                                }
                                            }
                                        }
                                        .support_file{
                                            display: flex;
                                            justify-content: right;
                                            margin: 8px 0 0 72px;
                                            @include mobile {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                margin: 0;
                                                padding-left: 70px;
                                            }
                                            li {
                                                margin-left: 16px;
                                                @include mobile {
                                                    display: block;
                                                    width: 100%;
                                                    margin: 0 0 0 8px;
                                                    border: 1px solid #ddd;
                                                    border-radius: 8px;
                                                    &:first-child {
                                                        margin-left: 0;
                                                    }
                                                }
                                                button{
                                                    display: block;
                                                    @include font(14px,20px);
                                                    color: #000;
                                                    // background: no-repeat 0 center/24px;
                                                    span {
                                                        display: inline-block;
                                                        margin-left: 8px;
                                                        padding-right: 16px;
                                                        @include font(14px,20px);
                                                        font-weight: 700;
                                                        color: #000;
                                                        background: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg) no-repeat right center/16px;
                                                        @include mobile {
                                                            margin-left: 4px;
                                                            padding-right: 0;
                                                            background: none;
                                                            @include font(11px,16px);
                                                        }
                                                    }
                                                    @include mobile {
                                                        width: 100%;
                                                        padding: 10px 16px;
                                                        @include font(11px,16px);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    & + .btn_area {
                                        margin-top: 0;
                                        padding-top: 24px;
                                        border-top: 1px solid #f4f4f4;
                                    }
                                }
                                &.story {
                                    @include mobile {
                                        margin: 0 -16px 0 -16px;
                                    }
                                    > ul {
                                        @include mobile {
                                            max-width: 100%;
                                            white-space: nowrap;
                                            overflow-x: auto;
                                            -ms-overflow-style: none;
                                            &::-webkit-scrollbar {
                                                display: none;
                                            }
                                        }
                                        > li {
                                            position: relative;
                                            min-height: 96px;
                                            padding: 16px 0;
                                            border-top: 1px solid #f4f4f4;
                                            &:only-child {
                                                @include mobile {
                                                    width: 100%;
                                                }
                                            }
                                            @include mobile {
                                                display: inline-block;
                                                width: 276px;
                                                padding: 0 16px;
                                                min-height: 80px;
                                                border: 0;
                                                vertical-align: top;
                                                &:first-child {
                                                    margin-top: -12px;
                                                    @include mobile {
                                                        margin-top: 0;
                                                    }
                                                }
                                                & + li {
                                                    position: relative;
                                                    &::before {
                                                        content: "";
                                                        position: absolute;
                                                        top: 10px;
                                                        left: 0;
                                                        width: 1px;
                                                        height: 100px;
                                                        background-color: #ddd;
                                                    }
                                                }
                                            }
                                            &:first-child {
                                                border-top: 0;
                                            }
                                            a {
                                                display: block;
                                                height: 100%;
                                                .story_area {
                                                    position: relative;
                                                    border: 0;
                                                    border-radius: 0;
                                                    box-shadow: none;
                                                    min-height: 112px;
                                                    @include mobile {
                                                        min-height: auto;
                                                    }
                                                    .story_tit {
                                                        padding-right: 176px;
                                                        @include font(16px,24px);
                                                        font-weight: 700;
                                                        @include textEllipsisClamp(1);
                                                        @include mobile {
                                                            width: 100%;
                                                            margin-bottom: 8px;
                                                            padding-right: 0;
                                                            @include font(14px,20px);
                                                            @include textEllipsisClamp(1);
                                                            white-space: normal;
                                                        }
                                                    }
                                                    .story_flexarea {
                                                        @include mobile {
                                                            overflow: hidden;
                                                            width: 100%;
                                                        }
                                                        .story_cnt {
                                                            margin-top: 4px;
                                                            padding-right: 176px;
                                                            @include mobile {
                                                                float: left;
                                                                margin-top: 0;
                                                                width: calc(100% - 118px);
                                                                padding-right: 8px;
                                                            }
                                                            .cnt_txt {
                                                                display: block;
                                                                @include font(16px,24px);
                                                                @include textEllipsisClamp(2);
                                                                @include mobile {
                                                                    @include font(13px,19px);
                                                                    @include textEllipsisClamp(4);
                                                                    white-space: normal;
                                                                }
                                                            }
                                                            .story_tag {
                                                                position: absolute;
                                                                left: 0;
                                                                bottom: 0;
                                                                width: 100%;
                                                                overflow: hidden;
                                                                @include mobile {
                                                                    position: static;
                                                                    margin-top: 8px;
                                                                    width: 100%;
                                                                    height: 15px;
                                                                }
                                                                .tag_logo {
                                                                    position: relative;
                                                                    float: left;
                                                                    width: 82px;
                                                                    height: 20px;
                                                                    margin-right: 8px;
                                                                    padding-right: 28px;
                                                                    background: url("/lg5-common/images/icons/ico-livelg-72x20.png") no-repeat left center/56px 14px;
                                                                    @include mobile {
                                                                        width: 56px;
                                                                        height: 14px;
                                                                        margin-right: 4px;
                                                                        background-size: 40px 10px;
                                                                    }
                                                                    &::before {
                                                                        content: "";
                                                                        position: absolute;
                                                                        top: 2px;
                                                                        right: 8px;
                                                                        width: 16px;
                                                                        height: 16px;
                                                                        background: url("/lg5-common/images/icons/btn-outlink-28.svg") no-repeat center/100%;
                                                                        @include mobile {
                                                                            top: 0;
                                                                            right: 2px;
                                                                            width: 16px;
                                                                            height: 16px;
                                                                        }
                                                                    }
                                                                    &::after {
                                                                        content: "";
                                                                        position: absolute;
                                                                        top: 4px;
                                                                        right: 0;
                                                                        width: 1px;
                                                                        height: 12px;
                                                                        background-color: #ddd;
                                                                        @include mobile {
                                                                            top: 2px;
                                                                        }
                                                                    }
                                                                }
                                                                .tag_itm {
                                                                    position: relative;
                                                                    float: left;
                                                                    @include font(14px,20px);
                                                                    color: #008182;
                                                                    @include mobile {
                                                                        @include font(10px,15px);
                                                                    }
                                                                    + .tag_itm {
                                                                        margin-left: 8px;
                                                                        padding-left: 9px;
                                                                        @include mobile {
                                                                            margin-left: 4px;
                                                                            padding-left: 5px;
                                                                        }
                                                                        &::before {
                                                                            content: "";
                                                                            position: absolute;
                                                                            top: 4px;
                                                                            left: 0;
                                                                            width: 1px;
                                                                            height: 12px;
                                                                            background-color: #ddd;
                                                                            @include mobile {
                                                                                top: 2px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .story_imgwrap {
                                                            position: absolute;
                                                            top: 0;
                                                            right: 0;
                                                            width: 152px;
                                                            height: 112px;
                                                            @include mobile {
                                                                float: right;
                                                                position: static;
                                                                width: 118px;
                                                                height: 87px;
                                                                border-radius: 4px;
                                                            }
                                                            .story_img {
                                                                overflow: hidden;
                                                                background-repeat: no-repeat;
                                                                background-size: cover;
                                                                background-position: center;
                                                                width: 100%;
                                                                height: 100%;
                                                                border: solid 1.5px #ddd;
                                                                border-radius: 8px;
                                                                @include mobile {
                                                                    border-radius: 4px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .story_paging {
                                        @include mobile {
                                            display: none;
                                        }
                                        padding: 18px 0 2px 0;
                                        border-top: 1px solid #f4f4f4;
                                        text-align: center;
                                        a {
                                            display: inline-block;
                                            width: 24px;
                                            height: 24px;
                                            border: 0;
                                            border-radius: 0;
                                            background: none;
                                            @include font(14px,24px);
                                            font-weight: 500;
                                            color: #666;
                                            text-align: center;
                                            &.first, &.last {
                                                display: none;
                                            }
                                            &.prev {
                                                margin-right: 10px;
                                                @include font(0,0);
                                                color: transparent;
                                                background: url('/lg5-common/images/icons/btn-story-paging-prev.svg') no-repeat center/16px;
                                            }

                                            &.next {
                                                margin-left: 10px;
                                                @include font(0,0);
                                                color: transparent;
                                                background: url('/lg5-common/images/icons/btn-story-paging-next.svg') no-repeat center/16px;
                                            }
                                        }
                                        .page_num {
                                            a {
                                                padding: 0;
                                                &.on {
                                                    font-weight: 700;
                                                }
                                            }
                                        }
                                    }
                                }
                                // BTOCSITE-23446 판매처 탭 추가
                                &.store {
                                    li {
                                        padding: 16px 0;
                                        &:first-child { 
                                            padding-top: 0;
                                            border-bottom: 1px solid #f4f4f4;
                                        }
                                        @include mobile {
                                            padding: 12px 0;
                                        }
                                        a {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            @include mobile {
                                                align-items: flex-start;
                                            }
                                            .store-info {
                                                display: flex;
                                                align-items: center;
                                                max-width: calc(100% - 154px);
                                                .store-name{
                                                    width: 100px;
                                                    min-width: 100px;
                                                    margin-right: 24px;
                                                    font-size: 14px;
                                                    line-height: 20px;
                                                    color: #666;
                                                    @include mobile {
                                                        font-size: 13px;
                                                        line-height: 19px;
                                                    }
                                                }
                                                .store-tit{
                                                    font-size: 14px;
                                                    font-weight: 500;
                                                    line-height: 20px;
                                                    overflow: hidden;
                                                    white-space: nowrap;
                                                    text-overflow: ellipsis;
                                                    @include mobile {
                                                        display: none;
                                                    }
                                                }
                                            }
                                            .price-detail {
                                                min-width: 154px;
                                                text-align: right;
                                                .price-in {
                                                    margin-bottom: 2px;
                                                    font-size: 0;
                                                    @include mobile {
                                                        margin-bottom: 4px;
                                                    }
                                                    span {
                                                        display: inline-block;
                                                        font-size: 14px;
                                                        line-height: 20px;
                                                        @include mobile {
                                                            font-size: 13px;
                                                            line-height: 19px;
                                                        }
                                                        &.lowest{
                                                            margin-right: 8px;
                                                            color: #ea1917;
                                                            @include mobile {
                                                                margin-right: 4px;
                                                            }
                                                        }
                                                        &.price {
                                                            font-weight: 700;
                                                            em {
                                                                font-size: 14px;
                                                                font-weight: 700;
                                                                line-height: 20px;
                                                                @include mobile {
                                                                    font-size: 13px;
                                                                    line-height: 19px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .price-etc {
                                                    font-size: 0;
                                                    span {
                                                        display: inline-block;
                                                        padding-left: 9px;
                                                        margin-left: 8px;
                                                        font-size: 12px;
                                                        line-height: 18px;
                                                        position: relative;
                                                        &:first-child {
                                                            padding-left: 0;
                                                            margin-left: 0;
                                                            &:before {
                                                                display: none;
                                                            }
                                                        }
                                                        &::before {
                                                            content: "";
                                                            position: absolute;
                                                            left: 0;
                                                            top: 50%;
                                                            transform: translateY(-50%);
                                                            width: 1px;
                                                            height: 8px;
                                                            background-color: #ddd;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .btn_area {
                                        margin-top: 24px;
                                        @include mobile {
                                            margin-top: 0;
                                        }
                                    }
                                    .guide-box {
                                        padding: 24px 24px 24px 32px;
                                        margin-top: 24px;
                                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                        border-radius: 16px;
                                        position: relative;
                                        @include mobile {
                                            padding: 20px 16px;
                                            border-radius: 8px;
                                        }
                                        .guide-list {
                                            li {
                                                display: flex;
                                                padding-top: 0;
                                                padding-bottom: 8px;
                                                font-size: 14px;
                                                line-height: 20px;
                                                border-bottom: none;
                                                position: relative;
                                                &:first-child {
                                                    padding-right: 24px;
                                                }
                                                &:last-child {
                                                    padding-bottom: 0;
                                                }
                                                @include mobile {
                                                    font-size: 12px;
                                                    line-height: 17px;
                                                }
                                                &::before {
                                                    content: "!";
                                                    display: block;
                                                    position: relative;
                                                    top: 3px;
                                                    width: 16px;
                                                    height: 16px;
                                                    min-width: 16px;
                                                    min-height: 16px;
                                                    margin-right: 8px;
                                                    font-size: 11px;
                                                    line-height: 14px;
                                                    text-align: center;
                                                    border: 1px solid #000;
                                                    border-radius: 50%;
                                                    color: #000;
                                                    @include mobile {
                                                        width: 12px;
                                                        height: 12px;
                                                        min-width: 12px;
                                                        min-height: 12px;
                                                        margin-right: 4px;
                                                        font-size: 9px;
                                                        line-height: 9px;
                                                    }
                                                }
                                            }
                                        }
                                        .guide-exp {
                                            padding-top: 4px;
                                            padding-left: 24px;
                                            font-size: 14px;
                                            line-height: 20px;
                                            color: #666;
                                            @include mobile {
                                                padding-left: 16px;
                                                font-size: 11px;
                                                line-height: 17px;
                                            }
                                        }
                                        .guide-exp-box {
                                            display: none;
                                            padding-top: 20px;
                                            margin-top: 20px;
                                            border-top: 1px solid #ddd;
                                            @include mobile {
                                                padding-top: 12px;
                                                margin-top: 12px;;
                                            }
                                            li {
                                                display: flex;
                                                padding-bottom: 8px;
                                                font-size: 14px;
                                                line-height: 20px;
                                                color: #666;
                                                &:last-child {
                                                    padding-top: 0;
                                                    padding-bottom: 0;
                                                }
                                                @include mobile {
                                                    font-size: 12px;
                                                    line-height: 17px;
                                                }
                                                &::before {
                                                    content: "";
                                                    display: block;
                                                    position: relative;
                                                    top: 8px;
                                                    width: 3px;
                                                    height: 3px;
                                                    margin-right: 4px;
                                                    background-color: #000;
                                                    border-radius: 50%;
                                                    color: #000;
                                                    @include mobile {
                                                        // top: 12px;
                                                        width: 2px;
                                                        height: 2px;
                                                        min-width: 2px;
                                                        min-height: 2px;
                                                    }
                                                }
                                            }
                                        }
                                        .down-arrow-btn {
                                            position: absolute;
                                            right: 24px;
                                            top: 24px;
                                            width: 16px;
                                            height: 16px;
                                            background: url(/lg5-common/images/icons/btn-down-16-black.svg) no-repeat 50% 50%;
                                            transition: transform 0.3s;
                                            @include mobile {
                                                right: 16px;
                                                top: 20px;
                                                width: 12px;
                                                height: 12px;
                                            }
                                            &.active {
                                                transform: rotate(180deg);
                                                transition: transform 0.3s;
                                            }
                                        }
                                    }
                                }
                                // BTOCSITE-30383 기본정보 탭 추가
                                &.basic_info {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: space-between;
                                    @include mobile {
                                        flex-direction: column;
                                    }
                                    .info_list {
                                        width: calc(100% - 200px);
                                        padding-top: 10px;
                                        margin-right: 20px;
                                        @include mobile {
                                            width: 100%;
                                            margin-right: 0;
                                            margin-bottom: 16px;
                                        }
                                        >ul {
                                            display: flex;
                                            flex-wrap: wrap;
                                            @include mobile {
                                                width: 100%;
                                            }
                                            >li {
                                                display: flex;
                                                align-items: flex-start;
                                                flex-wrap: wrap;
                                                margin-right: 16px;
                                                margin-bottom: 16px;
                                                @include mobile {
                                                    flex-wrap: nowrap;
                                                    width: 100%;
                                                    margin-right: 0;
                                                    margin-bottom: 8px;
                                                }
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                                .tit {
                                                    margin-right: 4px;
                                                    font-size: 14px;
                                                    line-height: 20px;
                                                    white-space: nowrap;
                                                    color: #666;
                                                    @include mobile {
                                                        width: 100px;
                                                        min-width: 100px;
                                                        margin-right: 0;
                                                        font-size: 13px;
                                                        line-height: 19px;
                                                    }
                                                }
                                                .txt {
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    span {
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        line-height: 20px;
                                                        color: #000;
                                                        @include mobile {
                                                            font-size: 13px;
                                                            line-height: 19px;
                                                        }
                                                        &:last-child {
                                                            margin-right: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .exp {
                                            font-size: 14px;
                                            line-height: 20px;
                                            color: #666;
                                            @include mobile {
                                                font-size: 13px;
                                                line-height: 19px;
                                            }
                                        }
                                    }
                                    .btn_area {
                                        margin-top: 0;
                                    }
                                }
                                // BTOCSITE-30383 구성상품 탭 추가
                                &.components {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: space-between;
                                    @include mobile {
                                        flex-direction: column;
                                    }
                                    .cmp_list {
                                        padding-top: 10px;
                                        margin-right: 20px;
                                        @include mobile {
                                            width: 100%;
                                            margin-right: 0;
                                            margin-bottom: 16px;
                                        }
                                        >ul {
                                            @include mobile {
                                                width: 100%;
                                            }
                                            >li {
                                                padding-left: 7px;
                                                margin-bottom: 16px;
                                                font-size: 14px;
                                                font-weight: 500;
                                                line-height: 20px;
                                                color: #000;
                                                position: relative;
                                                @include mobile {
                                                    width: 100%;
                                                    margin-bottom: 8px;
                                                    font-size: 13px;
                                                    line-height: 19px;
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                }
                                                &:last-child {
                                                    margin-bottom: 0;
                                                }
                                                &::after {
                                                    content: "";
                                                    position: absolute;
                                                    left: 0;
                                                    top: 50%;
                                                    transform: translateY(-50%);
                                                    width: 3px;
                                                    height: 3px;
                                                    background-color: #000;
                                                    border-radius: 50%;
                                                }
                                            }
                                        }
                                    }
                                    .btn_area {
                                        margin-top: 0;
                                    }
                                }
                            }
                            .btn_area {
                                margin-top: 8px;
                                text-align: right;
                                @include mobile {
                                    display: block;
                                    width: 100%;
                                    margin-top: 8px;
                                    .search-btn {
                                        width: 100%;
                                        height: 44px;
                                        border-radius: 8px;
                                        @include font(13px,44px);
                                    }
                                }
                            }
                        }
                    }
                    dl {
                        display: inline-block;
                        overflow: hidden;
                        font-size: 14px;
                        font-weight: 400;
                        color: #666;
                        line-height: 20px;
                        margin: 0 24px 0 0;
                        dt{
                            float: left;
                            @include mobile {
                                word-break: keep-all;
                            }
                        }
                        dd{
                            float: left;
                            margin-left: 8px;
                            font-weight: 500;
                            color: #000;
                        }
                        @include mobile {
                            display: block;
                            margin: 8px 0 0 0;
                            dt {
                                width: 104px;
                                @include font(13px,19px);
                            }
                            dd {
                                width: calc(100% - 112px);
                                @include font(13px,19px);
                            }
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                    .btn.search-btn {
                        padding: 0;
                    }
                }
                .cnt_mo_default {
                    position: relative;
                    border-top: 1px solid #f4f4f4;
                    @include pc {
                        display: none;
                    }
                    @include mobile {
                        display: block;
                    }
                    &.is_review {
                        .cnt_mo_txt.spec {
                            overflow: hidden;
                            white-space: nowrap;
                            span {
                                &:nth-child(3) {
                                    display: inline-block;
                                    padding-left: 8px;
                                    &:before {
                                        content: "";
                                    }
                                }
                            }
                        }
                        .cnt_mo_open {
                            &::after {
                                height: 29px;
                            }
                        }
                    }
                    .cnt_mo_txt {
                        display: block;
                        margin: 0 38px 0 19px;
                        max-height: 70px;
                        @include font(13px,32px);
                        overflow: hidden;
                        white-space: nowrap;
                        @include mobile {
                            &.benefit {
                                margin: 0;
                                > span {
                                    margin-right: 0;
                                }
                            }
                        }
                        &.spec {
                            span {
                                &:nth-child(3) {
                                    display: block;
                                    padding: 0;
                                    &:before {
                                        content: none;
                                    }
                                }
                            }
                        }
                        span {
                            position: relative;
                            display: inline-block;
                            margin-right: 8px;
                            color: #000;
                            vertical-align: top;
                            & + span {
                                padding-left: 8px;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: calc(50% - 4px);
                                    left: 0;
                                    width: 1px;
                                    height: 8px;
                                    background-color: #ddd;
                                }
                            }
                            em {
                                color: #666;
                                margin-right: 4px;
                            }
                        }

                        &.benefit {
                            em + span {
                                color: #ea1917;
                            }
                        }
                    }
                    .cnt_mo_open {
                        position: absolute;
                        top: 1px;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        font-size: 0;
                        color: transparent;
                        background: url("/lg5-common/images/icons/icon_category_open.svg") no-repeat right 14px top 3px/24px;
                        background-color: transparent;
                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 38px;
                            width: 15px;
                            height: 100%;
                            background:linear-gradient(to right, rgba(255,255,255,0), #fff);
                        }
                    }
                    .cnt_mo_review {
                        padding: 0 13px;
                        margin-bottom: 12px;
                        .review_score {
                            display: flex;
                            align-items: center;
                            padding: 12px 24px;
                            border-radius: 8px;
                            background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                            @include mobile {
                                padding: 10px 12px;
                            }
                            .score_star {
                                padding-left: 28px;
                                background: url(/lg5-common/images/icons/icon-star-on-18.svg) no-repeat left center/24px;
                                @include font(16px,24px);
                                font-weight: 700;
                                @include mobile {
                                    padding-left: 20px;
                                    background-size: 16px;
                                    @include font(13px,19px);
                                }
                            }
                            .score_class {
                                margin-left: 8px;
                                @include font(14px,20px);
                                color: #666;
                                @include mobile {
                                    margin-left: 2px;
                                    @include font(11px,19px);
                                }
                            }
                            .score_rate {
                                margin-left: 24px;
                                @include font(14px,20px);
                                @include mobile {
                                    margin-left: 4px;
                                    @include font(12px,19px);
                                }
                                em {
                                    margin-right: 2px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
            .item-type2 {
                .info-price {
                    display: block;
                }
            }

            .flag-wrap {
                margin-bottom: 2px;
                .flag {
                    display:inline;
                }
            }
            .result-tit {
                margin-bottom: 4px;
                font-weight: 700;
                @include font($font-large,26px);
                color: $color-default;
                @include mobile {
                    @include font(15px,23px);
                }
                > a:first-child,
                > strong {
                    display: block;
                    max-height: none;
                    overflow: initial;
                    text-overflow: initial;
                    // word-break: break-all;
                    @include mobile {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .add_tit {
                    margin-left: 8px;
                    @include font(16px,24px);
                    font-weight: 400;
                    @include mobile {
                        margin-left: 4px;
                        @include font(12px,17px);
                    }
                }

            }
            .cs {
                display: block;
                margin-top: 4px;
                font-weight: 400;
                @include font($font-medium,26px);
                color: $color-default;
                .cs-inner + .cs-inner {
                    margin-left: 4px;
                    &:before {
                        display: inline-block;
                        margin-right: 4px;
                        content: '>';
                    }
                }
                @include mobile {
                    margin-top: 2px;
                    @include font(13px,21px)
                }
            }
            .item-type2 {
                .result-tit {
                    max-width: 680px;
                    max-height: 60px;
                    @include font-medium;
                }
                .result-info {
                    .info-text {
                        width:100%;
                        padding-top: 0;
                        padding-right:0;
                    }
                    @include mobile {
                        .info-text {
                            min-height: 83px;
                        }
                    }
                }
            }
            .info-btm {
                margin-top: 12px;
                font-size: 0;
                @include mobile {
                    margin-top: 6px;
                }
                .care {
                    display: inline-block;
                    .text {
                        display: inline-block;
                        position: relative;
                        margin-right: 6px;
                        padding-right: 6px;
                        @include font($font-small,24px);
                        &:last-child {
                            margin-right: 6px;
                            padding-right: 6px;
                        }
                        &:not(:last-child):before {
                            display: inline-block;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            content: '';
                            width: 1px;
                            height: 12px;
                            transform: translate(-50%, -50%);
                            background: #ddd;
                        }
                        @include mobile {
                            display: inline-block;
                            margin-right: 0;
                            padding-right: 0;
                            @include font($font-x-small,20px);
                            &:before,
                            &:not(:last-child):before {
                                display: none;
                            }
                            &.date {
                                display: inline-block;
                                font-size: 13px;
                                line-height: 21px;
                            }
                            &.date + .hashtag-wrap {
                                display: inline-block;
                                margin-left: 4px;
                                padding-left: 4px;
                                &:before {
                                    display: inline-block;
                                }
                            }
                        }
                        &.model {
                            max-height: 24px;
                            @include textEllipsisClamp(1);
                            @include mobile {
                                max-height: 20px;
                            }
                        }
                    }

                    .care-n {
                        @include font($font-small,24px);
                        line-height:13px;
                        //position: absolute;
                        @include mobile {
                            display: inline-block;
                            margin-left: 0;
                            padding-left: 0;
                            @include font($font-x-small,20px);
                        }
                    }
                    .redcare-option {
                        margin-right:9px;
                        padding-right:9px;
                        @include font($font-small,24px);
                        line-height:13px;
                        color:$color-primary;
                        position:relative;
                        display:inline-block;
                        &:after{
                            display: inline-block;
                            position: absolute;
                            top: 50%;
                            right:0;
                            content: '';
                            width: 1px;
                            height: 12px;
                            transform: translate(-50%, -50%);
                            background: #ddd;
                        }
                        @include mobile {
                            display: inline-block;
                            @include font($font-x-small,20px);
                            margin-right: 3px;
                            padding-right: 3px;
                            &:after{
                                background:none;
                            }
                            &:before{
                                display:inline-block;
                                content: ',';
                                background:none;
                                margin-right: 8px;
                                color:#000;
                            }
                        }
                        // &:last-child {
                        //     margin-right: 0;
                        // }
                    }



                }
            }
            .hashtag-wrap {
                display: inline-block;
                .hashtag {
                    display: inline-block;
                    margin-right: 4px;
                    @include font-small;
                    @include mobile {
                        position: static;
                        height: auto;
                        margin-right: 6px;
                        padding: 0;
                        border-radius: 0;
                        border: 0;
                        font-size: 13px;
                        line-height: 13px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .sku {
                float: left;
                font-weight: 400;
                vertical-align: top;
                margin-right: 8px;
                font-size: 12px;
                line-height: 18px;
                color: #666;
                @include mobile {
                    font-size: 10px;
                    margin:0 4px 2px 0;
                }
                .search-word {
                    color:#000;
                }
            }

            .result-detail {
                overflow: hidden;
                .rentalModel {
                    display: inline-block;
                    font-weight: 500;
                    margin-right:6px;
                    @include font-x-small;
                    vertical-align: top;

                    @include mobile {
                        display:none;
                    }
                }
            }

            .hashtag-wrap + .careflag  {
                @include mobile {
                    display: block;
                    margin-left: 0 !important;
                    padding-left: 0 !important;
                    &:before {
                        display: none !important;

                    }
                }
            }

            .desc {
                margin-bottom: 6px;
                @include textEllipsisClamp(1);
                @include font($font-medium,24px);
                color: $color-default;
                @include mobile {
                    @include font(13px,21px);
                }
            }


            .review-info {
                position: relative;
                float: left;
                margin-right: 8px;
                padding-left:8px;
                font-size: 0;
                vertical-align: top;
                &::before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 1px;
                    height: 8px;
                    background-color: #ddd;
                    @include mobile {
                        top: 6px;
                    }
                }
                a {
                    overflow: hidden;
                    display:block;
                }
                @include mobile {
                    margin: 0 8px 2px 0;
                    padding-left: 5px;
                }
                .star,
                .average-rating,
                .review-count {
                    float: left;
                    font-size: 12px;
                    line-height: 18px;
                }
                .star {
                    width: 16px;
                    height: 18px;
                    margin-right: 4px;
                    background: url("/lg5-common/images/icons/icon-star-off-20.svg") no-repeat 0 1px/100%;
                    &.is-review {
                        background-image: url("/lg5-common/images/icons/icon-star-on-20.svg");
                    }
                    @include mobile {
                        width: 13px;
                        height: 15px;
                        margin-right: 4px;
                        background-position: 0 3px;
                    }
                }
                .review-count {
                    margin-left: 3px;
                }
            }
            .shop-lab {
                position: relative;
                float: left;
                margin: 0 8px 0 0;
                padding-left:8px;
                height: 18px;
                @include font(12px,18px);
                @include mobile {
                    margin-bottom: 2px;
                    @include font(10px,15px);
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 1px;
                    height: 8px;
                    background-color: #ddd;
                    @include mobile {
                        top: 6px;
                    }
                }
                // &.pickup {
                //     width: 42px;
                //     height: 18px;
                //     background: url("/lg5-common/images/icons/icon-shop-pickup.svg") no-repeat center/ 100%;
                // }
                // &.dpsale {
                //     width: 57px;
                //     height: 18px;
                //     background: url("/lg5-common/images/icons/icon-shop-dpsale.svg") no-repeat center/ 100%;
                // }
            }
            .shop-info {
                .desc {
                    display: block;
                    margin: 0;
                    @include textEllipsisClamp(1);
                    @include font-medium;
                    @include mobile {
                        &.time {
                            margin-top: 2px;
                        }
                    }
                    span + span {
                        margin-left: 8px;
                    }
                }
            }
            .shop-state {
                position: absolute;
                top: 24px;
                right: 32px;
                @include mobile {
                    top: 16px;
                    right: 20px;
                }
                span {
                    @include font-x-small;
                    &:before {
                        display: inline-block;
                        content: '';
                        width: 8px;
                        height: 8px;
                        margin-right: 8px;
                        border-radius: 4px;
                    }
                    &.skyblue {
                        &:before {
                            background: #21a9aa;
                        }
                    }
                    &.olive {
                        &:before {
                            background: #76892d;
                        }
                    }
                    &.red {
                        &:before {
                            background: #ff3723;
                        }
                    }
                }
            }

            .price-info {
                overflow: hidden;
                white-space: nowrap;
                text-align: right;
                .tit {
                    display:inline-block;
                    vertical-align: middle;
                    @include font(16px,26px);
                    color: #666666;
                    font-weight: $font-weight-bold;
                    padding-right:5px;
                    @include mobile {
                        font-size: 14px;
                        line-height: 22px;

                    }
                }
                .price {
                    display: inline-block;
                    vertical-align: middle;
                    @include font(16px,24px);
                    color: $color-default;
                    font-weight: $font-weight-bold;
                    @include mobile {
                        @include font(15px,21px);
                    }

                    @media screen and (max-width:320px) {
                        @include font(12px,22px);
                    }

                }
                .month {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: 8px;
                    @include font(12px,18px);
                    color: $color-gray-1;
                    font-weight: 400;
                }
                .option {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: 8px;
                    @include font(12px,18px);
                    font-weight: 400;
                    color: $color-gray-1;
                }
                .salerate {
                    float: left;
                    margin-right: 8px;
                    vertical-align: middle;
                    .rate {
                        @include font(16px,24px);
                        color:$color-primary;
                        @include mobile {
                            @include font(15px,21px);
                        }
                    }

                    @include mobile {
                        margin-right: 4px;
                    }
                }
                .price-in {
                    float: left;
                }
                .original {
                    float: left;
                    margin-right: 8px;
                    vertical-align: bottom;
                    .price {
                        font-weight: 400;
                        @include font(12px,24px);
                        color: $color-gray-1;
                        text-decoration: line-through;
                    }
                    @include mobile {
                        display: initial;
                        margin-right: 4px;
                        .price {
                            @include font(11px,21px);
                            color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                            height: 21px;
                        }
                    }

                    @media screen and (max-width:320px) {
                        .price {
                            @include font(10px,19px);
                        }
                    }

                }
            }
            .price-rental {
                overflow: hidden;
                margin-top: 2px;
                @include font(12px,18px);
                @include mobile {
                    font-size: 10px;
                    line-height: 15px;
                }
                span {
                    display: inline-block;
                    & + span {
                        position: relative;
                        margin-left: 8px;
                        padding-left: 9px;
                        &::before {
                            content: '';
                            position: absolute;
                            top: 5px;
                            left: 0;
                            width: 1px;
                            height: 8px;
                            background-color: #ddd;
                        }
                    }
                }
            }
            .point_area {
                display: block;
                margin-top: 2px;
                @include font(12px,18px);
                color: #008182;
                text-align: right;
                @include mobile {
                    margin-top: 4px;
                    @include font(11px, 16px);
                    text-align: left;
                }
            }
            .price-benefit {
                overflow: hidden;
                margin-top: 2px;
                font-size: 0;
                text-align: right;
                @include mobile {
                    margin-top: 4px;
                    text-align: left;
                    
                }
                .billsale {
                    display: inline-block;
                }
                .interest {
                    display: inline-block;
                    position: relative;
                    margin-left: 8px;
                    padding-left: 9px;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 1px;
                        height: 8px;
                        background-color: #ddd;
                        transform: translateY(-50%);
                    }
                }

                .billsale,
                .interest {
                    @include font(12px,18px);
                    color: $color-default;

                    @include mobile {
                        @include font(11px,16px);
                    }
                }
            }

        }

        &.product-list {
            .info-text {
                .result-tit {
                    max-width: 100%;
                    @include mobile {
                        max-width: 100%;
                    }
                }
            }
        }

        &.story-list {
            > li .item-type2 .result-tit {
                @include textEllipsisClamp(1);
            }
        }

        &.shop-list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -16px;
            @include mobile {
                margin-left: 0;
            }
            li {
                width: 50%;
                padding-left: 16px;
                @include mobile {
                    width: 100%;
                    padding-left: 0;
                }
                .item {
                    display: block;
                    padding: 24px 32px;
                    @include mobile {
                        padding: 16px 20px;
                    }
                }
                .result-info {
                    display: block;
                    //flex-direction: column;
                    //align-items: flex-start;
                    .info-text {
                        padding-right: 0;
                    }
                    @include mobile {
                        .info-text {
                            min-height: auto;
                            padding: 0;
                        }
                    }
                    @include pc {
                        .result-detail {
                            height: 52px;
                        }
                    }
                }
            }
            .btn-area {
                width: 100%;
                margin-top: 24px;
                text-align: right;
                .btn + .btn {
                    margin-left: 4px;
                }
                @include mobile {
                    margin-top: 12px;
                }
            }
        }

        &.support-list {
            .item {
                min-height: auto;
                padding: 24px 32px;
                @include mobile {
                    padding: 16px 20px;
                }
            }
            .info-btm {
                .text {
                    display: inline-block;
                    margin-right: 6px;
                    padding-right: 6px;
                    font-size: 14px;
                    line-height: 21px;
                }
                @include mobile {
                    margin-top: 6px;
                    font-size: 13px;
                }
            }
            .sku + .info-btm {
                .text {
                    margin-right:0;
                    padding-right:0;
                    &:not(:last-child):after {
                        content: ' \2758';
                        display: inline-block;
                        margin: 0 5px;
                    }
                }
                @include mobile {
                    margin-top: 12px;
                }
            }
            .result-thumb {
                margin: -8px 0;
                //position: static;
                & + .result-info {
                    padding: 4px 0 4px 32px;
                }
                @include mobile {
                    position: static;
                    margin: 0;
                    & + .result-info {
                        padding: 0 0 0 16px;
                    }
                }
            }
            .result-info {
                @include clearfix;
                @include mobile {
                    .info-text {
                        padding-left: 0;
                    }
                }
                .info-text {
                    flex: 1;
                    min-height: auto;
                    padding: 0;
                }
            }

            .result-tit {
                .search-content {
                    display: block;
                    > .desc {
                        margin-bottom: 6px;
                        @include textEllipsisClamp(2);
                        max-height: 46px;
                        @include font($font-medium,24px);
                        color: $color-default;
                        font-weight: $font-weight-regular;
                        white-space: normal;
                        @include mobile {
                            @include font(13px,21px);
                        }
                    }
                }
            }
            .btn-area {
                align-self: center;
                @include mobile {
                    width: 100%;
                    margin-top: 12px;
                    text-align: right;
                }
            }
            .video-info {
                align-self: center;
                display: inline-block;
                width: 48px;
                height: 48px;
                margin-left: 30px;
                background: url("/lg5-common/images/icons/btn-play-80.svg") no-repeat 50% 50%;
                background-size: 100% 100%;

                @include mobile {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    width: 17px;
                    height: 17px;
                    margin-left: 0;
                }
               span {
                    display: inline-block;
                    text-indent: -9999em;
                   @include mobile {

                   }
               }
            }
        }
        &.care-list {
            > li .result-info .info-text {
                display: block;
            }
        }
        // 회사소개 START
        &.company-list {
            .item {
                min-height: 142px!important;
                @include mobile {
                    min-height: auto!important;
                }
                .result-info {
                    .info-text {
                        @include mobile {
                            padding-left:0
                        }
                    }
                }
            }
        }
        // 회사소개 END
        &.gallery-wrap { // BTOCSITE-21785 갤러리 영역
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            .gallery-item {
                width: calc(33.333% - 24px);
                margin: 0 12px;
                margin-bottom: 24px;
                border-radius: 16px;
                box-shadow: 2px 4px 12px 0 rgba(0,0,0,0.14);
                overflow: hidden;
                @include tablet {
                    width: calc(50% - 24px);
                }
                @include mobile {
                    width: 100%;
                    margin-bottom: 12px;
                    border-radius: 8px;
                }
                .img-box {
                    display: block;
                    position: relative;
                    .thumb-btn {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .prd-btn {
                        position:absolute;
                        width: 34px;
                        height: 34px;
                        font-size: 0;
                        border-radius: 50%;
                        @include mobile {
                            width: 30px;
                            height: 30px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                            height: 24px;
                            background-color: rgba(255, 255, 255, 0.3);
                            border: 1px solid #fff;
                            border-radius: 50%;
                            @include mobile {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 6px;
                            height: 6px;
                            background-color: #fff;
                            border-radius: 50%;
                            @include mobile {
                                width: 5px;
                                height: 5px;
                            }
                        }
                        &.prd-btn-01 {
                            left: 15%;
                            top: 38%;
                        }
                        &.prd-btn-02 {
                            right: 3%;
                            top: 90%;
                        }
                        @media (hover: hover) {
                            &:hover {
                                &:after {
                                    background-color: #ea1917;
                                }
                            }
                        }
                        &.active {
                            &:after {
                                background-color: #ea1917;
                            }
                        }
                    }
                    .icon {
                        position:absolute;
                        right: 16px;
                        top: 16px;
                        width: 24px;
                        height: 24px;
                        font-size: 0;
                        background: url(/lg5-common/images/icons/ico_more.svg) no-repeat 50% 50% / cover;
                        @include mobile {
                            top: 8px;
                            right: 8px;
                        }

                        &.ico-video {
                            right: 50px;
                            width: 24px;
                            height: 24px;
                            background: url(/lg5-common/images/icons/ico_video.svg) no-repeat 50% 50% / cover;
                            @include mobile {
                                right: 40px;
                            }
                        }
                    }
                }
                >a {
                    display: block;
                    padding: 14px 20px;
                    @include tablet {
                        padding: 12px 20px;
                    }
                    @include mobile {
                        padding: 12px;
                    }
                    .prd-title {
                        margin-bottom: 8px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        @include mobile {
                            font-size: 15px;
                            line-height: 21px;
                        }
                    }
                    .prd-number {
                        display: block;
                        font-size: 14px;
                        line-height: 20px;
                        color: #666;
                        @include mobile {
                            font-size: 11px;
                            line-height: 16px;
                        }
                    }
                    .prd-price {
                        display: block;
                        height: 24px;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        @include mobile {
                            font-size: 15px;
                            line-height: 21px;
                        }
                    }
                }
                &.gallery-product {
                    .img-box {
                        height: 100%;
                    }
                    >a {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background-color: rgba(255,255,255,0.7);
                    }
                }
            }
        }
    }
}
// BTOCSITE-21785 갤러리 상세 팝업
.gallery-detail-wrap {
    .gallery-detail-popup { 
        display: none;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh;
        z-index: 9001;
        .gallery-detail {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 895px;
            height: 100%;
            max-height: 80%;
            padding-bottom: 30px;
            background-color: #fff;
            border-radius: 16px;
            overflow: hidden auto;
            @include mobile {
                max-height: 100%;
                border-radius: 0;
            }
            .gallery-head {
                height: 73px;
                min-height: 73px;
                line-height: 73px;
                text-align: center;
                border-bottom: 1px solid rgb(221, 221, 221);
                position: relative;
                @include mobile {
                    height: 56px;
                    min-height: 56px;
                    line-height: 56px;
                }
                h2 {
                    font-size: 20px;
                    @include mobile {
                        font-size: 16px;
                    }
                }
                .close-btn {
                    position: absolute;
                    right: 24px;
                    top: 20px;
                    width: 32px;
                    height: 32px;
                    @include mobile {
                        right: 16px;
                        top: 16px;
                        width: 24px;
                        height: 24px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .gallery-body {
                position: relative;
                h3 {
                    padding: 40px 0 20px;
                    font-size: 24px;
                    text-align: center;
                }
                .review {
                    display: flex;
                    background: rgba(244, 244, 244, 0.4);
                    @include mobile {
                        display: block;
                        padding: 50px 16px;
                    }
                    .upload-img {
                        display: block;
                        width: 50%;
                        margin-right: 25px;
                        margin-bottom: 10px;
                        @include mobile {
                            width: 100%;
                            margin-right: 0;
                        }
                        img {
                            width: 100%;
                        }
                    }
                    .user {
                        width: 50%;
                        padding-right: 32px;
                        @include mobile {
                            width: 100%;
                            padding-right: 0;
                        }
                        .user-name {
                            display: flex;
                            align-items: center;
                            padding: 17px 0 27px 0;
                            @include mobile {
                                position: absolute;
                                left: 16px;
                                top: 10px;
                                padding: 0;
                            }
                            .profile {
                                display: block;
                                width: 48px;
                                height: 48px;
                                margin-right: 12px;
                                border-radius: 50%;
                                @include mobile {
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 10px;
                                }
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .name {
                                font-size: 16px;
                                font-weight: 700;
                            }
                        }
                        .user-review {
                            margin-bottom: 16px;
                            font-size: 16px;
                            line-height: 26px;
                        }
                        .user-tag {
                            font-size: 14px;
                            line-height: 20px;
                            word-break: keep-all;
                            color: rgb(0, 55, 107);
                        }
                    }
                }
                .product {
                    width: 100%;
                    margin: 0px auto;
                    position: relative;
                    @include mobile {
                        padding: 0 8px;
                    }
                    .product-swiper {
                        max-width: 700px;
                        .swiper-slide {
                            padding-right: 16px;
                            padding-bottom: 16px;
                            @include mobile {
                                padding: 4px 8px 16px 8px;
                            }
                            .prd-wrap {
                                padding: 20px;
                                border-radius: 8px;
                                box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.14) ;
                                @include mobile {
                                    padding: 15px 12px;
                                }
                                .prd-area {
                                    margin-bottom: 10px;
                                    @include mobile {
                                        margin-bottom: 16px;
                                    }
                                    .prd-img {
                                        display: block;
                                        width: 120px;
                                        height: 120px;
                                        margin: 0 auto;
                                        @include mobile {
                                            width: 114px;
                                            height: 114px;
                                        }
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                    .prd-title {
                                        height: 55px;
                                        font-size: 16px;
                                        line-height: 26px;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        overflow: hidden;
                                        -webkit-box-orient: vertical;
                                        @include mobile {
                                            height: 45px;
                                            font-size: 14px;
                                            line-height: 22px;
                                        }
                                    }
                                    .prd-price {
                                        min-height: 57px;
                                        padding-top: 16px;
                                        @include mobile {
                                            min-height: 39px;
                                            padding-top: 4px;
                                        }
                                        .before-price {
                                            display: block;
                                            text-decoration-line: line-through;
                                            white-space: nowrap;
                                            color: rgb(167, 149, 149);
                                            @include mobile {
                                                font-size: 11px;
                                                line-height: 19px;
                                            }
                                        }
                                        .price {
                                            font-size: 20px;
                                            font-weight: 700;
                                            line-height: 28px;
                                            @include mobile {
                                                font-size: 13px;
                                                line-height: 1;
                                            }
                                        }
                                    }
                                }
                                .buy-area {
                                    display: flex;
                                    align-items: center;
                                    width: 90%;
                                    button {
                                        &:disabled {
                                            opacity: 0.5;
                                            cursor: initial;
                                        }
                                        &.cart-btn {
                                            width: 40px;
                                            height: 40px;
                                            margin-right: 10px;
                                            flex-grow: 0;
                                            @include mobile {
                                                width: 32px;
                                                height: 32px;
                                                margin-right: 8px;
                                            }
                                            img {
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                    .buy-now-btn {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-grow: 1;
                                        height: 40px;
                                        border: 1px solid rgba(48, 48, 48, 1);
                                        border-radius: 20px;
                                        @include mobile {
                                            font-size: 11px;
                                        }
                                        &.disabled {
                                            opacity: 0.5;
                                            cursor: initial;
                                            pointer-events: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .swiper-arrow-btn {
                        width: 40px;
                        height: 40px;
                        background: no-repeat 50% 50%;
                        border-radius: 50%;
                        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.14);
                        @include mobile {
                            display: none;
                        }
                        &::after {
                            display: none;
                        }
                        &.swiper-button-disabled {
                            opacity: 0;
                        }
                        &.swiper-button-next {
                            right: 18px;
                            background-image: url(/lg5-common/images/icons/btn-arr-40x40-rt.svg);
                        }
                        &.swiper-button-prev {
                            left: 18px;
                            background-image: url(/lg5-common/images/icons/btn-arr-40x40-lt.svg);
                        }
                    }
                }
            }
        }
        .black-bg {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
        }
    }
}
// BTOCSITE-21785 갤러리 이미지 뷰 팝업
.image-detail-wrap {
    .image-detail-popup {
        display: none;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) translateZ(1px);
        width: 100%;
        // height: 100%;
        height: 100vh;
        min-height: 100vh;
        background-color: #fff;
        z-index: 9001;
        .black-bg {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
        }
        .image-detail {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 60px);
            max-width: 1284px;
            height: calc(100% - 60px);
            background-color: #fff;
            border-radius: 8px;
            overflow: auto;
            @include mobile {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
            .image-detail-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 28px 0 14px;
                margin: 0 40px;
                border-bottom: 2px solid #222;
                @include mobile {
                    padding: 54px 20px 20px;
                    margin: 0;
                    border-bottom: none;
                }
                .tit {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 700;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: keep-all;
                    @include mobile {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .close-btn {
                    width: 24px;
                    height: 24px;
                    background: url(/lg5-common/images/icons/btn-close-32.svg) no-repeat 50% 50% / 24px;
                    @include mobile {
                        position: absolute;
                        right: 16px;
                        top: 18px;
                    }
                }
            }
            section.pop-conts {
                padding: 48px 40px 40px;
                @include mobile {
                    padding: 0;
                }
                .pop-visual-wrap {
                    position: relative;
                    .zoom-btn-area {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 1;
                        @include mobile {
                            top: 150px;
                            right: 16px;
                            transform: translateY(0);
                        }
                        .zoom-btn {
                            display: block;
                            width: 32px;
                            height: 32px;
                            margin: 18px 0;
                            background: no-repeat 50% 50%;
                            @include mobile {
                                width: 20px;
                                height: 20px;
                                margin: 8px 0;
                                background-size: 20px;
                            }
                            &.zoom-plus {
                                background-image: url(/lg5-common/images/KRP0008/icon-zoomin-normal.svg);
                                @include mobile {
                                    background-image: url(/lg5-common/images/KRP0008/icon-zoomin.svg);
                                }
                            }
                            &.zoom-minus {
                                background-image: url(/lg5-common/images/KRP0008/icon-zoomout-normal.svg);
                                @include mobile {
                                    background-image: url(/lg5-common/images/KRP0008/icon-zoomout.svg)
                                }
                            }
                        }
                    }
                    .default {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        max-width: 600px;
                        height: 600px;
                        max-height: 600px;
                        margin: 0 auto;
                        text-align: center;
                        border: 1px solid #fff;
                        @include mobile {
                            max-width: none;
                            height: auto;
                            max-height: none;
                        }
                        .item {
                            display: none;
                            width: 100%;
                            height: 100%;
                            &.view {
                                @include mobile {
                                    padding-bottom: 60px;
                                }
                                .item-box {
                                    align-items: flex-start;
                                }
                                .View360-holder {
                                    .View360-navigationHolder {
                                        position: absolute;
                                        left: 50%;
                                        bottom: 0;
                                        transform: translateX(-50%);
                                        width: 100%;
                                        height: 50px;
                                        @include mobile {
                                            bottom: -60px;
                                            height: 40px;
                                        }
                                        .View360-navigation {
                                            width: 100%;
                                            height: 100%;
                                            .View360-navigationBtn {
                                                display: inline-block;
                                                width: 40px;
                                                height: 40px;
                                                margin: 0 2.5px;
                                                background-repeat: no-repeat;
                                                background-size: 40px;
                                                cursor: pointer;
                                                @include mobile {
                                                    width: 32px;
                                                    height: 32px;
                                                    background-size: 32px;
                                                }
                                                &.View360-navigationPlay {
                                                    background-image: url(/lg5-common/images/icons/ic_360view_play_40.svg);
                                                }
                                                &.View360-navigationPause {
                                                    background-image: url(/lg5-common/images/icons/ic_360view_pause_40.svg);
                                                }
                                                &.View360-navigationOpen {
                                                    background-image: url(/lg5-common/images/icons/ic_360view_open_40.svg);
                                                }
                                                &.View360-navigationOpenExit {
                                                    background-image: url(/lg5-common/images/icons/ic_360view_close_40.svg);
                                                }
                                            }
                                        }
                                        
                                    }
                                }
                                .View360-text-info {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 80px;
                                    text-align: center;
                                    font-size: 13px;
                                    color: #888;
                                    @include mobile {
                                        bottom: 14px;
                                        font-size: 9px;
                                        line-height: 1.15;
                                    }
                                }
                            }
                            // &.video {
                            //     background-color: #e4e4e4;
                            //     .video-box {
                            //         width: 100%;
                            //         height: 100%;
                            //         video {
                            //             width: 100%;
                            //             background: 0 0!important;
                            //         }
                            //         .btn-box {
                            //             position: absolute;
                            //             top: 16px;
                            //             right: 16px;
                            //             .video-btn {
                            //                 width: 32px;
                            //                 height: 32px;
                            //                 margin-left: 12px;
                            //                 &.muted-btn {
                            //                     &.acc-btn {
                            //                         background: url('/lg5-common/images/KRP0008/icon-modal-video-sound.svg');
                            //                     }
                            //                     &.ani-btn {
                            //                         background: url('/lg5-common/images/KRP0008/icon-modal-video-muted.svg');
                            //                     }
                            //                 }
                            //                 &.play-btn {
                            //                     &.play {
                            //                         background-image: url('/lg5-common/images/KRP0008/icon-modal-video-play.svg');
                            //                     }
                            //                     &.pause {
                            //                         background-image: url('/lg5-common/images/KRP0008/icon-modal-video-pause.svg');
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //         .caption {
                            //             position: absolute;
                            //             left: 0;
                            //             bottom: 0;
                            //             width: 100%;
                            //             padding: 16px 24px;
                            //             text-align: left;
                            //             white-space: nowrap;
                            //             text-overflow: ellipsis;
                            //             overflow: hidden;
                            //             word-wrap: normal;
                            //             background-color: rgba(0,0,0,.5);
                            //             color: #fff;
                            //             @include mobile {
                            //                 bottom: 18px;
                            //                 padding: 0 16px;
                            //                 font-size: 12px;
                            //                 font-weight: 700;
                            //                 background-color: transparent;
                            //                 color: #000;
                            //             }
                            //         }
                            //     }
                            // }
                            // &.youtube {
                            //     background-color: #e4e4e4;
                            // }
                            &.video {
                                #modalVideoBox {
                                    overflow: hidden;
                                    .visual-area {
                                        .video-area {
                                            video {
                                                width: 100%;
                                            }
                                        }
                                        .vid-control-wrap {
                                            position: absolute;
                                            top: 16px;
                                            right: 16px;
                                            display: flex;
                                            a {
                                                width: 32px;
                                                height: 32px;
                                                font-size: 0;
                                                background-repeat: no-repeat;
                                                background-size: 100%;
                                                background-position: 50% 50%;
                                                &.btn-play-muted {
                                                    background-image: url('/lg5-common/images/KRP0008/icon-modal-video-sound.svg');
                                                }
                                                &.btn-play-sound {
                                                    background-image: url('/lg5-common/images/KRP0008/icon-modal-video-muted.svg');
                                                }
                                            }
                                            button {
                                                width: 32px;
                                                height: 32px;
                                                margin-left: 12px;
                                                font-size: 0;
                                                background-repeat: no-repeat;
                                                background-size: 100%;
                                                background-position: 50% 50%;
                                                &.btn-pause {
                                                    background-image: url('/lg5-common/images/KRP0008/icon-modal-video-pause.svg');
                                                }
                                                &.btn-play {
                                                    background-image: url('/lg5-common/images/KRP0008/icon-modal-video-play.svg');
                                                }
                                            }
                                        }
                                    }
                                    .subtitle {
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        z-index: 1;
                                        width: 100%;
                                        padding: 16px 24px;
                                        background-color: rgba(0,0,0,.5);
                                        text-align: left;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        word-wrap: normal;
                                        overflow: hidden;
                                        color: #fff;
                                        @include mobile {
                                            bottom: 18px;
                                            height: auto;
                                            padding: 0 16px;
                                            font-size: 12px;
                                            font-weight: 700;
                                            background-color: transparent;
                                            color: #000;
                                        }
                                    }
                                }
                                #modalYoutubeBox {
                                    background-color: #e4e4e4;
                                    .see-video {
                                        opacity: 0;
                                    }
                                }
                            }
                            .item-box {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                // display: table-cell;
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                vertical-align: middle;
                                // overflow: hidden;
                                position: relative;
                                .image-box {
                                    transform: scale(1);
                                    transition: transform 0.3s;
                                }
                            }
                        }
                    }
                    .thumbnail-nav {
                        width: 100%;
                        max-width: 760px;
                        margin: 0 auto;
                        margin-top: 20px;
                        padding: 10px 24px;
                        position: relative;
                        @include mobile {
                            padding: 0 24px;
                            margin-top: 32px;
                        }
                        .thumbnail {
                            width: 68px;
                            height: 68px;
                            border-radius: 10px;
                            border: 1px solid #e4e4e4;
                            overflow: hidden;
                            &.active {
                                border: 2px solid #000;
                            }
                            a {
                                display: block;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            &.video {
                                a {
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        transform: translate(-50%,-50%);
                                        width: 24px;
                                        height: 24px;
                                        background: url(/lg5-common/images/icons/btn-play-32-white_border.svg) no-repeat 50% 50%/24px 24px
                                    }
                                }
                            }
                            &.youtube {
                                a {
                                    height: 100%;
                                    background: url(/lg5-common/images/icons/icon-youtube-50.svg) no-repeat 50% 50%/39px 39px;
                                }
                            }
                        }
                        .image-thumb-btn {
                            top: 50%;
                            transform: translateY(-50%);
                            margin-top: 0;
                            width: 40px;
                            height: 40px;
                            background: no-repeat 50% 50%;
                            border-radius: 50%;
                            box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
                            @include mobile {
                                width: 28px;
                                height: 28px;
                                opacity: 0.8;
                                background-size: 100%;
                            }
                            &.swiper-button-next {
                                right: 0;
                                background-image: url(/lg5-common/images/icons/btn-arr-40x40-rt.svg);
                                @include mobile {
                                    right: 12px;
                                    background-image: url(/lg5-common/images/icons/btn-arr-40x40-rt-m.svg);
                                }
                            }
                            &.swiper-button-prev {
                                left: 0;
                                background-image: url(/lg5-common/images/icons/btn-arr-40x40-lt.svg);
                                @include mobile {
                                    left: 12px;
                                    background-image: url(/lg5-common/images/icons/btn-arr-40x40-lt-m.svg);
                                }
                            }
                            &::after {
                                display: none;
                            }
                            &.swiper-button-disabled {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// BTOCSITE-21785 장바구니 담기 메시지 z-index 수정
.toast-message {
    z-index: 9002;
}

// BTOCSITE-48682 다품목할인 뱃지 텍스트 컬러 수정
.text_badge {
    color: #da0f47;
}
