.chk-wrap-colorchip {
	// & input + label:before {
	// 	background-image:url('/lg5-common/images/colorchip.jpg');
	// 	background-size:400px;
	// }
	/* BTOCSITE-10823 시블링 컬러칩 형태 변경 요청 */
	input{
		cursor:pointer;
		&:before{
			content:'';
			position:absolute;
			z-index:1;
			top:0;
			left:0;
			width:40px;
			height:40px;
			background:url('/lg5-common/images/colorchip/colorchip_cover.png');
			background-size:100% 100%;
			@include mobile{
				padding-left:0;
				width:38px;
				height:38px;
			}
		}
	}
	/* //BTOCSITE-10823 시블링 컬러칩 형태 변경 요청 */
	& input + label:before {
		// background-image:url('/lg5-common/images/colorchip/colorchip_230404.png'); // BTOCSITE-35807 계열별 색상 2종 추가
		// background-image:url('/lg5-common/images/colorchip/colorchip_230403.png'); // BTOCSITE-35587 [시블링 생성 요청] 슈케이스, 슈케어 4개 색상
		// background-image:url('/lg5-common/images/colorchip/colorchip_230330.png'); // BTOCSITE-34937 시블링 컬러칩 추가 요청
		// background-image:url('/lg5-common/images/colorchip/colorchip_230316.png'); //BTOCSITE-33354 시블링 컬러칩 추가 요청
		// background-image:url('/lg5-common/images/colorchip/colorchip_230316.png'); //BTOCSITE-33354 시블링 컬러칩 추가 요청
		// background-image:url('/lg5-common/images/colorchip/colorchip_230119.png'); //BTOCSITE-30359 시블링 컬러칩 추가 요청
		// background-image:url('/lg5-common/images/colorchip/colorchip_221214.png'); //BTOCSITE-27725 시블링 컬러칩 추가 요청
		// background-image:url('/lg5-common/images/colorchip/colorchip_220817.png'); //BTOCSITE-20340 시블링 컬러칩 추가 요청
		//background-image:url('/lg5-common/images/colorchip/colorchip_220727.png'); //BTOCSITE-19435 [2.0 오픈] 시블링 컬러칩 2개 추가 건
		//background-image:url('/lg5-common/images/colorchip/colorchip_220203.png'); //BTOCSITE-11771 오브제컬렉션 체험 신규 컬러 추가 요청
		//background-image:url('/lg5-common/images/colorchip/colorchip_210708.png');
		// background-image:url('/kr/images/color/colorchip_210521.png');
		// background-size:144px auto;
		width:40px;
		height:40px;
		border-radius: 9px;
		@include mobile {
			width:37px;
			height:37px;
			border-radius:6px;
		}
	}
	&.large{
		& input + label:before {
			background-size:800px;
		}
	}
	&.Platinum,
	&.smooth-white {
		& input + label:before {
			background:#fff; color:#000000 !important;
		}
	}
	/* BTOCSITE-644 시블링코드 마스터 색상 추가 */
	&.mattblack_glossy-white {
		& input + label:before {
			background:#fff; color:#000000 !important;
		}
	}
	/* //BTOCSITE-644 시블링코드 마스터 색상 추가 */

	&.wild-cherry-redd,
	&.wild-cherry-red,
	&.blackcherry,
	&.cherry,
	&.Burgundy-Red{
		& input + label:before {
			background:#a41a2d; color:#ffffff !important;
		}
	}
	&.smooth-black,
	&.black,
	&.black-stainless-steel,
	&.BlackLeather	{
		& input + label:before {
			background-position:0 0; color: #000000 !important;
		}
	}
	&.red,
	&.Red{
		& input + label:before {
			background:#890002; color:#ffffff !important;
		}
	}
	&.Metallic-Red {
		& input + label:before {
			background:#9B1516; color:#ffffff !important;
		}
	}
	&.Metallic-Blue {
		& input + label:before {
			background:#2D456B; color:#ffffff !important;
		}
	}
	&.Matte-Blue {
		& input + label:before {
			background:#414E71; color:#ffffff !important;
		}
	}
	&.bahama-blue{
		& input + label:before {
			background:#98c8ce; color:#000000 !important;
		}
	}
	&.BlueSteel{
		& input + label:before {
			background-position:-20px 0; color: #000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-40px 0;
			}
		}
	}
	&.midnight-blue{
		& input + label:before {
			background:#334d71; color: #ffffff !important;
		}
	}
	&.pure-silver{
		& input + label:before {
			background:#cecdd3; color:#000000 !important; border-color:#6e6e6e !important;
		}
	}
	&.Platinum-Ice {
		& input + label:before {
			background:#A8B1BA; color:#000000 !important;
		}
	}
	&.Print-Proof-Stainless-Steel,
	&.stainless-steel,
	&.titanium,
	&.MetallicGray{
		& input + label:before {
			background-position:-40px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-80px 0;
			}
		}
	}
	&.silver{
		& input + label:before {
			background:#c4c4c4; color:#000000 !important;
		}
	}
	&.bluemint{
		& input + label:before {
			background:#4f5386; color:#ffffff !important;
		}
	}
	&.pink,
	&.strawberry{
		& input + label:before {
			background:#e6b2c9; color:#000000 !important;
		}
	}
	&.purple {
		& input + label:before {
			background:#948dc1; color:#000000 !important;
		}
	}
	&.blueice{
		& input + label:before {
			background:#91bed0; color:#000000 !important;
		}
	}
	&.turquoise{
		& input + label:before {
			background:#70b5c9; color:#000000 !important;
		}
	}
	&.Gold,
	&.Shine-Gold,
	&.ShinyGold{
		& input + label:before {
			background:#c6b3ad; color:#000000 !important;
		}
	}
	&.Espresso {
		& input + label:before {
			background:#36251D; color:#ffffff !important;
		}
	}
	&.Astro-Black {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}
	&.Metallic-Black{
		& input + label:before {
			background:#717173; color:#ffffff !important;
		}
	}
	&.DarkGraphite {
		& input + label:before {
			background:#777673; color:#ffffff !important;
		}
	}
	&.Silver,
	&.platinum-silver{
		& input + label:before {
			background:#4d4e50; color:#ffffff !important;
		}
	}
	&.Blazing-Red,
	&.VolcanoRed{
		& input + label:before {
			background:#a21623; color:#ffffff !important;
		}
	}
	&.chocolate-brown {
		& input + label:before {
			background:#221411; color:#ffffff !important;
		}
	}
	&.BrownLeather{
		& input + label:before {
			background:#ac5a2a; color:#ffffff !important;
		}
	}
	&.Burgundy{
		& input + label:before {
			background:#714056; color:#ffffff !important;
		}
	}
	&.shine-brushed-steel{
		& input + label:before {
			background-position:-60px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-120px 0;
			}
		}
	}
	&.graphite-steel{
		& input + label:before {
			background-position:-80px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-160px 0;
			}
		}
	}
	&.chardonnay{
		& input + label:before {
			background:#b39f7a; color:#000000 !important;
		}
	}
	&.blue{
		& input + label:before {
			background:#102948; color:#fff !important; // blue 색상 변경
		}
	}
	&.navy{
		& input + label:before {
			background:#132d52; color:#ffffff !important;
		}
	}
	&.emerald-green{
		& input + label:before {
			background:#1a8b6a; color:#000 !important;border-color:#2e2e2e !important;
		}
	}
	&.green{
		& input + label:before {
			background:#026429; color:#ffffff !important;
		}
	}
	&.pearl-gray{
		& input + label:before {
			background:#cccccc; color:#000000 !important;
		}
	}
	&.gray{
		& input + label:before {
			background:#b5b5b5; color:#000000 !important;
		}
	}
	&.stainless{
		& input + label:before {
			background-position:-100px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-200px 0;
			}
		}
	}
	&.lavender{
		& input + label:before {
			background:#8e64a8; color:#ffffff !important;
		}
	}
	&.limegreen{
		& input + label:before {
			background:#79b43e; color:#000000 !important;
		}
	}
	&.maroon{
		& input + label:before {
			background:#5b1024; color:#ffffff !important;
		}
	}
	&.plum{
		& input + label:before {
			background:#601b51; color:#ffffff !important;
		}
	}
	&.citrus{
		& input + label:before {
			background:#dc7043; color:#000000 !important;
		}
	}
	&.orange{
		& input + label:before {
			background:#f59241; color:#000000 !important;
		}
	}
	&.slate{
		& input + label:before {
			background-position:-120px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-240px 0;
			}
		}
	}
	&.mint{
		& input + label:before {
			background:#96d0b8; color:#000000 !important;
		}
	}
	&.Moon-Violet{
		& input + label:before {
			background:#895685; color:#ffffff !important;
		}
	}
	&.riviera-blue{
		& input + label:before {
			background:#399dda; color:#000000 !important;
		}
	}
	&.stainless-aluminum{
		& input + label:before {
			background-position:-140px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-280px 0;
			}
		}
	}
	&.white-black{
		& input + label:before {
			background-position:-160px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-320px 0;
			}
		}
	}
	&.fvdfbdfb,
	&.stainless-vcm{
		& input + label:before {
			background-position:-180px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-360px 0;
			}
		}
	}
	&.Black-Stainless-Steel{
		& input + label:before {
			background-position:-200px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-400px 0;
			}
		}
	}
	&.LeatherYellow{
		& input + label:before {
			background:#f1bf0a; color:#000000 !important;
		}
	}
	&.LeatherSkyBlue{
		& input + label:before {
			background:#94a8ae; color:#000000 !important;
		}
	}
	&.Aqua.Blue	{
		& input + label:before {
			background:#4c92c6; color:#000000 !important;
		}
	}
	&.Grey{
		& input + label:before {
			background:#65656b; color:#ffffff !important;
		}
	}
	&.PlatinumSilver{
		& input + label:before {
			background:#828282; color:#000000 !important;
		}
	}
	&.DeepBlue{
		& input + label:before {
			background:#1e3851; color:#ffffff !important;
		}
	}
	&.Violet{
		& input + label:before {
			background:#76678e; color:#ffffff !important;
		}
	}
	&.Chrome{
		& input + label:before {
			background-position:-220px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-440px 0;
			}
		}
	}
	&.Space_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.Luxe_White{
		& input + label:before {
			background:#f9f9f9; color:#000000 !important;
		}
	}
	&.Opal_Blue{
		& input + label:before {
			background:#abc5c3; color:#000000 !important;
		}
	}
	&.Carbon{
		& input + label:before {
			background:#3e3e40; color:#ffffff !important;
		}
	}
	&.Quartz{
		& input + label:before {
			background:#d9d9f3; color:#000000 !important;
		}
	}
	&.Ice{
		& input + label:before {
			background:#e1ffff; color:#000000 !important;
		}
	}
	&.PowderBlue{
		& input + label:before {
			background:#8aaed9; color:#000000 !important;
		}
	}
	&.Wood{
		& input + label:before {
			background-position:-240px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-480px 0;
			}
		}
	}
	&.Strap{
		& input + label:before {
			background-position:-260px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-520px 0;
			}
		}
	}
	&.titan{
		& input + label:before {
			background:#474745; color:#ffffff !important;
		}
	}
	&.Rose-Gold{
		& input + label:before {
			background:#daada8; color:#000000 !important;
		}
	}
	&.Metallic-Gold{
		& input + label:before {
			background:#a58c78; color:#000000 !important;
		}
	}
	&.Blue-Black{
		& input + label:before {
			background:#23233b; color:#ffffff !important;
		}
	}
	&.Black-Gray{
		& input + label:before {
			background:#1d1d1d; color:#ffffff !important;
		}
	}
	&.Dark-Gray-Blue{
		& input + label:before {
			background:#5a5b5f; color:#ffffff !important;
		}
	}

	&.MC_G6_Astro_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_G6_Ice_Platinum{
		& input + label:before {
			background:#7f8897; color:#000000 !important;
		}
	}
	&.MC_G6_Mystic_White{
		& input + label:before {
			background:#f9f9f9; color:#000000 !important;
		}
	}
	&.MC_G6_Marine_Blue{
		& input + label:before {
			background:#7187b6; color:#000000 !important;
		}
	}
	&.MC_G6_Terra_Gold{
		& input + label:before {
			background:#b5948b; color:#000000 !important;
		}
	}
	&.MC_G6_Plus_Marine_Blue{
		& input + label:before {
			background:#7187b6; color:#000000 !important;
		}
	}
	&.MC_G6_Plus_Optical_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_G6_Plus_Terra_Gold{
		& input + label:before {
			background:#b4938a; color:#000000 !important;
		}
	}
	&.MC_Q6_Alpha_Astro_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_Q6_Alpha_Ice_Platinum{
		& input + label:before {
			background:#85a1af; color:#000000 !important;
		}
	}
	&.MC_Q6_Alpha_Terra_Gold{
		& input + label:before {
			background:#c19a6f; color:#000000 !important;
		}
	}
	&.MC_Q6_Astro_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_Q6_Ice_Platinum{
		& input + label:before {
			background:#84a1af; color:#000000 !important;
		}
	}
	&.MC_Q6_Mystic_White{
		& input + label:before {
			background:#f9f9f9; color:#000000 !important;
		}
	}
	&.MC_Q6_Terra_Gold{
		& input + label:before {
			background:#bf9974; color:#000000 !important;
		}
	}
	&.MC_Q6_Plus_Astro_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}

	&.MC_Q6_Plus_Ice_Platinum{
		& input + label:before {
			background:#84a1af; color:#000000 !important;
		}
	}
	&.MC_Q6_Plus_Marine_Blue{
		& input + label:before {
			background:#7187b6; color:#000000 !important;
		}
	}
	&.MC_V20_Pink{
		& input + label:before {
			background:#e2b8ba; color:#000000 !important;
		}
	}
	&.MC_V20_Silver{
		& input + label:before {
			background:#b9b9bb; color:#000000 !important;
		}
	}
	&.MC_V20_Titan{
		& input + label:before {
			background:#4d4d4d; color:#ffffff !important;
		}
	}
	&.MC_V30_Aurora_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_V30_Cloud_Silver{
		& input + label:before {
			background:#aeaeae; color:#000000 !important;
		}
	}
	&.MC_G6_LavenderViolet,
	&.MC_Q6_LavenderViolet,
	&.MC_V30_Lavender_Violet{
		& input + label:before {
			background:#afa3b9; color:#000000 !important;
		}
	}
	&.MC_G6_MorocanBlue,
	&.MC_Q6_MoroccanBlue,
	&.MC_V30_Moroccan_Blue{
		& input + label:before {
			background:#1f3b53; color:#ffffff !important;
		}
	}
	&.MC_X_Power2_Qualcomm_Black_Titan{
		& input + label:before {
			background:#000002; color:#ffffff !important;
		}
	}
	&.MC_G8XThinQ_AuroraBlack,
	&.MC_DualScreen_AuroraBlack {
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_K40S_NewAuroraBlack,
	&.MC_K50S_NewAuroraBlack{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_K40S_NewMoroccanBlue,
	&.MC_K50S_NewMoroccanBlue{
		& input + label:before {
			background:#01456a; color:#ffffff !important;
		}
	}
	&.MC_X_Power2_Shiny_Blue{
		& input + label:before {
			background:#262932; color:#ffffff !important;
		}
	}
	&.MC_X_Power2_Shiny_Gold{
		& input + label:before {
			background:#b1997f; color:#000000 !important;
		}
	}
	&.MC_X_Power2_Shiny_Titan{
		& input + label:before {
			background:#8f8f8f; color:#000000 !important;
		}
	}
	&.MC_X_Venture_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.HA_CookingAppliance_Matte_Black_Stainless_Steel,
	&.HA_DW_Matte_Black_Stainless_Steel,
	&.HA_MWO_Matte_Black_Stainless_Steel,
	&.HA_Oven_Matte_Black_Stainless_Steel,
	&.HA_REF_Matte_Black_Stainless_Steel{
		& input + label:before {
			background-position:-280px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-560px 0;
			}
		}
	}
	&.MC_X_Cam_Pink_Gold{
		& input + label:before {
			background:#cfb3af; color:#000000 !important;
		}
	}
	&.MC_Watch_Sport_Titanium_Silver{
		& input + label:before {
			background:#716e75; color:#ffffff !important;
		}
	}
	&.MC_G6_RaspberryRose,
	&.MC_V30_Raspberry_Rose{
		& input + label:before {
			background:#a5405e; color:#ffffff !important;
		}
	}
	&.HA_REF_SIGNATURE_Textured_Steel{
		& input + label:before {
			background-position:-300px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-600px 0;
			}
		}
	}
	&.MC_K8_MoroccanBlue{
		& input + label:before {
			background:#04344a; color:#ffffff !important;
		}
	}
	&.MC_Fortune2_TitanBlack{
		& input + label:before {
			background:#2d2d2d; color:#ffffff !important;
		}
	}
	&.MC_G7_NewPlatinumGray{
		& input + label:before {
			background:#9397a0; color:#000000 !important;
		}
	}
	&.MC_G7_NewMoroccanBlue{
		& input + label:before {
			background:#004b6b; color:#ffffff !important;
		}
	}
	&.MC_G7_NewAuroraBlack{
		& input + label:before {
			background:#141c31; color:#ffffff !important;
		}
	}
	&.MC_G7_RaspberryRose{
		& input + label:before {
			background:#c33e69;	color:#ffffff !important;
		}
	}
	&.MC_V30S_ThinQ_NewMoroccanBlue{
		& input + label:before {
			background:#0c5f7f; color:#ffffff !important;
		}
	}
	&.MC_V30S_ThinQ_NewPlatinumGray{
		& input + label:before {
			background:#8b8e93; color:#000000 !important;
		}
	}
	&.MC_Q7_Plus_AuroraBlack{
		& input + label:before {
			background:#131b30; color:#ffffff !important;
		}
	}
	&.MC_Q7_Plus_MoroccanBlue_Hair{
		& input + label:before {
			background:#004768; color:#ffffff !important;
		}
	}
	&.MC_Q7_Plus_MoroccanBlue_Wave{
		& input + label:before {
			background:#014a6b; color:#ffffff !important;
		}
	}
	&.MC_Q7_Plus_LavenderViolet{
		& input + label:before {
			background:#958bad; color:#000000 !important;
		}
	}
	&.MC_Q7_Prime_AuroraBlack{
		& input + label:before {
			background:#151d32; color:#ffffff !important;
		}
	}
	&.MC_Q7_Prime_MoroccanBlue{
		& input + label:before {
			background:#014a6a; color:#ffffff !important;
		}
	}
	&.MC_Q7_Prime_LavenderViolet{
		& input + label:before {
			background:#968bad; color:#000000 !important;
		}
	}
	&.MC_Q7_Alpha_MoroccanBlue{
		& input + label:before {
			background:#014a6a; color:#ffffff !important;
		}
	}
	&.MC_K9_Moroccan_Blue{
		& input + label:before {
			background:#5f83a5; color:#000000 !important; border-color:#c8c8c8 !important;
		}
	}
	&.MC_K9_Terra_Gold{
		& input + label:before {
			background:#C7AB83; color:#000000 !important;
		}
	}
	&.MC_K9_Aurora_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_K11_Moroccan_Blue{
		& input + label:before {
			background:#014A6B; color:#ffffff !important;
		}
	}
	&.MC_K11_Terra_Gold{
		& input + label:before {
			background:#d5baa9; color:#000000 !important;
		}
	}
	&.MC_K11_Aurora_Black{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_Qstylus_AuroraBlack{
		& input + label:before {
			background:#000; color:#ffffff !important;
		}
	}
	&.MC_Qstylus_MoroccanBlue{
		& input + label:before {
			background:#014a6b; color:#ffffff !important;
		}
	}
	&.MC_XPower3_MoroccanBlue{
		& input + label:before {
			background:#014a6b; color:#ffffff !important;
		}
	}
	&.HA_REF_Beige{
		& input + label:before {
			background:#e5dfcf; color:#000000 !important;
		}
	}
	&.HA_REF_Beige_Dancing_Flower_Wine_Red{
		& input + label:before {
			background:#7c262f; color:#ffffff !important;
		}
	}
	&.HA_REF_Beige_Dancing_Flower_Silver{
		& input + label:before {
			background:#b1b1b9; color:#000000 !important;
		}
	}
	&.HA_REF_Black_Mirror{
		& input + label:before {
			background:#2b292a; color:#ffffff !important;
		}
	}
	&.HA_REF_Skin_Beige{
		& input + label:before {
			background:#e0dac4; color:#000000 !important;
		}
	}
	&.HA_VCC_Matte_Red{
		& input + label:before {
			background-position:-320px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-640px 0;
			}
		}
	}
	&.HA_VCC_Matte_Silver{
		& input + label:before {
			background-position:-340px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-680px 0;
			}
		}
	}
	&.MC_G7_Fit_Aurora_Black{
		& input + label:before {
			background:#141c31; color:#ffffff !important;
		}
	}
	&.MC_G7_Fit_Platinum_Gray{
		& input + label:before {
			background:#9397a0; color:#000000 !important;
		}
	}
	&.Black_Red {
		& input + label:before {
			background-position:-360px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-720px 0;
			}
		}
	}
	&.MC_G5_Speed_Gold {
		& input + label:before {
			background:#c7b293; color:#000000 !important;
		}
	}
	&.MC_G5_Speed_Pink {
		& input + label:before {
			background:#e6b0c7; color:#000000 !important;
		}
	}
	&.MC_G5_Speed_Silver {
		& input + label:before {
			background-position:-380px 0; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-760px 0;
			}
		}
	}
	&.MC_K10_2017_Shiny_Black {
		& input + label:before {
			background:#000000; color:#fff !important;border-color:#0f0f0f !important;
		}
	}
	&.MC_K10_2017_Shiny_Gold {
		& input + label:before {
			background-position:0 -20px;
		}
		&+.large{
			& input + label:before {
				background-position:0 -40px;
			}
		}
	}
	&.MC_K10_2017_Shiny_Titan {
		& input + label:before {
			background-position:-20px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-40px -40px;
			}
		}
	}
	&.MC_K4_2017_Brown {
		& input + label:before {
			background:#c6b192; color:#000000 !important;
		}
	}
	&.MC_K4_2017_Titan {
		& input + label:before {
			background-position:-40px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-80px -40px;
			}
		}
	}
	&.MC_K8_2017_Dark_Blue {
		& input + label:before {
			background:#262932; color:#ffffff !important;
		}
	}
	&.MC_K8_2017_Gold {
		& input + label:before {
			background-position:-60px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-120px -40px;
			}
		}
	}
	&.MC_Stylus2_Black {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}
	&.MC_Stylus2_Brown {
		& input + label:before {
			background-position:-80px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-160px -40px;
			}
		}
	}
	&.MC_Stylus2_Gold {
		& input + label:before {
			background-position:-100px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-200px -40px;
			}
		}
	}
	&.MC_Stylus3_Gold {
		& input + label:before {
			background:#c8b396; color:#000000 !important;
		}
	}
	&.MC_Stylus3_Rose_Gold {
		& input + label:before {
			background-position:-120px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-240px -40px;
			}
		}
	}
	&.MC_Stylus3_Titan {
		& input + label:before {
			background-position:-140px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-280px -40px;
			}
		}
	}
	&.MC_Watch_Sport_Dark_Blue {
		& input + label:before {
			background:#262932; color:#ffffff !important;
		}
	}
	&.MC_Watch_Style_Rose_Gold {
		& input + label:before {
			background-position:-160px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-320px -40px;
			}
		}
	}
	&.MC_Watch_Style_Silver {
		& input + label:before {
			background-position:-180px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-360px -40px;
			}
		}
	}
	&.MC_Watch_Style_Titanium {
		& input + label:before {
			background-position:-200px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-400px -40px;
			}
		}
	}
	&.MC_X_Mach_Titan {
		& input + label:before {
			background-position:-220px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-440px -40px;
			}
		}
	}
	&.MC_X_Mach_White {
		& input + label:before {
			background:#ffffff; color:#000000 !important;
		}
	}
	&.MC_X_Power_Gold {
		& input + label:before {
			background:#c4af90; color:#000000 !important;
		}
	}
	&.MC_X_Power_Indigo {
		& input + label:before {
			background:#262932; color:#fff !important;border-color:#a6a6a6 !important;
		}
	}
	&.MC_X_Power_White {
		& input + label:before {
			background:#ffffff; color:#000000 !important;
		}
	}
	&.MC_X_Style_Gold {
		& input + label:before {
			background:#c6b192; color:#000000 !important;
		}
	}
	&.MC_X_Style_Titan {
		& input + label:before {
			background-position:-240px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-480px -40px;
			}
		}
	}
	&.MC_X_Style_White {
		& input + label:before {
			background:#ffffff; color:#000000 !important;
		}
	}
	&.MC_K40_NewAuroraBlack {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}
	&.HA_VCC_HOMBOT_Ocean_Black {
		& input + label:before {
			background:#0e0e10; color:#ffffff !important;
		}
	}
	&.MC_V40_ThinQ_NewPlatinumGray {
		& input + label:before {
			background:#676765; color:#ffffff !important;
		}
	}
	&.HA_VCC_HOMBOT_Ruby_Red {
		& input + label:before {
			background-position:-260px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-520px -40px;
			}
		}
	}
	&.MC_V40_ThinQ_CarmineRed {
		& input + label:before {
			background:#7e193b; color:#ffffff !important;
		}
	}
	&.MC_V40_ThinQ_NewMoroccanBlue {
		& input + label:before {
			background:#01456a; color:#ffffff !important;
		}
	}
	&.MC_K40_NewPlatinumGray {
		& input + label:before {
			background:#666664; color:#ffffff !important;
		}
	}
	&.MC_K40_NewMoroccanBlue {
		& input + label:before {
			background:#01456a; color:#ffffff !important;
		}
	}
	&.HA_VCC_Matte_Grey {
		& input + label:before {
			background-position:-300px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-600px -40px;
			}
		}
	}
	&.HA_Styler_Mirror {
		& input + label:before {
			background-position:-320px -20px; color:#000000 !important;
		}
		&+.large{
			& input + label:before {
				background-position:-640px -40px;
			}
		}
	}
	&.MC_Q60_NewMoroccanBlue {
		& input + label:before {
			background:#004469; color:#ffffff !important;
		}
	}
	&.MC_V50ThinQ_AstroBlack,
	&.MC_DualScreen_NewAuroraBlack,
	&.MC_Q60_NewAuroraBlack {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}

	&.white,
	&.Orange,
	&.Black,
	&.Green,
	&.titan-silver,
	&.White-Orange,
	&.White-Lime,
	&.Black-Red,
	&.Ruby_Red,
	&.Ocean_Black,
	&.brown,
	//&.gold,
	&.Graphite_Gray,
	&.Espresso-Dark-Brown,
	&.Dark-Silver,
	&.Matte_Black,
	&.RoseGold,
	&.textured-steel,
	&.Silk-White,
	&.CeramicWhite,
	&.PowderBlueTest {
		& input + label:before {
			background:#f9f9f9; color:#000000 !important;
		}
	}

	&.MC_Stylo5_SilveryWhite {
		& input + label:before {
			background:#d0d2d1; color:#000000 !important;
		}
	}
	&.MC_Stylo5_BlondeRose {
		& input + label:before {
			background:#dea6a5; color:#000000 !important;
		}
	}
	&.MC_Stylo5_NewPlatinumGray {
		& input + label:before {
			background:#9397a0; color:#000000 !important;
		}
	}

	&.MC_Stylo6_HolographicBlue {
		& input + label:before {
			background:#63B0D6; color:#000000 !important;
		}
	}
	&.MC_Stylo6_TitanGray {
		& input + label:before {
			background:#575757; color:#ffffff !important;
		}
	}

	&.MC_K30_AuroraBlack {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}
	&.MC_K30_MoroccanBlue {
		& input + label:before {
			background:#01456A; color:#ffffff !important;border-color:#c8c8c8 !important;
		}
	}
	&.MC-K41S-Black {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}
	&.MC-K41S-Titan {
		& input + label:before {
			background:#474c50; color:#ffffff !important;
		}
	}
	&.MC-K51S-Pink {
		& input + label:before {
			background:#703a47; color:#ffffff !important;
		}
	}
	&.MC-K51S-Titan {
		& input + label:before {
			background:#474c50; color:#ffffff !important;
		}
	}
	&.MC-K61-White {
		& input + label:before {
			background:#f4f5f7; color:#000000 !important;
		}
	}
	&.MC-K61-Titan {
		& input + label:before {
			background:#474c50; color:#ffffff !important;
		}
	}
	&.MC_Stylo5_NewAuroraBlack {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}

	&.MC_V60_ClassyBlue {
		& input + label:before {
			background:#1d264d; color:#ffffff !important;
		}
	}
	&.MC_V60_ClassyWhite {
		& input + label:before {
			background:#ffffff; color:#000000 !important;
		}
	}
	&.MC_V60_TheBlack {
		& input + label:before {
			background:#000000; color:#ffffff !important;
		}
	}

	&.MC_Velvet_AuroraGreen {
		& input + label:before {
			background:#01514e; color:#ffffff !important;
		}
	}
	&.MC_Velvet_AuroraGrey {
		& input + label:before {
			background:#46474b; color:#ffffff !important;
		}
	}
	&.MC_Velvet_AuroraSilver {
		& input + label:before {
			background:#dad9d7; color:#000000 !important;
		}
	}
	&.MC_Velvet_IllusionSunset {
		& input + label:before {
			background:#d61969; color:#ffffff !important;
		}
	}
	&.MC_Velvet_AuroraWhite {
		& input + label:before {
			background:#ffffff; color:#000000 !important;
		}
	}
	&.MC_Velvet_NewBlack {
		& input + label:before {
			background:#040001; color:#ffffff !important;
		}
	}
	&.MC_Velvet_AuroraRed {
		& input + label:before {
			background:#c20430; color:#ffffff !important;
		}
	}
	&.MC_Velvet_PinkWhite {
		& input + label:before {
			background:#f0ecf5; color:#000000 !important;
		}
	}
	&.HE_TWS_ModernWhite {
		& input + label:before {
			background:#ffffff; color:#000000 !important;
		}
	}
	&.HE_TWS_StylishBlack {
		& input + label:before {
			background:#1a1915; color:#ffffff !important;
		}
	}

	&.MC_K22_Titan {
		& input + label:before {
			background:#7f8084; color:#000000 !important;
		}
	}
	&.MC_K22_Blue {
		& input + label:before {
			background:#36159a; color:#ffffff !important;
		}
	}
	&.MC_K22_Red {
		& input + label:before {
			background:#ae2738; color:#ffffff !important;
		}
	}

	&.MC-K71-HoloTitan {
		& input + label:before {
			background:#585f69; color:#ffffff !important;
		}
	}
	&.MC-K71-HoloWhite {
		& input + label:before {
			background:#e8f5fd; color:#000000 !important;
		}
	}

	&.MC_K42_Green{
		& input + label:before {
			background:#3f4a28;color:#ffffff !important;
		}
	}
	&.MC_K42_Grey{
		& input + label:before {
			background:#747474;color:#ffffff !important;
		}
	}
	&.MC_K42_Red{
		& input + label:before {
			background:#861b39;color:#ffffff !important;
		}
	}
	&.MC_K42_SkyBlue{
		& input + label:before {
			background:#a4d3d9;color:#000000 !important;
		}
	}

	&.MC_K52_White {
		& input + label:before {
			background: #ffffff; color:#000000 !important;
		}
	}
	&.MC_K52_Blue {
		& input + label:before {
			background: #211758; color:#ffffff !important;
		}
	}
	&.MC_K52_Red {
		& input + label:before {
			background: #872521; color:#ffffff !important;
		}
	}
	&.MC_K62_White {
		& input + label:before {
			background: #ffffff; color:#000000 !important;
		}
	}
	&.MC_K62_SkyBlue {
		& input + label:before {
			background: #c7d1ea; color:#000000 !important;
		}
	}

	&.aurora-white {
		& input + label:before {
			background:#FFFEFA; color:#ffffff !important;
		}
	}
	&.aurora-gray {
		& input + label:before {
			background:#292D36; color:#ffffff !important;
		}
	}
	&.aurora-green {
		& input + label:before {
			background:#007471; color:#ffffff !important;
		}
	}
	&.illusion-sunset {
		& input + label:before {
			background:#F2615C; color:#ffffff !important;
		}
	}
	&.aurora-pink {
		& input + label:before {
			background:#FFB1C1; color:#ffffff !important;
		}
	}
	&.aurora-blue {
		& input + label:before {
			background:#0022A2; color:#ffffff !important;
		}
	}
	&.aurora-red {
		& input + label:before {
			background:#A30821; color:#ffffff !important;
		}
	}
	&.ceramic-white	{
		& input + label:before {
			background:#F7F7F7; color:#ffffff !important;
		}
	}
	&.mirror-titanium {
		& input + label:before {
			background:#595959; color:#ffffff !important;
		}
	}
	&.mirror-red {
		& input + label:before {
			background:#BF103F; color:#ffffff !important;
		}
	}
	&.illusion-sky {
		& input + label:before {
			background:#A8D5EA; color:#ffffff !important;
		}
	}
	&.aurora-gray {
		& input + label:before {
			background:#292D36; color:#ffffff !important;
		}
	}
	&.moonlight-titan {
		& input + label:before {
			background:#2A2E37; color:#ffffff !important;
		}
	}
	&.frozen-white {
		& input + label:before {
			background:#FFFEFA; color:#ffffff !important;
		}
	}
	&.black {
		& input + label:before {
			background:#262626; color:#000 !important;
		}
	}
	&.astro-black {
		& input + label:before {
			background:#000; color:#000 !important;
		}
	}
	&.new-aurora-black {
		& input + label:before {
			background:#000; color:#000 !important;
		}
	}
	&.new-moroccan-blue {
		& input + label:before {
			background:#015499; color:#000 !important;
		}
	}
	&.carmine-red {
		& input + label:before {
			background:#8F0F26; color:#000 !important;
		}
	}
	&.mirror-black {
		& input + label:before {
			background:#39445D; color:#000 !important;
		}
	}
	&.new-platinum-gray {
		& input + label:before {
			background:#494D50; color:#000 !important;
		}
	}
	/* 20210607 스펙선택 추가 */
	&.white {
		& input + label:before {
			background:#fff; color:#000 !important; border: 1px solid #ddd;
		}
	}
	/* //20210607 스펙선택 추가 */
	&.blue-silver {
		& input + label:before {
			background:#2F5275; color:#000 !important;
		}
	}
	&.black-gold {
		& input + label:before {
			background:#F2D7D4; color:#000 !important;
		}
	}

	/* 0405 컬러추가 */
	/* 냉장고 */
	&.fridge_dark_shine {
		& input + label:before {
			background:#8a898f;
		}
	}
	&.fridge_montblanc-nature {
		& input + label:before {
			background:#a3a5a8;
		}
	}
	&.black {
		& input + label:before {
			background:#000;
		}
	}
	&.fridge_black-brown {
		& input + label:before {
			background:#3d3334;
		}
	}
	&.fridge_saffiano {
		& input + label:before {
			background:#a4a3a8;
		}
	}
	&.shiny-luce {
		& input + label:before {
			background:#676769;
		}
	}
	&.fridge_shiny-saffiano {
		& input + label:before {
			background:#8b8c8f;
		}
	}
	&.shiny-universe {
		& input + label:before {
			background:#666664;
		}
	}
	// &.fridge_shiny-pure {
	// 	& input + label:before {
	// 		background:#8b8d90;
	// 	}
	// }
	&.fridge_shine {
		& input + label:before {
			background:#a6a6a6;
		}
	}
	&.fridge_shine_ps3 {
		& input + label:before {
			background:#8a898e;
		}
	}
	&.fridge_shiny-luce {
		& input + label:before {
			background:#e4e4e6;
		}
	}
	// &.fridge_stari-shine {
	// 	& input + label:before {
	// 		background:#aaaaae;
	// 	}
	// }
	&.stainless {
		& input + label:before {
			background:#a8a8ab;
		}
	}
	// &.fridge_objet-grey {
	// 	& input + label:before {
	// 		background:#cdccc7;
	// 	}
	// }
	// &.fridge_objet-green {
	// 	& input + label:before {
	// 		background:#223529;
	// 	}
	// }
	// &.fridge_objet-manhattan-midnight {
	// 	& input + label:before {
	// 		background:#353336;
	// 	}
	// }
	// &.fridge_objet-mint {
	// 	& input + label:before {
	// 		background:#b7c7b6;
	// 	}
	// }
	// &.fridge_objet-beige {
	// 	& input + label:before {
	// 		background:#ede9e5;
	// 	}
	// }
	&.objet-black {
		& input + label:before {
			background:#3b3b3f;
		}
	}
	&.fridge_objet-silver {
		& input + label:before {
			background:#bebec3;
		}
	}
	&.objet-pink {
		& input + label:before {
			background:#e3c3c0;
		}
	}
	// &.fridge_objet-white {
	// 	& input + label:before {
	// 		background:#e2e3e4;
	// 	}
	// }
	&.fridge_western-black {
		& input + label:before {
			background:#363638;
		}
	}
	&.pure {
		& input + label:before {
			background:#6d6d6d;
		}
	}
	&.fridge_pure-flat {
		& input + label:before {
			background:#838387;
		}
	}
	&.fridge_harmony-white {
		& input + label:before {
			background:#e1e1e3;
		}
	}
	&.fridge_white {
		& input + label:before {
			background:#FFF;
		}
	}
	/* 김치냉장고 */
	// &.kimchi_shiny-luce {
	// 	& input + label:before {
	// 		background:#e0e0e3;
	// 	}
	// }
	// &.kimchi_manhattan-midnight {
	// 	& input + label:before {
	// 		background:#565656;
	// 	}
	// }
	// &.kimchi_montblanc-nature {
	// 	& input + label:before {
	// 		background:#7a7c80;
	// 	}
	// }
	// &.kimchi_shiny-luce {
	// 	& input + label:before {
	// 		background:#717274;
	// 	}
	// }
	// &.kimchi_shiny-saffiano {
	// 	& input + label:before {
	// 		background:#79797a;
	// 	}
	// }
	// &.kimchi_shiny-pure {
	// 	& input + label:before {
	// 		background:#bfbfc4;
	// 	}
	// }
	&.stari-shine {
		& input + label:before {
			background:#9c9ca1;
		}
	}
	&.kimchi_aria-wine {
		& input + label:before {
			background:#400400;
		}
	}
	&.objet-grey {
		& input + label:before {
			background:#cdccc7;
		}
	}
	// &.kimchi_objet-green {
	// 	& input + label:before {
	// 		background:#233328;
	// 	}
	// }
	// &.kimchi_manhattan-midnight {
	// 	& input + label:before {
	// 		background:#39393d;
	// 	}
	// }
	// &.kimchi_objet-mint {
	// 	& input + label:before {
	// 		background:#bacab9;
	// 	}
	// }
	// &.kimchi_objet-beige {
	// 	& input + label:before {
	// 		background:#eee9e6;
	// 	}
	// }
	// &.kimchi_objet-botanic {
	// 	& input + label:before {
	// 		background:#737d7d;
	// 	}
	// }
	// &.kimchi_objet-black {
	// 	& input + label:before {
	// 		background:#393b3f;
	// 	}
	// }
	&.sand {
		& input + label:before {
			background:#D4BEA6;
		}
	}
	&.kimchi_objet-stone {
		& input + label:before {
			background:#514b43;
		}
	}
	&.kimchi_objet-silver {
		& input + label:before {
			background:#bebec3;
		}
	}
	// &.kimchi_objet-pink {
	// 	& input + label:before {
	// 		background:#e5c6c4;
	// 	}
	// }
	&.kimchi_objet-white {
		& input + label:before {
			background:#e8e8ea;
		}
	}
	// &.kimchi_pure {
	// 	& input + label:before {
	// 		background:#898a8f;
	// 	}
	// }
	// &.kimchi_white {
	// 	& input + label:before {
	// 		background:#e8e8ea;
	// 	}
	// }
	/* 정수기 */
	&.water-purifiers_shiny-rose {
		& input + label:before {
			background:#bbb5b9;
		}
	}
	// &.water-purifiers_silver {
	// 	& input + label:before {
	// 		background:#848587;
	// 	}
	// }
	// &.water-purifiers_calming-green {
	// 	& input + label:before {
	// 		background:#3a4441;
	// 	}
	// }
	// &.water-purifiers_calming-beige {
	// 	& input + label:before {
	// 		background:#e1dfda;
	// 	}
	// }
	// &.water-purifiers_white {
	// 	& input + label:before {
	// 		background:#e8e8ea;
	// 	}
	// }
	/* 오븐 전자레인지 */
	// &.oven_mist-beige {
	// 	& input + label:before {
	// 		background:#dedddc;
	// 	}
	// }
	// &.oven_black {
	// 	& input + label:before {
	// 		background:#000000;
	// 	}
	// }
	&.oven_shiny-dia-black {
		& input + label:before {
			background:#676768;
		}
	}
	// &.oven_shiny-universe {
	// 	& input + label:before {
	// 		background:#8d8d8e;
	// 	}
	// }
	// &.oven_solid-green {
	// 	& input + label:before {
	// 		background:#343f34;
	// 	}
	// }
	// &.oven_solid-silver {
	// 	& input + label:before {
	// 		background:#bbbbbe;
	// 	}
	// }
	&.oven_stari-shine {
		& input + label:before {
			background:#868788;
		}
	}
	// &.oven_stainless {
	// 	& input + label:before {
	// 		background:#656563;
	// 	}
	// }
	// &.oven_white {
	// 	& input + label:before {
	// 		background:#e8e8ea;
	// 	}
	// }
	/* 식기세척기 */
	// &.dishwashers_nature-beige {
	// 	& input + label:before {
	// 		background:#d9d8d7;
	// 	}
	// }
	&.dishwashers_dawn-grey {
		& input + label:before {
			background:#565553;
		}
	}
	// &.dishwashers_manhattan-midnight {
	// 	& input + label:before {
	// 		background:#404042;
	// 	}
	// }
	&.shiny-pure {
		& input + label:before {
			background:#94918e;
		}
	}
	// &.dishwashers_solid-green {
	// 	& input + label:before {
	// 		background:#212c24;
	// 	}
	// }
	// &.dishwashers_solid-silver {
	// 	& input + label:before {
	// 		background:#bbbbbe;
	// 	}
	// }
	// &.dishwashers_stari-shine {
	// 	& input + label:before {
	// 		background:#8f9091;
	// 	}
	// }
	&.dishwashers_aria-white {
		& input + label:before {
			background:#d8d9da;
		}
	}
	// &.dishwashers_pure {
	// 	& input + label:before {
	// 		background:#999490;
	// 	}
	// }
	/* 전기레인지 */
	// &.ranges_black {
	// 	& input + label:before {
	// 		background:#000;
	// 	}
	// }
	// &.ranges_silver {
	// 	& input + label:before {
	// 		background:#28292d;
	// 	}
	// }
	/* 워시타워 */
	// &.washtower_nature-green {
	// 	& input + label:before {
	// 		background:#3f4645;
	// 	}
	// }
	// &.washtower_nature-beige {
	// 	& input + label:before {
	// 		background:#ded7ce;
	// 	}
	// }
	// &.washtower_nature-pink {
	// 	& input + label:before {
	// 		background:#d0b5af;
	// 	}
	// }
	&.washtower_lily-white {
		& input + label:before {
			background:#E8E8E8;
		}
	}
	&.washtower_metallic-silver {
		& input + label:before {
			background:#727373;
		}
	}
	// &.washtower_sand-beige {
	// 	& input + label:before {
	// 		background:#c6c6c3;
	// 	}
	// }
	&.washtower_starlight-silver {
		& input + label:before {
			background:#909090;
		}
	}
	&.washtower_space-black {
		& input + label:before {
			background:#373839;
		}
	}
	&.washtower_coral-pink {
		& input + label:before {
			background:#cab5b4;
		}
	}
	&.washtower_forest-green {
		& input + label:before {
			background:#465050;
		}
	}
	&.washing_mid-free-silver {
		& input + label:before {
			background:#aaaaaa;
		}
	}
	&.black-stainless {
		& input + label:before {
			background:#444446;
		}
	}
	&.sand-beige {
		& input + label:before {
			background:#EBE7E4;
		}
	}
	&.washing_stone-silver {
		& input + label:before {
			background:#7A7A7C;
		}
	}
	// &.washing_white {
	// 	& input + label:before {
	// 		background:#FFF;
	// 	}
	// }
	&.washing_white_ceramics-coating {
		& input + label:before {
			background:#EAEAEA;
		}
	}
	/* 건조기 */
	&.dryers_modern-stainless {
		& input + label:before {
			background:#727273;
		}
	}
	// &.dryers_black-stainless {
	// 	& input + label:before {
	// 		background:#444446;
	// 	}
	// }
	&.dryers_stone-silver {
		& input + label:before {
			background:#7A7A7C;
		}
	}
	// &.dryers_white {
	// 	& input + label:before {
	// 		background:#e8e8ea;
	// 	}
	// }
	/* 스타일러 */
	&.styler_linen-black {
		& input + label:before {
			background:#333438;
		}
	}
	&.linen-white {
		& input + label:before {
			background:#ffffff; // BTOCSITE-65530 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		}
	}
	// &.styler_mist-green {
	// 	& input + label:before {
	// 		background:#424E4C;
	// 	}
	// }
	// &.styler_mist-beige {
	// 	& input + label:before {
	// 		background:#e5e4e0;
	// 	}
	// }
	&.styler_black-tint-mirror {
		& input + label:before {
			background:#C5C6C8;
		}
	}
	/* 청소기 */
	&.vacuum_dark-silver {
		& input + label:before {
			background:#696C6E;
		}
	}
	&.vacuum_bohemian-red {
		& input + label:before {
			background:#A81E1E;
		}
	}
	&.vacuum_blossom-pink {
		& input + label:before {
			background:#ECAEC3;
		}
	}
	// &.vacuum_black {
	// 	& input + label:before {
	// 		background:#000000;
	// 	}
	// }
	&.vacuum_vintage-wine {
		& input + label:before {
			background:#825969;
		}
	}
	&.vacuum_shiny-blue {
		& input + label:before {
			background:#0E1F68;
		}
	}
	&.vacuum_shiny-silver {
		& input + label:before {
			background:#56555A;
		}
	}
	&.vacuum_steel-red {
		& input + label:before {
			background:#D92C26;
		}
	}
	&.vacuum_steel-blue {
		& input + label:before {
			background:#666962;
		}
	}
	&.vacuum_steel-titanium {
		& input + label:before {
			background:#67666C;
		}
	}
	&.vacuum_sparkle-red {
		& input + label:before {
			background:#CD0000;
		}
	}
	&.vacuum_iron-grey {
		& input + label:before {
			background:#54575d;
		}
	}
	&.vacuum_calming-green {
		& input + label:before {
			background:#525750;
		}
	}
	// &.vacuum_calming-beige {
	// 	& input + label:before {
	// 		background:#E3E0DB;
	// 	}
	// }
	&.vacuum_fantasy-silver {
		& input + label:before {
			background:#D0D1D4;
		}
	}
	/* 안마의자 */
	&.massage_noble-black {
		& input + label:before {
			background:#4A4F53;
		}
	}
	&.massage_ivory {
		& input + label:before {
			background:#DDD0C0;
		}
	}
	&.massage_ocean-grey {
		& input + label:before {
			background:#B7B5B6;
		}
	}
	&.massage_camel-brown {
		& input + label:before {
			background:#A7764E;
		}
	}
	/* 에어컨 */
	// &.aircon_newmetal-shine {
	// 	& input + label:before {
	// 		background:#EBECEE;
	// 	}
	// }
	// &.aircon_romantic-rose {
	// 	& input + label:before {
	// 		background:#C0B4B6;
	// 	}
	// }
	&.aircon_signaure-metal {
		& input + label:before {
			background:#B6BDC3;
		}
	}
	&.aircon_wedding-snow {
		& input + label:before {
			background:#F6F7F9;
		}
	}
	// &.aircon_calming-beige {
	// 	& input + label:before {
	// 		background:#E6E1DD;
	// 	}
	// }
	// &.aircon_creamy-snow {
	// 	& input + label:before {
	// 		background:#F8F8F8;
	// 	}
	// }
	// &.aircon_white {
	// 	& input + label:before {
	// 		background:#FFF;
	// 	}
	// }
	/* 공기청정기 */
	&.newmetal-shine {
		& input + label:before {
			background:#D7DCE0;
		}
	}
	&.romantic-rose {
		& input + label:before {
			background:#C8BBB5;
		}
	}
	&.air-purifier_rose-gold {
		& input + label:before {
			background:#DCC8BF;
		}
	}
	// &.air-purifier_silver {
	// 	& input + label:before {
	// 		background:#D2D6D9;
	// 	}
	// }
	&.air-purifier_emerald {
		& input + label:before {
			background:#C2D5DB;
		}
	}
	&.creamy-snow {
		& input + label:before {
			background:#F9F9F9;
		}
	}
	// &.air-purifier_white {
	// 	& input + label:before {
	// 		background:#FFF;
	// 	}
	// }
	/* 스마트폰 */
	&.smartphone_aurora-grey {
		& input + label:before {
			background:#292D36;
		}
	}
	&.smartphone_aurora-green {
		& input + label:before {
			background:#007471;
		}
	}
	&.smartphone_illusion-sunset {
		& input + label:before {
			background:#F2615C;
		}
	}
	&.smartphone_aurora-pink {
		& input + label:before {
			background:#FFB1C1;
		}
	}
	&.smartphone_aurora-blue {
		& input + label:before {
			background:#0022A2;
		}
	}
	&.smartphone_aurora-red {
		& input + label:before {
			background:#A30821;
		}
	}
	&.smartphone_ceramic-white {
		& input + label:before {
			background:#F7F7F7;
		}
	}
	&.smartphone_mirror-titan {
		& input + label:before {
			background:#595959;
		}
	}
	&.smartphone_mirror-red {
		& input + label:before {
			background:#BF103F;
		}
	}
	&.smartphone_illusion-sky {
		& input + label:before {
			background:#A8D5EA;
		}
	}
	&.smartphone_moonlight-titan {
		& input + label:before {
			background:#2A2E37;
		}
	}
	&.smartphone_frozen-white {
		& input + label:before {
			background:#FFFEFA;
		}
	}
	// &.smartphone_black {
	// 	& input + label:before {
	// 		background:#000000;
	// 	}
	// }
	&.smartphone_new-aurora-black {
		& input + label:before {
			background:#000000;
		}
	}
	&.smartphone_new-moroccan-blue {
		& input + label:before {
			background:#015499;
		}
	}
	&.smartphone_calming-red {
		& input + label:before {
			background:#8F0F26;
		}
	}
	&.smartphone_mirror-black {
		& input + label:before {
			background:#39445D;
		}
	}
	&.smartphone_new-platinum-grey {
		& input + label:before {
			background:#494D50;
		}
	}
	// &.smartphone_white {
	// 	& input + label:before {
	// 		background:#FFFFFF;
	// 	}
	// }
	&.smartphone_blue-silver {
		& input + label:before {
			background:#2F5275;
		}
	}
	&.smartphone_black-gold {
		& input + label:before {
			background:#F2D7D4;
		}
	}
	/* 뷰티 */
	&.beauty_steel-pink {
		& input + label:before {
			background:#E6C8DC;
		}
	}
	&.beauty_platinum-white {
		& input + label:before {
			background:#EEEEEE;
		}
	}
	&.beauty_pink {
		& input + label:before {
			background:#e69ead;
		}
	}
	// &.beauty_white {
	// 	& input + label:before {
	// 		background:#FFFFFF;
	// 	}
	// }
	&.beauty_white-gold {
		& input + label:before {
			background:#FBF2E0;
		}
	}
	/* 오브제컬렉션 */
	&.objet_green {
		& input + label:before {
			background:#293C2E;
		}
	}
	// &.objet_silver {
	// 	& input + label:before {
	// 		background:#c8c9ca;
	// 	}
	// }
	&.solid-green {
		& input + label:before {
			background:#343f34;
		}
	}
	&.objet_calming-green {
		& input + label:before {
			background:#3a4441;
		}
	}
	&.nature-green {
		& input + label:before {
			background:#4c5351;
		}
	}
	&.mist-green {
		& input + label:before {
			background:#586463;
		}
	}
	&.objet_manhattan-midnight {
		& input + label:before {
			background:#414146;
		}
	}
	&.nature-manhattan-midnight {
		& input + label:before {
			background:#414146;
		}
	}
	&.objet_grey {
		& input + label:before {
			background:#cdccc7;
		}
	}
	&.objet_beige {
		& input + label:before {
			background:#f0ebe7;
		}
	}
	&.nature-beige {
		& input + label:before {
			background:#dad2ca;
		}
	}
	&.calming-beige {
		& input + label:before {
			background:#dfdad4;
		}
	}
	&.objet_white {
		& input + label:before {
			background:#e2e2e3;
		}
	}
	&.objet_mint {
		& input + label:before {
			background:#b8c9b7;
		}
	}
	&.objet_pink {
		& input + label:before {
			background:#e3c3c0;
		}
	}
	&.nature-pink {
		& input + label:before {
			background:#dcc2bc;
		}
	}
	&.objet_grey {
		& input + label:before {
			background:#cdccc7;
		}
	}
	&.mist-beige {
		& input + label:before {
			background:#e5e4e0;
		}
	}
	&.objet_black {
		& input + label:before {
			background:#4B4D50;
		}
	}
	&.objet_pcm_black {
		& input + label:before {
			background:#3a3a3f;
		}
	}
	&.objet_botanic {
		& input + label:before {
			background:#eee9e5;
		}
	}
	&.sts_silver {
		& input + label:before {
			background:#DFDFE1;
		}
	}
	&.objet_solid-silver {
		& input + label:before {
			background:#bbbbbe;
		}
	}
	&.objet_sand {
		& input + label:before {
			background:#dcc8b0;
		}
	}
	&.objet_stone {
		& input + label:before {
			background:#554d44;
		}
	}
	&.fenix-stone {
		& input + label:before {
			background:#554d44;
		}
	}
	&.obsidian_black {
		& input + label:before {
			background:#333438;
		}
	}
	&.dark_silver {
		& input + label:before {
			background:#555557;
		}
	}

	/* 210413 추가 */
	&.botanic_botanic {
		& input + label:before {
			background-position:0 0;
		}
	}
	&.stone_stone {
		& input + label:before {
			background-position:-24px 0;
		}
	}
	&.mint_mint {
		& input + label:before {
			background-position:-48px 0;
		}
	}
	&.pink_silver {
		& input + label:before {
			background-position:-72px 0;
		}
	}
	&.black_black {
		& input + label:before {
			background-position:-96px 0;
		}
	}
	&.green_silver {
		& input + label:before {
			background-position:-120px 0;
		}
	}
	&.botanic_sand {
		& input + label:before {
			background-position:0 -24px;
		}
	}
	&.beige_beige {
		& input + label:before {
			background-position:-24px -24px;
		}
	}
	&.pink_beige {
		& input + label:before {
			background-position:-48px -24px;
		}
	}
	&.silver_beige {
		& input + label:before {
			background-position:-72px -24px;
		}
	}
	&.black_grey {
		& input + label:before {
			background-position:-96px -24px;
		}
	}
	&.black_silver {
		& input + label:before {
			background-position:-120px -24px;
		}
	}
	&.botanic_stone {
		& input + label:before {
			background-position:0 -48px;
		}
	}
	&.beige_mint {
		& input + label:before {
			background-position:-24px -48px;
		}
	}
	&.pink_pink {
		& input + label:before {
			background-position:-48px -48px;
		}
	}
	&.silver_mint {
		& input + label:before {
			background-position:-72px -48px;
		}
	}
	&.grey_grey {
		& input + label:before {
			background-position:-96px -48px;
		}
	}
	&.silver_green {
		& input + label:before {
			background-position:-120px -48px;
		}
	}
	&.sand_sand {
		& input + label:before {
			background-position:0 -72px;
		}
	}
	&.beige_pink {
		& input + label:before {
			background-position:-24px -72px;
		}
	}
	&.beige_silver {
		& input + label:before {
			background-position:-48px -72px;
		}
	}
	&.silver_pink {
		& input + label:before {
			background-position:-72px -72px;
		}
	}
	&.white_white {
		& input + label:before {
			background-position:-96px -72px;
		}
	}
	&.manhattan_silver {
		& input + label:before {
			background-position:-120px -72px;
		}
	}
	&.stone_sand {
		& input + label:before {
			background-position:0 -96px;
		}
	}
	&.mint_beige {
		& input + label:before {
			background-position:-24px -96px;
		}
	}
	&.mint_silver {
		& input + label:before {
			background-position:-48px -96px;
		}
	}
	&.silver_silver {
		& input + label:before {
			background-position:-72px -96px;
		}
	}
	&.green_green {
		& input + label:before {
			background-position:-96px -96px;
		}
	}

	/* 210624 추가 */
	&.redwood {
		& input + label:before {
			background-position:-24px -132px;
		}
	}
	&.claybrown {
		& input + label:before {
			background-position:0 -156px;
		}
	}
	&.redwood_claybrown {
		& input + label:before {
			background-position:-120px -144px;
		}
	}
	&.claybrown_redwood {
		& input + label:before {
			background-position:-96px -132px;
		}
	}
	&.beige_redwood {
		& input + label:before {
			background-position:-24px -120px;
		}
	}
	&.beige_claybrown {
		& input + label:before {
			background-position:-48px -120px;
		}
	}
	&.green-redwood {
		& input + label:before {
			background-position:-72px -120px;
		}
	}
	&.green_claybrown {
		& input + label:before {
			background-position:-96px -120px;
		}
	}
	&.pink_redwood {
		& input + label:before {
			background-position:-120px -120px;
		}
	}
	&.pink_claybrown {
		& input + label:before {
			background-position:0px -144px;
		}
	}
	&.redwood_beige {
		& input + label:before {
			background-position:-24px -144px;
		}
	}
	&.redwood_green {
		& input + label:before {
			background-position:-48px -144px;
		}
	}
	&.redwood_pink {
		& input + label:before {
			background-position:-72px -144px;
		}
	}
	&.redwood_redwood {
		& input + label:before {
			background-position:-96px -144px;
		}
	}
	&.claybrown_beige {
		& input + label:before {
			background-position:0px -168px;
		}
	}
	&.claybrown_green {
		& input + label:before {
			background-position:-24px -168px;
		}
	}
	&.claybrown_pink {
		& input + label:before {
			background-position:-48px -168px;
		}
	}
	&.claybrown_claybrown {
		& input + label:before {
			background-position:-96px -168px;
		}
	}
	&.nature-green_beige {
		& input + label:before {
			background-position:-120px -168px;
		}
	}
	&.nature-beige_green {
		& input + label:before {
			background-position:0 -192px;
		}
	}
	&.gray_white {
		& input + label:before {
			background-position:-48px -192px;
		}
	}
	&.white_gray {
		& input + label:before {
			background-position:-72px -192px;
		}
	}
	&.gray_black {
		& input + label:before {
			background-position:-96px -192px;
		}
	}
	&.manhattan_manhattan {
		& input + label:before {
			background-position:-120px -192px;
		}
	}
	&.quartz_silver {
		& input + label:before {
			background-color: #014a6a;
			background-position:-24px -192px;
		}
	}

	/* BTOCSITE-2758 시블링 컬러칩코드 추가요청 */
	&.nature-green_pink{
		& input + label:before {
			background-position: 0 -216px;
		}
	}
	&.nature-pink_green {
		& input + label:before {
			background-position:-24px -216px;
		}
	}
	/* //BTOCSITE-2758 시블링 컬러칩코드 추가요청 */

	/* BTOCSITE-10823 시블링 컬러칩 형태 변경 요청 */
	// BTOCSITE-34859 컬러칩 코드로 변환
	&.snow_white,
	&.white,
	&.white_white {
		& input + label:before {
			background: #fff !important;
			border: 1px solid #ddd !important;
		}
	}
	/* //BTOCSITE-10823 시블링 컬러칩 형태 변경 요청 */

	&.beige_beige {
		& input + label:before {
			background: #eee9e5;
		}
	}
	&.beige_mint {
		& input + label:before {
			background: linear-gradient(to bottom, #eee9e5 50%, #b8c9b7 50%);
		}
	}
	&.beige_pink {
		& input + label:before {
			background: linear-gradient(to bottom, #eee9e5 50%, #e6c4c2 50%);
		}
	}
	&.beige_silver {
		& input + label:before {
			background: linear-gradient(to bottom, #eee9e5 50%, #bfc0c5 50%);
		}
	}
	&.black_black {
		& input + label:before {
			background: #414143;
		}
	}
	&.black_grey {
		& input + label:before {
			background: linear-gradient(to bottom, #414143 50%, #cccbc6 50%);
		}
	}
	&.botanic_botanic {
		& input + label:before {
			background: #6c7677;
		}
	}
	&.botanic_sand {
		& input + label:before {
			background: linear-gradient(to bottom, #6c7677 50%, #d3bda5 50%);
		}
	}
	&.botanic_stone {
		& input + label:before {
			background: linear-gradient(to bottom, #6c7677 50%, #554e46 50%);
		}
	}
	&.gray_black {
		& input + label:before {
			background: linear-gradient(to bottom, #cacac3 50%, #414143 50%);
		}
	}
	&.gray_white {
		& input + label:before {
			background: linear-gradient(to bottom, #cacac3 50%, #e1e1e4 50%);
		}
	}
	&.green_green {
		& input + label:before {
			background: #293c2e;
		}
	}
	&.green_silver {
		& input + label:before {
			background: linear-gradient(to bottom, #293c2e 50%, #dfdfe1 50%);
		}
	}
	&.grey_grey {
		& input + label:before {
			background: #cdccc7;
		}
	}
	&.mint_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #b8c9b7 50%, #eee9e5 50%);
		}
	}
	&.objet_manhattan-midnight {
		& input + label:before {
			background: #414146;
		}
	}
	&.pink_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #e6c4c2 50%, #eee9e5 50%);
		}
	}
	&.sand_sand {
		& input + label:before {
			background: #d3bca4;
		}
	}
	&.silver_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #bfc0c5 50%, #eee9e5 50%);
		}
	}
	&.silver_green {
		& input + label:before {
			background: linear-gradient(to bottom, #bfc0c5 50%, #293c2e 50%);
		}
	}
	&.silver_mint {
		& input + label:before {
			background: linear-gradient(to bottom, #bfc0c5 50%, #b8c9b7 50%);
		}
	}
	&.silver_pink {
		& input + label:before {
			background: linear-gradient(to bottom, #bfc0c5 50%, #e6c4c2 50%);
		}
	}
	&.silver_silver {
		& input + label:before {
			background: #bfc0c5;
		}
	}
	&.stone_sand {
		& input + label:before {
			background: linear-gradient(to bottom, #554e46 50%, #d4bea6 50%);
		}
	}
	&.white_gray {
		& input + label:before {
			background: linear-gradient(to bottom, #e1e1e4 50%, #cacac3 50%);
		}
	}
	&.av_charcoal-black {
		& input + label:before {
			background: #404145;
		}
	}
	&.av_pearl-white {
		& input + label:before {
			background: #eeeeee;
		}
	}
	&.av_blue-black {
		& input + label:before {
			background: #3a424f;
		}
	}
	&.av_silver-white {
		& input + label:before {
			background: #e2e2e2;
		}
	}
	&.av_berry-blue {
		& input + label:before {
			background: #acdbe5;
		}
	}
	&.av_bubble-black {
		& input + label:before {
			background: #f8c4c6;
		}
	}
	&.av_sour-lemon {
		& input + label:before {
			background: #faf081;
		}
	}
	&.fridge_manhattan-midnight {
		& input + label:before {
			background: #444444;
		}
	}
	&.objet_stone {
		& input + label:before {
			background: #554d44;
		}
	}
	&.kimchi_shinee-pure {
		& input + label:before {
			background: #8d8e93;
		}
	}
	&.oven_shinee-pure-silver {
		& input + label:before {
			background: #4e4e4c;
		}
	}
	&.oven_pure-black {
		& input + label:before {
			background: #1f1f21;
		}
	}
	&.washing_black-stainless {
		& input + label:before {
			background: #464648;
		}
	}
	&.washing_modern-stainless {
		& input + label:before {
			background: #6f7074;
		}
	}
	&.washing_middle-black {
		& input + label:before {
			background: #3e3e3e;
		}
	}
	&.objet_calm-beige_calm-green {
		& input + label:before {
			background: linear-gradient(to bottom, #d7d4cd 50%, #32373a 50%);
		}
	}
	&.objet_calm-green_calm-beige {
		& input + label:before {
			background: linear-gradient(to bottom, #32373a 50%, #4b555e 50%);
		}
	}
	&.objet_calm-beige_Iron-gray {
		& input + label:before {
			background: linear-gradient(to bottom, #d7d4cd 50%, #4b555e 50%);
		}
	}
	&.objet_calm-green_Iron-gray {
		& input + label:before {
			background: linear-gradient(to bottom, #32373a 50%, #b3b4b8 50%);
		}
	}
	&.objet_calm-beige_fantasy-silver {
		& input + label:before {
			background: linear-gradient(to bottom, #d7d4cd 50%, #b3b4b8 50%);
		}
	}
	&.objet_calm-green_fantasy-silver {
		& input + label:before {
			background: linear-gradient(to bottom, #32373a 50%, #b3b4b8 50%);
		}
	}
	&.objet_calm-beige_black {
		& input + label:before {
			background: linear-gradient(to bottom, #d7d4cd 50%, #0b0b0b 50%);
		}
	}
	&.objet_calm-green_black {
		& input + label:before {
			background: linear-gradient(to bottom, #374142 50%, #0b0b0b 50%);
		}
	}
	&.air_milktea-latte {
		& input + label:before {
			background: #dcd2c8;
		}
	}
	&.air_new-metal-shine {
		& input + label:before {
			background: #bdc0c5;
		}
	}
	&.air_romantic-rose {
		& input + label:before {
			background: #dbcccd;
		}
	}
	&.air_dark-blue {
		& input + label:before {
			background: #363e51;
		}
	}
	&.air_rose-gold {
		& input + label:before {
			background: #debdb6;
		}
	}
	&.silver_manhattan {
		& input + label:before {
			background: linear-gradient(to bottom, #c3c3c3 50%, #383838 50%);
		}
	}
	&.kvadrat_beige {
		& input + label:before {
			background: #d6cac1;
		}
	}
	&.kvadrat_redwood {
		& input + label:before {
			background: #804f43;
		}
	}
	&.kvadrat_green {
		& input + label:before {
			background: #515b4b;
		}
	}
	&.stone_claypink_silver {
		& input + label:before {
			background: linear-gradient(to bottom, #9a8786 50%, #dfdfe1 50%);
		}
	}
	&.objet_calming_cottonsky {
		& input + label:before {
			background: #dbe1f0;
		}
	}
	&.objet_calming_palerose {
		& input + label:before {
			background: #e0c6b3;
		}
	}
	&.water-purifiers_navy {
		& input + label:before {
			background: #21354e;
		}
	}
	&.water-purifiers_solid_black {
		& input + label:before {
			background: #404144;
		}
	}
	&.water-purifiers_solid_beige {
		& input + label:before {
			background: #eee9e5;
		}
	}
	&.water-purifiers_solid_claybrown {
		& input + label:before {
			background: #c8b2a6;
		}
	}
	&.water-purifiers_solid_white {
		& input + label:before {
			background: #f5f3f1;
		}
	}
	&.washing_stainless_silver {
		& input + label:before {
			background: #7b7f82;
		}
	}
	&.objet_calming_brown {
		& input + label:before {
			background: #c8b2a6;
		}
	}
	&.objet_cream_white {
		& input + label:before {
			background: #f5f3f1;
		}
	}
	&.objet_fabric_beige {
		& input + label:before {
			background: #eee9e5;
		}
	}
	&.air-purifier_milktealatte {
		& input + label:before {
			background: #908782;
		}
	}
	&.objet_nature_redwood {
		& input + label:before {
			background: #9a5956;
		}
	}
	&.objet_nature_cream_white {
		& input + label:before {
			background: #f5f3f1;
		}
	}
	&.objet_nature_claybrown {
		& input + label:before {
			background: #c8b2a6;
		}
	}
	&.gold {
		& input + label:before {
			background: #dfcec0;
		}
	}
	&.lavender {
		& input + label:before {
			background: #8e64a8;
		}
	}
	&.air-purifier_mint {
		& input + label:before {
			background: #a9e0d5;
		}
	}
	&.mango {
		& input + label:before {
			background: #f3b953;
		}
	}
	&.objet_nature_claypink {
		& input + label:before {
			background: #9b8786;
		}
	}
	&.objet_mistpink {
		& input + label:before {
			background: #e6c5c1;
		}
	}
	&.smog_black {
		& input + label:before {
			background: #4b4a4f;
		}
	}
	&.noble_silver {
		& input + label:before {
			background: #b6b1a5;
		}
	}
	&.dishwashers_pure {
		& input + label:before {
			background: #a5a19e;
		}
	}
	&.objet_claymint {
		& input + label:before {
			background: #738c90;
		}
	}
	&.shiny_beige {
		& input + label:before {
			background: #e6e3db;
		}
	}
	&.vacuum_bohemian_red {
		& input + label:before {
			background: #cd2f55;
		}
	}
	&.objet_botanic_real {
		& input + label:before {
			background: #6c7677;
		}
	}
	&.objet_topaz_green {
		& input + label:before {
			background: #b8c0ac;
		}
	}
	&.topaz_green {
		& input + label:before {
			background: #b8c0ac;
		}
	}
	&.neptune-blue {
		& input + label:before {
			background: #474a53;
		}
	}
	&.objet_lavender_punch {
		& input + label:before {
			background: #bdbdca;
		}
	}
	&.objet_charcoal_gray {
		& input + label:before {
			background: #b8c0c6;
		}
	}
	&.objet_peach_pink {
		& input + label:before {
			background: #ffc0a1;
		}
	}
	&.objet_stone_silver {
		& input + label:before {
			background: #ebebed;
		}
	}
	&.clay_pink_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #a28f8f 50%, #f2eee9 50%);
		}
	}
	&.clay_mint_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #769396 50%, #f2eee9 50%);
		}
	}
	&.cream_sky_white {
		& input + label:before {
			background: linear-gradient(to bottom, #e8ecef 50%, #fdfdfd 50%);
		}
	}
	&.claypink_silver {
		& input + label:before {
			background: linear-gradient(to bottom, #c1a9ac 50%, #c4c6c6 50%);
		}
	}
	&.creamwhite_creamwhite {
		& input + label:before {
			background: #fafafa;
		}
	}
	&.gram_aurora-white {
		& input + label:before {
			background: linear-gradient(#c9c5e2, #e1f1fc);
		}
	}
	&.c_white {
		& input + label:before {
			background: #f9f9f9;
		}
	}
	&.c_silver {
		& input + label:before {
			background: #d9d9d9;
		}
	}
	&.c_stainless {
		& input + label:before {
			background: linear-gradient(-45deg, #bebebe, #dddddc, #bebebe);
		}
	}
	&.c_grey,
	&.c_gray {
		& input + label:before {
			background: #99999a;
		}
	}
	&.c_black {
		& input + label:before {
			background: #000000;
		}
	}
	&.c_pink {
		& input + label:before {
			background: #debdb9;
		}
	}
	&.c_red {
		& input + label:before {
			background: #cc4b59;
		}
	}
	&.c_beige {
		& input + label:before {
			background: #eae4df;
		}
	}
	&.c_sand {
		& input + label:before {
			background: #dfcfb8;
		}
	}
	&.c_yellow {
		& input + label:before {
			background: #e4cd7c;
		}
	}
	&.c_brown {
		& input + label:before {
			background: #bd9f8e;
		}
	}
	&.c_stone {
		& input + label:before {
			background: #6b635d;
		}
	}
	&.c_mint {
		& input + label:before {
			background: #97c2ba;
		}
	}
	&.c_botanic {
		& input + label:before {
			background: #687c7c;
		}
	}
	&.c_green {
		& input + label:before {
			background: #415254;
		}
	}
	&.c_blue {
		& input + label:before {
			background: #87b1c9;
		}
	}
	&.c_navy {
		& input + label:before {
			background: #21354e;
		}
	}
	&.washing_glossy-white {
		& input + label:before {
			background: #f0f0f2;
		}
	}
	&.washing_lily-white {
		& input + label:before {
			background: #f9fbfa;
		}
	}
	&.washing_space-black {
		& input + label:before {
			background: #37393b;
		}
	}
	&.washtower_platinum-black {
		& input + label:before {
			background: #252527;
		}
	}
	&.washtower_lily-white_lily-white {
		& input + label:before {
			background: #f9fbfa;
		}
	}
	&.washtower_nature-beige_nature-clay-brown {
		& input + label:before {
			background: linear-gradient(to bottom, #e9e3e1 50%, #ada49e 50%);
		}
	}
	&.washtower_nature-beige_nature-clay-mint {
		& input + label:before {
			background: linear-gradient(to bottom, #e9e3e1 50%, #7798ad 50%);
		}
	}
	&.washtower_nature-clay-brown_nature-beige {
		& input + label:before {
			background: linear-gradient(to bottom, #ada49e 50%, #e9e3e1 50%);
		}
	}
	&.washtower_nature-clay-brown_nature-clay-brown {
		& input + label:before {
			background: #ada49e;
		}
	}
	&.washtower_nature-clay-brown_nature-clay-mint {
		& input + label:before {
			background: linear-gradient(to bottom, #ada49e 50%, #7798ad 50%);
		}
	}
	&.washtower_nature-clay-brown_nature-green {
		& input + label:before {
			background: linear-gradient(to bottom, #ada49e 50%, #42514c 50%);
		}
	}
	&.washtower_nature-clay-mint_nature-beige {
		& input + label:before {
			background: linear-gradient(to bottom, #7798ad 50%, #e9e3e1 50%);
		}
	}
	&.washtower_nature-clay-mint_nature-clay-brown {
		& input + label:before {
			background: linear-gradient(to bottom, #7798ad 50%, #ada49e 50%);
		}
	}
	&.washtower_nature-clay-mint_nature-clay-mint {
		& input + label:before {
			background: #7798ad;
		}
	}
	&.washtower_nature-clay-mint_nature-green {
		& input + label:before {
			background: linear-gradient(to bottom, #7798ad 50%, #42514c 50%);
		}
	}
	&.washtower_nature-green_nature-clay-brown {
		& input + label:before {
			background: linear-gradient(to bottom, #42514c 50%, #ada49e 50%);
		}
	}
	&.washtower_nature-green_nature-clay-mint {
		& input + label:before {
			background: linear-gradient(to bottom, #42514c 50%, #7798ad 50%);
		}
	}
	&.washtower_space-black-space-black {
		& input + label:before {
			background: #37393b;
		}
	}
	&.washtower_modern-stainless_modern-stainless {
		& input + label:before {
			background: #b5b5b5;
		}
	}
	&.essence_white {
		& input + label:before {
			background: #f7f6f6;
		}
	}
	&.essence_graphite {
		& input + label:before {
			background: #434447;
		}
	}
	&.cream_yellow {
		& input + label:before {
			background: #edd373;
		}
	}
	&.cream_rose {
		& input + label:before {
			background: #d85e66;
		}
	}
	&.c_gold {
		& input + label:before {
			background: #c1a78e;
		}
	}
	&.c_purple {
		& input + label:before {
			background: #c4b6d4;
		}
	}
	&.washtower_glossy-white {
		& input + label:before {
			background: #f0f0f2;
		}
	}
	&.stone_silver {
		& input + label::before {
			background: #dfdfe1;
		}
	}
	// [BTOCSITE-42101] 차세대 구독 컬러칩 추가
	&.objet_pink_1 {
		& input + label:before {
			background: #e6c5c1;
		}
	}
	&.objet_brown {
		& input + label:before {
			background: #c8b2a6;
		}
	}
	&.objet_pink_brown {
		& input + label:before {
			background: linear-gradient(to bottom, #e6c5c1 50%, #c8b2a6 50%);
		}
	}
	&.objet_brown_botanic {
		& input + label:before {
			background: linear-gradient(to bottom, #c8b2a6 50%, #eEe9e5 50%);
		}
	}
	&.objet_cream_lavender {
		& input + label:before {
			background: #c1b8cc;
		}
	}
	&.objet_cream_peach {
		& input + label:before {
			background: #e1a990;
		}
	}
	&.objet_cream_lemon {
		& input + label:before {
			background: #dfd298;
		}
	}
	&.objet_cream_sky {
		& input + label:before {
			background: #e4e8ec;
		}
	}
	&.objet_botanic1 {
		& input + label:before {
			background: #eee9e5;
		}
	}
	&.clay_brown {
		& input + label:before {
			background: #c8b2a6;
		}
	}
	&.dark_gray {
		& input + label:before {
			background: #414146;
		}
	}
	&.storm_gray {
		& input + label:before {
			background: #6e7d9c;
		}
	}
	&.cream-gray_cream-gray {
		& input + label:before {
			background: #dad9db;
		}
	}
	// [BTOCSITE-38982] 냉장고 판매 옵션 적용
	&.navy_cream-gray {
		& input + label:before {
			background: linear-gradient(to bottom, #3a4151 50%, #dad9db 50%);
		}
	}
	&.manhattan_manhattan {
		& input + label:before {
			background: linear-gradient(to bottom, #414146 50%, #414146 50%);
		}
	}
	&.beige_claybrown {
		& input + label:before {
			background: linear-gradient(to bottom, #eee9e5 50%, #c8b2a6 50%);
		}
	}
	&.cream-gray_cream-white {
		& input + label:before {
			background: linear-gradient(to bottom, #dad9db 50%, #fffcfc 50%);
		}
	}
	&.cream-remon_cream-white {
		& input + label:before {
			background: linear-gradient(to bottom, #dfd298 50%, #fffcfc 50%);
		}
	}
	&.cream-sky_cream-white {
		& input + label:before {
			background: linear-gradient(to bottom, #e4e8ec 50%, #fffcfc 50%);
		}
	}
	&.cream-peach_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #e1a990 50%, #eee9e5 50%);
		}
	}
	&.cream-white_navy {
		& input + label:before {
			background: linear-gradient(to bottom, #fffcfc 50%, #3a4151 50%);
		}
	}
	&.navy_cream-white {
		& input + label:before {
			background: linear-gradient(to bottom, #3a4151 50%, #fffcfc 50%);
		}
	}
	&.cream-white_cream-gray {
		& input + label:before {
			background: linear-gradient(to bottom, #fffcfc 50%, #dad9db 50%);
		}
	}
	&.creamwhite_creamwhite {
		& input + label:before {
			background: linear-gradient(to bottom, #fffcfc 50%, #fffcfc 50%);
		}
	}
	&.claybrown_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #c8b2a6 50%, #eee9e5 50%);
		}
	}
	&.claybrown_claybrown {
		& input + label:before {
			background: linear-gradient(to bottom, #c8b2a6 50%, #c8b2a6 50%);
		}
	}
	&.pink_claybrown {
		& input + label:before {
			background: linear-gradient(to bottom, #e6c5c1 50%, #c8b2a6 50%);
		}
	}
	&.pink_pink {
		& input + label:before {
			background: linear-gradient(to bottom, #e6c5c1 50%, #e6c5c1 50%);
		}
	}
	&.claybrown,
	&.clay_brown {
		& input + label:before {
			background: #c8b2a6;
		}
	}
	&.objet_claypink {
		& input + label:before {
			background: #9a8786;
		}
	}
	&.prime-silver {
		& input + label:before {
			background: #bfc0c6;
		}
	}
	// [BTOCSITE-36805] 컬러칩 추가
	&.nature-beige_green {
		& input + label:before {
			background: linear-gradient(to bottom, #e9e3e1 50%, #415254 50%);
		}
	}
	&.nature-green_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #42514c 50%, #eae4df 50%);
		}
	}

	&.manhattan_silver {
		& input + label:before {
			background: -webkit-linear-gradient(to bottom, #32373a 50%, #b3b4b8 50%);
			background: linear-gradient(to bottom, #32373a 50%, #b3b4b8 50%);
		}
	}
	// BTOCSITE-63071 [PLP] 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
	&.mist-clay-brown {
		& input + label:before {
			background: #d1c7bd ;
		}
	}
	// BTOCSITE-57327 워시타워 컬러칩 추가
	&.nature_cream_gray-nature_green {
		& input + label:before {
			background: linear-gradient(to bottom, #d2cfd6 50%, #3a4848 50%);
		}
	}
	&.nature_navy-nature_green {
		& input + label:before {
			background: linear-gradient(to bottom, #2f375b 50%, #3a4848 50%);
		}
	}
	&.nature-beige_nature-green {
		& input + label:before {
			background: linear-gradient(to bottom, #eeede9 50%, #3a4848 50%);
		}
	}
	&.nature_cream_gray-nature_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #d2cfd6 50%, #eeede9 50%);
		}
	}
	&.nature_navy-nature_beige {
		& input + label:before {
			background: linear-gradient(to bottom, #2f375b 50%, #eeede9 50%);
		}
	}
	&.nature_navy-nature_cream_gray {
		& input + label:before {
			background: linear-gradient(to bottom, #2f375b 50%, #d2cfd6 50%);
		}
	}
	&.nature_beige-nature_cream_gray {
		& input + label:before {
			background: linear-gradient(to bottom, #eeede9 50%, #d2cfd6 50%);
		}
	}
	&.nature_cream_gray-nature_cream_gray {
		& input + label:before {
			background: linear-gradient(to bottom, #d2cfd6 50%, #d2cfd6 50%);
		}
	}
	&.nature_green-nature_cream_gray {
		& input + label:before {
			background: linear-gradient(to bottom, #3a4848 50%, #d2cfd6 50%);
		}
	}
	&.nature_cream_gray-nature_navy {
		& input + label:before {
			background: linear-gradient(to bottom, #d2cfd6 50%, #2f375b 50%);
		}
	}
	&.nature_navy-nature_navy {
		& input + label:before {
			background: linear-gradient(to bottom, #2f375b 50%, #2f375b 50%);
		}
	}
	&.nature_green-nature_navy {
		& input + label:before {
			background: linear-gradient(to bottom, #3a4848 50%, #2f375b 50%);
		}
	}
	&.nature_beige-nature_navy {
		& input + label:before {
			background: linear-gradient(to bottom, #eeede9 50%, #2f375b 50%);
		}
	}
	// BTOCSITE-59152 시블링코드 생성 요청
	&.objet_cream_lavender-objet_cream_lavender {
		& input + label:before {
			background: linear-gradient(to bottom, #c1b8cc 50%, #c1b8cc 50%);
		}
	}
	&.objet_cream_peach-objet_cream_peach {
		& input + label:before {
			background: linear-gradient(to bottom, #e1a990 50%, #e1a990 50%);
		}
	}
	&.objet_cream_lemon-objet_cream_lemon {
		& input + label:before {
			background: linear-gradient(to bottom, #dfd298 50%, #dfd298 50%);
		}
	}
	&.objet_cream_sky-objet_cream_sky {
		& input + label:before {
			background: linear-gradient(to bottom, #e4e8ec 50%, #e4e8ec 50%);
		}
	}
	// S : BTOCSITE-63392 [광파오븐] 옵션 컬러칩 추가 요청
	&.calming_clay_mint {
		& input + label:before {
			background: #7ca6b4;
		}
	}
	&.calming-pink {
		& input + label:before {
			background: #f0ddd9;
		}
	}
	&.calming_essence_white {
		& input + label:before {
			background: #f6f8f5;
		}
	}
	// E : BTOCSITE-63392 [광파오븐] 옵션 컬러칩 추가 요청
	// S : BTOCSITE-64221 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
	&.linen-brown {
		& input + label:before {
			background: #372c26;
		}
	}
	&.linen-ivory {
		& input + label:before {
			background: #eae6de; // BTOCSITE-65530 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		}
	}
	// E : BTOCSITE-64221 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
	// S : BTOCSITE-67869 [정수기] 옵션 컬러칩 추가 요청
	&.calming-cream-grey {
		& input + label:before {
			background: #CCCBD3;
		}
	}
	&.calming-cream-white {
		& input + label:before {
			background: #F3F3F3;
		}
	}
	&.calming-pebble-gray {
		& input + label:before {
			background: #D4D4D4;
		}
	}
	&.calming-cream-sky {
		& input + label:before {
			background: #DADEE7;
		}
	}
	// E : BTOCSITE-67869 [정수기] 옵션 컬러칩 추가 요청
	// S : BTOCSITE-68703 [PLP] 안마의자 및 청소기 신규 색상 등록 요청 (판매 옵션)
	&.italian-red {
		& input + label:before {
			background: #b21528;
		}
	}
	&.cozy-beige {
		& input + label:before {
			background: #f1f0ee;
		}
	}
	&.cozy-brown {
		& input + label:before {
			background: #dad1c0;
		}
	}
	// E : BTOCSITE-68703 [PLP] 안마의자 및 청소기 신규 색상 등록 요청 (판매 옵션)
	// S : BTOCSITE-69423 [PLP] 청소기 신규 색상 등록 요청
	&.cream-yellow {
		& input + label:before {
			background: #eee1ad;
		}
	}
	// E : BTOCSITE-69423 [PLP] 청소기 신규 색상 등록 요청
	// S : BTOCSITE-72607 [PDP옵션] 전기레인지 색상 등록 요청
	&.mist-essence-white {
		& input + label:before {
			background: #ececea;
		}
	}
	// E : BTOCSITE-72607 [PDP옵션] 전기레인지 색상 등록 요청
	// S : BTOCSITE-73491 [정수기] 옵션 컬러칩 추가요청
	&.mist-cream-sky {
		& input + label::before {
			background: #c6c7cc
		}
	}
	&.mist-cream-white {
		& input + label::before {
			background: #dedede
		}
	}
	&.calming_black {
		& input + label:before {
			background: #0b0b0d;
		}
	}
	// E : BTOCSITE-73491 [정수기] 옵션 컬러칩 추가요청
	// S : BTOCSITE-74089 [냉장고] 옵션 컬러칩 추가요청
	&.beige_cream_white {
		& input + label::before {
			background: linear-gradient(to bottom, #fffcfc 50%, #eee9e5 50%);
		}
	}
	// E : BTOCSITE-74089 [냉장고] 옵션 컬러칩 추가요청
	// S : BTOCSITE-76845 [PDP옵션] 식기세척기 색상 등록 요청
	&.nature_essence_white {
		& input + label:before {
			background: #F0F0F0;
		}
	}
	// E : BTOCSITE-76845 [PDP옵션] 식기세척기 색상 등록 요청
	// S : BTOCSITE-77636 냉장고 신규 컬러칩 생성 요청_옵션등록
	&.cream_sky_essence_white {
		& input + label:before {
			background: linear-gradient(to bottom, #e9eaee 50%, #f5f5f7 50%);
		}
	}
	// E : BTOCSITE-77636 냉장고 신규 컬러칩 생성 요청_옵션등록
	// S : BTOCSITE-80609 신규 컬러칩 요청
	&.cream_white_beige {
		& input + label::before {
			background: linear-gradient(to bottom, #eee9e5 50%, #fffcfc 50%);
		}
	}
	// E : BTOCSITE-80609 신규 컬러칩 요청
	// S : BTOCSITE-87130 신규 컬러칩 생성 요청
	&.titan-blue {
		& input + label:before {
			background: #434853;
		}
	}
	&.meta-gray {
		& input + label:before {
			background: #545453;
		}
	}
	// E : BTOCSITE-87130 신규 컬러칩 생성 요청
	// S : BTOCSITE-89455 PLP 컬러칩 노출 오류
	&.navy_navy {
		& input + label:before {
			background:#4c505c;
		}
	}
	// E : BTOCSITE-89455 PLP 컬러칩 노출 오류
	// S : BTOCSITE-89677 PLP 컬러칩 확인
	&.Essence.White_Essence.White {
		& input + label:before {
			background: linear-gradient(to bottom, #f3f3f5 50%, #f3f3f5 50%);
		}	
	}
	&.objet_taupe_taupe {
		& input + label:before {
			background: linear-gradient(to bottom, #e9e2dc 50%, #e9e2dc 50%);
		}	
	}
	&.objet_taupe_amond {
		& input + label:before {
			background: linear-gradient(to bottom, #e9e2dc 50%, #ccb8af 50%);
		}	
	}
	&.objet_amond_taupe {
		& input + label:before {
			background: linear-gradient(to bottom, #ccb8af 50%, #e9e2dc 50%);
		}	
	}
	&.objet_cream_gray_essence_white {
		& input + label:before {
			background: linear-gradient(to bottom, #cdcdcd 50%, #f3f3f5 50%);
		}	
	}
	&.objet_navy_essece.white {
		& input + label:before {
			background: linear-gradient(to bottom, #4c505c 50%, #f3f3f5 50%)!important;
			border:none!important;
		}	
	}
	&.Mist.Essence.White {
		& input + label:before {
			background: #f7f7f7;
		}	
	}
	&.cristal-pink {
		& input + label:before {
			background: #FBE7E4;
		}	
	}
	&.cristal-beige {
		& input + label:before {
			background: #F6EDE4;
		}	
	}
	&.mist-redwood {
		& input + label:before {
			background: #985856;
		}	
	}
	&.black-cream_gray {
		& input + label:before {
			background: linear-gradient(to bottom, #3C3B40 50%, #CFCFD1 50%);
		}	
	}
	&.nature-green_nature-beige {
		& input + label:before {
			background: linear-gradient(to bottom, #42514c 50%, #dad2ca 50%);
		}	
	}
	&.nature-beige_nature-beige {
		& input + label:before {
			background: linear-gradient(to bottom, #dad2ca 50%, #dad2ca 50%);
		}	
	}
	&.nature-green_nature-green {
		& input + label:before {
			background: linear-gradient(to bottom, #42514c 50%, #42514c 50%);
		}	
	}
	&.objet_cream_gray {
		& input + label:before {
			background: #d4d4d6;
		}	
	}
	&.dark-graphite {
		& input + label:before {
			background: #969698;
		}	
	}
	// E : BTOCSITE-89677 PLP 컬러칩 확인
	//[S] BTOCSITE-4224 PLP,PDP 시블링
	//[S] BTOCSITE-10889 컬러칩 샘플 추가
	//objet_botanic: -96px -240px, 보타닉 컬러는 기존 선언된 class 와 중복되어 기존소스로 사용하는것으로 함.
	//BTOCSITE-11771 오브제컬렉션 체험 신규 컬러 추가 요청
	//BTOCSITE-17400
	//BTOCSITE-19435 [2.0 오픈] 시블링 컬러칩 2개 추가 건  (BTOCSITE-18303 포함)
	//BTOCSITE-20340 시블링 컬러칩 추가 요청
	$colorChipData: (
		av_charcoal-black: -48px -216px,
		av_pearl-white: -72px -216px,
		av_blue-black: -96px -216px,
		av_silver-white: -120px -216px,

		av_berry-blue: 0 -240px,
		av_bubble-black: -24px -240px,
		av_sour-lemon: -48px -240px,
		fridge_manhattan-midnight: -72px -240px,
		objet_Stone: -120px -240px,

		kimchi_shinee-pure: 0 -264px,
		oven_shinee-pure-silver: -24px -264px,
		oven_pure-black: -48px -264px,
		washing_black-stainless: -72px -264px,
		washing_modern-stainless: -96px -264px,
		washing_middle-black: -120px -264px,

		objet_calm-beige_calm-green: 0 -288px,
		objet_calm-green_calm-beige: -24px -288px,
		objet_calm-beige_Iron-gray: -48px -288px,
		objet_calm-green_Iron-gray: -72px -288px,
		objet_calm-beige_fantasy-silver: -96px -288px,
		objet_calm-green_fantasy-silver: -120px -288px,

		objet_calm-beige_black: 0 -312px,
		objet_calm-green_black: -24px -312px,
		air_milktea-latte: -48px -312px,
		air_new-metal-shine: -72px -312px,
		air_romantic-rose: -96px -312px,
		air_dark-blue: -120px -312px,

		air_rose-gold: 0 -336px,
		silver_manhattan: -24px -336px,
		kvadrat_beige: -48px -336px,
		kvadrat_redwood: -72px -336px,
		kvadrat_green: -96px -336px,
		stone_claypink_silver: -120px -336px,

		objet_calming_cottonsky: 0 -360px,
		objet_calming_palerose: -24px -360px,
		water-purifiers_navy: -48px -360px,
		water-purifiers_solid_black: -72px -360px,
		water-purifiers_solid_beige: -96px -360px,
		water-purifiers_solid_claybrown: -120px -360px,

		water-purifiers_solid_white: 0 -384px,
		washing_stainless_silver: -24px -384px,
		objet_calming_brown: -48px -384px,
		objet_cream_white: -72px -384px,
		objet_fabric_beige: -96px -384px,
		air-purifier_milktealatte: -120px -384px,

		objet_nature_redwood: 0 -408px,
		objet_nature_cream_white: -24px -408px,
		objet_nature_claybrown: -48px -408px,
		gold: -72px -408px,
		lavender: -96px -408px,
		air-purifier_mint: -120px -408px,

		mango: 0 -432px,
		objet_nature_claypink: -24px -432px,
		objet_mistpink: -48px -432px,
		smog_black: -72px -432px,
		noble_silver: -96px -432px,
		dishwashers_pure: -120px -432px,

		objet_claymint: 0 -456px,
		shiny_beige: -24px -456px,
		vacuum_bohemian_red: -48px -456px,
		objet_botanic_real: -72px -456px,
		objet_topaz_green:-120px -456px,

		objet_lavender_punch:0 -480px,
		objet_charcoal_gray:-24px -480px,
		objet_peach_pink: -48px -480px,
		objet_stone_silver: -72px -480px,
		clay_pink_beige:-96px -480px,
		clay_mint_beige:-120px -480px,

		cream_sky_white:0 -504px,
		claypink_silver: -24px -504px,
		creamwhite_creamwhite: -48px -504px,
		gram_aurora-white: -72px -504px,
		c_white: -96px -504px,
		c_silver: -120px -504px,

		c_stainless: 0 -528px,
		c_gray: -24px -528px,
		c_black: -48px -528px,
		c_pink: -72px -528px,
		c_red: -96px -528px,
		c_beige: -120px -528px,

		c_sand: 0 -552px,
		c_yellow: -24px -552px,
		c_brown: -48px -552px,
		c_stone: -72px -552px,
		c_mint: -96px -552px,
		c_botanic: -120px -552px,

		c_green: 0 -576px,
		c_blue: -24px -576px,
		c_navy: -48px -576px,
		washing_glossy-white: -72px -576px,
		washing_lily-white: -96px -576px,
		washing_space-black: -120px -576px,

		washtower_platinum-black: 0 -600px,
		washtower_lily-white_lily-white: -24px -600px,
		washtower_nature-beige_nature-clay-brown: -48px -600px,
		washtower_nature-beige_nature-clay-mint: -72px -600px,
		washtower_nature-clay-brown_nature-beige: -96px -600px,
		washtower_nature-clay-brown_nature-clay-brown: -120px -600px,

		washtower_nature-clay-brown_nature-clay-mint: 0 -624px,
		washtower_nature-clay-brown_nature-green: -24px -624px,
		washtower_nature-clay-mint_nature-beige: -48px -624px,
		washtower_nature-clay-mint_nature-clay-brown: -72px -624px,
		washtower_nature-clay-mint_nature-clay-mint: -96px -624px,
		washtower_nature-clay-mint_nature-green: -120px -624px,

		washtower_nature-green_nature-clay-brown: 0 -648px,
		washtower_nature-green_nature-clay-mint: -24px -648px,
		washtower_space-black-space-black: -48px -648px,
		washtower_modern-stainless_modern-stainless: -72px -648px,
		essence_white: -96px -648px,
		essence_graphite: -120px -648px,

		cream_yellow: 0 -672px,
		cream_rose: -24px -672px,
		c_gold: -48px -672px,
		c_purple: -72px -672px,
		washtower_glossy-white: -96px -672px,
	);

	@each $color, $bgPosition in $colorChipData {
		&.#{$color} {
			& input + label:before {
				background-position: $bgPosition;
			}
		}
	}

	//선택 시 검은색 화살표 적용 컬러칩 (popup.scss의 spec 레이어와 싱크필요)
	$activeBlackArrowData: (av_pearl-white, av_silver-white, av_berry-blue, av_bubble-black, av_sour-lemon, snow_white, white, white_white, water-purifiers_solid_beige, water-purifiers_solid_white, objet_cream_white, objet_nature_cream_white, objet_fabric_beige, mist-essence-white);
	@each $color in $activeBlackArrowData {
		&.#{$color} {
			& input:checked + label:after {
				border-bottom: 2px solid #000;
				border-left: 2px solid #000;
			}
		}
	}
	//[E] BTOCSITE-4224 PLP,PDP 시블링
	//[E] BTOCSITE-10889 컬러칩 샘플 추가
}

// [BTOCSITE-33608] 차세대 구독 컬러칩 추가[검색]
.colorchip-wrap {
	.chk-wrap-colorchip {
		&.objet_pink_1 {
			&::before {
				background: #e6c5c1;
			}
		}
		&.objet_brown {
			&::before {
				background: #c8b2a6;
			}
		}
		&.objet_pink_brown {
			&::before {
				background: linear-gradient(to bottom, #e6c5c1 50%, #c8b2a6 50%);
			}
		}
		&.objet_brown_botanic {
			&::before {
				background: linear-gradient(to bottom, #c8b2a6 50%, #eEe9e5 50%);
			}
		}
		&.objet_cream_lavender {
			&::before {
				background: #c1b8cc;
			}
		}
		&.objet_cream_peach {
			&::before {
				background: #e1a990;
			}
		}
		&.objet_cream_lemon {
			&::before {
				background: #dfd298;
			}
		}
		&.objet_cream_sky {
			&::before {
				background: #e4e8ec;
			}
		}
		&.blue {
			&::before {
				background: #102948;
			}
		}
		&.objet_botanic {
			&::before {
				background: #eee9e5;
			}
		}
		&.calming-beige {
			&::before {
				background: #dfdad4;
			}
		}
		&.objet_cream_white {
			&::before {
				background: #f5f3f1;
			}
		}
		&.objet_green {
			&::before {
				background: #293C2E;
			}
		}
		&.objet_grey {
			&::before {
				background: #cdccc7;
			}
		}
		&.objet_pink {
			&::before {
				background: #e3c3c0;
			}
		}
		&.pink_beige {
			&::before {
				background: linear-gradient(to bottom, #e6c4c2 50%, #eee9e5 50%);
			}
		}
		&.white {
			&::before {
				background: #fff;
			}
		}
		&.objet_botanic1 {
			&::before {
				background: #eee9e5;
			}
		}
		&.clay_brown {
			&::before {
				background: #c8b2a6;
			}
		}
		&.dark_gray {
			&::before {
				background: #414146;
			}
		}
		&.storm_gray {
			&::before {
				background: #6e7d9c;
			}
		}
		&.nature-beige {
			&::before {
				background:#dad2ca;
			}
		}
		
		&.nature-green {
			&::before {
				background:#4c5351;
			}
		}
		&.navy_cream-gray {
			&::before {
				background: linear-gradient(to bottom, #3a4151 50%, #dad9db 50%);
			}
		}
		&.manhattan_manhattan {
			&::before {
				background: linear-gradient(to bottom, #414146 50%, #414146 50%);
			}
		}
		&.beige_claybrown {
			&::before {
				background: linear-gradient(to bottom, #eee9e5 50%, #c8b2a6 50%);
			}
		}
		&.cream-gray_cream-white {
			&::before {
				background: linear-gradient(to bottom, #dad9db 50%, #fffcfc 50%);
			}
		}
		&.cream-remon_cream-white {
			&::before {
				background: linear-gradient(to bottom, #dfd298 50%, #fffcfc 50%);
			}
		}
		&.cream-sky_cream-white {
			&::before {
				background: linear-gradient(to bottom, #e4e8ec 50%, #fffcfc 50%);
			}
		}
		&.cream-peach_beige {
			&::before {
				background: linear-gradient(to bottom, #e1a990 50%, #eee9e5 50%);
			}
		}
		&.cream-white_navy {
			&::before {
				background: linear-gradient(to bottom, #fffcfc 50%, #3a4151 50%);
			}
		}
		&.navy_cream-white {
			&::before {
				background: linear-gradient(to bottom, #3a4151 50%, #fffcfc 50%);
			}
		}
		&.cream-white_cream-gray {
			&::before {
				background: linear-gradient(to bottom, #fffcfc 50%, #dad9db 50%);
			}
		}
		&.creamwhite_creamwhite {
			&::before {
				background: linear-gradient(to bottom, #fffcfc 50%, #fffcfc 50%);
			}
		}
		&.claybrown_beige {
			&::before {
				background: linear-gradient(to bottom, #c8b2a6 50%, #eee9e5 50%);
			}
		}
		&.claybrown_claybrown {
			&::before {
				background: linear-gradient(to bottom, #c8b2a6 50%, #c8b2a6 50%);
			}
		}
		&.pink_claybrown {
			&::before {
				background: linear-gradient(to bottom, #e6c5c1 50%, #c8b2a6 50%);
			}
		}
		&.pink_pink {
			&::before {
				background: linear-gradient(to bottom, #e6c5c1 50%, #e6c5c1 50%);
			}
		}
		&.claybrown,
		&.clay_brown {
			&::before {
				background: #c8b2a6;
			}
		}
		&.objet_claypink {
			&::before {
				background: #9a8786;
			}
		}
		&.prime-silver {
			&::before {
				background: #bfc0c6;
			}
		}
		&.nature-beige_green {
			&::before {
				background: linear-gradient(to bottom, #e9e3e1 50%, #415254 50%);
			}
		}
		&.nature-green_beige {
			&::before {
				background: linear-gradient(to bottom, #42514c 50%, #eae4df 50%);
			}
		}
		&.manhattan_silver {
			&::before {
				background: linear-gradient(to bottom, #32373a 50%, #b3b4b8 50%);
			}
		}
		&.essence_white {
			&::before {
				background: #f7f6f6;
			}
		}
		&.essence_graphite {
			&::before {
				background: #434447;
			}
		}
		&.cream_yellow {
			&::before {
				background: #edd373;
			}
		}
		&.cream_rose {
			&::before {
				background: #d85e66;
			}
		}
		&.c_gold {
			&::before {
				background: #c1a78e;
			}
		}
		&.c_purple {
			&::before {
				background: #c4b6d4;
			}
		}
		&.washtower_glossy-white {
			&::before {
				background: #f0f0f2;
			}
		}
		&.topaz_green {
			&::before {
				background: #b8c0ac;
			}
		}
		&.neptune-blue {
			&::before {
				background: #474a53;
			}
		}
		&.stone_silver {
			&::before {
				background: #dfdfe1;
			}
		}
		&.nature-manhattan-midnight {
			&::before {
				background: #414146;
			}
		}
		// BTOCSITE-63071 [PLP] 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		&.mist-clay-brown {
			&::before {
				background: #d1c7bd ;
			}
		}
		// BTOCSITE-57327 워시타워 컬러칩 추가
		&.nature_cream_gray-nature_green {
			&::before {
				background: linear-gradient(to bottom, #d2cfd6 50%, #3a4848 50%);
			}
		}
		&.nature_navy-nature_green {
			&::before {
				background: linear-gradient(to bottom, #2f375b 50%, #3a4848 50%);
			}
		}
		&.nature-beige_nature-green {
			&::before {
				background: linear-gradient(to bottom, #eeede9 50%, #3a4848 50%);
			}
		}
		&.nature_cream_gray-nature_beige {
			&::before {
				background: linear-gradient(to bottom, #d2cfd6 50%, #eeede9 50%);
			}
		}
		&.nature_navy-nature_beige {
			&::before {
				background: linear-gradient(to bottom, #2f375b 50%, #eeede9 50%);
			}
		}
		&.nature_navy-nature_cream_gray {
			&::before {
				background: linear-gradient(to bottom, #2f375b 50%, #d2cfd6 50%);
			}
		}
		&.nature_beige-nature_cream_gray {
			&::before {
				background: linear-gradient(to bottom, #eeede9 50%, #d2cfd6 50%);
			}
		}
		&.nature_cream_gray-nature_cream_gray {
			&::before {
				background: linear-gradient(to bottom, #d2cfd6 50%, #d2cfd6 50%);
			}
		}
		&.nature_green-nature_cream_gray {
			&::before {
				background: linear-gradient(to bottom, #3a4848 50%, #d2cfd6 50%);
			}
		}
		&.nature_cream_gray-nature_navy {
			&::before {
				background: linear-gradient(to bottom, #d2cfd6 50%, #2f375b 50%);
			}
		}
		&.nature_navy-nature_navy {
			&::before {
				background: linear-gradient(to bottom, #2f375b 50%, #2f375b 50%);
			}
		}
		&.nature_green-nature_navy {
			&::before {
				background: linear-gradient(to bottom, #3a4848 50%, #2f375b 50%);
			}
		}
		&.nature_beige-nature_navy {
			&::before {
				background: linear-gradient(to bottom, #eeede9 50%, #2f375b 50%);
			}
		}
		// BTOCSITE-59152 시블링코드 생성 요청
		&.objet_cream_lavender-objet_cream_lavender {
			&::before {
				background: linear-gradient(to bottom, #c1b8cc 50%, #c1b8cc 50%);
			}
		}
		&.objet_cream_peach-objet_cream_peach {
			&::before {
				background: linear-gradient(to bottom, #e1a990 50%, #e1a990 50%);
			}
		}
		&.objet_cream_lemon-objet_cream_lemon {
			&::before {
				background: linear-gradient(to bottom, #dfd298 50%, #dfd298 50%);
			}
		}
		&.objet_cream_sky-objet_cream_sky {
			&::before {
				background: linear-gradient(to bottom, #e4e8ec 50%, #e4e8ec 50%);
			}
		}
		// S : BTOCSITE-63392 [광파오븐] 옵션 컬러칩 추가 요청
		&.calming_clay_mint {
			&::before {
				background: #7ca6b4;
			}
		}
		&.calming-pink {
			&::before {
				background: #f0ddd9;
			}
		}
		&.calming_essence_white {
			&::before {
				background: #f6f8f5;
			}
		}
		// E : BTOCSITE-63392 [광파오븐] 옵션 컬러칩 추가 요청
		// S : BTOCSITE-64221 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		&.linen-brown {
			&::before {
				background: #372c26;
			}
		}
		&.linen-ivory {
			&::before {
				background: #eae6de; // BTOCSITE-65530 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
			}
		}
		// E : BTOCSITE-64221 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		// S : BTOCSITE-65530 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		&.linen-white {
			&::before {
				background: #ffffff;
			}
		}
		// E : BTOCSITE-65530 스타일러 옵션 묶음 관련 (색상 등록 수정 요청)
		// S : BTOCSITE-67869 [정수기] 옵션 컬러칩 추가 요청
		&.calming-cream-grey {
			&::before {
				background: #CCCBD3;
			}
		}
		&.calming-cream-white {
			&::before {
				background: #F3F3F3;
			}
		}
		&.calming-pebble-gray {
			&::before {
				background: #D4D4D4;
			}
		}
		&.calming-cream-sky {
			&::before {
				background: #DADEE7;
			}
		}
		// E : BTOCSITE-67869 [정수기] 옵션 컬러칩 추가 요청
		// S : BTOCSITE-68703 [PLP] 안마의자 및 청소기 신규 색상 등록 요청 (판매 옵션)
		&.italian-red {
			&::before {
				background: #b21528;
			}
		}
		&.cozy-beige {
			&::before {
				background: #f1f0ee;
			}
		}
		&.cozy-brown {
			&::before {
				background: #dad1c0;
			}
		}
		// E : BTOCSITE-68703 [PLP] 안마의자 및 청소기 신규 색상 등록 요청 (판매 옵션)
		// S : BTOCSITE-69423 [PLP] 청소기 신규 색상 등록 요청
		&.cream-yellow {
			&::before {
				background: #eee1ad;
			}
		}
		// E : BTOCSITE-69423 [PLP] 청소기 신규 색상 등록 요청
		// S : BTOCSITE-72607 [PDP옵션] 전기레인지 색상 등록 요청
		&.mist-essence-white {
			&::before {
				background: #ececea;
			}
		}
		// E : BTOCSITE-72607 [PDP옵션] 전기레인지 색상 등록 요청
		// S : BTOCSITE-73491 [정수기] 옵션 컬러칩 추가요청
		&.mist-cream-sky {
			&::before {
				background: #c6c7cc
			}
		}
		&.mist-cream-white {
			&::before {
				background: #dedede
			}
		}
		&.calming_black {
			&::before {
				background: #0b0b0d;
			}
		}
		// E : BTOCSITE-73491 [정수기] 옵션 컬러칩 추가요청
		// S : BTOCSITE-74089 [냉장고] 옵션 컬러칩 추가요청
		&.beige_cream_white {
			&::before {
				background: linear-gradient(to bottom, #fffcfc 50%, #eee9e5 50%);
			}
		}
		// E : BTOCSITE-74089 [냉장고] 옵션 컬러칩 추가요청
		// S : BTOCSITE-76845 [PDP옵션] 식기세척기 색상 등록 요청
		&.nature_essence_white {
			&::before {
				background: #F0F0F0;
			}
		}
		// E : BTOCSITE-76845 [PDP옵션] 식기세척기 색상 등록 요청
		// S : BTOCSITE-77636 냉장고 신규 컬러칩 생성 요청_옵션등록
		&.cream_sky_essence_white {
			&::before {
				background: linear-gradient(to bottom, #e9eaee 50%, #f5f5f7 50%);
			}
		}
		// E : BTOCSITE-77636 냉장고 신규 컬러칩 생성 요청_옵션등록
		// S : BTOCSITE-80609 신규 컬러칩 요청
		&.cream_white_beige {
			&::before {
				background: linear-gradient(to bottom, #eee9e5 50%, #fffcfc 50%);
			}
		}
		// E : BTOCSITE-80609 신규 컬러칩 요청
		// S : BTOCSITE-87130 신규 컬러칩 생성 요청
		&.titan-blue {
			& input + label:before {
				background: #434853;
			}
		}
		&.meta-gray {
			& input + label:before {
				background: #545453;
			}
		}
		// E : BTOCSITE-87130 신규 컬러칩 생성 요청
		// S : BTOCSITE-89455 PLP 컬러칩 노출 오류
		&.navy_navy {
			& input + label:before {
				background:#4c505c;
			}
		}
		// E : BTOCSITE-89455 PLP 컬러칩 노출 오류
		// S : BTOCSITE-89677 PLP 컬러칩 확인
		&.Essence.White_Essence.White {
			& input + label:before {
				background: linear-gradient(to bottom, #f3f3f5 50%, #f3f3f5 50%);
			}	
		}
		&.objet_taupe_taupe {
			& input + label:before {
				background: linear-gradient(to bottom, #e9e2dc 50%, #e9e2dc 50%);
			}	
		}
		&.objet_taupe_amond {
			& input + label:before {
				background: linear-gradient(to bottom, #e9e2dc 50%, #ccb8af 50%);
			}	
		}
		&.objet_amond_taupe {
			& input + label:before {
				background: linear-gradient(to bottom, #ccb8af 50%, #e9e2dc 50%);
			}	
		}
		&.objet_cream_gray_essence_white {
			& input + label:before {
				background: linear-gradient(to bottom, #cdcdcd 50%, #f3f3f5 50%);
			}	
		}
		&.objet_navy_essece.white {
			& input + label:before {
				background: linear-gradient(to bottom, #4c505c 50%, #f3f3f5 50%)!important;
				border:none!important;
			}	
		}
		&.Mist.Essence.White {
			& input + label:before {
				background: #f7f7f7;
			}	
		}
		&.cristal-pink {
			& input + label:before {
				background: #FBE7E4;
			}	
		}
		&.cristal-beige {
			& input + label:before {
				background: #F6EDE4;
			}	
		}
		&.mist-redwood {
			& input + label:before {
				background: #985856;
			}	
		}
		&.black-cream_gray {
			& input + label:before {
				background: linear-gradient(to bottom, #3C3B40 50%, #CFCFD1 50%);
			}	
		}
		&.nature-green_nature-beige {
			& input + label:before {
				background: linear-gradient(to bottom, #42514c 50%, #dad2ca 50%);
			}	
		}
		&.nature-beige_nature-beige {
			& input + label:before {
				background: linear-gradient(to bottom, #dad2ca 50%, #dad2ca 50%);
			}	
		}
		&.nature-green_nature-green {
			& input + label:before {
				background: linear-gradient(to bottom, #42514c 50%, #42514c 50%);
			}	
		}
		&.objet_cream_gray {
			& input + label:before {
				background: #d4d4d6;
			}	
		}
		&.dark-graphite {
			& input + label:before {
				background: #969698;
			}	
		}
		// E : BTOCSITE-89677 PLP 컬러칩 확인
	}
}