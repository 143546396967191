// Accordion
.accordion-wrap {
    > ul {
        > .lists {
            position: relative;
            margin-top: 8px;
            border-radius: 4px;
            background: #fff;
            z-index: 1;
            @include mobile {
                margin-top: 4px;
                border-radius: 0;
            }
            &:first-child {
                margin-top: 0;
            }
            .head {
                .accord-btn {
                    display: flex;
                    position: relative;
                    padding: 32px 136px 32px 56px;
                    font-size: 0;
                    @include mobile {
                        padding: 18px 56px 18px 15px;
                    }
                    &::after {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        right: 62px;
                        width: 0;
                        height: 0;
                        margin-top: -4px;
                        border-top: 6px solid #979797;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        transition: border-color 0.3s, transform 0.3s;
                        @include mobile {
                            right: 27px;
                        }
                    }
                    .flag {
                        display: inline-block;
                        height: 24px;
                        margin-right: 12px;
                        padding: 0 7px;
                        border: 1px solid $color-primary;
                        border-radius: 4px;
                        color: $color-primary;
                        font-size: 12px;
                        line-height: 22px;
                        white-space: nowrap;
                        vertical-align: top;
                    }
                    .subtitle {
                        margin-right: 10px;
                        color: #000;
                        font-size: 16px;
                        line-height: 24px;
                        strong {
                            font-weight: normal;
                        }
                    }
                    .title {
                        display: inline-block;
                        color: #000;
                        font-size: 16px;
                        line-height: 24px;
                        @include mobile {
                            font-weight: 500;
                        }

                        @for $i from 1 through 5 {
                            &.line#{$i} {
                                max-height: calc(24px * #{$i});
                                @include textEllipsisClamp($i);
                            }
                        }
                    }
                }
            }
            .accord-cont {
                padding: 0 136px 32px 56px;
                color: #616161;
                font-size: 16px;
                line-height: 24px;
                @include mobile {
                    padding: 8px 20px 32px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            &.on {
                box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.16);
                z-index: 5;
                .accord-btn:after {
                    border-top-color: #222;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

// Accordion border-type
.accordion-border-wrap {
    > ul {
        > .lists {
            position: relative;
            background: #fff;
            border-bottom: 1px solid #ddd;
            z-index: 1;
            &:first-child {
                border-top: 1px solid #ddd;
            }
            .head {
                .accord-btn {
                    display: flex;
                    position: relative;
                    padding: 24px 136px 24px 24px;
                    font-size: 0;
                    @include mobile {
                        padding: 18px 56px 18px 15px;
                    }
                    &::after {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        right: 48px;
                        width: 24px;
                        height: 24px;
                        margin-top: -12px;
                        background: url("/lg5-common/images/icons/btn-arr-24x24-black.svg") no-repeat 0 0;
                        background-size: 24px;
                        transform: rotate(-270deg);
                        /*
                        border-top:6px solid #222;
                        border-left:5px solid transparent;
                        border-right:5px solid transparent;
                        transition:border-color .3s, transform .3s;
                        */
                        @include mobile {
                            right: 27px;
                        }
                    }
                    .subtitle {
                        margin-right: 10px;
                        color: #616161;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .title {
                        display: inline-block;
                        color: #000;
                        font-size: 16px;
                        line-height: 24px;
                        @include mobile {
                            font-weight: 500;
                        }
                        @for $i from 1 through 5 {
                            &.line#{$i} {
                                max-height: calc(24px * #{$i});
                                @include textEllipsisClamp($i);
                            }
                        }
                    }
                }
            }
            .accord-cont {
                padding: 24px;
                color: #616161;
                font-size: 16px;
                line-height: 24px;
                @include mobile {
                    padding: 8px 20px 24px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            &.on {
                z-index: 5;
                background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                .accord-btn:after {
                    transform: rotate(-90deg);
                }
            }
        }
    }
    &.bg-white {
        > ul {
            > .lists {
                border-bottom: 1px solid #666;
                &:first-child {
                    border-top: 1px solid #666;
                }
                &.on {
                    background-color: #fff;
                    .head {
                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    }
                }
            }
        }
    }
    &.bg-type {
        > ul {
            > .lists {
                .head {
                    .accord-btn {
                        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        .title {
                            font-weight: 700;
                        }
                        @include mobile {
                            padding: 24px 56px 24px 8px;
                        }
                    }
                }
                .accord-cont {
                    padding: 32px 48px;
                    @include mobile {
                        padding: 32px 8px;
                    }
                }
            }
        }
    }
}

.accordion-section {
    > ul {
        .lists {
            padding-bottom: 120px;
            &:last-child {
                padding-bottom: 0;
            }
            &.on .accord-btn:after {
                transform: rotate(180deg);
            }
        }
    }
    .head {
        border-bottom: 2px solid #222;
        .accord-btn {
            display: flex;
            position: relative;
            padding-bottom: 16px;
            font-size: 0;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 32px;
                height: 32px;
                margin-top: -24px;
                background: url("/lg5-common/images/icons/icon-arrow-accor.svg") no-repeat 0 0;
            }
        }
        .accord-btn.type-none {
            &::after {
                content: none;
            }
        }
        .title {
            font-weight: 700;
            // @include font($font-large-4,52px);
            @include font-large-3;
        }
        .title.type2 {
            @include font-large-1;
        }
    }
    .accord-cont {
        padding-top: 8px;
    }

    @include mobile {
        > ul {
            .lists {
                border-top: 12px solid $color-bg;
                padding: 60px 16px 18px;
                &:last-child {
                    padding-bottom: 16px;
                }
            }
        }
        .head {
            border-bottom: 0;
            &.btn_close {
                border-bottom: 2px solid $color-default;
            }
            .accord-btn {
                padding-bottom: 14px;
                &::after {
                    right: 0; /* 0331 수정 7px */
                    width: 24px;
                    height: 24px;
                    margin-top: -19px;
                    background-size: 100% 100%;
                }
            }
            .title {
                font-size: $font-large;
                line-height: 27px;
            }
        }
        .head.type2 {
            border-bottom: 2px solid #222;
        }
        .accord-cont {
            padding-bottom: 42px;
        }
    }
}
