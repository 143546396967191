/*  footer */
footer {
    padding:0 40px 48px;
    background:#f4f4f4;
    @media screen and (max-width:1200px) {
        padding-bottom:66px;
    }
    /* 20210601 : BTOCSITE-874 추가 */
    @include pc {
        .pc-only-ib{display: inline-block;}
    }
    @include mobile {
        .pc-only-ib{display: none;}
        padding: 0 0 60px; //BTOCSITE-26403 LGE.COM 하단 메뉴영역 삭제 요청
    }
    /* 20210601 : BTOCSITE-874 추가 */

    // -S- BTOCSITE-5938-204 푸터 하단 가리는 현상 수정
    &.default-bottom-wrap {
        padding-bottom: 130px;
        @include mobile {
            padding-bottom: 230px;
        }
    }
    // -E- BTOCSITE-5938-204 푸터 하단 가리는 현상 수정


    // footer cont
    .foot-cont {
        .cont-area {
            max-width:1284px;
            margin:0 auto;
        }

        // link-wrap - Mobile
        @media screen and (max-width:767px) {
            .link-wrap {
                .link-section {
                    border-bottom:1px solid #ddd;
                    .dep1 {
                        position:relative;
                        padding:0 16px; //BTOCSITE-16830	웹접근성 확인 요청_ LGE.com(렌탈케어십 신청/조회, 출장서비스 신청/조회) 접근성 이슈 검토 요청padding:0 52px 0 16px;
                        &::after {
                            content:' ';
                            position:absolute;
                            right:16px;
                            top:50%;
                            width:16px;
                            height:16px;
                            margin-top:-10px;
                            background:url('/lg5-common/images/icons/btn-arr-16-black.svg') center no-repeat;
                            background-size:100%;
                            transform: rotate(90deg);
                            transition:all .3s;
                        }
                        a, span {
                            display:block;
                            padding:17px 0;
                            color:#000;
                            font-size:13px;
                            line-height:21px;
                            font-weight:700;
                        }
                        a[target="_blank"] {
                            white-space:nowrap;
                            &::after {
                                content:' ';
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin-left:4px;
                                background:url('/lg5-common/images/icons/btn-outlink-16.svg') center no-repeat;
                                background-size:100%;
                                vertical-align:-3px;
                            }
                        }
                        &.hidden {
                            display:none !important;
                        }
                        &.dep1-only {
                            &::after {
                                display:none;
                            }
                        }
                    }
                    &.on {
                        .dep1::after {
                            transform:rotate(270deg);
                        }
                        .dep1
                            a {
                                //text-decoration:underline;
                            }
                    }
                    &:first-child {
                        .dep1 {
                            border-top:none;
                        }
                    }

                    .dep2-wrap {
                        padding:0 16px;
                        border-top:1px solid #ddd;
                        .dep2-list {
                            border-top:1px solid #ddd;
                            &:first-child {
                                border-top:none;
                            }
                            .dep2 {
                                display:block;
                                position:relative;
                                padding-right:20px;
                                &::after {
                                    content:' ';
                                    position:absolute;
                                    top:50%;
                                    right:2px;
                                    width:16px;
                                    height:16px;
                                    margin-top:-8px;
                                    background:url('/lg5-common/images/icons/btn-plus-16.svg') center no-repeat;
                                    background-size:100%;
                                    transition:all 0.3s;
                                }
                                &:only-child {
                                    &::after {
                                        display:none;
                                    }
                                }
                                a, span {
                                    display:block;
                                    padding:12px 0;
                                    color:#000;
                                    font-size:11px;
                                    line-height:19px;
                                    font-weight:500;
                                }
                                a {
                                    &[target="_blank"] {
                                        white-space:nowrap;
                                        &::after {
                                            content:' ';
                                            display:inline-block;
                                            width:16px;
                                            height:16px;
                                            margin-left:4px;
                                            background:url('/lg5-common/images/icons/icon-blank-arr2.svg') center no-repeat;
                                            background-size:100%;
                                            vertical-align:top;
                                        }
                                    }
                                }
                            }
                            &.on {
                                .dep2 {
                                    &::after {
                                        transform:rotate(135deg);
                                    }
                                    a {
                                        //text-decoration:underline;
                                    }
                                }
                            }
                        }
                    }
                    .hidden + .dep2-wrap {
                        margin-top:-16px;
                        border-top:none;
                    }

                    .dep3-wrap {
                        padding:16px 0;
                        border-top:1px solid #ddd;
                        > li {
                            margin-top:12px;
                            &:first-child {
                                margin-top:0;
                            }
                        }
                        .dep3 {
                            display:block;
                            //padding:0 12px;
                            color:#767676;
                            font-size:11px;
                            line-height:19px;
                            //font-weight:500;
                            &[target="_blank"] {
                                white-space:nowrap;
                                &::after {
                                    content:' ';
                                    display:inline-block;
                                    width:16px;
                                    height:16px;
                                    margin-left:4px;
                                    background:url('/lg5-common/images/icons/icon-blank-arr3.svg') center no-repeat;
                                    background-size:100%;
                                    vertical-align:top;
                                }
                            }
                        }
                    }
                }
            }
            // BTOCSITE-20229 OBSA 심플 푸터
            &.foot-cont-shop {
                .cont-area .foot-bottom .bottom-body {
                    padding: 0 0 70px;
                    border-top: 0;
                }
            }
        }
        // link-wrap - PC
        @media screen and (min-width:768px) {
            .pc-dropdown-wrap {
                display:none; //BTOCSITE-26403 LGE.COM 하단 메뉴영역 삭제 요청
                position:relative;
                padding-bottom:136px;
                .btns {
                    position:absolute;
                    bottom:0;
                    right:0;
                    left:0;
                    padding:48px 0;
                    text-align:center;
                    .btn.border {
                        &::after {
                            content:' ';
                            display:inline-block;
                            width:16px;
                            height:16px;
                            margin-left:4px;
                            margin-top:4px;
                            background:url('/lg5-common/images/icons/icon-arr-footer-16.svg') center no-repeat;
                            vertical-align:top;
                            transition:all 0.3s;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            border-color:#000 !important;
                            background-color: transparent;
                            color: #000;
                        }
                    }
                }
                &.open {
                    .btns {
                        padding-top: 6px;
                        .btn.border::after {
                            transform:rotate(180deg);
                        }
                    }
                }
                .link-wrap {
                    display:none;
                }
                &.open {
                    .link-wrap {
                        display:block;
                    }
                }
            }
            .link-wrap {
                margin:-12px -6px -20px;
                padding-bottom:16px;
                font-size:0;
                .link-section {
                    display:inline-block;
                    width:12.5%;
                    margin-top:48px;
                    padding:0 6px;
                    vertical-align:top;
                    @media screen and (max-width:1200px) {
                        width:25%;
                    }
                    div.dep1 {
                        height:36px;
                        margin-top:28px;
                        padding-bottom:20px;
                        a, span {
                            display:inline-block;
                            color:#000;
                            font-size:14px;
                            line-height:16px;
                            font-weight:700;
                            vertical-align:top;
                        }
                        a[target="_blank"] {
                            white-space:nowrap;
                            &::after {
                                content:' ';
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin-left:4px;
                                background:url('/lg5-common/images/icons/icon-blank-arr.svg') center no-repeat;
                                background-size:100%;
                                vertical-align:top;
                            }
                        }
                        &.hidden {
                            a, span {
                                width:0;
                                height:0;
                                font-size:0 !important;
                                overflow:hidden;
                            }
                        }
                        &:first-child {
                            margin-top:0;
                        }
                    }
                    .dep2-wrap {
                        .dep2-list {
                            margin-top:6px;
                            &:first-child {
                                margin-top:0;
                            }
                            .dep2 {
                                a, span {
                                    display:inline-block;
                                    color:#000;
                                    font-size:12px;
                                    line-height:16px;
                                    font-weight:500;
                                    vertical-align:top;
                                }
                                a {
                                    &[target="_blank"] {
                                        white-space:nowrap;
                                        &::after {
                                            content:' ';
                                            display:inline-block;
                                            width:16px;
                                            height:16px;
                                            margin-left:4px;
                                            background:url('/lg5-common/images/icons/icon-blank-arr2.svg') center no-repeat;
                                            background-size:100%;
                                            vertical-align:top;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dep3-wrap {
                        margin:6px 0 20px;
                        > li {
                            margin-top:4px;
                            &:first-child {
                                margin-top:0;
                            }
                        }
                        .dep3 {
                            display:inline-block;
                            color:#616161;
                            font-size:12px;
                            line-height:16px;
                            //font-weight:500;
                            vertical-align:top;
                            &[target="_blank"] {
                                white-space:nowrap;
                                &::after {
                                    content:' ';
                                    display:inline-block;
                                    width:16px;
                                    height:16px;
                                    margin-left:4px;
                                    background:url('/lg5-common/images/icons/icon-blank-arr3.svg') center no-repeat;
                                    background-size:100%;
                                    vertical-align:top;
                                }
                            }
                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .foot-bottom {
            @include mobile {
                padding:0 16px;
            }
            .bottom-head {
                position:relative;
                padding:16px 0;
                @include clearfix;
                .dropdown-wrap {
                    float:left;
                    .dropdowns {
                        display:inline-block;
                        position:relative;
                        margin-right:24px;
                        padding: 8px 0;
                        vertical-align:top;
                        .slt-btn {
                            display:inline-block;
                            color:#000;
                            font-size:12px;
                            line-height:16px;
                            font-weight:500;
                            vertical-align:top;
                            &::after {
                                content:' ';
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin:0 0 0 4px;
                                background:url("/lg5-common/images/icons/btn-down-16-black.svg") center no-repeat;
                                vertical-align:top;
                                transition: all .3s;
                            }
                        }
                        .lists {
                            display:none;
                            position:absolute;
                            top:33px;
                            left:50%;
                            transform:translateX(-50%);
                            z-index:10;
                            padding:4px 0;
                            border-radius:4px;
                            background:#fff;
                            box-shadow:2px 4px 12px 0 rgba(#000, .16);
                            li {
                                a {
                                    display:block;
                                    padding:0 20px;
                                    color:#000;
                                    font-size:12px;
                                    line-height:40px;
                                    text-align:center;
                                    font-weight:500;
                                    white-space:nowrap;
                                    &.selected, &:hover {
                                        background:#f7f7f7; // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                                    }
                                }
                            }
                        }
                        &.open {
                            .slt-btn {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            .lists {
                                display:block;
                            }
                        }
                    }
                }
                .sns-link {
                    display: flex;
                    float:right;
                    font-size:0;
                    li {
                        //display:flex;
                        margin-left:20px;
                        //vertical-align:top;
                        &:first-child {
                            margin-left:0;
                        }
                        //BTOCSITE-12557 푸터 소셜 채널에 카카오 채널 추가 요청
                        &.mo-only{
                            display:none;
                            @include mobile{
                                display:block;
                            }
                        }
                        a {
                            display:flex;
                            align-items: center;
                            //padding-left: 40px;
                            font-weight: 500;
                            @include font(12px,16px);
                            color: $color-gray-1;
                            // S : BTOCSITE-37929 검색 결과 페이지 성능/속도 개선 
                            .sns-icon {
                                display:inline-block;
                                width:28px;
                                height:28px;
                                margin-right:4px;
                                svg {
                                    width:100%;
                                    height:100%;
                                }
                            }
                            //BTOCSITE-16830	웹접근성 확인 요청_ LGE.com(렌탈케어십 신청/조회, 출장서비스 신청/조회) 접근성 이슈 검토 요청
                            .sns-icon + span{
                                max-width:56px;
                            }
                            // E : BTOCSITE-37929 검색 결과 페이지 성능/속도 개선
                        }
                    }
                }
                @include tablet {
                    padding:16px 0;
                    .dropdown-wrap {
                        float: none;
                        .dropdowns {
                            padding: 0;
                            .slt-btn {
                                line-height: 16px;
                            }
                        }
                    }
                    .sns-link {
                        display: flex;
                        //justify-content: space-evenly;
                        flex-wrap: wrap;
                        float: none;
                        margin-top: 16px;
                        li {
                            width: (100% / 5);
                            margin-top: 8px;
                            margin-left:0;
                            a {
                                padding-left: 0;
                                background-position: center top;
                                text-align: center;
                                // S : BTOCSITE-37929 검색 결과 페이지 성능/속도 개선
                                .sns-icon {
                                    margin-right:0;
                                }
                                // E : BTOCSITE-37929 검색 결과 페이지 성능/속도 개선
                            }
                        }
                    }
                }
                @include mobile {
                    padding:40px 0 20px;
                    .dropdown-wrap {
                        font-size:0;
                        .dropdowns {
                            margin-right:0;
                            margin-left:6px;
                            width:calc((100% - 6px) / 2);
                            &:first-child {
                                margin-left:0;
                            }
                            .slt-btn {
                                position:relative;
                                display:block;
                                padding:11px 28px 11px 12px;
                                border:1px solid #8f8f8f; // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                                border-radius:8px;
                                &::after {
                                    position:absolute;
                                    top:50%;
                                    right:12px;
                                    margin:-8px 0 0 0;
                                    background:url('/lg5-common/images/icons/icon-arr-40-bk.svg') center no-repeat;
                                    background-size:100%;
                                }
                            }
                            .lists {
                                top:48px;
                                left:0;
                                right:0;
                                transform:translateX(0);
                            }
                        }
                    }
                    .sns-link {
                        margin:12px -14px 0;
                        li {
                            width: (100% / 4); //BTOCSITE-12557 푸터 소셜 채널에 카카오 채널 추가 요청
                            margin-top: 0;
                            padding: 0 14px;
                            a {
                                flex-direction: column;
                                padding: 12px 0;
                            }
                        }
                    }
                }
            }
            .bottom-body {
                position:relative;
                padding-top:16px;
                border-top:1px solid #ddd;
                .bottom-link {
                    font-size:0;
                    li {
                        display:inline-block;
                        margin-left:12px;
                        vertical-align:top;
                        @include mobile {
                            width:50%;
                            margin-top:16px;
                            margin-left:0;
                        }
                        &:first-child {
                            margin-left:0;
                        }
                        a {
                            display:inline-block;
                            color:#000;
                            @include font(12px,16px);
                            &:hover {
                                text-decoration:underline;
                            }
                        }
                    }
                }
                // 고객센터 번호
                .call-to {
                    margin-top: 8px;
                    font-size: 0;
                    li {
                        display: inline-block;
                        font-size: 12px;
                        line-height: 16px;
                        color: $color-gray-1;
                        a {
                            color: $color-default;
                        }
                        // BTOCSITE-39000 (전시)대표번호 통합에 따른 수정건
                        &:not(:last-of-type):after {
                            display: inline-block;
                            width: 1px;
                            height: 10px;
                            margin: 0 6px;
                            background-color: $color-gray-4;
                            vertical-align: -1px;
                            content: '';
                        }
                    }
                    @include mobile {
                        margin-top: 24px;
                        li {
                            &:not(:last-child) {
                                margin-right: 12px;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                /* 20210513 운영시간 추가 */
                //운영시간
                .time-to {
                    margin-top: 8px;
                    font-size: 0;
                    li {
                        display: inline-block;
                        font-size: 12px;
                        line-height: 16px;
                        color: $color-gray-1;
                        p {
                            span {
                                color: #000;
                            }
                        }
                    }
                    @include mobile {
                        margin-top: 0px;
                        li {
                            &:not(:last-child) {
                                margin-right: 12px;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                /* //20210513 운영시간 추가 */
                .comp-info {
                    margin-top:16px;
                    padding-right:220px;
                    // S : BTOCSITE-39000 (전시)대표번호 통합에 따른 수정건
                    @include mobile {
                        margin-top:24px;
                    }
                    // E : BTOCSITE-39000 (전시)대표번호 통합에 따른 수정건
                    @media screen and (max-width:1200px) {
                        padding-right:0;
                    }
                    p {
                        margin-top: 4px;
                        color:#767676;
                        @include font(12px,16px);
                        &.info {
                            margin-bottom:4px;
                        }
                        @include mobile {
                            margin-top: 0;
                            &.info {
                                span {
                                    margin-top: 4px;
                                }
                            }
                        }
                        span {
                            display:inline-block;
                            margin-right:16px;
                            vertical-align:top;
                            &:last-child {
                                margin-right:0;
                            }
                        }
                        &:first-child {
                            margin-bottom:12px;
                            @include mobile {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
                .foot-last {
                    position:absolute;
                    right:0;
                    bottom:0;
                    font-size:0; // BTOCSITE-46973 푸터] 공정위 산하 한국소비자원 소비자중심경영 (CCM) 인증 심사 대응 건
                    @media screen and (max-width:1200px) {
                        position:relative;
                        right:auto;
                        bottom:auto;
                        margin-top:24px;
                    }
                    .ismsp {
                        position:relative;
                        display:inline-block;
                        padding:8px 0 8px 56px;
                        color:#000;
                        @include font(12px,16px);
                        font-weight:500;
                        @include mobile {
                            padding:0 0 0 40px;
                        }
                        &::before {
                            content:' ';
                            position:absolute;
                            top:50%;
                            left:0;
                            width:48px;
                            height:48px;
                            margin-top:-24px;
                            background:url('/lg5-common/images/img-ismsp.png') center no-repeat;
                            background-size:100%;
                            @include mobile {
                                width:32px;
                                height:32px;
                                margin-top:-16px;
                            }
                        }
                        /* BTOCSITE-6743 [급] 엘지이닷컴 ISMS인증마크에 범위 및 유효기간 추가 */
                        dl{
                            display:flex;
                            dd{
                                margin-left:7px;
                                font-weight:400;
                                color:#666;
                            }
                        }
                        /* //BTOCSITE-6743 [급] 엘지이닷컴 ISMS인증마크에 범위 및 유효기간 추가 */
                    }
                    // S : BTOCSITE-47791 푸터] 산업통상자원부 산하 서비스진흥협회 (SQ) 인증 로고 추가 요청 건 
                    // S : BTOCSITE-46973 푸터] 공정위 산하 한국소비자원 소비자중심경영 (CCM) 인증 심사 대응 건 
                    .logo-img {
                        display:inline-block;
                        margin-left:16px;
                        vertical-align:top;
                        @include mobile {
                            margin-left:24px;
                            & + .logo-img {
                                margin-left:12px;
                            }
                        }
                        img {
                            width:48px;
                            height:48px;
                            @include mobile {
                                width:32px;
                                height:32px;
                            }
                        }
                    }
                    // E : BTOCSITE-46973 푸터] 공정위 산하 한국소비자원 소비자중심경영 (CCM) 인증 심사 대응 건
                    // E : BTOCSITE-47791 푸터] 산업통상자원부 산하 서비스진흥협회 (SQ) 인증 로고 추가 요청 건 
                }
            }
        }
    }
}

.no-footer {
    padding:24px 40px;
    text-align:center;
    @include mobile {
        padding:24px 20px;
    }
}

/* BTOCSITE-1967 */
.mdevice {
    &:not(.app) {
        @include mobile {
            $statusbarHeight: 70px;
            footer:not(.no-footer):not(.pop-footer):not(.default-bottom-wrap) {
                padding-bottom:60px + $statusbarHeight;
            }

        }
    }
}


/*  #footer */
#footer {
    padding:16px 40px 36px;
    border-top: 1px solid #ddd;
    background:#fff;
    .bold {
        font-weight: $font-weight-bold;
    }
    .under-line {
        text-decoration: underline;
        text-underline-position: under;
    }
    @media screen and (max-width:1200px) {
        padding-bottom:66px;
    }
    @include pc {
        .pc-only-ib{display: inline-block;}
    }
    
    @include mobile {
        .pc-only-ib{display: none;}
        padding: 16px 16px 78px;  
        //letter-spacing: -0.2px;
        letter-spacing:0;
    }

    &.default-bottom-wrap {
        padding-bottom: 130px;
        @include mobile {
            padding-bottom: 230px;
        }
    }

    // footer cont
    .foot-cont {
        .cont-area {
            max-width:1380px;
            margin:0 auto;
        }

        // link-wrap - Mobile
        @media screen and (max-width:767px) {
            .link-wrap {
                .link-section {
                    border-bottom:1px solid #ddd;
                    .dep1 {
                        position:relative;
                        padding:0 16px; 
                        &::after {
                            content:' ';
                            position:absolute;
                            right:16px;
                            top:50%;
                            width:16px;
                            height:16px;
                            margin-top:-10px;
                            background:url('/lg5-common/images/icons/btn-arr-16-black.svg') center no-repeat;
                            background-size:100%;
                            transform: rotate(90deg);
                            transition:all .3s;
                        }
                        a, span {
                            display:block;
                            padding:17px 0;
                            color:#000;
                            font-size:13px;
                            line-height:21px;
                            font-weight:700;
                        }
                        a[target="_blank"] {
                            white-space:nowrap;
                            &::after {
                                content:' ';
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin-left:4px;
                                background:url('/lg5-common/images/icons/btn-outlink-16.svg') center no-repeat;
                                background-size:100%;
                                vertical-align:-3px;
                            }
                        }
                        &.hidden {
                            display:none !important;
                        }
                        &.dep1-only {
                            &::after {
                                display:none;
                            }
                        }
                    }
                    &.on {
                        .dep1::after {
                            transform:rotate(270deg);
                        }
                        .dep1
                            a {
                                //text-decoration:underline;
                            }
                    }
                    &:first-child {
                        .dep1 {
                            border-top:none;
                        }
                    }

                    .dep2-wrap {
                        padding:0 16px;
                        border-top:1px solid #ddd;
                        .dep2-list {
                            border-top:1px solid #ddd;
                            &:first-child {
                                border-top:none;
                            }
                            .dep2 {
                                display:block;
                                position:relative;
                                padding-right:20px;
                                &::after {
                                    content:' ';
                                    position:absolute;
                                    top:50%;
                                    right:2px;
                                    width:16px;
                                    height:16px;
                                    margin-top:-8px;
                                    background:url('/lg5-common/images/icons/btn-plus-16.svg') center no-repeat;
                                    background-size:100%;
                                    transition:all 0.3s;
                                }
                                &:only-child {
                                    &::after {
                                        display:none;
                                    }
                                }
                                a, span {
                                    display:block;
                                    padding:12px 0;
                                    color:#000;
                                    font-size:11px;
                                    line-height:19px;
                                    font-weight:500;
                                }
                                a {
                                    &[target="_blank"] {
                                        white-space:nowrap;
                                        &::after {
                                            content:' ';
                                            display:inline-block;
                                            width:16px;
                                            height:16px;
                                            margin-left:4px;
                                            background:url('/lg5-common/images/icons/icon-blank-arr2.svg') center no-repeat;
                                            background-size:100%;
                                            vertical-align:top;
                                        }
                                    }
                                }
                            }
                            &.on {
                                .dep2 {
                                    &::after {
                                        transform:rotate(135deg);
                                    }
                                    a {
                                        //text-decoration:underline;
                                    }
                                }
                            }
                        }
                    }
                    .hidden + .dep2-wrap {
                        margin-top:-16px;
                        border-top:none;
                    }

                    .dep3-wrap {
                        padding:16px 0;
                        border-top:1px solid #ddd;
                        > li {
                            margin-top:12px;
                            &:first-child {
                                margin-top:0;
                            }
                        }
                        .dep3 {
                            display:block;
                            //padding:0 12px;
                            color:#767676;
                            font-size:11px;
                            line-height:19px;
                            //font-weight:500;
                            &[target="_blank"] {
                                white-space:nowrap;
                                &::after {
                                    content:' ';
                                    display:inline-block;
                                    width:16px;
                                    height:16px;
                                    margin-left:4px;
                                    background:url('/lg5-common/images/icons/icon-blank-arr3.svg') center no-repeat;
                                    background-size:100%;
                                    vertical-align:top;
                                }
                            }
                        }
                    }
                }
            }
            &.foot-cont-shop {
                .cont-area .foot-bottom .bottom-body {
                    padding: 0 0 70px;
                    border-top: 0;
                }
            }
        }
        // link-wrap - PC
        @media screen and (min-width:768px) {
            .pc-dropdown-wrap {
                display:none;
                position:relative;
                padding-bottom:136px;
                .btns {
                    position:absolute;
                    bottom:0;
                    right:0;
                    left:0;
                    padding:48px 0;
                    text-align:center;
                    .btn.border {
                        &::after {
                            content:' ';
                            display:inline-block;
                            width:16px;
                            height:16px;
                            margin-left:4px;
                            margin-top:4px;
                            background:url('/lg5-common/images/icons/icon-arr-footer-16.svg') center no-repeat;
                            vertical-align:top;
                            transition:all 0.3s;
                        }
                        &:hover,
                        &:focus,
                        &:active {
                            border-color:#000 !important;
                            background-color: transparent;
                            color: #000;
                        }
                    }
                }
                &.open {
                    .btns {
                        padding-top: 6px;
                        .btn.border::after {
                            transform:rotate(180deg);
                        }
                    }
                }
                .link-wrap {
                    display:none;
                }
                &.open {
                    .link-wrap {
                        display:block;
                    }
                }
            }
            .link-wrap {
                margin:-12px -6px -20px;
                padding-bottom:16px;
                font-size:0;
                .link-section {
                    display:inline-block;
                    width:12.5%;
                    margin-top:48px;
                    padding:0 6px;
                    vertical-align:top;
                    @media screen and (max-width:1200px) {
                        width:25%;
                    }
                    div.dep1 {
                        height:36px;
                        margin-top:28px;
                        padding-bottom:20px;
                        a, span {
                            display:inline-block;
                            color:#000;
                            font-size:14px;
                            line-height:16px;
                            font-weight:700;
                            vertical-align:top;
                        }
                        a[target="_blank"] {
                            white-space:nowrap;
                            &::after {
                                content:' ';
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin-left:4px;
                                background:url('/lg5-common/images/icons/icon-blank-arr.svg') center no-repeat;
                                background-size:100%;
                                vertical-align:top;
                            }
                        }
                        &.hidden {
                            a, span {
                                width:0;
                                height:0;
                                font-size:0 !important;
                                overflow:hidden;
                            }
                        }
                        &:first-child {
                            margin-top:0;
                        }
                    }
                    .dep2-wrap {
                        .dep2-list {
                            margin-top:6px;
                            &:first-child {
                                margin-top:0;
                            }
                            .dep2 {
                                a, span {
                                    display:inline-block;
                                    color:#000;
                                    font-size:12px;
                                    line-height:16px;
                                    font-weight:500;
                                    vertical-align:top;
                                }
                                a {
                                    &[target="_blank"] {
                                        white-space:nowrap;
                                        &::after {
                                            content:' ';
                                            display:inline-block;
                                            width:16px;
                                            height:16px;
                                            margin-left:4px;
                                            background:url('/lg5-common/images/icons/icon-blank-arr2.svg') center no-repeat;
                                            background-size:100%;
                                            vertical-align:top;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dep3-wrap {
                        margin:6px 0 20px;
                        > li {
                            margin-top:4px;
                            &:first-child {
                                margin-top:0;
                            }
                        }
                        .dep3 {
                            display:inline-block;
                            color:#616161;
                            font-size:12px;
                            line-height:16px;
                            vertical-align:top;
                            &[target="_blank"] {
                                white-space:nowrap;
                                &::after {
                                    content:' ';
                                    display:inline-block;
                                    width:16px;
                                    height:16px;
                                    margin-left:4px;
                                    background:url('/lg5-common/images/icons/icon-blank-arr3.svg') center no-repeat;
                                    background-size:100%;
                                    vertical-align:top;
                                }
                            }
                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .foot-bottom {
            position: relative;
            @include mobile {
                padding: 0 0 48px;
            }
            .bottom-cont1 {
                position:relative;

                .bottom-link {
                    font-size:0;
                    li {
                        display:inline-block;
                        vertical-align:top;
                        margin-bottom:8px;
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 1px;
                            height: 10px;
                            background-color: #979797;
                            margin: 0 15px;
                        }
                        &:last-child{
                            margin-bottom:0;
                            &::after{
                                display: none;
                            }
                        }
                        @include mobile {
                            margin-bottom:0;
                            &::after {
                                content: '';
                                display: inline-block;
                                width: 1px;
                                height: 10.17px;
                                background-color: #ddd;
                                margin: 0 9px;
                            }
                            &:last-child::after{
                                display: none;
                            }
                        }
                        a {
                            display:inline-block;
                            color:#666;
                            @include font(12px,16px);
                            &:hover {
                                text-decoration:underline;
                            }
                            @include mobile {
                                @include font(10px,17px);
                            }

                        }
                    }
                }

                .footer-logo {
                    margin: 16px 0;
                    img {
                        display: inline-block;
                        width: 76px;
                        height: 20px;
                    }
                    @include mobile {
                        margin: 14px 0 4px;
                        img {
                            width: 56.584px;
                            height: 15px;
                        }
                    }
                }
                // 고객센터 번호
                .call-to {
                    margin: 2px 0 8px;
                    li {
                        display: inline-block;
                        font-size: 12px;
                        line-height: 16px;
                        color: $color-gray-1;
                        &.block {
                            display: block;
                            height: 8px;
                            font-size: 0;
                            &::after {
                                display: none;
                            }
                        }
                        a {
                            color: $color-gray-1;
                        }
                        &::after {
                            display: inline-block;
                            width: 1px;
                            height: 10px;
                            margin: 0 15px;
                            background-color: $color-gray-2;
                            vertical-align: -1px;
                            content: '';
                        }
                        &:nth-child(3)::after,
                        &:last-child::after {
                            display: none;
                        }
                    }
                    @include mobile {
                        margin-top: 0;
                        display: inline-block;
                        li {
                            font-size: 10px;
                            line-height: 15px;
                            &.block {
                                //display: none;
                                &::after{
                                    display:none;
                                }
                            }
                            a {
                                //letter-spacing: -0.2px;
                                letter-spacing:0;
                            }
                            &::after {
                                background-color: #ddd;
                                margin: 0 9px;
                                content: '';
                            }
                            &:nth-child(3)::after{
                                display:none;
                            }
                        }
                        
                    }
                }
                .time-to {
                    margin-top: 2px;
                    font-size: 0;
                    li {
                        font-size: 12px;
                        line-height: 18px;
                        color: $color-gray-1;
                        &:first-child::after {
                            content: '';
                            display: none;
                            width: 1px;
                            height: 10.172px;
                            background: #979797;
                            margin-left: 10px;
                            position: relative;
                            top:2px
                        }
                        br {
                            display: none;
                        }
                    }
                    @include mobile {
                        margin-top: 0px;
                        li {
                            font-size: 10px;
                            line-height: 15px;
                            //letter-spacing: -0.2px;
                            letter-spacing:0;
                            &:not(:last-child) {
                                margin-right: 12px;
                                &:after {
                                    display: none;
                                }
                            }
                            br {
                                display: block;
                            }
                        }
                    }
                }
                .comp-info {
                    @include mobile {
                        margin-top:14px;
                    }
                    @media screen and (max-width:1200px) {
                        padding-right:0;
                    }
                    p.info {
                        color:#666;
                        @include font(12px,18px);
                        &.info {
                            margin-bottom: 12px;
                            padding: 14px 0;
                            border-bottom: 1px solid #dedede;
                            line-height: 17px;
                        }
                        @include mobile {
                            margin-top: 0;
                            @include font(10px,17px);
                            &.info {
                                border-top: 1px solid #e9e9e9;
                                border-bottom: none ;
                                
                                &.info{
                                    line-height:15px;
                                }
                                span {
                                    margin-top: 0px;
                                }
                            }
                        }
                        span {
                            display:inline-block;
                            vertical-align:top;
                            &:last-child {
                                margin-right:0;
                                &::after {
                                display: none;
                                }
                            }
                            &::after {
                                content: '';
                                display: inline-block;
                                margin: 0 15px;
                                width: 1px;
                                height: 10.172px;
                                background: #979797;
                                position: relative;
                                top: 2px;
                            }
                            @include mobile {
                                &::after {
                                    // content: '';
                                    // display: inline-block;
                                    margin: 0 9px;
                                    // width: 1px;
                                    // height: 10.172px;
                                    // background: #979797;
                                    // position: relative;
                                    // top: 2px;
                                }
                            }
                        }
                        &:first-child {
                            margin-bottom:12px;
                            @include mobile {
                                margin-bottom: 0px;
                            }
                        }
                    }
                    .desc {
                        color: #666;
                        @include font(12px,16px);
                        &:first-child {
                            margin-bottom:0px;
                        }
                        span {
                            display: block;
                            margin-bottom:4px;
                        }
                        @include mobile {
                            color: #666;
                            @include font(10px,15px);
                            span {
                                display: inline;
                                &:last-child {
                                    display: block;
                                    margin-bottom:0;
                                }
                            }
                        }
                    }
                }
            }

            .bottom-cont2 {
                position:relative;
                @include clearfix;
                .bottom-cont2-1 {
                    margin: 16px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: end;

                .dropdown-wrap {
                    position: absolute;
                    right: 0;
                    bottom: 58px;
                    @include mobile {
                        bottom: -40px;
                    }

                    .dropdowns {
                        width: 130px;
                        height: 26px;
                        border: 1px solid #8f8f8f; // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                        border-radius: 4px;
                        display:inline-block;
                        position:relative;
                        padding: 4px 8px;
                        vertical-align:top;
                        .slt-btn {
                            display:flex;
                            justify-content: space-between;
                            align-items: center;
                            color:#666; // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                            font-size:12px;
                            line-height:18px;
                            font-weight:500;
                            vertical-align:top;
                            &::after {
                                content:' ';
                                display:inline-block;
                                width:12px;
                                height:12px;
                                margin:0 0 0 4px;
                                background:url("/kr/common/footer/ic_arrow_down.png") center no-repeat;
                                background-size:100% auto;
                                vertical-align:top;
                                transition: all .3s;
                            }
                        }
                        .lists {
                            display:none;
                            position:absolute;
                            top:-75px;
                            left:50%;
                            transform:translateX(-50%);
                            z-index:10;
                            padding:4px 0;
                            border-radius:4px;
                            background:#fff;
                            box-shadow:2px 4px 12px 0 rgba(#000, .16);
                            li {
                                a {
                                    display:block;
                                    padding:0 20px;
                                    color:#666; // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                                    font-size:12px;
                                    line-height:30px;
                                    text-align:center;
                                    font-weight:400; // BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                                    white-space:nowrap;
                                    // S : BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                                    &:hover {
                                        background:#f7f7f7;
                                    }
                                    &.selected {
                                        text-decoration:underline;
                                        color:#000;
                                        font-weight:700;
                                    }
                                    // E : BTOCSITE-77494 [웹앱 접근성 개선활동] 전시_공통/사이트맵
                                }
                            }
                        }
                        &.open {
                            .slt-btn {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            .lists {
                                display:block;
                            }
                        }
                    }
                }
                .sns-link {
                    display: flex;
                    gap: 16px;
                    li {
                        a {
                            font-weight: 400;
                            @include font(12px,16px);
                            color: $color-gray-1;
                        }
                    }
                }
                @include mobile {
                    margin: 0;
                    padding:14px 0;
                    .dropdown-wrap {
                        font-size:0;
                        .dropdowns {
                            margin-right:0;
                            margin-left:6px;
                            width:100px;
                            &:first-child {
                                margin-left:0;
                            }
                            .slt-btn {
                                position:relative;
                                display:block;
                              //   padding:11px 28px 11px 12px;
                              //   border:1px solid #ddd;
                                border-radius:4px;
                                font-size: 10px;
                                &::after {
                                    position:absolute;
                                    top:50%;
                                    right:0;
                                    margin:-6px 0 0 0;
                                    background:url('/kr/common/footer/ic_arrow_down.png') center no-repeat;
                                    background-size:100% auto;
                                }
                            }
                            .lists {
                                top:-65px;
                                left:0;
                                right:0;
                                transform:translateX(0);
                                li {
                                    a {
                                        padding:0;
                                        font-size:10px;
                                        line-height:26px;
                                        letter-spacing: -0.2px;
                                    }
                                }
                            }
                        }
                    }
                    .sns-link {
                        gap: 10px;
                        li {
                            margin-top: 0;
                            a {
                                @include font(10px,17px);
                            }
                        }
                    }
                }
                }
                .bottom-cont2-2 {
                    display: flex;
                    justify-content: space-between;
                    align-items: end;

                    .sns-link {
                        display: flex;
                        // gap: 12px;
                        font-size:0;
                        li {
                            width: 61px;
                            &:first-child {
                                margin-left:0;
                                // BTOCSITE-81335 'LIVE LG LG전자 뉴스룸' => 'LG전자 뉴스룸' 으로 명칭 변경
                                width: 44px;
                                a {
                                    align-items: flex-start;
                                }
                            }
                            &.mo-only{
                                display:none;
                                @include mobile{
                                    display:block;
                                }
                            }
                            a {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                                font-weight: 500;
                                @include font(12px,16px);
                                color: $color-gray-1;
                                .sns-icon {
                                    display:inline-block;
                                    width:28px;
                                    height:28px;
                                    @include mobile {
                                        width:26px;
                                        height:26px;
                                    }
                                    img {
                                        width:100%;
                                        height:100%;
                                        font-size: 0;
                                    }
                                }
                                .sns-name {
                                    margin-top: 4px;
                                    font-size: 10px;
                                    line-height: 16px;
                                }
                                .sns-icon + .sns-name{
                                    // max-width:56px;
                                }
                            }
                        }
                    }
                    .foot-last {
                        position:absolute;
                        right:0;
                        bottom:0;
                        font-size:0; 
                        display: flex;
                        gap: 10px;
                        width: 88px;
                        height: 40px;
                        @include mobile {
                            margin-top:16px;
                            left: 0;
                            bottom: -40px;
                            gap: 9.5px;
                            width: 60px;
                            height: 26px;
                        }
                        .logo-img {
                            width: 40px;
                            height: 40px;
                            vertical-align:top;
                            &.sq {
                                width: 38px;
                            }
                            @include mobile {
                                width: 26px;
                                height: 26px;
                                vertical-align:top;
                                &.sq {
                                    width: 25px;
                                }
                            }
                            img {
                                width:100%;
                                height:100%;
                            }
                        }
                    }
                    @include mobile {
                        padding:0;
                        .sns-link {
                            // gap: 10px;
                            li {
                                margin-top: 0;
                                a {
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

