.icon-download {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(/lg5-common/images/icons/icon-download.svg) no-repeat center;
    background-size: 100%;

    @include mobile {
    }
}
.icon-download-red {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(/lg5-common/images/icons/icon-download-red.svg) no-repeat center;
    background-size: 100%;

    @include mobile {
    }
}