.search-word {
    color: $color-primary;
}

.input-keyword-wrap {
    max-width: (1146px + 80px);
    margin: 0 auto 64px;
    padding: 56px 40px 0;
    @include mobile {
        max-width: 100%;
        //padding: 102px 16px 50px;
        margin-bottom: 48px;
        padding: 12px 16px 0;

        &.fixed-top {
            .input-keyword {
                position: relative;
                @include mobile {
                    position: fixed;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 3;
                    padding: 40px 16px 16px;
                    //background-color: $color-bg-wt;
                }
            }
        }
    }
}

.input-keyword {
    position: relative;
    @include pc {
        max-width: 678px;
        margin: 0 auto;
    }
    //border-radius: 28px;
    //box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
    @include mobile {
        //position: fixed;
        //top: 0;
        //right: 0;
        //left: 0;
        //z-index: 3;



        //padding: 30px 0 16px;
        background-color: $color-bg-wt;
    }

    .sch-input-wrap {
        position: relative;

        .input-sch {
            position: relative;

            input.txt {
                width: 100%;
                height: 62px;
                padding: 0 78px 0 0;
                border-bottom: 2px solid $color-default;
                //border-radius: 28px;
                background-color: #fff;
                //box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
                font-weight: 700;
                @include font(24px,34px);

                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder {
                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                }

                &::-ms-clear,
                &::-ms-reveal {
                    display: none;
                    width: 0;
                    height: 0;
                }
                &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    display: none;
                }

                &:focus {
                    outline: none;
                    // border-bottom: 2px solid transparent;
                    // background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(119deg, #9c86e7 0%, #de64a0 100%) border-box;
                }
            }

            .btn-delete {
                position: absolute;
                top: 50%;
                right: 44px;
                width: 24px;
                height: 24px;
                margin-top: -12px;
                background: url('/lg5-common/images/icons/btn-clear-24.svg') no-repeat 0 0;
            }

            .btn-search {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 38px;
                height: 100%;
                background:none;

                &::after {
                    content: '';
                    display: block;
                    margin-left: 6px;
                    width: 32px;
                    height: 32px;
                    background: url('/lg5-common/images/icons/icon-search-32.svg') no-repeat;
                }
            }
        }

        @include mobile {
            border-radius: 4px;
            .input-sch {
                /* 2021.08.20 BTOCSITE-4643 : 통합검색 검색어 입력 진행 시 'X' 삭제 버튼과 문구가 겹침 */
                .clear {
                    input.txt {
                        padding-right: 75px;
                    }
                }
                /* //2021.08.20 BTOCSITE-4643 : 통합검색 검색어 입력 진행 시 'X' 삭제 버튼과 문구가 겹침 */
                input.txt {
                    height: 46px;
                    padding-right: 22px;
                    font-weight: 500;
                    @include font(20px,28px);
                }
                .btn-delete {
                    right: 40px;
                }
                .btn-search {
                    width: 32px;
                    &::after {
                        margin-left: 8px;
                        width: 24px;
                        height: 24px;
                        background-size: 100%;
                    }
                }
            }
        }
    }

    &.focus-on {
        .sch-input-wrap {
            .input-sch {
                z-index: 3;
                input.txt {
                    border-bottom: 0;
                }
            }
        }
    }
}

// -S- BTOCSITE-16 검색 결과 구획 정리
.related-keyword {
    position: relative;
    max-width: 726px;
    height: 42px;
    margin: 12px auto 16px;
    padding-right: 26px;
    text-align: center;
    overflow: hidden;

    .inner {
        position: relative;
        display: inline-block;
        padding-left: 78px;

        @include mobile {
            padding-left: 32px;
        }
    }

    .title {
        position: absolute;
        top: 8px;
        left: 0;
        font-weight: 700;
        @include font-small;
    }

    .keyword-list {
        display: inline-block;
        margin-bottom: -4px;
        margin-right: -13px;
        font-size: 0;
        text-align: left;

        li {
            display: inline-block;
            margin: 0 8px 8px 0;


            &:nth-last-child(1) {
                margin-right: 0;
            }

            a {
                display:inline-block;
                // @include font-small;
                font-size: 14px;
                line-height:21px;

                padding:8px 14px;
                margin:1px;
                border-radius: 8px;
                border: solid 1px #ddd;

                &:hover {
                    margin:0;
                    border: solid 2px #da0f47;
                }
            }
        }
    }



        .mobile-more-btn {
            display: block;
            position: absolute;
            top: 13px;
            right: 0;

            .btn-more {
                display: block;
                width: 16px;
                height: 16px;
                background: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat 0 0;
                transform: rotate(90deg);
                transition: transform .3s .1s ease-out;
            }
        }

        &.open {
            height: auto;

            .mobile-more-btn {
                .btn-more {
                    transform: rotate(270deg);
                }
            }
        }
}
// -E- BTOCSITE-16 검색 결과 구획 정리


.search-input-layer {
    display: none;
    position: absolute;
    top: 62px;
    right: 0;
    left: 0;
    z-index: 10;
    padding: 32px 32px 56px;
    border-radius: 8px;
    border: 2px solid #000; //BTOCSITE-14407-DTO
    background-color: #fff; //BTOCSITE-14407-DTO
    box-shadow: 2px 8px 12px 0 rgba(202, 111, 183, 0.13);

    .btn-close {
        position: absolute;
        right: 24px;
        bottom: 24px;
        width: 24px;
        height: 24px;
        background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat 0 0;
        @include mobile {
            right: 18px;
            bottom: 18px;
        }
        span {
            @include blind;
        }
    }

    .not-result {
        padding: 32px 0 64px;
        @include font-medium;
        text-align: center;
    }

    .search-keyword-area {
        display: table;
        table-layout: fixed;
        width: 100%;

        .recent-keyword,
        .popular-keyword {
            display: table-cell;
            width: 50%;
        }

        .recent-keyword {
            padding-right: 32px;
        }
        .popular-keyword {
            padding-left: 32px;
        }

        .title {
            display: block;
            margin-bottom: 16px;
            font-weight: 700;
            font-size: $font-medium;
            line-height: 30px;
        }
    }

    @include mobile {
        top: 52px;
        padding: 24px 24px 42px;
        .auto-complete {
            margin: 0 -12px;
        }
        .search-keyword-area {
            //display: block;

            // .recent-keyword,
            // .popular-keyword {
            //     //display: block;
            //     //width: 100%;
            // }

            .popular-keyword {
                //margin-top: 32px;
                padding-left: 0;
            }

            .title {
                font-size: $font-small;
                line-height: 22px;
            }
        }
        .not-result {
            padding: 16px 0 40px;
        }
    }

    .srch-direct-wrap {
        margin-top: 40px;
        padding: 0 32px;
        .brandhall {
            position: relative;
            margin: 0 0 8px 0;
            padding: 0 0 24px 0;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 32px;
                left: 32px;
                height: 1px;
                background: #eee;
            }
        }
        @include mobile {
            margin-top: 24px;
            padding: 0 0 20px 0;

            .prod-direct-list {
                margin: 0 0 10px 0;
            }
            .brandhall {
                margin: 0;
                padding-bottom: 10px;
                &::after {
                    display: none;
                }
            }
        }
    }
    .brandhall {
        a {
            padding: 0 24px 0 32px;
            border: 0;
            @include mobile {
                padding: 21px 10px 21px 24px;
                border: 1px solid #e4e4e4;
            }
        }
    }
}

.wrap {
    .contents.search.re22 {
        .search-input-layer {
            .auto-complete {
                margin-top: 24px;
            }
            .prod-direct-list + .brandhall {
                margin-top: 24px;

                @include mobile {
                    margin-top: 0;
                }
            }
        }
    }
}


.auto-complete {
    margin-top: 24px;
    .keyword-list {
        //margin-top: -4px;

        li {
            margin-top: 4px;
            padding: 4px 12px;

            &:first-child {
                margin-top: 0;
            }

            a {
                @include font-medium;
            }

            &.on,
            &:hover,
            &:focus {
                border-radius: 17px;
                background-color: $color-bg;

                a {
                    font-weight: 700;
                }
            }
        }
    }
}

.input-result-list {
    li {
        margin-top: 16px;
        @include textEllipsisClamp(1);

        &:first-child {
            margin-top: 0;
        }
    }
}

.search-similar {
    margin-top: 80px;
    text-align: center;

    .search-result-text {
        margin-bottom: 8px;
        @include font($font-large-4,52px);

        .search-word {
            font-weight: 700;
            color: $color-default;
            .word {
                color: $color-primary;
                word-break: break-all;
            }
        }
    }

    .similar-text {
        @include font($font-small,24px);
        text-decoration: underline;
        word-break: break-all; //BTOCSITE-14412 [검색] 검색어가 긴 경우 결과화면에서 화면을 넘어 감
        &:after {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('/lg5-common/images/icons/btn-arr-20x20-gray.svg') no-repeat 0 0;
            vertical-align: -5px;
            content: '';
        }
    }

    @include mobile {
        margin-top: 4px;
        .search-result-text {
            margin-bottom: 12px;
            @include font($font-large-1,28px);
        }
        .similar-text {
            @include font($font-small - 1,21px);
            line-height: 21px;
        }
    }
}

/*BTOCSITE-2117 공통 > 모바일 웹/앱 GNB 개선*/
@media screen and (max-width: 767px) {
    .subRenewWrap {
        .contents {
            &.search {
                .input-keyword-wrap {
                    position: fixed;
                    top: 0;
                    width: 100%;
                    padding: 12px 16px 12px;
                    z-index: 90;
                    background: $color-bg-wt;
                    .btn-back {
                        position: relative;
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        margin: 11px 8px 11px 0;
                        background: url(/lg5-common/images/icons/btn-arr-32-left.svg) no-repeat 50% 50%;
                        background-size: 24px;
                        vertical-align: top;
                    }
                    .input-keyword {
                        display: inline-block;
                        width: calc(100% - 36px);
                        .sch-input-wrap {
                            .input-sch {
                                input {
                                    &.txt {
                                        padding: 0 82px 0 21px;
                                        font-size: 15px;
                                        line-height: 1.6;
                                        border: 1px solid $color-border;
                                        border-radius: 46px;
                                    }
                                }
                                .btn-search {
                                    right: 16px;
                                }
                                .btn-delete {
                                    right: 56px;
                                }
                            }
                        }
                    }
                }
                .search-tabs-wrap {
                    padding-top: 5px;
                }
            }
        }
        &.isHide {
            .input-keyword-wrap {
                z-index: 99;
            }
        }
    }
}

// LGECOMVIO-16
// 이전버튼 처리
.wrap .contents.search.re22 {
    .input-keyword {
        .sch-input-wrap {
            @include pc{
                .input-sch {
                    .btn-back {
                        display: none;
                    }
                }
            }
             @include tablet{
                .input-sch {
                    .btn-back {
                        display: none;
                    }
                }
            }
            @include mobile {
                .input-sch {
                    padding-left: 44px;
                    .btn-back {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 32px;
                        height: 32px;
                        margin-top: -16px;
                        background: url('/lg5-common/images/icons/btn-arr-32x32-headback.svg') no-repeat 0 0/100%;
                    }
                }
            }
        }
    }
}
.search-layer.re22 {
    .input-keyword {
        .sch-input-wrap {
            .input-sch {
                .btn-back {
                    display: none;
                }
            }
        }
    }
}
.search-layer.re22,
.wrap .contents.search.re22 {
    .search-word {
            color: $color-primary;
        }
    .input-keyword-wrap {
        margin: 0 auto 64px;
        padding: 56px 0 0;
        // BTOCSITE-27053 자동완성 사용성 개선
        position: sticky !important;
        top: 0;
        max-width: initial;
        border-bottom: 1px solid transparent;
        z-index: 99; // BTOCSITE-31975 검색창 우선순위 하락
        @include mobile {
            overflow-y: auto;
            width: 100%;
            height: auto;
            max-width: 100%;
            min-width: 100%;
            margin: 0;
            padding: 70px 0 0 0;
            position: relative !important;

            &.fixed-top {
                .input-keyword {
                    position: relative;
                    @include mobile {
                        position: fixed;
                        top: 0;
                        right: 0;
                        left: 0;
                        z-index: 3;
                        padding: 40px 16px 16px;
                    }
                }
            }
        }
        // BTOCSITE-27053 자동완성 사용성 개선
        &.scroll {
            margin-bottom: 0;
            padding: 12px 0;
            background-color: #fff;
            border-bottom-color: #ddd;
            .search-similar {
                margin-top: 0;
                @include mobile {
                    display: none;
                }
            }

            @include pc {
                .related-keyword {
                    overflow: hidden;
                    height: 0;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    pointer-events: none;
                }
            }

            @include mobile {
                position: relative !important;
                padding: 70px 0 0;
                border-bottom: 0;

                .input-keyword {
                    border-bottom: 1px solid #ddd;
                }
            }
        }
        .search-keyword-area {
            margin: 0;
            padding-top: 0;
            @include mobile {
                & > div {
                    padding-left: 0;
                }
            }
        }
        .popular-keyword {
            &::after {
                @include mobile {
                    left: 0;
                }
            }
        }
        &.key-fixed {
            @include mobile {
                position: fixed;
                width: 100%;
                height: 100vh;
                z-index: 999;
                
                // BTOCSITE-76488 검색 결과 내 장바구니 아이콘 추가
                .sch-input-wrap {
                    .sch-utility.cart {
                        display: none
                    }
                }
            }

            .search-input-layer {
                .srch-direct-wrap {
                    margin-top: 0;
                    padding: 0;

                    .prod-direct-list {
                        ul {
                            li {
                                a {
                                    padding: 8px 32px;
                                }
                                .txt-direct {
                                    right: 32px;
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    .srch-direct-wrap {
                        padding: 0;
    
                        .prod-direct-list {
                            ul {
                                li {
                                    a {
                                        padding: 8px 8px;
                                    }
                                    .txt-direct {
                                        right: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .input-keyword {
        position: relative;
        @include pc {
            max-width: 726px;
            padding-right: 48px;
            margin: 0 auto;
        }
        border-radius: 0;
        box-shadow: none;
        @include mobile {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100% !important;
            padding: 12px 56px 12px 12px;
            background-color: $color-bg-wt;
        }

        .sch-input-wrap {
            position: relative;

            .input-sch {
                position: relative;

                input.txt {
                    width: 100%;
                    height: 64px;
                    margin: initial;
                    padding: 16px 62px 16px 32px;
                    border-bottom: 0;
                    border-radius: 40px;
                    background-color: #f4f5f7;
                    box-shadow: none;
                    font-weight: 400;
                    @include font(24px,32px);

                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder {
                        color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    }

                    &::-ms-clear,
                    &::-ms-reveal {
                        display: none;
                        width: 0;
                        height: 0;
                    }
                    &::-webkit-search-decoration,
                    &::-webkit-search-cancel-button,
                    &::-webkit-search-results-button,
                    &::-webkit-search-results-decoration {
                        display: none;
                    }

                    &:focus {
                        outline: none;
                        border-bottom: 0;
                        background: #f4f5f7;
                    }
                }

                .btn-delete {
                    position: absolute;
                    top: 50%;
                    right: 24px;
                    width: 24px;
                    height: 24px;
                    margin-top: -12px;
                    background: url('/lg5-common/images/icons/btn-clear-32.svg') no-repeat 0 0/100%;
                }

                .btn-search {
                    position: absolute;
                    top: 50%;
                    right: -48px;
                    transform: translateY(-50%);
                    width: 38px;
                    height: 100%;
                    background:none;

                    &::after {
                        content: '';
                        display: block;
                        margin-left: 6px;
                        width: 32px;
                        height: 32px;
                        background: url('/lg5-common/images/icons/icon-search-32.svg') no-repeat;
                    }
                }

                .btn-camera {
                    display: none;
                    position: absolute;
                    top: 50%;
                    right: -48px;
                    transform: translateY(-50%);
                    width: 38px;
                    height: 100%;
                    background:none;

                    &::after {
                        content: '';
                        display: block;
                        margin-left: 6px;
                        width: 32px;
                        height: 32px;
                        background: url('/lg5-common/images/icons/btn-search-camera-re.svg') no-repeat; // BTOCSITE-23590 수정
                    }
                }
            }

            // BTOCSITE-76488 검색 결과 내 장바구니 아이콘 추가
            @include pc {
                .sch-utility {
                    display: none
                }
            }

            @include mobile {
                display: flex; // BTOCSITE-76488 검색 결과 내 장바구니 아이콘 추가
                align-items: center;
                gap: 12px;
                border-radius: 4px;
                .input-sch {
                    flex: 1; // BTOCSITE-76488 검색 결과 내 장바구니 아이콘 추가
                    .clear {
                        input.txt {
                            padding-right: 65px;
                        }
                    }

                    input.txt {
                        height: 46px;
                        padding-right: 22px;
                        font-weight: 500;
                        @include font(16px,22px);
                        border: 0 !important;
                        border-radius: 40px !important;
                    }
                    .btn-delete {
                        right: -1px;
                        width: 46px;
                        height: 46px;
                        margin-top: 0;
                        transform: translateY(-50%);
                        background-position: 50% 50%;
                        background-size: 24px 24px;
                    }
                    .btn-search {
                        width: 32px;
                        right: -44px !important;
                        &::after {
                            margin-left: 0;
                            width: 24px;
                            height: 24px;
                            background-size: 100%;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 10.8137 4.18629 13.5 7.5 13.5Z' stroke='%230F0F0F' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='square'/%3E%3Cpath d='M12 12L16.5 16.5' stroke='%230F0F0F' stroke-width='1.2' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E");
                        }
                    }
                    .btn-camera {
                        width: 32px;
                        right: -44px !important;
                        &::after {
                            margin-left: 0;
                            width: 24px;
                            height: 24px;
                            background-size: 100%;
                        }
                    }
                    .btn-back {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5899 4.98476L5.83301 12.0007L12.5899 19.0167L11.7255 19.8491L4.16699 12.0007L11.7255 4.15234L12.5899 4.98476Z' fill='%230F0F0F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.79941 11.4004H21.5994V12.6004H4.79941V11.4004Z' fill='%230F0F0F'/%3E%3C/svg%3E");
                        background-position: center
                    }
                }

                // BTOCSITE-76488 검색 결과 내 장바구니 아이콘 추가
                .sch-utility {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 100%;
                    .btn-cart {
                        position: relative;
                        width: 24px;
                        height: 24px;
                        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='ic'%3E%3Cpath id='&%23237;&%23140;&%23168;&%23236;&%23138;&%23164; 48346' d='M1 1H3.96408L6.97323 16H19' stroke='%230F0F0F' stroke-width='1.2' stroke-linecap='square'/%3E%3Cpath id='&%23237;&%23140;&%23168;&%23236;&%23138;&%23164; 48347' d='M5 4H21L19.1742 13H6.19918' stroke='%230F0F0F' stroke-width='1.2'/%3E%3Cpath id='&%23237;&%23131;&%23128;&%23236;&%23155;&%23144; 157' d='M8.5 21C9.32843 21 10 20.3284 10 19.5C10 18.6716 9.32843 18 8.5 18C7.67157 18 7 18.6716 7 19.5C7 20.3284 7.67157 21 8.5 21Z' stroke='%230F0F0F' stroke-width='1.2' stroke-linecap='square'/%3E%3Cpath id='&%23237;&%23131;&%23128;&%23236;&%23155;&%23144; 158' d='M18.5 21C19.3284 21 20 20.3284 20 19.5C20 18.6716 19.3284 18 18.5 18C17.6716 18 17 18.6716 17 19.5C17 20.3284 17.6716 21 18.5 21Z' stroke='%230F0F0F' stroke-width='1.2' stroke-linecap='square'/%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 22px
                    }
                    .count {
                        position: absolute;
                        top: 0;
                        right: -4px;
                        display: block;
                        min-width: 14px;
                        height: 14px;
                        padding: 2px 4px;
                        font-weight: 700;
                        font-size: 10px;
                        color: #fff;
                        text-align: center;
                        line-height: 10px;
                        background-color: #ea1917;
                        border-radius: 8px;
                    }
                }
            }
        }

        &.focus-on {
            .sch-input-wrap {
                .input-sch {
                    z-index: 3;
                    input.txt {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    .related-keyword {
        display: none;
        position: relative;
        max-width: 726px;
        height: auto;
        margin: 16px auto;
        padding-right: 0;
        text-align: center;
        overflow: hidden;
        @include mobile {
            padding: 0;
            margin: 4px 0 0;
        }

        .inner {
            position: relative;
            display: flex;
            padding-left: 78px;
            text-align: left;
            @include mobile {
                width: 100%;
                padding-left: 16px;
            }
        }

        .title {
            position: relative;
            top: 0;
            padding-right: 16px;
            font-weight: 700;
            @include font-small;
            line-height: 38px !important;
            &::after {
                // content: ""; 
                //BTOCSITE-59845 [검색고도화] Quick-Win: 검색어 입력 개선 -연관검색어에서 회색 줄 안보이게 처리
                position: absolute;
                top: 14px;
                right: 6px;
                width: 1px;
                height: 10px;
                background-color: #ddd;
                @include mobile {
                    top: calc(50% - 5px);
                    right: 8px;
                }
            }
        }

        .keyword-list {
            display: inline-block;
            margin-bottom: -4px;
            margin-right: 0;
            font-size: 0;
            text-align: left;
            @include mobile {
                max-width: 100%;
                white-space: nowrap;
                overflow-y: hidden;
                overflow-x: auto;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }

            li {
                display: inline-block;
                margin: 0 16px 0 0;
                &:nth-last-child(1) {
                    margin-right: 0;
                }
                &:last-child {
                    padding-right: 16px;
                }

                a {
                    display:inline-block;
                    font-size: 14px;
                    line-height:20px;
                    color: #666;
                    margin:1px;
                    padding: 8px 0;
                    border: 0;
                    border-radius: 0;
                    @include mobile {
                        font-size: 13px;
                    }

                    &:hover {
                        //margin:0;
                        border: 0;
                    }
                }
            }
        }



        .mobile-more-btn {
            display: none;
            position: absolute;
            top: 13px;
            right: 0;

            .btn-more {
                display: block;
                width: 16px;
                height: 16px;
                background: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat 0 0;
                transform: rotate(90deg);
                transition: transform .3s .1s ease-out;
            }
        }

        &.open {
            height: auto;

            .mobile-more-btn {
                .btn-more {
                    transform: rotate(270deg);
                }
            }
        }
        
    }
    .search-input-layer {
        display: none;
        position: absolute;
        top: 74px;
        right: 0;
        left: 0;
        z-index: 25;
        margin-right: 48px;
        padding: 0 0 40px 0;
        border-radius: 16px;
        border: 0;
        background: #fff;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
        .btn-close {
            position: absolute;
            right: 24px;
            bottom: 24px;
            width: 24px;
            height: 24px;
            background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat 0 0;
            @include mobile {
                right: 18px;
                bottom: 18px;
            }
            span {
                @include blind;
            }
        }

        .not-result {
            padding: 32px 0 64px;
            @include font-medium;
            text-align: center;
        }

        .search-keyword-area {
            display: block;
            width: 100%;
            padding: 0 32px;
            margin-top: 40px;
            @include mobile {
                display: block;
                padding: 0;
                margin-top: 24px;
            }
            .recent-keyword {
                display: block;
                width: 100%;
                margin-bottom: 40px;
                padding: 0 !important;
                border-bottom: 0;
                .all-delete {
                    position: absolute;
                    top: 4px;
                    right: 0;
                    @include font(14px,20px);
                    text-decoration: underline;
                    @include mobile {
                        @include font(12px,17px)
                    }
                }
            }
            .popular-keyword {
                display: block;
                width: 100%;
                margin-bottom: 40px;
                padding: 0;
                .title{
                    margin-bottom: 24px;
                    @include mobile {
                        margin-bottom: 12px;
                    }
                }
            }
            .symptom-keyword {
                display: block;
                width: 100%;
                padding-top: 24px;
                border-top: 1px solid #ddd;
                .title{
                    margin-bottom: 24px;
                    @include mobile {
                        margin-bottom: 12px;
                    }
                }
            }

            .title {
                display: block;
                margin-bottom: 18px;
                font-weight: 700;
                @include font(18px,30px)
            }
            &.obs {
                .symptom-keyword {
                    display: none !important;
                }
                @include mobile {
                    .popular-keyword {
                        margin-bottom: 0;
                        padding-bottom: 24px;
                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background: #ddd;
                        }
                    }
                    .layer_close {
                        margin-top: 24px;
                    }
                }
            }
        }

        @include mobile {
            top: 70px;
            width: 100%;
            height: 100vh;
            padding: 8px 20px 42px;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            .auto-complete {
                margin: 0;
            }
            .search-keyword-area {
                .recent-keyword {
                    display: block;
                    width: 100%;
                    .keyword-list {
                        margin: 0 -20px 0 -20px;
                        ul {
                            li {
                                &:first-child {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }

                .popular-keyword {
                    display: block;
                    width: 100%;
                    padding-left: 0;
                    margin-bottom: 32px;
                    &::after {
                        content: none;
                    }
                }

                .title {
                    @include font(16px,22px);
                }
            }
            .not-result {
                padding: 16px 0 40px;
            }
        }
    }
    .auto-complete {
        margin-top: 40px;
        @include mobile {
            margin-top: 0;
        }
        .keyword-list {
            //margin-top: -4px;
            @include mobile {
                margin-top: 16px;
                padding-left: 52px;
            }
            li {
                display: flex;
                margin-top: 0;
                padding: 0;
                //background: url('/lg5-common/images/icons/btn-autocomplete-26.svg') no-repeat right 24px center/24px;
                @include mobile {
                    border-radius: 4px;
                    //background-size: 16px;
                    //background-position: right 12px center;
                }

                &:first-child {
                    margin-top: 0;
                }

                a {
                    display: block;
                    width: calc(100% - 58px);
                    padding: 16px 0 16px 32px;
                    @include font(18px,26px);
                    @include mobile {
                        width: calc(100% - 45px);
                        padding: 8px 0 8px 8px;
                        @include font(15px,21px);
                    }
                }

                button.keyword_select {
                    width: 58px;
                    height: 58px;
                    padding: 0;
                    color: transparent;
                    font-size: 0;
                    background: url('/lg5-common/images/icons/btn-autocomplete-26.svg') no-repeat right 24px center/24px;
                    @include mobile {
                        width: 45px;
                        height: 37px;
                        background-size: 16px;
                        background-position: right 12px center;
                    }
                }

                &.on {
                    border-radius: 0;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    a {
                        font-weight: 400;
                    }
                }
                &:hover {
                    border-radius: 0;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    a {
                        font-weight: 400;
                    }
                }
                &:focus {
                    border-radius: 0;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    a {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .input-result-list {
        li {
            margin-top: 16px;
            @include textEllipsisClamp(1);

            &:first-child {
                margin-top: 0;
            }
        }
    }
    .search_word_area {
        overflow: initial;
        margin: 0;
        .search_word {
            @include font(14px,20px);
            font-weight: 700;
            color: #666;
            em {
                font-weight: 400;
            }
        }
    }
    .search-similar {
        margin: 8px 0 0 0;
        text-align: center;
        p {
            display: none;
            margin-top: 80px;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            text-align: center;
            .search_word_area {
                display: block;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666;
                word-break: break-all;
                @include mobile {
                    margin-bottom: 12px;
                }
                .search_word {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 700;
                    color: #666;
                    line-height: 20px;
                    @include mobile {
                        font-size: 12px;
                    }
                    .word_area {
                        display: inline-block;
                        vertical-align: middle;
                        max-width: 250px;
                        margin-bottom: 0;
                        @include textEllipsis;
                        @include font(14px,20px);
                        font-weight: 700;
                        color: #666;
                        @include mobile {
                            max-width: 160px;
                            @include font(12px,17px);
                        }
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 8px;
                        word-break: break-all;
                        @include mobile {
                            margin-left: 4px;
                        }
                    }
                }
            }
            .search_word_msg {
                display: block;
                margin-top: 8px;
                font-size: 18px;
                font-weight: 400;
                color: #000;
                line-height: 26px;
                @include mobile {
                    font-size: 16px;
                    line-height: 22px;
                }
                em {
                    font-weight: 700;
                }
            }
            .search_word_link {
                display: inline-block;
                margin-top: 8px;
                padding-right: 20px;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                background: url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat right center/ 16px;
                color: #000;
                @include mobile {
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 17px;
                }
                &.similar-text {
                    &::after {
                        content: none;
                    }
                }
            }
        }
        .search-result-text {
            margin-bottom: 0;
            @include font(18px,26px);

            .search-word {
                font-weight: 700;
                color: $color-default;
                .word {
                    color: $color-primary;
                    word-break: break-all;
                }
            }
        }
        .similar-text {
            @include font($font-small,24px);
            text-decoration: underline;

            &:after {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('/lg5-common/images/icons/btn-arr-20x20-gray.svg') no-repeat 0 0;
                vertical-align: -5px;
                content: '';
            }
        }
        @include mobile {
            margin-top: 32px;
            .search-result-text {
                margin-bottom: 12px;
                @include font(16px,22px);
                font-weight: 700;
            }
            .similar-text {
                @include font($font-small - 1,21px);
                line-height: 21px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .subRenewWrap {
            .contents {
                &.search {
                    .input-keyword-wrap {
                        position: fixed;
                        top: 0;
                        width: 100%;
                        padding: 12px 16px 12px;
                        z-index: 100;
                        background: $color-bg-wt;
                        .btn-back {
                            position: relative;
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin: 11px 8px 11px 0;
                            background: url(/lg5-common/images/icons/btn-arr-32-left.svg) no-repeat 50% 50%;
                            background-size: 24px;
                            vertical-align: top;
                        }
                        .input-keyword {
                            display: inline-block;
                            width: calc(100% - 36px);
                            .sch-input-wrap {
                                .input-sch {
                                    input {
                                        &.txt {
                                            padding: 0 82px 0 21px;
                                            font-size: 15px;
                                            line-height: 1.6;
                                            border: 1px solid $color-border;
                                            border-radius: 46px;
                                        }
                                    }
                                    .btn-search {
                                        right: 16px;
                                    }
                                    .btn-delete {
                                        right: 56px;
                                    }
                                }
                            }
                        }
                    }
                    .search-tabs-wrap {
                        padding-top: 5px;
                    }
                }
            }
            &.isHide {
                .input-keyword-wrap {
                    z-index: 99;
                }
            }
        }
    }

    & ~ footer {
        position: relative;
        z-index: 20;
    }

    @include mobile {
        .search-input-layer {
            .auto-complete {
                .keyword-list {
                    padding-left: 44px;
                }
                .srch-direct-wrap + .keyword-list {
                    margin-top: 0;
                }
            }
        }
    }
    
}
.search-layer.re22 {
    .input-keyword-wrap {
        @include mobile {
            padding-top: 0;
        }
    }
    .auto-complete {
        .keyword-list {
            li {
                //background: url('/lg5-common/images/icons/btn-autocomplete-26.svg') no-repeat right 18px center/24px;
                background: none;
                &:focus {
                    border-radius: 8px;
                    a {
                        font-weight: 400;
                    }
                }
                &:hover {
                    border-radius: 8px;
                    a {
                        font-weight: 400;
                    }
                }
                &.on {
                    border-radius: 8px;
                    a {
                        font-weight: 400;
                    }
                }
            }
        }
        @include mobile {
            .keyword-list {
                margin-top: -12px;
                padding-left: 40px;
                padding-right: 12px;
                li {
                    a {
                        padding: 8px 32px 8px 32px;
                        @include font(15px,21px);
                    }
                    &:focus {
                        border-radius: 4px;
                        a {
                            font-weight: 400;
                        }
                    }
                    &:hover {
                        border-radius: 4px;
                        a {
                            font-weight: 400;
                        }
                    }
                    &.on {
                        border-radius: 4px;
                        a {
                            font-weight: 400;
                        }
                    }
                }
            }
            .srch-direct-wrap + .keyword-list {
                margin-top: 0;
            }
        }
    }
}