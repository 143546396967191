// 제품리스트(gray-box list, script)
.breadcrumb {
	display:block;
	flex-wrap:inherit;
	margin:0;
	padding:0;
	border-radius:0;
	border-top: 1px solid $color-border;
	background-color:inherit;
	&.edm-bc {
		ul {
			max-width: 1720px;
			padding-left: 40px;
		}
	}
	ul {
		max-width: 1760px;
		margin: 0 auto;
		padding: 8px 42px;  //  BTOCSITE-58606 B2C, B2B GNB 브래드크럼 영역의 정렬 정리
		font-size:0;
		> li {
			display:inline-block;
			a,
			span,
			strong {
				@include font-x-small;
				font-weight:500;
			}
		}
		> li + li:before {
			content:' ';
			display:inline-block;
			width:16px;
			height:16px;
			margin:0 4px;
			background:url('/lg5-common/images/icons/btn_arrow_right_16.svg') no-repeat 0 0;
			vertical-align:-4px;
		}
	}
	@include mobile {
		display:none;
	}
	
}