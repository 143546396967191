// Flag
.flag-wrap {
	font-size:0;
	.flag {
		display:inline-block;
		height:22px;
		margin-left:4px;
		color:$color-primary;
		@include font-x-small;
		line-height:22px;
		font-weight:700;
		vertical-align:middle;
		&:first-child {
			margin-left:0;
		}
		&.gray {
			border-color:#616161;
			color:#616161;
		}
		&.green {
			color: #007E82; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
		}
		&.blue {
			color: #6236ff;
		}
		&.end {
			border-color:#CCCCCC;
			color:#CCCCCC;
		}
		@include mobile {
			font-size:12px;
			line-height:22px;
		}
		// 20210802 BTOCSITE-3345 기획전 PLP 영역 가격노출 및 뱃지 적용 수정, 비활성화 시 .disabled 추가
		&.disabled {
			background-image: url('/lg5-common/images/icons/btn-cart-32-gray.svg');
			cursor: default;
		}
		// 20210802 BTOCSITE-3345 기획전 PLP 영역 가격노출 및 뱃지 적용 수정, 비활성화 시 .disabled 추가
	}
	// 20210809 BTOCSITE-3345 기획전 PLP 영역 가격노출 및 뱃지 적용 수정, 비활성화 시 .disabled 추가
	&.bg-type {
		.flag {
			height:24px;
			padding:0 12px;
			border-radius:4px;
			border: 1px solid rgba(0,0,0,.4);
			font-weight: 500;
			color:$color-default;
			line-height:22px;
			//@include mobile {
			//	font-size:12px;
			//	line-height:22px;
			//}

			// S : BTOCSITE-29983 모델 판매상태 신규값 추가에 따른 전시영역 수정
			&.colortypy01 {
				padding: 0 8px;
				color: #6735ea;
				background-color: #f7f3ff;
				border-color: #f7f3ff;
			}
			&.colortypy02 {
				padding: 0 8px;
				color: $color-primary;
				font-weight: 700;
				border-color: $color-primary;
			}
			&.soldout {
				padding: 0 8px;
				color: #fff;
				background-color: #666;
				border-color: #666;
			}
			@include mobile {
				&.colortypy01, &.colortypy02 {
					margin-right: 5px;
					height: 18px;
					padding: 2px 4px 3px;
					font-size: 11.5px;
					font-weight: 500;
					line-height: 1;
				}
				&.soldout {
					height: 20px;
					padding: 3px 4px 4px;
					font-size: 11px;
					line-height: 1;
					font-weight: 400;
				}
			}
			&.cardDiscount,
			&.box{
				border:0;
				background-color: $color-primary;
				font-weight: 500;
				color: #fff;
				padding: 0 5px;
				border-radius: 2px;
				height: 18px;
				line-height: 18px;
				&:before {
					display: none !important;
				}
			}
			// E : BTOCSITE-29983 모델 판매상태 신규값 추가에 따른 전시영역 수정
			//BTOCSITE-24797 [렛츠그램] VOC기반 CX고도화 과제 
			&.colortypy03 {
				padding: 0 8px;
				color: #fff;
				font-weight: $font-weight-medium;
				background-color: $color-primary;
				border: none;
			}
		}
	}
	// 20210820 BTOCSITE-4226 뱃지 생성 및 적용 요청 
	&.bar-type {
		.flag {
			min-width: auto;
			margin:0;
			padding: 0;
			border: 0;
			font-size: $font-x-small;
			line-height: 22px;
			&:not(:first-child) {
				&:before {
					display: inline-block;
					width: 1px;
					height: 10px;
					margin: 0 6px;
					background-color: $color-border;
					vertical-align: -1px;
					content: '';
				}
			}
			&.cardDiscount,
			&.box{
				background-color: $color-primary;
				font-weight: 500;
				color: #fff;
				padding: 0 5px;
				border-radius: 2px;
				height: 18px;
				line-height: 18px;
				&:before {
					display: none !important;
				}
			}
			// 20210809 BTOCSITE-3345 기획전 PLP 영역 가격노출 및 뱃지 적용 수정, 비활성화 시 .disabled 추가
		}
	}
	&.box-type {
		.flag {
			min-width: auto;
			height: 24px;
			margin: 0;
			padding: 5px 8px;
			border: 1px solid #a7a7a7;
			border-radius: 4px;
			font-weight: 500;
			font-size: $font-x-small;
			line-height: 1;
			color: $color-default;
		}
	}
	&.icon-type {
		.flag {
			padding: 2px 4px 2px 24px;
			border-radius: 4px;
			background: #f7f7f7 no-repeat left 4px center; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
			font-size: 10px;
			line-height: 18px;
			color: $color-default;
			&.cash {
				background-image: url('/lg5-common/images/icons/icon-cash-16.svg');
			}
			&.gift {
				background-image: url('/lg5-common/images/icons/icon-gift-16.svg');
			}
		}
	}
}

// design flag
.big-flag {
	display:inline-block;
	width:60px;
	height:60px;
	padding:10px 0;
	font-size:0;
	font-weight:500;
	text-align:center;
	z-index:10;
	vertical-align:top;
	&.left {
		border-radius:4px 0 4px 0;
	}
	&.right {
		border-radius:0 4px 0 4px;
	}
	@include mobile {
		width:48px;
		height:48px;
		padding:8px 0;
	}
	span {
		display:inline-block;
		font-size:12px;
		line-height:20px;
		vertical-align:top;
		@include mobile {
			font-size:10px;
			line-height:16px;
		}
	}
	&.type1 {
		span {
			padding-left:5px;
			letter-spacing:5px;
			@include mobile {
				padding-left:2px;
				letter-spacing:2px;
			}
		}
	}
	&.type2 {
		span {
			padding-left:2px;
			letter-spacing:2px;
			@include mobile {
				padding-left:1px;
				letter-spacing:1px;
			}
		}
		em {
			display:inline-block;
			margin-left:-2px;
			font-size:16px;
			font-weight:700;
			line-height:20px;
			letter-spacing:0;
			vertical-align:top;
			@include mobile {
				font-size:14px;
				line-height:16px;
			}
		}
	}
	&.red {
		background-image:linear-gradient(#F04E3E, #EC455A);
		color:#fff;
	}
	&.green {
		background:#65c467;
		color:#fff;
	}

}

.flag-wrap.image-type {
	// 20210820 BTOCSITE-4226 뱃지 생성 및 적용 요청 
	.big-flag {
		// position: relative;
		// display: block;
		width: 56px;
		height: 58px;
		padding: 0;
		// &:not(:first-child) {
		// 	margin-top: -10px;
		// }

		@include mobile {
			width:42px;
			height:46px;
		}

		&:nth-child(1) {z-index: 14;}
		&:nth-child(2) {z-index: 13;}
		&:nth-child(3) {z-index: 12;}
		&:nth-child(4) {z-index: 11;}
		
		img {
			max-width: 100%;
		}
	}

	&.left {
		.big-flag {
			&:nth-child(1) {
				img {
					border-top-left-radius: 8px;
				}
			}
		}
	}
	&.right {
		.big-flag {
			&:nth-child(1) {
				img {
					border-top-right-radius: 8px;
				}
			}
		}
	}
}