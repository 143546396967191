@charset "UTF-8";
/* video-asset start */
.video-asset {
	width: 100%;
	height: 56.25vw;
	position: relative;
	overflow: hidden;
	iframe {
		border: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		// transform: translateY(-50%);
	}
}

.video-link {
	.see-video {
		display:inline-block;
		font-size:16px;
		line-height:32px;
		font-weight:500;
		vertical-align:top;
		@include mobile {
			font-size:14px;
		}
		&:before {
			display: inline-block;
			width: 32px;
			height: 32px;
			margin-right: 8px;
			vertical-align:top;
			background: no-repeat 0 0;
			content: '';
			background:url('/lg5-common/images/icons/btn-play-32-black.svg') center no-repeat;
		}
		&.bc-black {
			&:before {
				background:url('/lg5-common/images/icons/btn-play-32-black.svg') center no-repeat;
			}
		}
		&.bc-white {
			&:before {
				background:url('/lg5-common/images/icons/btn-play-32-white.svg') center no-repeat;
			}
		}
	}
}

.component .visual-area  { 
	position: relative;
}

.visual-area {
	&.video-box {
		position: relative;
	}

	&.video {
        text-align:center;
		.see-video {
            position:relative;
			display:inline-block;
			max-width:100%;
            vertical-align:top;
			&:before {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 48px;
				height: 48px;
				margin: -24px 0 0 -24px;
				background: url('/lg5-common/images/btn-video-play.svg') no-repeat 0 0;
				background-size: 100% 100%;
				border-radius: 50% 50%;
				z-index:10;
			}
			&.acc-video-content {
				position:absolute;
				top:16px;
				right:16px;
				width:24px;
				height:24px;
				overflow:hidden;
				z-index:9;
				text-indent:2000%;
				white-space:nowrap;
				&:before {
					content:'';
					display:inline-block;
					position:absolute;
					top:0;
					right:0;
					left:inherit;
					width:24px;
					height:24px;
					margin:0;
					background:url('/lg5-common/images/btn-AD-on.svg');
					background-size:100%;
					border-radius:inherit;
				}
			}
		}
	}

	.caption {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 5;
		overflow: hidden;
		padding: 12px 24px;
		height: 44px;
		background-color: rgba(0,0,0,0.5);
		font-weight: 700;
		font-size:14px;
		line-height: 20px;
		color: #fff;
		text-align: left;
		white-space: nowrap;
	}
}

// video close common button
.video-box-closeset {
	&.video-modal {
		button.close-video {
			top: 20px; 			
		}
		button.change-video, button.acc-change-video {
			top:63px;
		}
	}
	button.close-video {
		overflow:hidden;
		position: absolute;
		right: 20px;
		top: 62px;		
		width:36px;
		height:36px;
		border-radius: 18px;
		background-color: #fff;
		white-space:nowrap;
		text-indent:200%;
		border:0;
		z-index: 1;
		cursor: pointer;
		&:before,
		&:after {
			display: inline-block;
			position: absolute;
			@include calc(top,50%,12px);
			@include calc(left,50%,1px);
			width: 2px;
			height: 24px;
			background-color: #111;
			content: '';
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
}

// Video asset inside components
.video-box {
	position: relative;
	.video-asset {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		z-index: 1;
	}
}

.video-modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: #fff;
	.modal-video-asset {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		overflow-y: auto;
		max-width:none;
		height: 100vh;
		padding:8% 0 5%;
		@include mobile {
			height:auto;
			// padding-top:18%;
			padding-top:3%;
		}
		// S : BTOCSITE-77411 [웹앱 접근성 개선활동] 전시_고객지원_LG 베스트 케어
		.video-asset {
			position:relative;
			max-width: 1284px;
			max-height: 722px;
			margin: 0 auto;
			@media screen and (min-aspect-ratio: 16/9) {
				height: 100vh;
			}
			.video-caption-wrap {
				position:absolute;
				top:24px;
				right:24px;
				.btn-video {
					padding: 0 8px;
					height: 28px;
					background: rgba(0, 0, 0, 0.5);
					border: 1px solid #fff;
					border-radius: 99px;
					color: #fff;
					font-size: 13px;
					line-height: 21px;
					font-weight: 500;	
				}
				@include mobile {
					top:16px;
					right:16px;
				}	
			}
		}
		// E : BTOCSITE-77411 [웹앱 접근성 개선활동] 전시_고객지원_LG 베스트 케어
	}
	&.youtube {
		.video-asset {
			@media screen and (max-aspect-ratio: 16/9) {
				height: calc((100vw - 15px) * (9/16));
			}
		}
	}

	/* Caption 추가 */
	.cap-section{
		max-width: 1284px;
		max-height: 722px;
		margin: 0 auto;
		white-space: nowrap;
		@include mobile {
			padding:0 16px;
		}
		h2{
			margin:32px 0 0 0;
			font-size:24px;
			line-height:34px;
			white-space: normal;
			@include mobile {
				font-size:20px;
				line-height:28px;
			}
		}
		.rectangle {
			width: 100%;
			height: 240px;
			margin: 16px 0 0;
			padding: 24px;
			border-radius: 8px;
			border: solid 1px #dddddd;
			background-color: #ffffff;
			overflow-y: hidden;
			white-space: normal;
			@include font-small;
			&:hover,
			&:focus {
				overflow-y:overlay;
			}
			@include mobile {
				padding: 12px 16px;
				@include font-x-small;
			}
			// font opion style
			&.font-regular {
				@include font-family($font-weight-regular);
			}
			&.font-bold {
				@include font-family($font-weight-bold);
			}
			&.font-regular-italic {
				@include font-family($font-weight-regular, italic);
			}
			&.font-bold-italic {
				@include font-family($font-weight-bold, italic);
			}
			&.font-underline {
				text-decoration: underline;
			}
		}
		// S : BTOCSITE-77411 [웹앱 접근성 개선활동] 전시_고객지원_LG 베스트 케어
		&.cap-bcs {
			display:none;
			position:relative;
			padding:24px;
			margin-top:32px;
			border:1px solid #ddd;
			border-radius:8px;
			h2 {
				margin-top:0;
				font-size:15px;
				line-height:22px;	
			}
			.box {
				margin-top:13px;
				height:70px;
				overflow-y:auto;
				font-size:14px;
				line-height:24px;
				white-space:normal;
			}
			.btn-close {
				position: absolute;
				top: 20px;
				right: 20px;
				width: 16px;
				height: 16px;	
				&:after {
					content: '';
					display: block;
					width: 16px;
					height: 16px;
					background: url(/lg5-common/images/icons/btn-close-32.svg) no-repeat;
					background-size: 100%;	
				}
			}
			@include mobile {
				padding:12px;
				margin:10px 8px 0;
				width:calc(100% - 16px);
				h2 {
					padding-right:40px;
				}
				.box {
					margin-top:6px;
					height:84px;
					line-height:20px;
				}
				.btn-close {
					top:12px;
					right:12px;
				}
			}
		}
		// E : BTOCSITE-77411 [웹앱 접근성 개선활동] 전시_고객지원_LG 베스트 케어
	}
	/* //Caption 추가 */
}

html {
	&.modal-open {
		overflow: hidden;
	}
}

// S : BTOCSITE-15718
#lge-pip-player {	
	.lge-ctrl {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 29.5px;
		height: 29.5px;
		background: url('/lg5-common/images/MYC/pip_video/icon_drag.svg') no-repeat 0 0;
		background-size: 22px;
		touch-action: none;
		z-index: 2147483647;
	}
	.lge-btn-back {
		position: absolute;
		left: 0;
		top: -32px;
		width: 80px;
		height: 32px;
		line-height: 32px;
		border: 1.5px solid #000;
		background: #fff;
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		touch-action: none;
		box-sizing: border-box;
	}
	&.jw-flag-floating {
		.lge-player-width-s {
			.jw-icon-inline,
			.jw-display .jw-icon {
				width: 25px;
				height: 25px;
				line-height: 25px;
				transition: all 0.3s;
			}
			.jw-button-container {
				padding: 0 8px;
			}
			.jw-autostart-mute {
				left: 8px;
				bottom: 8px;

			}
			
		}
		.jw-button-container {
			padding: 0;
		}
		.jw-autostart-mute {
			right: auto;
			left: 0;
			bottom: 0;
		}
		.jw-float-bar {
			display: block;
			left: 0;
			right: 0;
			top: auto;
			.jw-float-bar-title {
				display: none;
			}
		}	
		&.jw-flag-floating .jw-wrapper{
			border-radius: 10px;
			overflow: hidden;			
			top: auto;
			left: auto;
			right: 20px;
			bottom: 20px;
			background-color: transparent;
			.jw-float-bar {
				display:none;
			}
			.jw-controls {
				display:none;
			}
			.btn-area {
				.btn-top {
					position: absolute;
					top: 12px;
					right: 12px;
				}
				button {
					position: absolute;
					width: 24px;
					height: 24px;
					background: url('/lg5-common/images/MYC/pip_video/icon_expansion.svg') no-repeat center center;
					background-size: 100%;
					z-index: 1001;
					&.btn-stop,&.btn-play {
						display:block;
						bottom: 8px;
						left: 50%;
						background-size: 16px;				
						margin-left: -12px;
					}
					&.btn-expansion {
						position: relative;
						margin-right: 12px; 
					}
					&.btn-close {
						position: relative;
						background-image: url('/lg5-common/images/MYC/pip_video/icon_close.svg');
					}	
					&.btn-play {
						background-image: url('/lg5-common/images/MYC/pip_video/icon_play.svg');												
					}
					&.btn-stop {					
						background-image: url('/lg5-common/images/MYC/pip_video/icon_stop.svg');						
					}									
				}
				// &.wide {
				// 	button {
				// 		&.btn-expansion {
				// 			right: 20%;
				// 		}
				// 		&.btn-close {
				// 			right: 4.3%;
				// 		}
				// 	}
				// }
			}			
			.dimmed {
				display: none;
				position:fixed;
				top:0;
				left:0;
				right:0;
				bottom:0;
				width:100%;
				height:100%;
				background-color:#111;
				opacity:0.7;
				z-index:1000;
			}
		}	
	}
}
// E : BTOCSITE-15718
