.product-items {
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    @include mobile {
        margin: 0;
    }

    > li {
        float: left;
        padding: 0 6px 12px;
        @media screen and (min-width: $desktop-s-width + 1) {
            width: 33.333333%;
            &:nth-child(3n+1) {
                clear: left;
            }
            &.item-banner {
                &.large {
                    width: 66.666667%;
                }
                .item {
                    overflow: hidden;
                    padding: 0;
                    a {
                        display:block;
                        height:100%;
                    }
                }
            }
        }
        @media (min-width: $tablet-s-width + 1) and (max-width: $desktop-s-width) {
            width: 50%;
            &:nth-child(odd) {
                clear: left;
            }
            &.item-banner {
                &.large {
                    width: 50%;
                }
            }
        }
        @include mobile {
            float: none;
            width: 100%;
            padding: 0 0 12px;
            &.item-banner {
                &.large {
                    width: 100%;
                }
            }
        }
    }

    &.col4 {
        margin:-12px;
        @include mobile {
            margin:0;
        }
        > li {
            padding:12px 12px;
            @media screen and (min-width: $desktop-s-width + 1) {
                width:25%;
            }
            @media screen and (max-width: $desktop-s-width) {
                width:33.33%;
            }
            @media (min-width: $tablet-s-width) and (max-width: $tablet-l-width) {
                width:50%;
            }
            @include mobile {
                float: none;
                width: 100%;
                padding: 0 0 12px;
            }
        }
    }

    .item {
        position: relative;
        height: 100%;
        padding: 40px 48px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);

        @include pc {
            &.plp-item {
                padding-bottom: 138px;
            }
        }

        @include mobile {
            padding: 37px 40px 32px;
            a {
                display:block;
                height:100%;
                .product-image {
                    img {
                        width:100%;
                    }
                }
            }
        }

        .badge {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        .product-image {
            min-height: 250px;
            a {
                display: block;
                text-align: center;
                img {
                    width: 100%;
                    max-width: 250px;
                }
            }
            @include mobile {
                min-height: 200px;
                a {
                    img {
                        max-width: 200px;
                    }
                }
            }

            .slide-wrap {
                .indi-wrap {
                    display: none;
                }
                .slide-controls {
                    @include pc {
                        display: none;
                    }
                    @include mobile {
                        display: block;
                        transform: translateX(0);
                        .btn-arrow {
                            width: 40px;
                            height: 40px;
                            &:before {
                                width: 32px;
                                height: 32px;
                                background-image: url("/lg5-common/images/icons/btn-arr-64-black.svg");
                                background-size: 32px 32px;
                                box-shadow: none;
                            }
                            &.prev {
                                left: 8px;
                                &:before {
                                    transform: rotate(180deg);
                                }
                            }
                            &.next {
                                right: 8px;
                            }
                        }
                    }
                }
            }
        }

        .product-contents {
            position: relative;
            //height: 280px;
            @at-root .product-items {
                .item.result-item .product-contents {
                    height: auto;
                }
            }
            // S : TOCSITE-29983 모델 판매상태 신규값 추가에 따른 전시영역 수정
            .flag-wrap {
                &.bg-type {
                    margin-top:0!important;
                    .flag {
                        margin:3px 8px 4px 0; 
                        @include mobile {
                            margin-right:5px;
                        }
                        &.colortypy02 {
                            border:none;
                            padding:0;
                            font-weight:500;
                            @include mobile {
                                padding:2px 0 3px;
                            }
                        }
                    }
                }    
            }
            // E : TOCSITE-29983 모델 판매상태 신규값 추가에 따른 전시영역 수정
            @include pc {
                .flag-wrap {
                    &:first-child {
                        margin-top: 56px;
                    }
                }
            }
        }
        .product-option {
            position: relative;
            //width: 100%;
            margin: 5px -5px 12px;
            font-size: 0;
            text-align: center;
            &:before,
            &:after {
                content:' ';
                display:inline-block;
                position:absolute;
                top:0;
                z-index:1;
                width:24px;
                height:36px;
                
            }
            &:before {
                left:0;
                background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
            }
            &:after {
                right:0;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
            }
            @include mobile {
                margin: 16px -5px;
                &:before,
                &:after {
                    width:32px;
                    height:36px;
                    
                }
            }
            .option-list {
                display: inline-block;
                padding: 3px;
                font-size: 0;
                text-align:center;
                white-space: nowrap;
                li {
                    display: inline-block;
                    padding: 0 2px;
                }
            }
            .scroll-controls {
                display: none !important;
                position: absolute;
                top: 50%;
                left: 50%;
                //z-index: 10;
                transform: translateX(-50%);
                width: 100%;
                margin-top: -16px;
                .btn-arrow {
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    padding: 0;
                    border-radius: 0;
                    background:none;
                    box-shadow:initial;
                    &.prev {
                        left: 16px;
                        &:before {
                            transform:rotate(180deg);
                        }
                    }
                    &.next {
                        right: 16px;
                    }
                    &:before {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        background: url('/lg5-common/images/icons/btn-carousel-32.svg') no-repeat 0 0;
                    }

                }
                @include mobile {
                    display: none !important;
                }
            }

            &.color {
                .option-list {
                    li {
                        padding: 0 5px;
                    }
                }
                .chk-wrap-colorchip {
                    input {
                        & + label {
                            width:24px;
                            height:24px;
                            padding-left:0;
                        }
                        &:checked {
                            & + label {
                                &:after {
                                    border-color: $color-primary;
                                }
                            }
                        }
                        @include mobile {
                            & + label {
                                width:28px;
                                height:28px;
                                &:before {
                                    content:' ';
                                    position:absolute;
                                    top:3px;
                                    left:3px;
                                    width:22px;
                                    height:22px;
                                }
                                &:after {
                                    width:28px;
                                    height:28px;
                                }
                            }
                        }
                    }
                }
            }

            &.text {
                .rdo-wrap {
                    font-size: 0;
                    input {
                        & + label {
                            padding: 3px 12px;
                            border: 1px solid $color-border;
                            border-radius: 15px;
                            @include font-x-small;
                            &:before,
                            &:after {
                                display: none;
                            }
                        }
                        &:checked {
                            & + label {
                                border-color: $color-primary;
                            }
                        }
                    }
                }
            }
        }

        .flag-wrap {
            & + .product-name {
                margin-top: 0;
            }
            & + .product-info {
                padding-top: 0;
            }
        }

        .product-info {
            padding-top: 20px;
            //margin-bottom: 24px;
            .product-name {
                margin-top: 2px;
                //height: 48px;
                a {
                    font-weight: 700;
                    @include font-large;
                    @include textEllipsisClamp(2);
                    word-wrap:break-word;
                }
                // S : BTOCSITE-49147 [전시] 제품유닛 최소 적용안 중 제품명+속성값 적용
                .main-title {
                    font-size: 18px;
                    line-height: 28px;
                    .main {
                        font-weight: $font-weight-bold;
                    }
                    .sub {
                        // BTOCSITE-53236 (모바일) 홈메인, 기획전 : 상품명 노출 어색 건
                        font-weight: $font-weight-regular;
                        color: #333;
                        &::before {
                            content: '·';
                            margin:0 3px;
                            line-height: 27px;
                            font-weight: 400;
                        }
                    }
                    @include mobile {
                        font-size: 15px;
                        line-height: 23px;
                        overflow-wrap:anywhere;
                        .sub {
                            &::before {
                                line-height: 23px;
                            }
                        }
                    }
                }
                // E :  BTOCSITE-49147 [전시] 제품유닛 최소 적용안 중 제품명+속성값 적용
                }
            .sku {
                display: inline-block;
                margin-right:6px;
                font-size: $font-small;
                line-height: 24px;
                vertical-align: top;
                @include mobile {
                    line-height: 22px;
                }
            }
            .review-info {
                display: inline-block;
                font-size: 0;
                vertical-align: top;
                .star,
                .average-rating,
                .review-count {
                    display: inline-block;
                    font-size: $font-small;
                    line-height: 24px;
                    @include mobile {
                        line-height: 22px;
                    }
                }
                .star {
                    width: 20px;
                    height: 20px;
                    margin-right: 2px;
                    background: url("/lg5-common/images/icons/icon-star-off-20.svg") no-repeat 0 0;
                    vertical-align: -5px;
                    &.is-review {
                        background-image: url("/lg5-common/images/icons/icon-star-on-20.svg");
                    }
                }
            }
            .spec-info {
                margin: 6px 0 12px;
                font-size: $font-small;
                line-height: 14px;
                color: #000;
                li {
                    margin-top: 6px;
                    font-size:13px;
                    line-height:13px;
                    @include pc {
                        @include textClipClamp(1);
                    }
                    // BTOCSITE-3404 검색, PLP > 얼음정수기냉장고 1년무상케어 태그 추가 건
                    .care-n {
                        font-size: 13px;
                        line-height: 13px;
                        position: relative;
                        top: -1px;
                    }
                    .redcare-option {
                        margin-left: 10px;
                        margin-top: 6px;
                        font-size:13px;
                        line-height:13px;
                        color:$color-primary;
                    }
                }
                @include mobile {
                    margin: 8px 0;
                    li {
                        display: inline-block;
                        margin-top: 0;
                        font-size: 14px;
                        line-height: 22px;
                        &:after {
                            display: inline-block;
                            width: 1px;
                            height: 10px;
                            margin: 0 6px;
                            background-color: $color-border;
                            content: '';
                        }
                        &:last-child:after {
                            display: none;
                        }
                    }
                }
            }
            .spec-info2 {
                margin-top: 8px;
                font-size: 14px;
                line-height: 20px;
                color: #616161;
            }
            .spec-info3 {
                display: inline-block;
                @include font-small;
                color: $color-gray-1;
                vertical-align: top;
                &:before {
                    display: inline-block;
                    width: 1px;
                    height: 10px;
                    margin: 0 8px;
                    background-color: $color-border;
                    content: '';
                }
            }
        }
        .price-area {
            //@include clearfix;
            font-size: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: baseline;
            .total {
                //float: left;
                margin-right: 8px;
                font-weight: 700;
                font-size: $font-large-1;
                line-height: 30px;
            }
            .original {
                position: relative;
                display: inline-block;
                margin-right: 4px;
                font-size: $font-small;
                line-height: 24px;
                color: $color-gray-5;
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: #616161;
                    content: '';
                }
            }
            .discount {
                font-weight: 700;
                @include font-medium;
                color:$color-primary;
            }

            & + .hashtag-wrap {
                margin-top: 4px;
            }
            .small-text {
                display: block;
                margin-top: 4px;
                @include font-small;
            }

            @include mobile {
                .original {
                    font-size: 14px;
                    line-height: 22px;
                }
                .total {
                    font-size: $font-medium;
                    line-height: 24px;
                }
            }

            &.care {
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-end;
                .total-price {
                    .text {
                        font-weight: 500;
                        @include font-x-small;
                    }
                    .price {
                        display: block;
                        font-weight: 700;
                        @include font-large-1;
                    }
                }
                .small-text {
                    margin-top: 0;
                    margin-left: 12px;
                    @include font-small;
                    color: $color-gray-5;
                }
            }
        }
        .hashtag-wrap {
            margin-top: 60px;
            .hashtag {
                @include font-small;
            }

            @include mobile {
                margin-top: 0;
            }
        }

        .product-button {
            //@include pc {
            //    position: absolute;
            //    right: 47px;
            //    bottom: 36px;
            //    left: 47px;
            //
            //}
            margin: 24px -7px 0;
            .btn {
                width: 100%;
                min-width: auto;
            }
            &.single {
                margin: 24px 0 0;
            }
            &.double {
                display: flex;
                justify-content: center;
                margin: 24px 0 0;
                .btn {
                    @include calc(width,50%,4px);
                    &:not(:first-child) {
                        margin-left: 8px;
                    }
                }
            }
        }
        .product-wish {
            position: absolute;
            top: 24px;
            right: 24px;
        }
        .product-compare {
            position: absolute;
            top: 16px;
            right: 24px;
            a {
                font-weight: 500;
                @include font-small;
                &.on {
                    color: $color-primary;
                }
            }
            @include mobile {
                top: 10px;
            }
        }
        .product-ar {
            position: absolute;
            top: 16px;
            right: 24px;
            &:before {
                display: inline-block;
                width: 1px;
                height: 12px;
                margin-right: 4px;
                background-color: $color-border;
                content: '';
            }
            a {
                display: inline-block;
                padding-left: 28px;
                font-weight: 500;
                @include font-small;
                color: #6735ea;
                background: url('/lg5-common/images/icons/icon-ar-80.png') no-repeat left center;
                background-size: 24px;
            }

            & + .product-compare {
                right: 116px;
            }
            @include mobile {
                top: 10px;
                &:before {
                    height: 10px;
                }
                a {
                    padding-left: 24px;
                    background-size: 20px;
                }
                & + .product-compare {
                    right: 112px;
                }
            }
        }
        .product-bottom {
            @include pc {
                position: absolute;
                bottom: 32px;
                right: 46px;
                left: 46px;
            }
            .flag-wrap {
                margin-top: 2px;
                @include mobile {
                    margin-top: 5px;
                }
            }
            // S : TOCSITE-29983 모델 판매상태 신규값 추가에 따른 전시영역 수정
            .flag-wrap.bg-type {
                .flag.soldout {
                    margin-top:3px;
                    margin-bottom:4px;
                    @include mobile {
                        margin-bottom:0;
                    }       
                }
                & + .price-area { // BTOCSITE-77418 웹접근성
                    color:#8f8f8f;
                }
            }
            .price-area.care {
                .total-price {
                    .flag-wrap.stockout {
                        position:absolute;
                        display:inline-block;
                        margin:0;
                        margin-left:8px;
                        vertical-align:4px;
                        @include mobile {
                            margin-left:5px;
                        }
                        .flag {
                            margin:0;
                        }
                    }
                    .flag-wrap.bg-type {
                        & + .price {
                            color:#666;
                            opacity:.5;    
                        }
                    }
                }
            }
            // E : TOCSITE-29983 모델 판매상태 신규값 추가에 따른 전시영역 수정
        }
        .btn-area-wrap {
            display: table;
            width: 100%;
            margin-top: 12px;
            @include mobile {
                margin-top: 12px;
            }
            .wishlist,
            .cart,
            .btn-area {
                display: table-cell;
                vertical-align: middle;
            }
            .wishlist {
                width: 32px;
                padding-right: 16px;
            }
            .cart {
                width: 32px;
                padding-right: 24px;
                .btn-cart {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background: url('/lg5-common/images/icons/btn-cart-32.svg') no-repeat 0 0;
                    background-size: 32px 32px;
                    &.disabled {
                        // BTOCSITE-6375 
                        // background-image: url('/lg5-common/images/icons/btn-cart-32-gray.svg');
                        // cursor: default;
                        display: none;
                    }
                }
            }
            .btn-area {
                .btn {
                    display: block;
                    &:hover,
                    &:focus {
                        border-color: $color-primary;
                        color: $color-white;
                    }
                }
            }
        }
        &.evt-item {
            padding:20px 40px 210px; //BTOCSITE-5938-737 [UI] 모니터링 737 - 홈 > 이벤트/멤버십 > 기획전 2. 'LG 휘센 타워에어컨 렌탈 런칭 기념 기획전' '캐시백' 글씨 겹침
            @include mobile {
                padding:24px 36px 32px;
            }
            .product-image {
                a img {
                    max-width:240px;
                }
            }
            .product-bottom {
                bottom:48px;
                right:40px;
                left:40px;
                .discount {
                    order:-1;
                    line-height:32px;
                }
            }
            .product-button {
                margin:16px 0 0;
            }
        }

        // S : BTOCSITE-65935 3차 기획전 제작 모듈화 개발_상품유닛 수정
        .verify-wrap {
            margin:12px 0;
            .veri-badge {
                display:inline-block;
                width:100%;
                height: 26px;
                padding:4px 10px;
                background:#333;
                color:#fff;
                border-radius:3px;
                font-size:12px;
                line-height:1.5;
                font-weight:500;
                text-align:center;
            }
            @include mobile {
                margin:12px 0 16px;
                .veri-badge {
                    padding:4px 8px;
                    border-radius:3px;
                    font-size:12px; 
                    line-height:18px;
                }    
            }    
        }
        // E : BTOCSITE-65935 3차 기획전 제작 모듈화 개발_상품유닛 수정
    }

    .item-banner {
        .item {
            overflow: hidden;
            padding: 0;
            .flag-wrap {
                position: absolute;
                top: 16px ;
                right: 16px;
                z-index: 1;
                .flag {
                    height: auto;
                    padding: 5px 10px;
                    border: 1px solid #000;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: $font-x-small;
                    line-height: 1;
                    color: $color-default;
                }
            }
            .product-image {
                overflow: hidden;
                height: 100%;
                min-height: auto;
                border-radius: 8px;
                a {
                    position: relative;
                    height: 100%;
                }
                img {
                    max-width: none;
                    @media screen and (min-width: 768px) {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: auto;
                        height: 100%;
                    }
                    &.pc-only {
                        display: block;
                    }
                    &.mo-only {
                        display: none;
                    }
                    @include mobile {
                        &.pc-only {
                            display: none;
                        }
                        &.mo-only {
                            display: block;
                        }
                    }
                }
            }

            .banner-contents {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                width: 100%;
                padding: 80px 48px;
                text-align: center;
                @include mobile {
                    padding:60px 40px;
                }

                .banner-info {
                    .title {
                        margin-bottom: 8px;
                        font-weight: 700;
                        @include font($font-large-3,34px);
                        @include mobile {
                            font-size:24px;
                            line-height:33px;
                            word-break:keep-all;
                            word-wrap:break-word;
                        }
                    }

                    .sub-copy {
                        font-weight: 500;
                        @include font($font-large,28px);
                        @include mobile {
                            @include font($font-small,22px);
                        }
                    }
                }

                .banner-button {
                    margin-top: 24px;

                    .btn {
                        min-width: 126px;
                        background-color: transparent;
                    }
                }
            }
        }

        &.large {

            .product-image {
                overflow: hidden;
                width: 100%;
                a {
                    position: relative;
                    height: 100%;
                }
                //img {
                //    width: 100%;
                //}
            }

            // pc
            @media screen and (min-width: $desktop-s-width + 1) {
                //.item {
                //    display: flex;
                //    flex-wrap: wrap;
                //    flex-direction: row;
                //    .product-image {
                //        @include calc(height, 100%, 208px);
                //        border-radius: 8px 8px 0 0;
                //    }
                //    &.result-item {
                //        .product-image {
                //            @include calc(height, 100%, 133px);
                //        }
                //    }
                //}
                .banner-contents {
                    //position: static;
                    top: auto;
                    bottom: 0;
                    padding: 32px 48px 40px;
                    background-color: $color-bg-wt;
                    .banner-info {
                        .title {
                            @include textEllipsisClamp(1);
                        }
                    }
                    .banner-button {
                        margin-top: 16px;
                        .btn {
                            min-width: 172px;
                        }
                    }
                }
            }
            @media (min-width: $tablet-s-width + 1) and (max-width: $desktop-s-width) {
                .product-image {
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                .banner-contents {
                    padding: 64px 54px;
                }
            }
        }
    }


    // 기획전 상세 하단 small타입 추가
    &.small-type {
        display:block;
        margin:-20px -10px 0;
        @include mobile {
            margin:-12px 20px 0;
        }
        > li {
            width:calc(100% / 3);
            margin-top:20px;
            padding:0 10px;
            @media (min-width: 768px) and (max-width: 1284px) {
                width:50%;
            }
            @include mobile {
                width:100%;
                margin-top:12px;
                padding:0;
            }
            .item {
                padding:0 40px 36px;
                .product-image {
                    margin:0 -19px 0;
                    @include mobile {
                        margin:0 auto;
                        max-width:200px;
                    }
                }
                .product-contents {
                    height:auto;
                    .product-info {
                        padding-top:16px;
                    }
                }
                .price-area {
                    margin-top:12px;
                }
            }
        }
    }

    // S : BTOCSITE-65935 3차 기획전 제작 모듈화 개발_상품유닛 수정    
    &.new-type {
        & > li {
            margin-top: -1px;
            margin-left: -1px;
            padding: 0;
            width: 25%;
            border: 1px solid #eee;
            @media screen and (min-width: $desktop-s-width + 1) {
                width: 33.333333%;
                &:nth-child(3n+1) {
                    clear: left;
                }
                &.item-banner {
                    &.large {
                        width: 66.666667%;
                    }
                    .item {
                        overflow: hidden;
                        padding: 0;
                        a {
                            display:block;
                            height:100%;
                        }
                    }
                }
            }
            @media (min-width: $tablet-s-width + 1) and (max-width: $desktop-s-width) {
                width: 50%;
                &:nth-child(odd) {
                    clear: left;
                }
                &.item-banner {
                    &.large {
                        width: 50%;
                    }
                }
            }
            @include mobile {
                float: none;
                padding: 0;
                border-left: none;
                border-right: none;
                border-radius: 0;
            }
        }
        &.col4 {
            margin: 0;
            @include mobile {
                margin:0;
            }
            & > li {
                @media screen and (min-width: $desktop-s-width + 1) {
                    width:25%;
                }
                @media screen and (max-width: $desktop-s-width) {
                    width:33.33%;
                }
                @media (min-width: $tablet-s-width) and (max-width: $tablet-l-width) {
                    width:50%;
                }
            }
        }
        .item {
            padding: 32px 24px;
            display: flex;
            flex-direction: column;
            border-radius: 0;
            box-shadow: none;
            text-align: left;

            &.soldout, &.sold-out {
                .price-area {
                    color: #8f8f8f;
                    &.care {
                        .small-text {
                            color: #8f8f8f;
                        }
                    }
                    .discount {
                        color: #8f8f8f;
                    }
                }
                .benefit-price {
                    color: #000;
                }
                .benefit-price {
                    color: #8f8f8f;
                    &.emp {
                        color: #8f8f8f;
                    }
                }
                .timeDeal-box {
                    &::before {
                        content: '';
                        background: url('/lg5-common/images/icons/badge_timedeal_2_soldout.svg') no-repeat 0 50% / auto 100%;                           
                    }
                    .icon-time {
                        background: url('/lg5-common/images/TDP/badge_timedeal_2_soldout.svg') no-repeat 0 50% / auto 100%;
                    }
                    .tit {
                        color: #aaa;
                        display: none;
                    }
                    .time {
                       display: none;
                    }
                    .desc {
                        margin-left: 0;
                        padding-left: 0;
                        font-size: 15px;
                        font-weight: 700;
                        color: #aaa;
                        @include mobile {
                            font-size: 11px;
                            line-height: 15px;
                        }
                        &::before{
                            display: none;
                        }
                    }
                }
                .item_spec {
                    .spec_modelarea {
                        .model_btnall {
                            color: #8f8f8f;
                            &:after {
                                background: #8f8f8f no-repeat right center / 16px;
                                -webkit-mask-image: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg');
                                mask-image: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg');
                            }
                        }
                        .model_btnall {
                            background: no-repeat right center / 16px;
                            &:after {
                                mask-image: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg');
                            }
                        }
                    }
                }
            }
            .dmm {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 16px;
                z-index: 10;
                @include mobile {
                    border-radius: 0;
                }
                .text-box {
                    font-size: 28px;
                    line-height: 36px;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 700;
                    }
                }
                &.sold-out, &.evt-open {
                    background: rgba(0, 0, 0, 0.7);
                    .text-box {
                        color: #fff;
                        font-weight: 700;
                    }
                }
            }
            .flag-wrap {
                display: flex;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                @include pc {
                    height: 16px;
                }
                .flag {
                    position: relative;
                    margin: 0 8px 0 0;
                    padding: 0 8px 0 0;
                    line-height: 16px;
                    height: auto;
                    font-size: 12px;
                    font-weight: $font-weight-regular;
                    letter-spacing: -0.2px;
                    color: #000;
                    @include mobile {
                        font-size: 11px;
                        height: 16px;
                        line-height: 16px;
                    }
                    &.red {
                        color: #ea1917;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: -1px;
                        top: 50%;
                        margin: 0;
                        display: block;
                        width: 1px;
                        height: 8px;
                        background-color: #d9d9d9;
                        transform: translateY(-50%);
                    }
                }
            }
            .product-contents {
                @include mobile {
                    position: static;
                    // display: flex;
                    // flex-direction: column;
                }
                &:first-child {
                    .product-info {
                        padding-top: 0;
                    }
                }
            }
            .product-image {
                position: relative;
                margin: 20px auto 0;
                min-height: inherit;
                text-align: center;
                .view-text {
                    position: absolute;
                    left: 0;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    z-index: 1;
                }
                .badge-only {
                    position: absolute;
                    top: 0;
                    left: calc(100% - 34px);
                    display: block;
                    width: 48px;
                    height: 48px;
                    background: url('/lg5-common/images/icons/lge_only_card_badge.svg') no-repeat 0 0/ 100% auto;
                    @include mobile {
                        top: 7px;
                        right: -10px;
                        width: 32px;
                        height: 32px;
                    }
                }
                > a {
                    position:initial;
                }
                .middle-badge-list {
                    position:absolute;
                    top:0;
                    right:16px;
                    display:flex;
                    flex-direction:column;
                    flex-wrap:wrap;
                    z-index:1;  
                    gap:6px;
                    > span {
                        position:unset;
                        display:block;
                        width:48px;
                        height:48px;
                        &.badge-lge-only {
                            background:url(/lg5-common/images/icons/badge_lge_only.svg) no-repeat 0 0 / 100%;    
                        }
                        &.badge-gift-card {
                            background:url(/lg5-common/images/icons/badge_gift_card.svg) no-repeat 0 0 / 100%;
                        }
                        &.badge-gift {
                            background:url(/lg5-common/images/icons/badge_gift.svg) no-repeat 0 0 / 100%;    
                        }
                        &.badge-discount {
                            background:url(/lg5-common/images/icons/badge_discount.svg) no-repeat 0 0 / 100%;        
                        }
                        &.badge-point {
                            background:url(/lg5-common/images/icons/badge_point.svg) no-repeat 0 0 / 100%;
                        }
                    }  
                    @media screen and (max-width:1300px) {
                        right:0;
                    }
                    @include mobile {
                        top:0;
                        right:0;
                        gap:2px;
                        > span {
                            width:32px;
                            height:32px;
                        }
                    }
                }
                img {
                    max-width: 210px;
                    max-height: 210px;
                    @include mobile {
                        width: 90px;
                        height: 90px;
                    }
                }
            }
            .product-info {
                padding-top: 6px;
                text-align: left;
                min-height: 76px;
                @include mobile {
                    min-height: auto;
                }
                .product-name {
                    margin-top: 0;
                    a {
                        line-height: 20px;
                        @include mobile {
                            font-size: 14px;
                        }
                    }
                    .main-title {
                        font-size: 18px;
                        line-height: 24px;
                        // @include textRepaint;
                        @include mobile {
                            font-size: 13px;
                            line-height: 20px;
                        }
                        .sub {
                            display:inline-block;
                            font-weight: $font-weight-regular;
                            color: #333;
                            @include mobile {
                                // padding-left: 14px;
                            }
                            &::before {
                                content: '·';
                                margin:0 3px;
                                line-height: 20px;
                                font-weight: 400;
                                @include mobile {
                                    line-height: 20px;
                                }
                            }
                        }                            
                    }
                }
                .sku {
                    margin-top: 4px;
                    margin-right: 0;
                    font-size: 12px;
                    line-height: 16px;
                    color: #666;
                    @include mobile {
                        margin-top: 4px;
                        font-size: 11px;
                        line-height: 15px;
                    }
                    em {
                        @include mobile {
                            word-break: break-all;
                        }
                        &:last-child {
                            @include mobile {
                                display: none;
                            }
                            &:first-child {
                                @include mobile {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .review-info {
                    position: relative;
                    margin-top: 4px;
                    margin-left: 5px;
                    padding-left: 5px;
                    @include mobile {
                        margin-top: 4px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 1px;
                        height:8px;
                        background: #d9d9d9;
                        transform: translateY(-50%);
                    }
                    .star {
                        @include mobile {
                            width: 16px;
                            height: 16px;
                            background-size: 100% auto;
                        }
                    }
                    .star.is-review {
                        width: 14px;
                        height: 16px;
                        vertical-align: top;
                        background-size: 14px 14px;
                        background-position: 50%;
                    }
                    .average-rating, .review-count {
                        font-size: 12px;
                        line-height: 16px;
                       @include mobile {
                            font-size: 10px;
                            line-height: 15px;
                        }
                    }
                }
            }
            .product-bottom {
                position: relative;
                bottom: inherit;
                left: 0;
                right: 0;
                padding-top: 20px;
                @include mobile {
                    padding-top: 12px;
                }
                .flag-wrap {
                    height: auto;
                    .flag {
                        color: #ea1917;
                        font-weight: 700;
                        margin-right: 10px;
                        position: relative;
                        margin-top: 3px;
                        margin-bottom: 4px;
                    }
                }
            }
            .price-area {
                margin-top: 0;
                align-items: center;
                text-align: left;
                &.care {
                    align-items: flex-end; 
                    .total-price { 
                        @include mobile { 
                            margin-right: 12px; 
                        } 
                        .price {
                            line-height: 1.25;
                            @include mobile {
                                font-size: 14px;
                                line-height: 20px;
                            }
                            em { 
                                font-weight: 400; 
                            } 
                        } 
                    }
                    .small-text { 
                        margin-left: 10px; 
                        font-size: 16px; 
                        color: #000; 
                        @include mobile { 
                            margin-top: 0px;
                            margin-left: 0;
                            display: block;
                            width: 100%;
                            font-size: 12px; 
                            line-height: 18px; 
                        } 
                    }
                }
                .original {
                    margin-right: 5px;
                    font-size: 16px;
                    line-height: 24px;
                    color: #8f8f8f;
                    @include mobile {
                        font-size: 12px;
                        line-height: 17px;
                    }
                    &:after {
                        background-color: #8f8f8f;
                    }
                }
                .discount {
                    margin-right: 5px;
                    font-size: 20px;
                    line-height: 28px;
                    color: #000;
                    font-weight: 400;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                .total {
                    margin-right: 5px;
                    display: flex;
                    line-height: 28px;
                    font-weight: 400;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }
                    em {
                        font-weight: 400;
                    }
                    strong {
                        font-weight: $font-weight-bold;
                    }
                }
            }
            .benefit-price {
                margin-top: 4px;
                display: flex;
                align-items: center;
                text-align: left;
                color: #ea1917;
                font-size: 16px;
                line-height: 24px;
                @include mobile {
                    margin-top: 2px;
                    font-size: 13px;
                    line-height: 20px;
                }
                &.emp {
                    color: #000;
                    @include mobile {
                        margin-top: 2px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    &:first-child {
                        @include mobile {
                            margin-top: 0;
                        }
                    }
                    .price {
                        em {
                            font-weight: 700;
                        }
                    }
                }
                a {
                    @include pc {
                        display: flex;
                    }
                }
                .price {
                    margin-left: 10px;
                    @include mobile {
                        margin-left: 5px;
                    }
                    em {
                        // margin-left: 5px;
                        font-weight: $font-weight-bold;
                    }
                }
            }
            .btm-infobox {
                position: relative;
                display: flex;
                align-items: center;
                @include mobile {
                    // margin-top: 6px;
                }
                .flag-wrap {
                    margin-top: 16px;
                    height: auto;
                    @include mobile {
                        margin-top: 6px;
                        margin-bottom: 0;
                    }
                    &.bg-type {
                        .flag {
                            margin: 0;
                            padding: 0 6px;
                            height: 22px;
                            line-height: 21px;
                            font-size: 12px;
                            color: #fff;
                            font-weight: $font-weight-medium;
                            @include mobile {
                                height: 20px;
                                line-height: 19px;
                                font-size: 11px;
                            }
                            &.soldout {
                                margin: 0;
                                background: #666;
                                border: none;
                            }
                        }
                    }
                }
                .tooltip-wrap {
                    @include mobile {
                        // display: none;
                        margin-top: 4px;
                    }
                }
            }            
            .delivery-text {
                margin-top: 4px;
                margin-right: 2px;
                height: auto;
                font-size: 14px;
                line-height: 22px;
                color: #008182;
                @include mobile {
                    // display: none;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
            .product-btm-lower {
                position: relative;
                text-align: left;
            }
            .btn-area-wrap {
                margin-top: auto;
                padding-top: 20px;
                @include mobile {
                    display: none;
                }
                margin-top: auto;
                padding-top: 20px;
                @include mobile {
                    display: none;
                }
                .btn.border {
                    &.red {
                        border-color: #ea1917 !important;
                        color: #ea1917;
                        &:hover, &:focus {
                            color: #fff;
                        }
                    }
                }
                .wishlist {
                    padding-right: 0;
                    width: auto;
                    display: block;
                    text-align: right;
                }
                .chk-wish-wrap {
                    input {
                       &:checked {
                         & + label {
                            &::before {
                                background-color: transparent;
                                background-image: url("/lg5-common/images/icons/btn-like-on-24.svg");
                                -webkit-mask-image: none;
                                mask-image: none;
                            }
                         }
                       }
                        & + label {
                            padding-right: 18px;
                            width: auto;
                            height: 16px;
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: 500;
                            color: #8f8f8f;
                            &::before {
                                left: auto;
                                right: 0;
                                width: 16px;
                                height: 16px;
                                background: #000 no-repeat 100% 50%;
                                background-size: 100% auto;
                                -webkit-mask-image:  url('/lg5-common/images/icons/btn-like-off-16.svg');
                                mask-image:  url('/lg5-common/images/icons/btn-like-off-16.svg');
                            }
                        }
                    }

                }
            }
            .item-middle-info {
                margin-top: 20px;
                
                .middle-info-inner {
                    font-size: 12px;
                    line-height: 16px;
                    @include mobile {
                        line-height: 18p;
                    }
                }
                .middle-info-list {
                    position: relative;
                    display: inline;
                    &::after {
                        content: '·';
                        padding: 0 3px;
                        display: inline;
                        line-height: 15px;
                        font-size: 11px;
                        font-weight: normal;
                        color: #666;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                        .info {
                            &.point {
                                padding-right: 0;
                                &::after {
                                    // display: none;
                                }
                            }
                        }
                    }
                }     
                .info {
                    color: #666;
                    &.point {
                        position: relative;
                        // padding-right: 12px;
                        margin-left: 4px;
                        color: #0F0F0F;
                        // &::before {
                        //     content: '·';
                        //     position: absolute;
                        //     right: 1px;
                        //     top: 0;
                        //     display: inline-block;
                        //     line-height: 16px;
                        //     color: #666;
                        // }
                    }
                    
                    &.comma {
                        position: relative;
                        &::after {
                            content: ',';
                            padding: 1px;
                            display: inline;
                            line-height: 16px;
                            font-size: 11px;
                            font-weight: normal;
                            color: #666;
                        }
                    }
                }
                em.info + .info {
                    margin-left: 0;
                }                
                & + .product-bottom {
                    padding-top: 16px;
                }
            }
            .btn-bottom {
                padding-top: 20px;
                @include mobile {
                    padding-top: 12px;
                }
                .btn-box {
                    padding-right: 12px;
                    display: inline-block;
                    font-size: 16px;
                    line-height: 24px;
                    color: #0f0f0f;
                    font-weight: 400;
                    white-space: nowrap;
                    background: url(/lg5-common/images/icons/icon-btn-arrow-right.svg) no-repeat right -3px top 50% / 16px auto;
                    letter-spacing: -.55px;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
            .timeDeal-box {
                margin-top: 4px;
                margin-bottom: -4px;
                font-size: 14px;
                line-height: 1.1;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                border-radius: 8px;
                @include mobile {
                    height: 32px;
                    border-radius: 8px;
                }    
                &::before {
                    content: '';
                    margin-right: 5px;
                    display: block;
                    min-width: 20px;
                    height: 20px;
                    background: url('/lg5-common/images/TDP/badge_timedeal_2.svg') no-repeat 0 50% / auto 100%;
                    @include mobile {
                        margin-right: 2px;
                        min-width: 18px;
                        height: 18px;
                    }                       
                }
                .tit {
                    margin-right: 5px;
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    @include mobile {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                .time {
                    display: block;
                    font-size: 14px;
                    color: #ea1917;
                    font-weight: $font-weight-bold;
                    @include mobile {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }                    
                .desc {
                    position: relative;
                    padding-left: 9px;
                    @include mobile {
                        font-size: 12px;
                        line-height: 18px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 4px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        width: 1px;
                        height: 8px;
                        background-color: #d9d9d9;
                    }
                }
            }
            .verify-wrap {
                margin-bottom: 0;
                @include mobile {
                    margin: 16px 0 0;
                }
            }
            
            .item_spec {
                margin-top: 4px;
                border: none;
                @include mobile {
                    margin-top: 2px;
                    margin-bottom: 0;
                }
                .spec_modelarea {
                    .model_btnall {
                        position: relative;
                        margin-top: 0;
                        font-weight: 400;    
                        background: no-repeat right center / 16px;                    
                        @include mobile {
                            margin-bottom: 0;
                            padding-right: 17px;
                            font-size: 14px;
                            line-height: 21px;
                            background-size: 16px;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 0;
                            display: block;
                            width: 16px;
                            height: 16px;
                            background: #000 no-repeat right center / 16px;  
                            -webkit-mask-image: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg');
                            mask-image: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg');
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            .btn-delivery {
                margin-top: 6px;
                display: inline-block;
                position: relative;
                padding-left: 20px;
                padding-right: 12px;
                height: 20px;
                min-width: 80px;
                @include font (12px, 16px);
                font-weight: 400;
                color: #0f0f0f;
                border: solid 1px #ddd;
                border-radius: 4px;
                @include mobile {
                    margin-top: 4px;
                    height: 18px;
                    min-width: 74px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: url('/lg5-common/images/icons/btn-delivery_12x12.svg') no-repeat;
                    background-size: 100% 100%;
                }
                &::after {
                    content: '';
                    position: absolute;
                    right: 2px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 12px;
                    height: 12px;
                    background: url('/lg5-common/images/icons/btn-arrow_right_12x12.svg') no-repeat;
                    background-size: 100% 100%;
                    @include mobile {
                        width: 10px;
                        height: 10px;
                    }
                }
                & + span {
                    margin-left: 8px;
                    @include mobile {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
    // 모바일 리스트형
    &.list-type {        
        &.timedeal-type {
            & > li {
                @include mobile {
                    border: none;
                }
                &:first-child {
                    @include mobile {
                        border-top: 1px solid #eee;
                    }
                }
            }
            .item {
                @include mobile {
                    padding-top: 32px;
                    padding-bottom: 46px;
                }
                .timeDeal-box {
                    @include mobile {
                        position: absolute;
                        bottom: 1px;
                        left: 16px;
                        right: 16px;
                        margin-bottom: 0;
                    }
                }
                .product-bottom {
                    @include mobile {
                        padding-top: 6px;
                    }
                }
            }
        }
        & > li {
            @include mobile {
                width: 100%;
            }
        }
        &.col4 {
            & > li {
                @include mobile {
                    width: 100%;
                }
            }
        }
        .item {
            @include mobile {
                padding: 24px 16px 24px 120px;
                width: 100%;
                overflow: visible;
            }
            .flag-wrap {
                @include mobile {
                    margin-bottom: 4px;
                }
            }
            .product-contents {
                @include mobile {
                    position: static;
                }
            }
            .product-image {
                @include mobile {
                    position: absolute;
                    top: 24px;
                    left: 16px;
                    margin-top: 0;
                    order: 1;
                }
                .view-text {
                    @include mobile {
                        display: none;
                    }
                }
            }
            .product-info {
                @include mobile {
                    padding-top: 0;
                }
                .sku {
                    @include mobile {
                        margin-top: 4px;
                    }
                }
                .review-info {
                    @include mobile {
                        margin-top: 4px;
                    }
                }
            }
            .price-area {
                &.care {
                    & + .benefit-price.emp {
                        @include mobile {
                            margin-top: 7px;
                        }
                    }
                }
            }
            .item-middle-info {
                @include mobile {
                    margin-top: 12px;
                }
                & + .product-bottom {
                    @include mobile {
                        padding-top: 2px;
                    }
                }
            }
            
        }
    }
    // 모바일 요약형
    &.twin-type {
        &.timedeal-type {
            .item {
                .timeDeal-box {
                    @include mobile {
                        height: 26px;
                        border-radius: 4px;
                    }
                    .tit {
                        @include mobile {
                            display: none;
                        }
                        @media screen and (max-width: 359px) { 
                            display: none;
                        }
                    }
                    .time {
                        @include mobile {
                            font-size: 11px;
                            line-height: 15px;
                        }
                    }
                    .desc {
                        @include mobile {
                            font-size: 11px;
                            line-height: 15px;
                        }
                    }
                }
            }
        }
        & > li {
            @include mobile {
                margin-left: 0;
                width: 50%;
            }
            &:nth-child(odd) {
                @include mobile {
                    border-right: 1px solid #eee;
                }
            }
        }
        &.col4 {
            & > li {
                @include mobile {
                    width: 50%;
                }
            }
        }
        .item {
            @include mobile {
                padding: 24px 16px 28px;
            }
            .flag-wrap {
                @include mobile {
                    margin-bottom: 6px;
                    height: 16px;
                }
            }
            .product-contents {
                @include mobile {
                    display: flex;
                    flex-direction: column;
                }
            }
            .product-image {
                @include mobile {
                    margin-top: 0;
                }
                .view-text {
                    @include mobile {
                        display: none;
                    }
                }
                img {
                    @include mobile {
                        width: 138px;
                        height: 138px;
                    }
                }
            }
            .product-info {
                @include mobile {
                    padding-top: 16px;
                    order: 1;
                }
                .product-name {
                    .main-title {
                        .main {
                            @include mobile {
                                font-weight: 400;
                            }
                        }
                    }
                }
                .review-info {
                    @include mobile {
                        display: none;
                    }
                }
            }
            .product-bottom {
                @include mobile {
                    padding-top: 8px;
                    padding-bottom: 20px;
                }
                & + .verify-wrap {
                    @include mobile {
                        margin-top: -4px;
                    }
                }
                & + .btm-infobox {
                    @include mobile {
                        margin-top: -20px;
                    }
                }
                // &:nth-last-child(2) {
                //     @include mobile {
                //         padding-bottom: 20px;
                //     }
                // }
            }
            .price-area {
                &.care {
                    .total-price { 
                        .price {
                            @include mobile {
                                font-size: 13px;
                            }
                        } 
                    }
                }
                .original {
                    @include mobile {
                        font-size: 11px;
                        ;height: 15px;
                    }
                }
                .total {
                    @include mobile {
                        font-size: 13px;
                    }
                }
            }
            .benefit-price {
                &.emp {                    
                    @include mobile {
                        margin-top: 4px;
                        font-size: 13px;
                        line-height: 19px;
                        white-space: nowrap;
                    }
                }
            }
            .btm-infobox {
                .flag-wrap {
                    @include mobile {
                        margin-bottom: 20px;
                    }
                }
                .tooltip-wrap {
                    @include mobile {
                        display: none;
                    }
                }
            }
            .delivery-text {
                @include mobile {
                    display: none;
                }
            }
            .item-middle-info {
                @include mobile {
                    margin: auto -16px -28px;
                    padding: 16px 12px;
                    order: 5;
                    background: #f9f9f9;
                }
                .middle-info-list {
                    @include mobile {
                        padding-left: 8px;
                        display: block;
                    }
                    &::before {
                        @include mobile {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 6px;
                            display: block;
                            width: 2px;
                            height: 2px;
                            background-color: #666;
                        }
                    }
                    &:after {
                        @include mobile {
                            display: none;
                        }
                    }
                }
                .info {
                    &.point {
                        @include mobile {
                            padding-right: 0;
                            color: #666;
                        }
                        &::before {
                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }
                & + .product-bottom {
                    @include mobile {
                        padding-top: 8px;
                    }
                }
            }
            .btn-bottom {
                @include mobile {
                    padding-top: 8px;
                }
            }
            .btn-delivery {
                @include mobile {
                    display: none;
                }
            }
        }

    }
    // E : BTOCSITE-65935 3차 기획전 제작 모듈화 개발_상품유닛 수정    
}

.product-items-wrap {
    overflow:hidden; // .product-items wrapping DOM. 
}

// S : BTOCSITE-48216 [전시] 닷컴 ONLY 뱃지 적용
.badge.lge-only {
    position: absolute;
    display: inline-block;
    width: 48px;
    height: 48px;
    background: url(/lg5-common/images/icons/lge_only_card_badge.svg) no-repeat 0 0 / 100%;
    @include mobile {
        width: 32px;
        height: 32px;
    }
}
 // E : BTOCSITE-48216 [전시] 닷컴 ONLY 뱃지 적용