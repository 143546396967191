$pcFloatDistance: 48px; // 데스크탑 화면 하단과의 간격
$moFloatDistance: 40px; // 앱 화면 하단과의 간격 or 모바일웹 .mobile-status-bar 상단과의 간격
$statusBarHeight: 54px; // 모바일웹 .mobile-status-bar 높이

/*

## 편의상 높이 정의된 element 에 .js-floating-menu 적용 시 KRP0005.js 통해 공통 적용

청약 완료 - html/UTC/RMSF9011.html

## 그 외 자체 js에서 적용

멤버십 (.mobile-status-bar 있음) - html/BMC/ACC5000.html
이벤트/멤버십 (.mobile-status-bar 없음) - /htm/BMS/BMS-BTOCSITE-1814.html
매장찾기 (옵션선택) - /html/CS/CSRF6040.html
PDP (구매하기) - /html/components/KRP0008-additional-2.0.0.html
PDP (회원 할인가) - /html/components/KRP0008-notebook-2.0.0.html
구독 PDP (이용요금, 최대해택가) - /html/components/KRP0008-rental-2.0.0.html
고객지원 (이어서 예약 완료 하기) - /html/CS_mobile/CSMF0010_member.html
구독 장바구니 (총n건 신청하기) - /html/UTC/OBSF9003.html
재구독 혜택 적용 (재구독 혜택 n건 중 n건 적용) - /html/UTC/OBSF9005.html
구매 혜택 계산기 (제품을 선택해서 혜택을 계산해보세요) - /html/OBS/PROMO_Wedding_calculator_new.html (웨딩 기획전)
                                                - /html/OBS/RE_PROMO_MT.html (다품목 기획전)


--bottom 변수 없이 자체 scss 혹은 js 인라인 스타일 처리 화면

[js] 메인 (개인화 메세지) - /html/MA/MKTF1000_2.0.0.html
사업자몰 견적문의 (견적문의하기) - /html/MYC/B2BOBS200.html
임직원몰 (임직원 등록하기) - /html/EMP/MKFT-40004_1.html
그램 찾기 (제품 비교하기) - /html/GRC/MKTF30031.html
그램 추천 (추천제품 바로보기, 라이프스타일 다시 선택하기) - /html/GRC/MKTF30022-02.html
[js] 구독 모아보기 (담은 구독 상품) - /html/components/KRP0007-2.0.0-subscribe.html

*/

.floating-wrap {
    position:fixed;
    right:48px;
    bottom: calc(var(--bottom, 0px) + #{$pcFloatDistance}); 
    z-index:110; /* BTOCSITE-1967 */
    @include mobile {
        right:14px;
        bottom: calc(var(--bottom, 0px) + #{$moFloatDistance}); //BTOCSITE-39174 챗봇아이콘 관련 개선 사항 // BTOCSITE-68538
    }
    .floating-menu {
        position:static; 
        right:0;
        bottom:0;
        margin-top:16px;
        @include mobile {
            margin-top:10px;
        }
        > div {
            width:56px;
            height:56px;
            border-radius:50%;
            box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15);
            text-align:center;
            > a {
                display:block;
                width:100%;
                height:100%;
            }
            @include mobile {
                width: 40px;
                height: 40px;
            }
        }
        .cs-cst-linker {
            background:url('/lg5-common/images/icons/icon-cs-cst-linker.svg') no-repeat center center;
        }
        .back-to-top {
            background-image:url('/lg5-common/images/icons/icon-top-new.svg');
            background-color:#fff;
        }
        .easy-path {
            position:relative;
            box-shadow:none;
            .inner {
                position:absolute;
                top:0;
                right:0;
                a {
                    display:block;
                    width:56px;
                    height:56px;
                    padding:15px 24px;
                    background-image: linear-gradient(135deg, #9c72e7 28%, #f85784 89%);
                    border-radius:28px;
                    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15);
                    transition:width .5s;
                    overflow:hidden;
                    @include mobile {
                        width:40px;
                        height:40px;
                        padding:0;
                    }
                    &:hover,
                    &:active {
                        width:343px;
                        @include mobile {
                            width:290px;
                            padding: 9px 24px;
                        }
                        p {
                            display:block;
                        }
                    }
                    p {
                        display:none;
                        position:relative;
                        color:#fff;
                        font-size:16px;
                        line-height:26px;
                        white-space:nowrap;
                        &:after {
                            content:' ';
                            display:inline-block;
                            width:16px;
                            height:16px;
                            margin-left:6px;
                            background:url('/lg5-common/images/icons/icon-float-arr-16.svg') no-repeat 0 0;
                            vertical-align:-2px;
                        }
                        @include mobile {
                            font-size:13px;
                            line-height:21px;
                            &:after {
                                vertical-align:-3px;
                            }
                        }
                    }
                }

            }
        }
    }
}

.btn-type2 {
    .btn-inner {
        display:none;
        &.open {
            display:block;
        }
    }
    .floating-menu.chat {
        bottom:284px;
        @include mobile {
            bottom:231px;
        }
    }
    .floating-menu.cs-call {
        bottom:372px;
        @include mobile {
            bottom:319px;
        }
    }
}

.btn-floating-wrap {
    .btn-inner {
        display:none;
        //&.open {
        //    display:block;
        //}
    }

    &.open {
        .more-plus-linker {
            transform:rotate(225deg);
            &:before {
                content:' ';
                display:block;
                position:absolute;
                top:50%;
                left:50%;
                background:url('/lg5-common/images/icons/icon-more-plus-x.png') no-repeat;
                background-size:100%;
                transform:translate(-50%, -50%);
            }
        }
        .btn-inner {
            display: block;
        }
    }
    &.scroll {
        .floating-menu.floating-chatbot, // BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화
        .floating-menu.more-plus {
            bottom: calc(var(--bottom, 0px) + 120px); //BTOCSITE-39174 챗봇아이콘 관련 개선 사항
        }
        .floating-list {
            bottom:192px;
        }
        @include mobile {
            .floating-menu.floating-chatbot, // BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화
            .floating-menu.more-plus {
                bottom: calc(var(--bottom, 0px) + #{$moFloatDistance} + 50px); //BTOCSITE-39174 챗봇아이콘 관련 개선 사항 // BTOCSITE-68538
            }
            .floating-list {
                bottom:60px + 24px + 40px;
            }
        }
    }
}

.floating-menu {
	position:fixed;
	opacity:1;
	transition:opacity 0.8s ease-out, transform 0.5s ease-out;
	z-index:99;
	&.call-yet {
        position:fixed;
		opacity:0;
		z-index:-100;
        transform: translateX(171px);

        @include mobile {
            transform: translateX(77.5px);
        }
	}
	&.top {
        right:48px;
        bottom: calc(var(--bottom, 0px) + #{$pcFloatDistance});  // BTOCSITE-68538
        @include mobile {
            right:14px;
            bottom: calc(var(--bottom, 0px) + #{$moFloatDistance}); // BTOCSITE-68538
        }
    }
	.back-to-top {
        width:56px;
        height:56px;
        box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15);
		background:url('/lg5-common/images/icons/icon-top-new.svg') #fff no-repeat center center;
		border-radius:50%;
		// margin-top:12px;
        line-height:0;
        @include mobile {
            width: 40px;
            height: 40px;
            background-size: 29px; // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
        }
		button{
			width:100%;
			height:100%;
			padding:0;
			border:none;
			background:none;
			cursor:pointer;
        	&:focus,
            &:active {
                outline: none !important;
            }
		}
        &:active {
            background-image:url('/lg5-common/images/icons/icon-top-new-active.svg');
        }
	}
	&.chat {
        right:48px;
        bottom:(112px+72px+12px);

        @include mobile {
            right:24px;
            bottom:144px;
        }
    }
    &.cs-call {
        right:48px;
        bottom:(112px+172px);
        @include mobile {
            right:24px;
            bottom:232px;
        }
    }
    &.more-plus {
        //right:112px;
        //bottom:(112px+72px+28px);
        //@include mobile {
        //    right:36px;
        //    bottom:155px;
        //}
        position:fixed;
        right:48px;
        bottom:48px;
        transition:all .1s;
        // bottom:104px + 16px;
        @include mobile {
            right:14px;
            bottom:24px;
            // bottom:92px;
        }
    }
    &.btn3 {
        right:48px;
        bottom:460px;
        @include mobile {
            right:24px;
            bottom:407px;
        }
    }
    &.btn4 {
        right:48px;
        bottom:548px;
        @include mobile {
            right:24px;
            bottom:495px;
        }
    }
    &.lately {
        right:48px;
        bottom:255px;
        z-index:111;
        & + .easy-path {
            margin-top:14px;
        }
        @include mobile {
            right:14px;
            bottom:160px;
            & + .easy-path {
                margin-top:10px;
            }
        }
    }
    &.cs-cst {
        right:48px;
        bottom:120px;
        @include mobile {
            right:14px;
            bottom:92px;
        }
    }
    &.floating-list {
        position:fixed;
        right:48px;
        bottom:122px;
        // bottom:104px + 32px + 56px;
        @include mobile {
            right:14px;
            bottom:10px + 24px + 40px;
            // bottom:80px + 24px + 56px;
        }
        display: flex;
        flex-direction: column;
    }

    .floating-linker {
        width:100% !important;
        height:100% !important;
        &:not(:first-child) {
            margin-top: 10px;
        }
        a {
            display: block;
            min-width: 170px;
            padding: 14px 68px 14px 28px;
            border: 1px solid $color-white;
            border-radius: 28px;
            box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15);
            background: $color-white no-repeat right 16px center;
            text-align: center;
            &:active {
                border: 1px solid $color-primary;
            }
            @include mobile {
                min-width: 112px;
                padding: 8px 36px 9px 16px;
                background-position: right 10px center;
                background-size: 24px;
            }
        }
        .text {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            @include mobile {
                font-size: 13px;
                line-height: 21px;
            }
        }
        &.cs-cst {
            a {
                background-image:url('/lg5-common/images/icons/icon-phone-40.svg');
            }
        }
        &.chat {
            a {
                background-image:url('/lg5-common/images/icons/icon-chat-40.svg');
            }
        }
        &.my-collection {
            a {
                background-image:url('/lg5-common/images/icons/icon-mycollection-40.svg');
            }
        }
        &.recently {
            a {
                background-image:url('/lg5-common/images/icons/icon-recently-40.svg');
            }
        }
        &.store-reservation {
            a {
                background-image:url('/lg5-common/images/icons/img_shop_40.svg');
            }
        }
        &.faq {
            a {
                background-image:url('/lg5-common/images/icons/img_floatingfaq_40.svg');
            }
        }

        /* BTOCSITE-2958 - 플로팅 배너 개선 2021-08-23 : 추가 */
        &.product-troubleshooting {
            a {
                background-image:url('/lg5-common/images/icons/icon_floating_solutions.svg');
                background-size: 40px;
                @include mobile {
                    background-size: 24px;
                }
            }
        }
        &.store-center {
            a {
                background-image:url('/lg5-common/images/icons/icon_find-center.svg');
                background-size: 40px;
                @include mobile {
                    background-size: 24px;
                }
            }
        }
        /* BTOCSITE-2958 - 플로팅 배너 개선 2021-08-23 : 추가 */
    }
	.chatbot-linker {
		width:88px;
        height:88px;
		background:url('/lg5-common/images/icons/icon-chatbot.svg') no-repeat;
		background-size:88px 88px;
		// border-radius:25px;
        // box-shadow: 7px 7px 18px 0 rgba(0, 0, 0, 0.12);
		a {
			display:block;
			width:100%;
			height:100%;
			color:#fff;
        }
	}
    .cs-call-linker {
        width:88px;
        height:88px;
		background:url('/lg5-common/images/icons/icon-cs-call.svg') no-repeat;
        background-size:88px 88px;
        a {
			display:block;
			width:100%;
			height:100%;
			color:#fff;
        }
    }
    .more-plus-linker {
        position:relative;
        width:56px;
        height:56px;
        border-radius:50%;
        background-color:$color-primary;
        background-size:100%;
        //transition:opacity 0.8s ease-out, transform 0.3s ease-out;
        &:before {
            content:' ';
            display:block;
            position:absolute;
            top:50%;
            left:50%;
            width:40px;
            height:40px;
            background:url('/lg5-common/images/icons/icon_img_floating_40.svg') no-repeat;
            background-size:100%;
            transform:translate(-50%, -50%);
        }
        @include mobile {
            width:40px;
            height:40px;
            &:before {
                width:32px;
                height:32px;
            }
        }
        &:hover,
        &:focus,
        &:active {
            background: $color-primary;
        }
        a {
			display:block;
            position:relative;
			width:100%;
			height:100%;
            color:#fff;
        }
        &.open {
            transform:rotate(45deg);
        }
    }
    .lately-linker {
        width:56px;
        height:56px;
        border-radius:50%;
        background-color:#fff;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
        overflow:hidden;
        a {
            display:block;
			width:100%;
            height:100%;
            img {
                width:100%;
                transform:scale(.8);
            }
        }
    }
    .cs-cst-linker {
        width:56px;
        height:56px;
        border-radius:50%;
        box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15);
        background:url('/lg5-common/images/icons/icon-cs-cst-linker.svg') #fff no-repeat center center;
        text-align:center;
        > a {
            display:block;
            width:100%;
            height:100%;
        }
    }
}
.lately-popup {
    top:-415px;
    right:0;
    z-index:101;
    width:368px;
    min-height:480px;
    box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
    &.open {
        display:block;
    }
    @include mobile {
        position:fixed;
        top:auto;
        right:-80%;
        left:auto !important;
        z-index:101;
        width:100%;
        &.open {
            right:0;
        }
    }
    .pop-header {
        margin:0 24px;
        padding:24px 0 12px;
        .tit {
            span {
                font-size:18px;
                line-height:28px;
            }
        }
        @include mobile {
            margin:0 16px;
            padding:27px 0 17px;
            .tit {
                span {
                    height:100%;
                    font-size:16px;
                    line-height:24px;
                }
            }
        }
    }
    .pop-conts {
        max-height:408px;
        padding:0 24px;
        @include mobile {
            max-height:100%;
            height:calc(100% - 72px);
        }
        .lately-list {
            ul {
                li {
                    display:table;
                    position:relative;
                    width:100%;
                    height:96px;
                    padding:16px 0;
                    border-bottom:1px solid #ddd;
                    &:last-child {
                        border-bottom:none;
                    }
                    .img {
                        position:absolute;
                        top:50%;
                        left:0;
                        width:64px;
                        height:64px;
                        transform:translateY(-50%);
                        img {
                            width:100%;
                        }
                    }
                    dl {
                        display:table-cell;
                        padding-left:90px;
                        vertical-align:middle;
                        dt {
                            display: -webkit-box;
                            -webkit-line-clamp:2;
                            -webkit-box-orient: vertical;
                            max-height:48px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size:14px;
                            font-weight:500;
                            line-height:24px;
                        }
                        dd {
                            margin-top:8px;
                            font-size:14px;
                            font-weight:500;
                            line-height:24px;
                        }
                    }
                    &.disabled {
                        dl {
                            dd {
                                color: #F04E3E;
                                font-weight: 500;
                                @include font-small;
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    width: 20px;
                                    height: 20px;
                                    margin: 2px 4px 0 0;
                                    background: url("/lg5-common/images/icons/icon-error.svg") center no-repeat;
                                    vertical-align: top;
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    li {
                        height:120px;
                        padding:20px 0;
                        dl {
                            padding-left:104px;
                            dt {
                                font-size:14px;
                                line-height:22px;
                            }
                            dd {
                                font-size:15px;
                                line-height:23px;
                            }
                        }
                    }
                    .img {
                        width:80px !important;
                        height:80px !important;
                    }
                }
            }
        }
    }
    > .btn-close {
        top:24px;
        right:14px;
    }
}
.lately-dim {
    display:none;
    position:fixed;
    top:0;
    width:100%;
    height:100%;
    background:#000;
    opacity:.7;
    @include mobile {
        &.open {
            display:none;
        }
    }
}

.open-compare {
    .floating-menu {
        margin-bottom: 72px;
        @include mobile {
            margin-bottom: 56px;
        }
    }
}

// 모바일 웹 전용
.mdevice {
    &:not(.app) {
        // BTOCSITE-68538 [모니터링 G][APP] Chatbot 아이콘 위에 Top버튼이 노출
        // 모바일웹 임에도 .mobile-status-bar가 존재하지 않는 경우 존재해 :has() 분기
        // .mdevice:not(.app) 아닌 경우엔 val(--bottom, 0px)으로 정의되어 기존위치 유지 가능
        // .more-plus 레거시 소스로 판단되어 해당 분기에서 제거
        @include mobile {
            body:has(.mobile-status-bar) {
                .floating-wrap {
                    bottom: calc(var(--bottom, #{$statusBarHeight}) + #{$moFloatDistance});
                }

                .floating-menu {
                    &.floating-chatbot {
                        // .top이 없을 때 chatbot의 위치 최하단 고정
                        bottom: calc(var(--bottom, #{$statusBarHeight}) + #{$moFloatDistance});
                    }
                    &.top {
                        // .call-yet 으로 오른쪽으로 사라지면 position: fixed; 상태가 되므로 bottom 적용
                        // 그 외 position: static 유지 ( .call-yet 토글은 lg.common.js 스크롤 이벤트 공통으로 적용 중 )
                        // 부모 .floating-wrap는 position: fixed; 상시 유지
                        bottom: calc(var(--bottom, #{$statusBarHeight}) + #{$moFloatDistance});
                    }
                }

                .btn-floating-wrap {
                    &.scroll {
                        .floating-menu.floating-chatbot {
                            bottom: calc(var(--bottom, #{$statusBarHeight}) + #{$moFloatDistance} + 50px); // 50px는 top 상단과 chatbot 상단 거리
                        }
                    }
                }
            }
        }

        // <-- BTOCSITE-68538 레거시 소스로 판단 --bottom 미적용
        // 어딘가 있을지 모를 소스 기존 높이만 유지하려 $statusBarHeight 적용후 + px 상태 (2024.07.08)
        .floating-menu {
            // 서비스 메뉴열기 리스트 (2021년 이전 소스, 2024.07.11 확인) BTOCSITE-5223 지라 기획서 참조
            // .floating-list > .floating-linker ( 상담챗, 매장 상담 예약, 제품 문제 해결, 서비스 센터 찾기 )
            &.floating-list { 
                @include mobile {
                    bottom:(74px + $statusBarHeight + 16px)
                }
            }

            // 더보기 버튼에 대한 리스트들 (2020년 이전 소스, 2024.07.11 확인)
            // .more-plus + .btn-inner >
            &.chat { // 챗봇
                @include mobile {
                    bottom:(144px + $statusBarHeight + 16px);
                }
            }
            &.cs-call { // 전문가 상담
                @include mobile {
                    bottom:(232px + $statusBarHeight + 16px);
                }
            }
            &.btn3 { // 더미
                @include mobile {
                    bottom:(407px + $statusBarHeight + 16px);
                }
            }
            &.btn4 { // 더미
                @include mobile {
                    bottom:(495px + $statusBarHeight + 16px);
                }
            }


            &.lately { // 최근 본 제품 (숨김 처리되어 있으나 운영 마크업은 유지 중, 2024.07.11 확인)
                @include mobile {
                    bottom:(160px + $statusBarHeight + 16px);
                }
            }
            &.cs-cst { // 고객 상담 (숨김 처리되어 있으나 운영 마크업은 유지 중, 2024.07.11 확인)
                @include mobile {
                    bottom:(92px + $statusBarHeight + 16px);
                }
            }
            &.message-bar {
                @include mobile {
                    bottom:(92px + $statusBarHeight + 16px);
                }
            }
        }

        // .more-plus 부모 element
        .btn-type2 {
            .floating-menu.chat {
                @include mobile {
                    bottom:(231px + $statusBarHeight + 16px);
                }
            }
            .floating-menu.cs-call {
                @include mobile {
                    bottom:(319px + $statusBarHeight + 16px);
                }
            }
        }
        // BTOCSITE-68538 레거시 소스로 판단 --bottom 미적용 -->

    }
    // BTOCSITE-68147   
    &.app {
        .floating-wrap:has(.top) {
            z-index:97 !important;
        }
    }
}

// S : BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화
.floating-chatbot {
    right: 48px;
    bottom: calc(var(--bottom, 0px) + #{$pcFloatDistance});
    @include mobile {
        right: 14px;
        bottom: calc(var(--bottom, 0px) + #{$moFloatDistance}); //BTOCSITE-39174 챗봇아이콘 관련 개선 사항
    }
    .btn-chatbot {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        &-wrap {
            width: 56px;
            height: 56px;
            @include mobile {
                width: 40px;
                height: 40px;
            }
        }
        // S : BTOCSITE-46981 공통 > 챗봇 아이콘 변경 요청
        &:after {
            content:"";
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 56px;
            height: 56px;
            padding: 8px;
            border-radius: 28px;
            background: $color-primary url(/lg5-common/images/icons/icon_floating_chatbot.gif) no-repeat 50% / 56px; // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
            z-index: 1;
            @include mobile {
                width: 40px;
                height: 40px;
                padding: 4px;
                border-radius: 20px;
                background-size: 40px; // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
            }
        }
        // E : BTOCSITE-46981 공통 > 챗봇 아이콘 변경 요청
        .msg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 56px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            padding-left: 24px;
            font-size: 18px;
            color: #fff;
            line-height: 1.46;
            border-radius: 28px;
            background: $color-primary;
            opacity: 0;
            white-space: nowrap;
            transition:width .5s,opacity .5s;
            @include pc {
                letter-spacing: -0.9px;
            }
            @include mobile {
                width: 40px;
                font-size: 13px;
                border-radius: 20px;
            }
            &:after {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-left: 2px;
                background: url(/lg5-common/images/icons/icon-float-arr-32.svg) no-repeat 0 0 / 100%;
                vertical-align: middle;
            }
        }
        &.active .msg {
            width: 337px;
            opacity: 1;
            @include mobile {
                width: 271px;
            }
        }
    }
}
// E : BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화

/* BTOCSITE-48943 [개인맞춤서비스] 홈메인 개인화된 메시지 노출 건 */
.messagebar-wrap{
    // display:none;
    position: fixed;
    right: 48px;
    bottom: -88px;
    z-index: 111;   // BTOCSITE-79875 챗봇 둥둥이 미노출(가전구독홈)
    transition: bottom .3s;
    @include mobile{
        left:16px;
        bottom: -88px;
        width:calc(100% - 32px);
    }
    &.on{
        display:block;
        bottom: 48px;
        transition: bottom .3s;
        @include mobile{
            bottom:61px;
        }
    }
    .messagebar-box{
        position:relative;
        display:flex;
        align-items:center;
        background:rgba(10, 22, 34, 0.9);
        // S : BTOCSITE-58189 [회원] 복지몰/계열사 후속과제
        @include pc{
            padding:9px 36px 9px 12px;
            // height:40px;
            border-radius:300px;
        }
        @include mobile{
            padding:5px 30px 5px 6px;
            min-height:32px;
            justify-content:center;
            border-radius:22px; 
        }
        .tit{
            position:relative;
            margin-right:8px;
            font-size:14px;
            line-height: 22px;
            font-weight:400;
            color:#fff;
            text-align: right;
            @include mobile{
                margin-right:7px;
                font-size:12px;
                line-height:17px;
                text-align:center;
            }
            >strong{
                color:#ffd600;
            }
            &:after{
                content:'';
                position:absolute;
                width:10px;
                height:10px;
                background:url('/lg5-common/images/icons/icon-messagebar-arrow.svg') no-repeat;
                background-size:100% 100%;
                @include pc{
                    bottom: 5px;
                    right:-12px;
                    // transform:translateY(-50%);
                }
                @include mobile{
                    bottom:3px;
                    margin-left:2px;
                }
            }
        }
        // E : BTOCSITE-58189 [회원] 복지몰/계열사 후속과제
        .btn-close{
            position:absolute;
            top:50%;
            right:12px;
            width:16px;
            height:16px;
            background:url('/lg5-common/images/icons/icon-messagebar-close.svg');
            background-size:100% 100%;
            transform:translateY(-50%);
            @include mobile{
                right:8px;
            }
        }
    }
}
html.app{
    .onMsgBar{
        &.btn-floating-wrap{
            .floating-chatbot{
                bottom:calc(var(--bottom, 0px) + 49px) !important;
            }
            &.scroll{
                .floating-chatbot{
                    bottom:calc(var(--bottom, 0px) + 99px) !important;
                }
            }
            ~.messagebar-wrap.on{
                bottom:calc(var(--bottom, 0px) + 8px);
            }
        }
        // &.floating-wrap{
        //     .back-to-top{
        //         top:-25px !important;
        //     }
        // }
    }
}
/* //BTOCSITE-48943 [개인맞춤서비스] 홈메인 개인화된 메시지 노출 건*/

/* BTOCSITE-57306 APP > B2B > 하단 고정(fixed)버튼 있을때 */
@media screen and (max-width: 767px) {
	.floating-wrap {
		z-index: 97;
	}
    // BTOCSITE-66890 [모니터링] 베스트랭킹 상세 페이지 챗봇 버튼이 필터 팝업 위로 올라오는 현상
    .floating-chatbot {
        z-index:97;
    }
    //.bottom-btn
	.app .btn-floating-wrap.bottom-btn .floating-menu.floating-chatbot {
		bottom: 94px;
	}
	.app .btn-floating-wrap.bottom-btn .floating-menu.floating-chatbot.KRP0005 {
		bottom: 133px;
	}
	.app .btn-floating-wrap.bottom-btn.scroll .floating-menu.floating-chatbot {
		bottom: 144px;
	}
	.app .btn-floating-wrap.bottom-btn.scroll .floating-menu.floating-chatbot.KRP0005 {
		bottom: 183px;
	}
	.app .floating-wrap.bottom-btn, .app .floating-wrap.bottom-btn.top {
		bottom: 94px;
	}
	.app .floating-wrap.bottom-btn:has(.KRP0004) {
		bottom: 133px;
	}
	.app .floating-wrap.bottom-btn .floating-menu {
		bottom: 94px;
	}
	.app .floating-wrap.bottom-btn .floating-menu.KRP0004.top {
		bottom: 133px;
	}
}