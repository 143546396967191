/* 가로형 테이블 */
.tb-scroll {
    margin:0 -16px;
    padding:0 16px;
    overflow-x:auto;
    -webkit-overflow-scrolling:touch;
    table {
        white-space:nowrap;
    }
}
.tb_row {
    table {
        width: 100%;
        border-collapse: collapse;
        thead {
            th {
                padding: 16px 24px;
                border-top: 1px solid #666;
                background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                color:#666;
                font-weight: 500;
                @include font-medium;
                text-align: center;
            }
        }
        td {
            padding: 24px;
            border-bottom: 1px solid #ddd;
            color:#000;
            @include font-medium;
            text-align: center;
            &.lower {
                color:#808080;
            }
            &.point {
                color:$color-primary;
            }
            .bold {
                font-weight:700;
            }
            &.black, .black {
                color:#000;
            }
        }
        tr {
            &.point {
                td {
                    color:$color-primary;
                }
            }
        }
        .al-left {
            text-align:left;
        }
    }
    .btm_info {
        margin-top: 24px;
        font-size: 16px;
        line-height: 24px;
        color: #000;
        .btm-tit {
            color:#000;
            @include font-medium;
            font-weight:700;
        }
        .btm-desc {
            margin-top:8px;
            color:#000;
            @include font-medium;
            em {
                color:$color-primary;
            }
        }
        .btm-desc-more {
            margin-top:4px;
            color:#767676;
            @include font-small;
            font-weight:500;
        }
    }

    @include mobile {
        table {
            thead {
                th {
                    padding: 15px 10px;
                }
            }
            td {
                padding: 20px 10px;
            }
        }
        .btm_info {
            margin-top: 20px;
            font-size: 14px;
            line-height: 18px;
        }
    }
    &.tb-row-bl {
        table {
            th,
            td {
                 border-left:1px solid #d8d8d8;
                 &:first-child {
                     border-left:none;
                     /* s : BTOCSITE-5938-417 */
                     border-right:1px solid #d8d8d8;
                     &:last-child {
                        border-right:none;
                     }
                     /* e : BTOCSITE-5938-417 */
                 }
            }
        }
    }
    &.board-type {
        table {
            table-layout:fixed;
        }
        .board-tit {
            & > a {
                text-align:left;
            }
            p {
                color:#000;
                font-weight:500;
                @include font-medium;
            }
            .flag-wrap {
                margin-right:12px;
                white-space:nowrap;
                .flag {
                    vertical-align:middle;
                }
            }
        }
        td:not(.board-tit) {
            @include font-small;
        }
        @include pc {
            .board-tit {
                & > a {
                    display:flex;
                }
                p {
                    @include textEllipsis;
                }
            }
        }
        @include mobile {
            table {
                display:block;
                border-top:2px solid #222;
            }
            thead {
                @include blind;
            }
            tbody {
                display:block;
                tr {
                    display:block;
                    overflow:hidden;
                    padding:16px 0; 
                    border-bottom:1px solid #ddd;
                }
                td {
                    display:block;
                    padding:0;
                    text-align:left;
                    border:0 none;
                    &.board-tit {
                        margin-bottom:4px;
                        & > a {
                            display:block;
                        }
                        p {
                            @include textEllipsisClamp(2);
                        }
                        .flag-wrap {
                            display:block;
                            margin:0 0 2px 0;
                        }
                    }
                    &:not(.board-tit) {
                        float:left;
                        & + td:not(.board-tit) {
                            position:relative;
                            margin-left:6px;
                            padding-left:7px;
                            &:before {
                                content:'';
                                position:absolute;
                                left:0;
                                top:6px;
                                width:1px;
                                height:10px;
                                background:#ddd;
                            }
                        }
                    }
                    .no-data {
                        width: calc(100vw - 40px);
                    }
                }
            }
        }
    }
}

// 세로형 테이블
.tb-col {
    table {
        width: 100%;
        border-collapse: collapse;
        text-align:left;
        border-bottom: 1px solid #ddd;
        th, td {
            padding: 19px 24px;
            border-top: 1px solid #ddd;
            @include font-medium;
        }
        tr:first-child {
            th, td {
                border-top:1px solid #666;
            }
        }
        th {
            background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            color:#666;
            font-weight:500;
            text-align:center;
            vertical-align:top;
        }
        td {
            color:#000;
        }
        .al-center {
            text-align:left;
        }
    }
    @include mobile {
        th, td {
            padding:18px 16px;
        }
    }
}