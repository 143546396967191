// toast meassage
.toast-message {
    display: none;
    position: fixed;
    left: 0;
    bottom: 24px;
    width: auto;
    z-index: 1002;
    /* BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선 */
    .toast-message-box {
        .inner {
            position:relative;
            display: inline-block;
            min-width:444px;
            min-height: 58px;
            background: rgba(34, 34, 34, .6);
            border-radius:58px;
            padding: 16px 60px 16px 48px;
            color: #fff;
            font-size:16px;
            font-weight:700;
            line-height:26px;
            text-align:center;
            @include mobile {
                min-width:100%;
                padding:17px 50px 17px 24px; //BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선 (모니터링506 반영)
                .btn-area {
                    right:16px;
                }
            }
            .toast-text {
                display: inline-block;
                .btn-link{
                    font-size:14px;
                    line-height:24px;
                    font-weight:400;
                    @include mobile{
                        font-size:13px;
                        line-height:21px;
                    }
                }
            }
            .btn-area {
                position:absolute;
                top:50%;
                right:24px;
                width:24px;
                height:24px;
                transform:translateY(-50%);
                &:after {
                    content:'';
                    display:block;
                    width:24px;
                    height:24px;
                    background:url('/lg5-common/images/icons/btn-close-24-white.svg') no-repeat;
                }
            }
            .link-underline {/* BTOCSITE-22701 class추가 */
                display: inline-block;
                text-decoration: underline !important;
            }
        }
    }
    /* //BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선 */
    @include mobile {
        left:16px;
        right:16px;
    }
}

/* BTOCSITE-1967 */
.mdevice:not(.app) {
    //BTOCSITE-1967 2차 추가수정 모바일 사이즈만 적용
    @include mobile {
        .toast-message {
            $statusBarHeight:70px;
            bottom: (24px + $statusBarHeight);
        }
    }

}
