.bullet-list {
    margin-top:24px;
    color:#000;
    @include font-small;
    .b-tit {
        margin-bottom:2px;
        color:#767676;
        font-size:0;
        font-weight:700;
        line-height:0;
        @include mobile {
            margin-bottom:4px;
        }
    }
    .b-txt {
        position:relative;
        margin-top:2px;
        padding-left:11px;
        // font-weight:500;
        word-break: keep-all;
        @include mobile {
            margin-top:0;
        }
        &::before {
            content:' ';
            display:inline-block;
            width:3px;
            height:3px;
            margin-top:11px;
            margin-left:-11px;
            margin-right:8px;
            border-radius:50%;
            background:#000;
            vertical-align:top;
            @include mobile {
                margin-top:9px;
            }
        }
        &:first-child {
            margin-top:0;
        }
        .point {
            color:#ec455a;
        }
    }

    &.top-border {
        margin-top:48px;
        padding-top:24px;
        border-top:1px solid #ddd;
        @include mobile {
            margin-top:40px;
        }
    }
    &.nomargin-top {
        margin-top: 0;
    }
    &.bgGray {
        padding: 24px;
        border-radius: 8px;
        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
    }
}

.bullet-list-box {
    margin-top: 32px;
    padding: 24px 35px;
    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
    .bullet-list {
        margin-top: 0;
    }
    @include mobile {
        padding: 12px 20px;
    }
}

p.bullet-text {
    position:relative;
    margin-top:2px;
    padding-left:11px;
    @include font-small;
    @include mobile {
        margin-top:0;
    }
    &::before {
        content:' ';
        display:inline-block;
        width:3px;
        height:3px;
        margin-top: 9px;
        margin-right:8px;
        margin-left:-11px;
        border-radius:50%;
        background:$color-default;
        vertical-align:top;
        @include mobile {
            margin-top:9px;
        }
    }
}

/* BTOCSITE-98 add */
#iostxt {
    display:none;
}
/* //BTOCSITE-98 add */