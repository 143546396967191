.tooltip-wrap {
    display:inline-block;
    position:relative;
    height:24px;
    margin-top:2px;
    line-height:24px;
    vertical-align:top;
    @include mobile {
        position:static;
        margin-top:0;
        padding-top:0;
    }
    .tooltip-icon {
        display:block;
        position:relative;
        width:24px;
        height:24px;
        background:url('/lg5-common/images/icons/icon-tooltip.svg') center no-repeat;
        background-size:100%;
        font-size:0;
        line-height:16px;
        cursor:pointer;
    }
    &.active {
        .tooltip-icon::before {
            display:block;
        }
    }
    .tooltip-box {
        display:none;
        position:absolute;
        left:-8px;
        top:28px;
        min-width:460px;
        padding:32px 80px 32px 32px;
        border-radius:8px;
        background:#fff;
        text-align:left;
        box-shadow:1px 2px 10px 0 rgba(0, 0, 0, 0.22);
        z-index:90;
        p {
            @include font-small;
            font-weight:500;
        }
        .bullet-list {
            color:#000;
            font-weight:500;
            &.only {
                margin-top:0;
            }
        }
        // S : BTOCSITE-64440 [청약-2차 개선] 멤버십 포인트 차감 기능 개선의 건 (서비스구독 신청페이지)
        &.membership-box {
            .txt-list {
                padding: 0 0 16px 0;
                border-bottom: 1px solid #ddd;
                li {
                    margin-top: 4px;
                    &:first-child {
                        margin-top: 12px;
                    }
                    dl {
                        display: flex;
                        justify-content: space-between;
                        dt, dd {
                            display: inline-block;
                            padding-right: 0;
                            width: auto !important;
                            @include font-small;
                            font-weight: $font-weight-regular;
                            line-height: 20px;
                            color: $color-gray-1;
                            @include mobile {
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                        dd {
                            text-align:right;
                            @include mobile {
                                display: inline-block;
                                margin-top: 0 !important;
                            }
                        }
                    }
                }
            }
            .txt-box {
                padding: 12px 0;
                .desc {
                    @include font-small;
                    font-weight: $font-weight-regular;
                    line-height: 20px;
                    color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    .point {
                        color: $color-primary;
                    }
                    @include mobile {
                        font-size: 12px;
                        line-height: 18px;
                    }
                } 
            }
            .gray-txt-box {
                padding: 16px;
                border-radius: 8px;
                background: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                .desc {
                    @include font-small;
                    font-weight: $font-weight-regular;
                    line-height: 20px;
                    .bold {
                        font-weight: $font-weight-bold;
                    }
                }
                @include mobile {
                    padding: 14px 12px;
                    .desc {
                        font-size: 12px;
                        line-height: 18px;
                        color: #333;
                    }
                }
            }
            .bold,
            .point {
                display: inline;
            }
            @include mobile {
                left: 16px;
            }
        }
        // E : BTOCSITE-64440 [청약-2차 개선] 멤버십 포인트 차감 기능 개선의 건 (서비스구독 신청페이지)
        @include mobile {
            position:absolute;
            left:16px;
            right:16px;
            top:initial;
            bottom:initial;
            min-width:initial;
            margin-top:8px;
            padding:24px 60px 24px 24px;
            box-shadow:2px 4px 20px 0 rgba(0, 0, 0, 0.22);
        }
        &.fixed-right {
            left:auto;
            right:0;
        }
    }
    &.active {
        .tooltip-box {
            display:block;
        }
    }
    .btn-close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 32px;
        height: 32px;
        &:after {
            content: '';
            display:block;
            width:32px;
            height:32px;
            background:url('/lg5-common/images/icons/btn-close-32.svg') no-repeat;
            background-size: 100%;
        }
        @include mobile {
            top: 24px;
            right: 24px;
            width: 24px;
            height: 24px;
            &:after {
                width:24px;
                height:24px;
            }
        }
    }

    @include pc {
        [data-fixed="fixed-right"] {
            & + .tooltip-box {
                left: auto;
                right: 0;
                padding-right:70px;
            }
        }
    }

    &.share {
        height:32px;
        margin-top: 0;
        padding:4px;
        .tooltip-icon {
            width: 24px;
            height: 24px;
            background: url('/lg5-common/images/icons/icon-share-24.svg') no-repeat 0 0;
            line-height: 32px;
        }
        .tooltip-box {
            top: 34px;
            min-width: 348px;
            //padding: 32px;
            border-radius: 8px;
            box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.16);
            line-height: 0;
            @include mobile {
                min-width: 320px;
            }
        }

        .title {
            display: block;
            font-weight: 700;
            @include font-large-1;
        }
        .sns-wrap {
            margin-top: 16px;
            padding-top: 16px;
            border-top: 2px solid $color-default;
            text-align: center;
            .sns-list {
                > li {
                    display: inline-block;
                    &:not(:first-child) {
                        margin-left: 20px;
                        @include mobile {
                            margin-left: 16px;
                        }
                    }
                }
            }
        }

        [data-fixed="fixed-right"] {
            & + .tooltip-box {
                left: auto;
                right: 0;
                padding-right: 32px;
                @include mobile {
                    padding-right: 24px;
                }
            }
        }
    }
    // S : BTOCSITE-64440 [청약-2차 개선] 멤버십 포인트 차감 기능 개선의 건 (서비스구독 신청페이지)
    // new-tooltip (타이틀+콘텐츠 같이 있는 type)
    &.new-tooltip {
        height: 20px;
        line-height: 20px;
        .tooltip-icon {
            width: 16px;
            height: 16px;
            background-image: url("/lg5-common/images/icons/icon-tooltip-new.svg");
        }
        .tooltip-box {
            padding: 60px 24px 24px;
            min-width: 428px;
            &:has(.tooltip-tit) {
                padding-top: 24px;
            }
            @include mobile {
                min-width: initial;
                padding-top: 52px;
            }
            p {
                font-size: 14px;
                line-height: 20px;
                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                font-weight: 400;
            }
        }
        .tooltip-tit {
            display: block;
            font-size: 16px;
            line-height: 1.5;
            color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            font-weight: 700;
            padding-right: 24px;
            margin-bottom: 12px;
            @include mobile {
                font-size: 15px;
                line-height: 22px;
            } 
        }
        .btn-close {
            width: 24px;
            height: 24px;
            top: 24px;
            right: 24px;
            &:after {
                width: 24px;
                height: 24px;
            }
            @include mobile {
                width: 16px;
                height: 16px;
                &:after {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    // E : BTOCSITE-64440 [청약-2차 개선] 멤버십 포인트 차감 기능 개선의 건 (서비스구독 신청페이지)
}

// 요소 + tooltip-wrap 일 때 감싸주는 DOM.
.on-tooltip {
    position:relative;
    .tooltip-wrap {
        margin-left:4px;
    }
}